<script setup>

import {Head, router, useForm} from "@inertiajs/vue3";
import PageHeader from "@/Components/PageHeader.vue";
import PageContentCard from "@/Components/PageContentCard.vue";
import {computed, ref, watch} from 'vue';
import CourseParts from '@/Pages/Courses/Partials/CourseParts.vue';
import DiskUsageProgress from '@/Components/DiskUsageProgress.vue';
import ShareModal from '@/Components/modals/ShareModal.vue';
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const props = defineProps({
  course: Object,
  team: Object,
  courseStatuses: Object,
  certificates_type: Object,
  courseUsers: Object,
  students: Object,
  link: '#',
})

const form = useForm({
  course: props.course,
  filePreview: null,
});

const pageTitle = computed(() => props.course.title)

const page = ref( {
    title: pageTitle.value,
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route('dashboard'),
        },
        {
            text: "Курси",
            href: route('courses.courses'),
            active: false,
        },
        {
            text: pageTitle.value,
            active: true,
        },
    ],
})

const shareLinkModal = ref(false);
const shareLink = ref('');

function handleShare() {
  shareLinkModal.value = !shareLinkModal.value;
  shareLink.value = props.link; //TODO: get share link from course
}

const statuses = computed(() => {
  // console.log(props);
  return (props.courseStatuses && props.courseStatuses.length > 0) ? props.courseStatuses.map((item) => {
    return {value: item, text: t(item)};
  }) : [];
});

const certificateTypes = computed(() => {
  // console.log(props);
  return (props.certificates_type && props.certificates_type.length > 0) ? props.certificates_type.map((item) => {
    return {value: item, text: t(item)};
  }) : [];
});

const save = () => {
  console.log('update');
  // props.form.post(route('courses.course_update'));
  router.visit(route('courses.course_update'),
      {
        method: 'post',
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true
      }
  )
};

const onReset = () => {
  form.reset();
};

watch(
    () => form.course.title,
    (value) => {
        console.log(value)
        page.value.title = value
        page.value.breadcrumbs[page.value.breadcrumbs.length - 1].text = value
    }
    , {deep: true}
);


</script>

<template>
  <div>
    <Head :title="page.title"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>

    <PageContentCard :title="page.title">
      <template #header-end>
        <div class="header-end">
          <DiskUsageProgress/>
<!--          <b-button class="outline-dark" pill size="lg" @click="handleShare">-->
<!--            Поділитися подією-->
<!--            <i class="ph-upload-simple-bold"></i>-->
<!--          </b-button>-->
<!--          <ShareModal v-model="shareLinkModal" :shared-link="shareLink"/>-->
        </div>
      </template>

      <template #default>
        <CourseParts :course="course" :link="link" :form="form" :team="team" :statuses="statuses" :certificates_type="certificateTypes"
                     :students="students" :courseUsers="courseUsers" @save="save"/>
      </template>
    </PageContentCard>
  </div>
</template>

<style lang="scss">

</style>
