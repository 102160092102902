<script setup>
import { computed } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { useForm } from "@inertiajs/vue3";
import DateTimeSelector from "@/Components/form/DateTimeSelector.vue";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
    modelValue: Boolean,
    title: String,
    addCourseLessonProps: Object,
});

const emit = defineEmits(["update:modelValue", "add-lesson"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

// const form = {
//   title: '',
//   description: '',
//   config: {
//     date: '',
//     time: '',
//   },
//   lesson_type: 'LESSON_TYPE_WITHOUT_TRANSLATION',
//   lessonParameters: props.addCourseLessonProps
// };

const form = useForm({
    title: "",
    description: "",
    config: {
        date: "",
        time: "",
        duration: "",
    },
    lesson_type: "LESSON_TYPE_WITHOUT_TRANSLATION",
    lessonParameters: props.addCourseLessonProps,
});

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    description: {
        maxLength: helpers.withMessage(
            "Не більше 300 символів",
            maxLength(300)
        ),
    },
};

const v$ = useVuelidate(rules, form, { $lazy: true });

const addLesson = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        emit("add-lesson", form);
    }
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>{{ title }}</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <InputGroup
                label="Назва уроку"
                v-model="form.title"
                size="lg"
                class="mt-3 w-100"
                :max-length="90"
                :is-error="v$.title?.$error"
                :error-message="v$.title?.$errors[0]?.$message"
                @blur="v$.title.$touch()"
            />
            <InputGroup
                element="textarea"
                label="Опис уроку"
                v-model="form.description"
                :rows="5"
                :max-rows="10"
                :max-length="255"
                size="lg"
                class="mt-3 w-100"
                :is-error="v$.description.$error"
                :error-message="v$.description.$errors[0]?.$message"
                @blur="v$.description.$touch()"
            />
            <!--      <div class="date-time-duration__group">-->
            <!--        <InputGroup-->
            <!--            element="date"-->
            <!--            label="Дата початку"-->
            <!--            v-model="form.config.date"-->
            <!--            size="lg"-->
            <!--        />-->
            <!--        <InputGroup-->
            <!--            element="time"-->
            <!--            label="Час початку"-->
            <!--            v-model="form.config.date"-->
            <!--            size="lg"-->
            <!--        />-->
            <!--        <InputGroup-->
            <!--            element="duration"-->
            <!--            label="Тривалість"-->
            <!--            type="duration"-->
            <!--            v-model="form.config.duration"-->
            <!--            size="lg"-->
            <!--            class="w-25"-->
            <!--        />-->
            <!--      </div>-->
            <div>
                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3 float-end"
                    @click="addLesson"
                >
                    Зберегти
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped></style>
