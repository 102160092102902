<script setup>


</script>

<template>
    <div class="road-map__layout">
      <slot/>
    </div>
</template>

<style lang="scss">

.road-map__layout {

}

</style>
