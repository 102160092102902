
export function messageDate(date) {
    let now = new Date();
    let inputDate = new Date(date);

    const isToday = (now.toDateString() === inputDate.toDateString());
    const options = { hour: '2-digit', minute: '2-digit' };

    if (!isToday) {
        options.year = 'numeric';
        options.month = '2-digit';
        options.day = '2-digit';
    }

    return inputDate.toLocaleDateString(undefined, options);
}
