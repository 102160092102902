<script setup>
import TimeIntervalItem from "@/Components/Schedule/TimeIntervalItem.vue";


const props = defineProps({
  day: Object
});

const addTimeInterval = () => {
  props.day.availableHours.push({ from: '', to: ''})
}

const removeIntervalByIndex = (index) => {
  props.day.availableHours.splice(index, 1);
}

</script>

<template>
  <div
      class="week-day-item"
      :class="{ 'week-day-item--empty' : day.availableHours.length === 0 }"
  >
    <div
        v-if="day.title"
        class="week-day-item__title"
        :class="{ 'week-day-item__title--active' : day.availableHours.length > 0 }"
    >
      {{ day.title }}
    </div>

    <div v-if="day.availableHours.length === 0" class="week-day-item__not-defined">
      Не визначено
    </div>
    <div v-else class="d-flex flex-column flex-grow-1">
      <TimeIntervalItem
          v-for="(interval, index) in day.availableHours"
          :key="`interval_${index}`"
          :time-interval="interval"
          :index="index"
          @remove-by-index="removeIntervalByIndex"
      />
    </div>
    <i class="bi bi-plus week-day-item__add-time-interval" @click="addTimeInterval" />
  </div>
</template>

<style lang="scss" scoped>
  .week-day-item {
    display: flex;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 28px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--light_bg_grey, #F8FAF8);
    margin-top: 9px;
    .week-day-item__title {
      color: var(--dark, #1E1E1E);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      padding: 16px;
    }
    .week-day-item__title--active {
      color: var(--green, #1DB954);
      font-weight: 600;
    }
    &:first-child { margin-top: 0; }
    .week-day-item__add-time-interval {
      font-size: 30px;
      padding: 0;
      cursor: pointer;
    }
    .week-day-item__not-defined {
      color: var(--dark, #1E1E1E);;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
      opacity: 0.5;
      padding: 16px 0;
    }
  }
  .week-day-item--empty {
    width: fit-content;
  }
</style>
