<script setup>
import NavBar from "@/Components/NavBar.vue";
import FooterComponents from "@/Components/Footer.vue";
import { computed, ref } from "vue";
import horMenu from "./hor-menu";
import horStudentMenu from "./hor-student-menu";

import AppMenu from "@/Components/AppMenu.vue";
import RequestProgressHandler from "@/Components/RequestProgressHandler.vue";
import { usePage } from "@inertiajs/vue3";

const menuItems = ref(horMenu);
const studendMmenuItems = ref(horStudentMenu);

const page = usePage();
const user = page.props.auth.user;
const roles = page.props.auth.roles;

const filterMenuByRoles = (menu, userRoles) => {
    return menu
        .filter((item) => {
            return (
                !item.roles ||
                item.roles.some((role) => userRoles.includes(role))
            );
        })
        .map((item) => {
            if (item.actions) {
                item.actions = item.actions.filter((action) => {
                    return (
                        !action.roles ||
                        action.roles.some((role) => userRoles.includes(role))
                    );
                });
                if (item.actions.length === 0) {
                    delete item.actions;
                }
            }

            if (item.subItems) {
                item.subItems = filterMenuByRoles(item.subItems, userRoles);
            }

            return item;
        });
};

const filteredMenu = computed(() => {
    return menuItems.value && roles
        ? filterMenuByRoles(menuItems.value, roles)
        : [];
});
</script>

<template>
    <div>
        <RequestProgressHandler />
        <div id="layout-wrapper">
            <NavBar is-preview />

            <AppMenu
                :menu-items="studendMmenuItems"
                style="pointer-events: none"
            />

            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->
            <div class="main-content">
                <div class="page-content">
                    <!--          <pre>{{roles}}</pre>-->
                    <!-- Start Content-->
                    <b-container fluid>
                        <slot />
                    </b-container>
                </div>
                <FooterComponents v-if="false" />
            </div>
            <!--            <RightBar/>-->
        </div>
    </div>
    <div class="preview-info-block">Так виглядатиме ваш курс для студентів</div>
</template>
