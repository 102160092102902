<script setup>

import {Link, useForm} from '@inertiajs/vue3';
import CourseItemCard from "@/Pages/Courses/Partials/Registration/CourseItemCard.vue";
import {computed, reactive} from "vue";

const digits = reactive({
  digit1: null,
  digit2: null,
  digit3: null,
  digit4: null
})


const props = defineProps({
  course: Object,
  courseUser: Object,
  isPaid: false
});

const form = useForm({
  code: '',
  courseId: props.course.id
});

const codeComputed = computed(() => '' + (digits.digit1 ?? '') + (digits.digit2 ?? '') + (digits.digit3 ?? '') + (digits.digit4 ?? ''))

const isFinished = computed(() => codeComputed.value.length === 4)

const getInputElement = (index) => {
  return document.getElementById('digit' + index + '-input');
};

const moveToNext = (index) => {
  if (getInputElement(index).value.length === 1) {
    if (index !== 4) {
      digits['digit' + (index + 1)] = null
      getInputElement(index + 1).focus();
    } else {
      getInputElement(index).blur();
    }
  }
}

const moveToPrev = (index, event) => {
  if (event.key === 'Backspace' && getInputElement(index).value.length === 0) {
    event.preventDefault();
    digits['digit' + index] = null;
    if (index > 1) {
      getInputElement(index - 1).focus();
    }
  }
}

const onSubmit = () => {
  form.transform((data) => ({
    code: codeComputed.value,
    courseId: props.course.id,
    courseUser: props.courseUser,
  })).post(route('courses.codeVerify'));
};


</script>

<template>
  <div class="course-verification">

    <b-form class="login-form"  @submit.prevent="onSubmit">
      <div class="form-login-header text-center mt-5">
        <h1 class="text-md login-title">
          Введіть код підтввердження
        </h1>
        <p>Відправлений на korotkijbogdan@gmail.com</p>
        <div class="d-flex">
          <b-form-input type="text" v-model="digits.digit1"
                        class="form-control form-control-lg text-center"
                        placeholder="0"
                        @input="moveToNext(1)"
                        @keydown="moveToPrev(1, $event)"
                        maxLength="1"
                        id="digit1-input"
          />
          <b-form-input type="text" v-model="digits.digit2"
                        class="form-control form-control-lg text-center"
                        placeholder="0"
                        @input="moveToNext(2)"
                        @keydown="moveToPrev(2, $event)"
                        maxLength="1"
                        id="digit2-input"
          />
          <b-form-input type="text" v-model="digits.digit3"
                        class="form-control form-control-lg text-center"
                        placeholder="0"
                        @input="moveToNext(3)"
                        @keydown="moveToPrev(3, $event)"
                        maxLength="1"
                        id="digit3-input"
          />
          <b-form-input type="text" v-model="digits.digit4"
                        class="form-control form-control-lg text-center"
                        placeholder="0"
                        @input="moveToNext(4)"
                        @keydown="moveToPrev(4, $event)"
                        maxLength="1"
                        id="digit4-input"
          />

          <div v-if="form.errors.code" class="text-danger py-3 text-center">
            {{ form.errors.code }}
          </div>

        </div>
        <div class="d-flex flex-column align-items-center justify-content-end my-4">
          <b-button variant="primary" size="lg" pill type="submit" >
            Далі
          </b-button>
        </div>
        <div class="text-center">Ви зможете надіслати код повторно через: 0:59</div>
        <div class="text-center">
          <Link>Я не отримав код</Link>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex flex-column align-items-center justify-content-end">
          <b-button variant="text-variant" size="md" class="d-flex align-items-center justify-content-center" pill>
            <i class="ri-arrow-left-s-line"></i> Назад
          </b-button>
        </div>
        <div class="text-center">Вже є акаунт?
          <Link>Вхід</Link>
        </div>
      </div>
      <div class="course-registration__info">
        <CourseItemCard :course="course"/>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>
.course-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;

  .login-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: space-between;
  }

  .form-login-header {
    p {
      color: var(--gray, #C2C2C2);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
    }
  }

  .verification-digit {
    text-align: center;
    display: flex;
    padding: 28px 18px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: var(--white, #FFF);
    color: black;
  }
}
</style>
