<script setup>
import { computed, ref, watch } from "vue";
import VideoPlayer from "@/Components/VideoPlayer.vue";
import addVideo from "../../../images/svg/lesson-icons/add-video.svg";
import addFile from "../../../images/svg/lesson-icons/add-file.svg";
import PageContentCard from "@/Components/PageContentCard.vue";
import { Head, router } from "@inertiajs/vue3";
import StudentCourseModule from "@/Pages/StudentCourses/Partials/StudentCourseModule.vue";
import PageHeader from "@/Components/PageHeader.vue";

const props = defineProps({
    lesson: Object,
    course: Object,
});

const page = {
    // Актуалізувати
    title: props.course.title,
    breadcrumbs: [
        {
            text: "Всі курси",
            href: route("student.student_courses"),
        },
        {
            text: props.course.title,
            href: route("student.student_course_by_id", {
                id: props.course.id,
            }),
        },
        {
            text: props.lesson.title,
            active: true,
        },
    ],
};

const videos = computed(() =>
    props.lesson.tasks
        ? props.lesson.tasks.filter((task) => task.type === "LESSON_TASK_VIDEO")
        : []
);
const files = computed(() =>
    props.lesson.tasks
        ? props.lesson.tasks.filter((task) => task.type === "LESSON_TASK_FILE")
        : []
);

const videoStates = ref({});

watch(
    videos,
    (newVideos) => {
        newVideos.forEach((video) => {
            if (!videoStates.value.hasOwnProperty(video.id)) {
                videoStates.value[video.id] = false;
            }
        });
    },
    { immediate: true }
);

const fileTypesToOpen = ["pdf", "jpg", "jpeg", "png"];

function markComplete(lessonId) {
    router.visit(route("student.markLessonCompete"), {
        method: "post",
        data: {
            lessonId: lessonId,
        },
        preserveState: false,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: () => {
            router.replace(
                route("student.student_course_by_id", { id: props.course.id })
            );
        },
    });
}
</script>

<template>
    <div>
        <Head :title="page.title" />

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

        <PageContentCard :title="page.title">
            <template #default>
                <div class="d-flex flex-column align-items-center">
                    <!--                    <pre>{{lesson}}</pre>-->
                    <!--                    <pre>{{lesson.done}}</pre>-->
                    <b-card
                        no-body
                        class="overflow-hidden shadow-none bg-light rounded-3 mt-4 w-50"
                    >
                        <b-card-body class="student-course-module__lesson">
                            <div class="course-lesson__header">
                                <div class="course-lesson__title">
                                    {{ lesson.title }}
                                </div>
                                <div class="course-lesson__corner-element">
                                    <i
                                        v-if="lesson.done"
                                        class="bi bi-check-circle-fill"
                                    ></i>
                                </div>
                            </div>
                            <div class="course-lesson__description">
                                {{ lesson.description }}
                            </div>

                            <div
                                v-if="videos.length > 0"
                                v-for="video in videos"
                                :key="video.id"
                                class="course-lesson__video"
                            >
                                <div
                                    class="course-lesson__video--header"
                                    @click="
                                        videoStates[video.id] =
                                            !videoStates[video.id]
                                    "
                                >
                                    <div
                                        class="course-lesson__video--header-title"
                                    >
                                        <img :src="addVideo" title="" alt="" />
                                        {{ video.video.original_name }}
                                    </div>
                                    <i
                                        v-if="videoStates[video.id]"
                                        class="bi bi-caret-up-fill"
                                    ></i>
                                    <i v-else class="bi bi-caret-down-fill"></i>
                                </div>
                                <b-collapse
                                    v-model="videoStates[video.id]"
                                    class="w-100"
                                >
                                    <div
                                        v-if="
                                            !video.video.status ||
                                            video.video.status !== 'Finished'
                                        "
                                        class="video-status"
                                    >
                                        <img
                                            :src="video.video_preview"
                                            title=""
                                            alt=""
                                            class="video-status__preview"
                                        />
                                        <div class="video-status__loader">
                                            <div>
                                                <b-spinner
                                                    variant="primary"
                                                ></b-spinner>
                                            </div>
                                            <div>
                                                {{
                                                    video.video.status
                                                        ? $t(video.video.status)
                                                        : "Обробка"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <VideoPlayer
                                        v-else
                                        :video-src="video.video.path"
                                        class="mt-2"
                                    />
                                    <!--                                    <VideoPlayer-->
                                    <!--                                        v-else-->
                                    <!--                                        :previewImageLink="video.video_preview"-->
                                    <!--                                        :link="video.video.path"-->
                                    <!--                                        class="course-lesson__video&#45;&#45;player"-->
                                    <!--                                        type="default"-->
                                    <!--                                        :progress="30"-->
                                    <!--                                        :isMuted="false"-->
                                    <!--                                        :isControls="true"-->
                                    <!--                                    >-->
                                    <!--                                    </VideoPlayer>-->
                                    <div
                                        v-if="video.video.description"
                                        class="course-lesson__video--header-subtitle"
                                    >
                                        {{ video.video.description }}
                                    </div>
                                </b-collapse>
                            </div>
                            <div
                                v-if="files.length > 0"
                                v-for="file in files"
                                :key="file.id"
                                class="course-lesson__file"
                            >
                                <div class="course-lesson__file--header">
                                    <div
                                        class="course-lesson__file--header-title"
                                    >
                                        <img :src="addFile" title="" alt="" />
                                        <div>
                                            <div>
                                                <a
                                                    v-if="file.file"
                                                    :href="file.file.path"
                                                    :target="
                                                        fileTypesToOpen.includes(
                                                            file.file[
                                                                'file_type'
                                                            ]
                                                        )
                                                            ? '_blank'
                                                            : '_self'
                                                    "
                                                >
                                                    {{
                                                        file.file.original_name
                                                    }}
                                                </a>
                                                <span class="ms-2 opacity-50"
                                                    >({{
                                                        file.file.size
                                                    }})</span
                                                >
                                            </div>
                                            <div
                                                v-if="file.file.description"
                                                class="course-lesson__file--header-subtitle"
                                            >
                                                {{ file.file.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                    <div class="w-50 d-flex justify-content-end gap-2">
                        <b-button
                            type="button"
                            pill
                            class="outline-dark px-3"
                            @click="
                                router.replace(
                                    route('student.student_course_by_id', {
                                        id: course.id,
                                    })
                                )
                            "
                        >
                            Вийти з уроку
                        </b-button>
                        <b-button
                            v-if="!lesson.done"
                            type="button"
                            variant="primary"
                            pill
                            class="px-3"
                            @click="markComplete(lesson.id)"
                        >
                            Відмітити урок як пройдений
                        </b-button>
                    </div>
                </div>
            </template>
        </PageContentCard>
    </div>
</template>

<style scoped lang="scss">
.student-course-module__lesson {
    font-family: e-Ukraine;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .course-lesson__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .course-lesson__title {
            flex: 1;
            color: var(--dark, #1e1e1e);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
        .course-lesson__corner-element {
            font-size: 25px;
            color: var(--green, #1db954);
        }
    }
    .course-lesson__description {
        color: var(--dark, #1e1e1e);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        opacity: 0.5;
        cursor: pointer;
    }
    .course-lesson__video,
    .course-lesson__file {
        border-radius: 8px;
        border: 1px solid var(--stroke, #ebebeb);
        background: var(--white, #fff);
        padding: 8px;
        .course-lesson__video--header-title,
        .course-lesson__file--header-title {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1;
            img {
                width: 20px;
            }
        }
    }
    .course-lesson__video {
        .course-lesson__video--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }
        .course-lesson__video--player {
            margin-top: 8px;
            width: 100%;
            border-radius: 8px;
        }
        .video-status {
            margin-top: 8px;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            background: #f7f7f8;
            min-height: 150px;
            .video-status__preview {
                width: 100%;
                opacity: 25%;
            }
            .video-status__loader {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                padding: 16px;
                border-radius: 8px;
                background-color: #fff;
            }
        }
        .course-lesson__video--header-subtitle {
            color: var(--dark, #1e1e1e);
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            opacity: 50%;
            margin: 16px 0;
        }
    }
    .course-lesson__file {
        .course-lesson__file--header-title {
            align-items: flex-start;
            & > div {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
            .course-lesson__file--header-subtitle {
                color: var(--dark, #1e1e1e);
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
                opacity: 50%;
            }
        }
    }
}
</style>
