<script setup>
import { computed, ref } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
    modelValue: Boolean,
    user: Object,
    emailWithTenant: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue", "addUser"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const form = ref({
    // name: '',
    email: "",
    // phone: ''
});

// Емейл - блок
// ПІБ - вводить
// телефон - вводить
// Професія вводить не обовязкове
// Додаткова інфа - вводить не обовязкове

//
// onUnmounted(
//     () => {
//       if (user) {
//         form.name = user.name;
//         form.email = user.email;
//         form.phone = user.phone;
//       }
//     }
// );

const handleSend = () => {
    v$.value["email"].$touch();
    console.log(!v$.value.$invalid);
    console.log(!notNewEmail.value);

    if (!v$.value.$invalid && !notNewEmail.value) {
        emit("addUser", form.value);
        isVisible.value = !isVisible.value;
    }
};

const rules = {
    email: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        email: helpers.withMessage("Введіть дійсну електронну адресу", email),
    },
};

const v$ = useVuelidate(rules, form.value, { $lazy: true });

const notNewEmail = ref(false);
const isVeryfy = ref(false);
const disableButton = ref(true);

const checkEmail = () => {
    v$.value["email"].$touch();
    if (!v$.value["email"].$invalid && form.value.email.length > 0) {
        (async () => {
            const result = await verifyAvailability();
            notNewEmail.value = result ? result : false;
            disableButton.value = result;
        })();
    } else notNewEmail.value = false;
};

let debounceTimer = null;

const debouncedCheckEmail = () => {
    disableButton.value = true;
    if (debounceTimer) {
        clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(checkEmail, 500);
};

const verifyAvailability = async () => {
    let verifyProps = { column: "email", text: form.value.email };
    if (props.emailWithTenant) verifyProps.tenant = props.emailWithTenant;
    isVeryfy.value = true;
    try {
        const response = await axios.post(
            "/verify-student-availability",
            verifyProps
        );
        isVeryfy.value = false;
        return response.data.exists;
    } catch (error) {
        isVeryfy.value = false;
        return false;
    }
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Запросити студента</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <!--      <b-form-group class="" label="Ім'я">-->
            <!--        <b-form-input v-model="form.name" type="text"></b-form-input>-->
            <!--      </b-form-group>-->
            <!--      <b-form-group class="" label="Адереса Ел. Пошти">-->
            <!--        <b-form-input v-model="form.email" type="text"></b-form-input>-->
            <!--      </b-form-group>-->
            <!--      <b-form-group class="" label="Телефон">-->
            <!--        <b-form-input v-model="form.phone" type="text"></b-form-input>-->
            <!--      </b-form-group>-->

            <InputGroup
                label="Введіть еmail студента"
                v-model="form.email"
                size="lg"
                class="modal__input-group"
                :is-loading="isVeryfy"
                :is-error="v$.email.$invalid || notNewEmail"
                :error-message="
                    notNewEmail
                        ? 'Ця електронна пошта вже зареєстрована!'
                        : v$.email?.$errors[0]?.$message
                "
                @input="debouncedCheckEmail"
            />
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3"
                    :disabled="disableButton"
                    @click="handleSend"
                >
                    Додати
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped>
.modal-body {
    display: flex;
    flex-direction: column;
    gap: 28px;
}
</style>
