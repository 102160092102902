<script setup>
import { computed } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { LESSON_TYPE_WITH_TRANSLATION } from "@/Pages/Courses/Partials/lessonTypes.js";
import { useForm } from "@inertiajs/vue3";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
    modelValue: Boolean,
    title: String,
    addCourseLessonProps: Object,
});

const emit = defineEmits(["update:modelValue", "add-lesson"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const form = useForm({
    title: "",
    description: "",
    config: {
        date: "",
        time: "",
        duration: "",
    },
    lesson_type: "LESSON_TYPE_WITH_TRANSLATION",
    lessonParameters: props.addCourseLessonProps,
});

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    description: {
        maxLength: helpers.withMessage(
            "Не більше 300 символів",
            maxLength(300)
        ),
    },
    config: {
        date: {
            required: helpers.withMessage("Це поле є обов'язковим", required),
        },
        duration: {
            required: helpers.withMessage("Це поле є обов'язковим", required),
        },
    },
};

const v$ = useVuelidate(rules, form, { $lazy: true });

const addLesson = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        emit("add-lesson", form);
    }
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
        class="add-lesson-modal"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>{{ title }}</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <InputGroup
                label="Назва уроку"
                v-model="form.title"
                size="lg"
                class="mt-3 w-100"
                :max-length="90"
                :is-error="v$.title?.$error"
                :error-message="v$.title?.$errors[0]?.$message"
                @blur="v$.title.$touch()"
            />
            <InputGroup
                element="textarea"
                label="Опис уроку"
                v-model="form.description"
                :rows="5"
                :max-rows="10"
                :max-length="255"
                size="lg"
                class="mt-3 w-100"
                :is-error="v$.description.$error"
                :error-message="v$.description.$errors[0]?.$message"
                @blur="v$.description.$touch()"
            />
            <div class="my-3 card__title">Налаштування ефіру</div>
            <div class="date-time-duration__group">
                <InputGroup
                    element="date"
                    label="Дата початку"
                    v-model="form.config.date"
                    size="lg"
                    :is-error="v$.config.date?.$error"
                    :error-message="v$.config.date?.$errors[0]?.$message"
                    @blur="v$.config.date.$touch()"
                />
                <InputGroup
                    element="time"
                    label="Час початку"
                    v-model="form.config.date"
                    size="lg"
                    :is-error="v$.config.date?.$error"
                    :error-message="v$.config.date?.$errors[0]?.$message"
                    @blur="v$.config.date.$touch()"
                />
                <InputGroup
                    element="duration"
                    label="Тривалість"
                    type="duration"
                    v-model="form.config.duration"
                    size="lg"
                    :is-error="v$.config.duration?.$error"
                    :error-message="v$.config.duration?.$errors[0]?.$message"
                    @blur="v$.config.duration.$touch()"
                />
            </div>
            <!--            <div class="d-flex my-3 gap-2 justify-content-between">-->
            <!--                <div>-->
            <!--                    <b-form-checkbox-->
            <!--                        v-model="form.config.is_chat_write"-->
            <!--                        unchecked-value="false"-->
            <!--                    >-->
            <!--                        Дозволити писати в чаті-->
            <!--                    </b-form-checkbox>-->
            <!--                    <b-form-checkbox-->
            <!--                        v-model="form.config.is_video_record"-->
            <!--                        unchecked-value="false"-->
            <!--                    >-->
            <!--                        Зробити запис відео-->
            <!--                        <i id="tooltip-record" class="bi bi-info-circle"></i>-->
            <!--                    </b-form-checkbox>-->
            <!--                    <b-tooltip-->
            <!--                        target="tooltip-record"-->
            <!--                        custom-class="custom-tooltip"-->
            <!--                        offset="5"-->
            <!--                    >-->
            <!--                        Трансляція буде збережена на<Br /> курсі. Студенти в-->
            <!--                        будь-який час<Br /> зможуть переглянути її повторно в<Br />-->
            <!--                        режимі запису.-->
            <!--                    </b-tooltip>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                    <b-form-checkbox-->
            <!--                        v-model="form.config.is_chat_record"-->
            <!--                        unchecked-value="false"-->
            <!--                    >-->
            <!--                        Записувати чат-->
            <!--                    </b-form-checkbox>-->
            <!--                    <b-form-checkbox-->
            <!--                        v-model="form.config.is_save_to_auto_webinar"-->
            <!--                        unchecked-value="false"-->
            <!--                    >-->
            <!--                        Зберегти запис до автовебінарів-->
            <!--                        <i-->
            <!--                            id="tooltip-save-record"-->
            <!--                            class="bi bi-info-circle"-->
            <!--                        ></i>-->
            <!--                    </b-form-checkbox>-->
            <!--                    <b-tooltip-->
            <!--                        target="tooltip-save-record"-->
            <!--                        custom-class="custom-tooltip"-->
            <!--                        offset="5"-->
            <!--                    >-->
            <!--                        Трансляція буде збережена в<Br /> розділ-->
            <!--                        <span class="text-primary">Cloud</span>. Ви зможете-->
            <!--                        подавати<Br /> даний матеріал повторно, як урок,<Br />-->
            <!--                        що не прив’язаний до курсу-->
            <!--                    </b-tooltip>-->
            <!--                </div>-->
            <!--            </div>-->
            <div>
                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3 float-end"
                    @click="addLesson"
                >
                    Зберегти
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss">
.add-lesson-modal {
    .modal-dialog {
        max-width: 620px !important;
    }
}
</style>
