<script setup>
import { computed, ref } from "vue";
import CommentField from "@/Components/form/CommentField.vue";

const props = defineProps({
    modelValue: Boolean,
    comment: String,
});

const emit = defineEmits(["update:modelValue", "commentUpdate"]);

const commentText = ref(props.comment);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Нотатки</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <CommentField
                v-model="commentText"
                @comment-update="emit('commentUpdate', commentText)"
            />
        </div>
    </b-modal>
</template>
<style lang="scss" scoped></style>
