<script setup>
import {
    LESSON_TYPE_WITH_TRANSLATION,
    LESSON_TYPE_WITHOUT_TRANSLATION,
} from "./lessonTypes.js";
import { ref } from "vue";
import ModuleLessonItem from "@/Pages/Courses/Partials/ModuleLessonItem.vue";
import InputGroup from "@/Components/InputGroup.vue";
import CourseLessonsItem from "@/Pages/Courses/Partials/CourseLessonsItem.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const isLessonsVisible = ref(false);

const titleEdit = ref(false);

const props = defineProps({
    module: Object,
    active: Boolean,
    key: Number,
    editing: Object,
    courseStatus: String
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const emit = defineEmits([
    "delete-module",
    "delete-lesson",
    "add-lesson",
    "edit-lesson",
    "module-title-edit",
    "module-up",
    "module-down",
    "lesson-up",
    "lesson-down",
]);

const addLesson = (type) => {
    // emit(LESSON_ACTION_EMIT, {action: 'add', type: type});
    emit("add-lesson", { type: type });
};

const editLesson = (id) => {
    emit("edit-lesson", { id: id });
};

const deleteLesson = (id) => {
    console.log("coursemoduleitem delete");
    deleteLessonModal.value = true;
    tmpLessonId.value = id;
    // emit('delete-lesson', {action: 'delete', id: id});
};

const deleteModule = () => {
    deleteModuleModal.value = true;

    //emit('delete-module', props.module.id);
};

const endEdit = () => {
    if (!isReadOnly) {
        titleEdit.value = !titleEdit.value;
        if (!titleEdit.value) {
            emit("module-title-edit", props.module);
        }
    }
};
const moduleUp = () => {
    emit("module-up", props.module.id, "up", 1);
};
const moduleDown = () => {
    emit("module-down", props.module.id, "down", 1);
};
const lessonUp = (lessonId, order, position) => {
    emit("lesson-up", lessonId, order, position);
};
const lessonDown = (lessonId, order, position) => {
    emit("lesson-down", lessonId, order, position);
};

const deleteModuleModal = ref(false);
const deleteLessonModal = ref(false);
const tmpLessonId = ref(null);
</script>

<template>
    <b-card
        v-if="module.module_type === 'MULTI_LESSON'"
        no-body
        class="shadow-none course-module"
    >
        <b-card-body
            class="d-flex flex-column bg-light p-3 course-module__body"
        >
            <b-row no-gutters class="w-100 d-flex justify-content-end">
                <b-col cols="9" class="d-flex flex-column gap-2">
                    <div
                        class="course-module__type d-flex align-items-center gap-2"
                    >
                        Модуль
                    </div>
                    <div class="course-module__title d-flex align-items-center">
                        <span v-if="!titleEdit" @click="endEdit">{{
                            module.title
                        }}</span>
                        <!--                        <InputGroup-->
                        <!--                            v-else-->
                        <!--                            v-model="module.title"-->
                        <!--                            class="course-module__title&#45;&#45;edit"-->
                        <!--                        />-->
                        <input
                            v-else
                            type="text"
                            v-model="module.title"
                            class="course-module__title--edit"
                        />
                        <button
                            v-if="titleEdit"
                            class="course-module__title--save"
                            @click="endEdit"
                        >
                            Зберегти
                        </button>
                    </div>
                </b-col>
                <b-col cols="3" class="text-right">
                    <div
                        class="d-flex align-items-center justify-content-end w-100"
                    >
                        <!--            <div class="course-module__label">Модуль</div>-->
                        <div class="dropdown flex-shrink-0">
                            <b-dropdown
                                size="lg"
                                variant="link"
                                :disabled="isReadOnly"
                                toggle-class="text-decoration-none text-black"
                                no-caret
                            >
                                <template #button-content>
                                    <i class="bi bi-three-dots-vertical"></i>
                                </template>
                                <!-- <b-dropdown-text>Порядковість</b-dropdown-text> -->
                                <b-dropdown-item @click="moduleUp">
                                    <i class="bi bi-arrow-up"></i>
                                    Пересунути вгору
                                </b-dropdown-item>
                                <b-dropdown-item @click="moduleDown">
                                    <i class="bi bi-arrow-down"></i>
                                    Пересунути вниз
                                </b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item @click="deleteModule">
                                    <i
                                        class="bi bi-trash3 align-baseline me-1"
                                    ></i>
                                    Видалити
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="dropdown flex-shrink-0">
                            <b-button
                                id="add-lesson-button"
                                variant="danger"
                                class="red-plus-button"
                                :disabled="isReadOnly"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i class="bx bx-plus"></i>
                            </b-button>

                            <ul class="dropdown-menu dropdown-menu-end">
                                <li
                                    @click="
                                        () =>
                                            addLesson(
                                                LESSON_TYPE_WITH_TRANSLATION
                                            )
                                    "
                                >
                                    <span class="dropdown-item">
                                        Урок з прямим ефіром
                                    </span>
                                </li>
                                <li
                                    @click="
                                        () =>
                                            addLesson(
                                                LESSON_TYPE_WITHOUT_TRANSLATION
                                            )
                                    "
                                >
                                    <span
                                        class="dropdown-item d-flex align-items-center"
                                    >
                                        Урок без прямого ефіру
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row
                no-gutters
                v-if="module.lessons && module.lessons.length > 0"
                class="w-100 d-flex"
            >
                <b-col cols="12">
                    <div
                        class="course-module__lessons-title cursor-pointer"
                        @click="isLessonsVisible = !isLessonsVisible"
                    >
                        Уроки ({{ module.lessons.length }})
                        <i v-if="isLessonsVisible" class="ph-caret-up-fill"></i>
                        <i v-else class="ph-caret-down-fill"></i>
                    </div>
                    <b-collapse v-model="isLessonsVisible" class="w-100 mt-2">
                        <div class="course-module__lessons">
                            <ModuleLessonItem
                                v-for="lesson in module.lessons"
                                :lesson="lesson"
                                :editing="editing"
                                :disabled="isReadOnly"
                                :course-status="courseStatus"
                                @delete-lesson="deleteLesson"
                                @edit-lesson="editLesson"
                                @lesson-up="lessonUp"
                                @lesson-down="lessonDown"
                            />
                        </div>
                    </b-collapse>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>

    <template v-else v-for="item of module.lessons">
        <CourseLessonsItem
            :lesson="item"
            :editing="editing"
            :disabled="isReadOnly"
            :course-status="courseStatus"
            @edit-lesson="(lesson_id) => editLesson(lesson_id.id)"
            @delete-lesson="() => deleteLesson(item.id)"
            @delete-task="(id) => handleDeleteTask(id)"
            @module-up="moduleUp"
            @module-down="moduleDown"
            @lesson-up="lessonUp"
            @lesson-down="lessonDown"
        />
    </template>
    <ConfirmModal
        v-model="deleteModuleModal"
        title="Видалення модуля"
        decline-label="Скасувати"
        accept-label="Так, видалити"
        @accept-btn="() => emit('delete-module', module.id)"
        @decline-btn="() => console.log('decline')"
    >
        <template v-slot:description>
            Після видалення модуля його не можна буде повернути. Ви впевнені, що
            хочете продовжити?
        </template>
    </ConfirmModal>
    <ConfirmModal
        v-model="deleteLessonModal"
        title="Видалення уроку"
        decline-label="Скасувати"
        accept-label="Так, видалити"
        @accept-btn="
            () => emit('delete-lesson', { action: 'delete', id: tmpLessonId })
        "
        @decline-btn="() => console.log('decline')"
    >
        <template v-slot:description>
            Після видалення уроку його не можна буде повернути. Ви впевнені, що
            хочете продовжити?
        </template>
    </ConfirmModal>
    <b-tooltip
        target="add-lesson-button"
        custom-class="webinar-tooltip"
        offset="5"
    >
        Додати новий урок
    </b-tooltip>
</template>

<style lang="scss" scoped>
.course-module__title--icon {
    color: var(--green, #1db954);
}

.course-module {
    border-radius: 8px;
    outline: 3px solid transparent;
    outline-offset: -3px;
    position: relative;

    &::before {
        content: "";
        width: 8px;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 8px 0 0 8px;
        background: var(--green, #1db954);
    }

    &::after {
        content: "";
        width: 8px;
        height: 100%;
        position: absolute;
        left: 3px;
        border-radius: 8px 0 0 8px;
        background: #f7f7f8;
    }

    &:hover {
        outline: 3px solid var(--green, #1db954);

        &::after,
        &::before {
            content: "";
            display: none;
        }
    }

    .course-module__type {
        color: var(--green, #1db954);
    }
}
</style>
