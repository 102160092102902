<template>
    <div>
        <Head :title="page.title"/>

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

        <b-card no-body>
            <b-card-header class="border-bottom d-flex flex-row justify-content-between pb-3">
                <div class="d-flex flex-row align-items-center justify-content-between-md">
                    <h2 class="my-0">UI  Style Guidelines</h2>
                </div>
                <div class="">
                    <b-button class="outline-dark" pill size="lg">
                        Створити
                        <i class="ph-plus-thin"></i>
                    </b-button>
                </div>
            </b-card-header>
            <b-card-body>
                <b-tabs nav-class="nav-tabs-custom nav-success mb-3" >
                    <b-tab title="Таб 1" active>
                        Контент 1
                    </b-tab>
                    <b-tab title="Таб 2">
                        Контент 2
                    </b-tab>
                    <b-tab title="Таб 3">
                        Контент 3
                    </b-tab>
                </b-tabs>

                <b-row class="mt-4">
                    <b-col cols="2">
                        <b-form-select
                            v-model="status"
                            :placeholder="''"
                            :options="statuses"
                            size="xs"
                            v-uid
                        />
                    </b-col>
                    <b-col cols="2">
                        <b-form-select
                            v-model="status"
                            :placeholder="''"
                            :options="statuses"
                            size="sm"
                            v-uid
                        />
                    </b-col>
                    <b-col cols="2">
                        <b-form-select
                            v-model="status"
                            :placeholder="''"
                            :options="statuses"
                            size="md"
                            v-uid
                        />
                    </b-col>
                    <b-col cols="2">
                        <b-form-select
                            v-model="status"
                            :placeholder="''"
                            :options="statuses"
                            size="xl"
                            ref="input"
                            v-uid
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-4 bg">
                    <b-col cols="2">
                        <InputGroup
                            label="Назва курсу"
                            v-model="inputModel"
                            size="lg"
                            class="w-100"
                        />
                    </b-col>
                    <b-col cols="2">

                    </b-col>
                    <b-col cols="2">

                    </b-col>
                    <b-col cols="2">

                    </b-col>
                </b-row>

                <b-row class="mt-4">
                    <b-col cols="12">
                        <b-button class="outline-dark" pill size="sm">
                            Створити
                        </b-button>

                        <b-button class="outline-dark ms-2" pill size="md" >
                            Створити
                        </b-button>

                        <b-button class="outline-dark ms-2" pill size="lg" >
                            Створити
                        </b-button>

                        <b-button class="outline-dark ms-2" size="sm" >
                            Створити
                        </b-button>

                        <b-button class="outline-dark ms-2" size="md" >
                            Створити
                        </b-button>

                        <b-button class="outline-dark ms">
                            Створити
                        </b-button>

                        <b-button variant="dark" size="sm" class="ms-2">
                            Створити
                        </b-button>

                        <b-button variant="dark" size="md" class="ms-2">
                            Створити
                        </b-button>

                        <b-button variant="dark" size="lg" class="ms-2">
                            Створити
                        </b-button>

                        <b-button variant="link" size="sm" class="ms-2">
                            Створити
                        </b-button>

                        <b-button variant="link" size="md" class="ms-2">
                            Створити
                        </b-button>

                        <b-button variant="link" size="lg" class="ms-2">
                            Створити
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="mt-4">
                    <b-col cols="2">

                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="2">

                    </b-col>
                </b-row>

                <b-row class="mb-4">
                    <b-col cols="2">

                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script setup>

import {Head} from "@inertiajs/vue3";
import PageHeader from "@/Components/PageHeader.vue";
import {ref} from "vue";
import InputGroup from "@/Components/InputGroup.vue";

const statuses = [
    { value: null, text: 'Всі' },
    { value: 'draft', text: 'Чернетка' },
    { value: 'published', text: 'Опублікований' },
    { value: 'archived', text: 'Архівний' },
];

const status = ref(null)
const inputModel = ref(null)

const page = {
    title: "UI Style Guidelines",
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route('dashboard'),
        },
        {
            text: "UI Style Guidelines",
            active: true,
        },
    ],
}

</script>

<style scoped>

</style>
