<script setup>
import {Head, Link} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";
import StatusBage from "@/Components/StatusBage.vue";
import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import noImage from "../../../images/no-image.png";
import {useDateFormatter, useWorkshopStatus} from "@/composables/useDateFormatter.js";
import MeetingButton from "@/Components/buttons/MeetingButton.vue";

const props = defineProps({
  user: Object,
  workshops: Object,
})

const {t} = useI18n();
const { formatDate } = useDateFormatter()
const { getWorkshopStatus } = useWorkshopStatus()

const page = {
  title: t('t-workshops'),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route('dashboard'),
    },
    {
      text: "Воркшопи",
      active: true,
    },
  ],
}

function generateViewLink(type, id) {
    return route('workshops.edit', {"type": type.toLowerCase(), id: id}); //TODO: link for view
}

</script>

<template>
  <div>
    <Head :title="page.title"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>

    <PageContentCard :title="$t('t-workshops')">
      <template #default>
          <div class="module-table workshops-list">
              <div class="module-table__header border-bottom pb-2">
                  <div class="module-table__col module-table__col--title text-uppercase opacity-50">
                      {{ $t("t-broadcast") }}
                  </div>
                  <div class="module-table__col module-table__col--date text-uppercase opacity-50">
                      {{ $t("t-date") }}
                  </div>
                  <div class="module-table__col module-table__col--status text-uppercase opacity-50">
                      {{ $t("t-status") }}
                  </div>
                  <div class="module-table__col module-table__col--type text-uppercase opacity-50">
                      {{ $t("t-type") }}
                  </div>
<!--                  <div class="module-table__col module-table__col&#45;&#45;actions opacity-50">-->

                        <!--                  </div>-->
                    </div>
                    <div
                        v-for="workshop of workshops.data"
                        :key="workshop.id"
                        class="module-table__row border-bottom py-3"
                    >
                        <div class="module-table__col module-table__col--title">
                            <div class="module-table__title-image">
                                <b-img :src="workshop.image ? workshop.image : noImage"
                                       :title="workshop.title" :alt="workshop.title" rounded fluid></b-img>
                            </div>
                            <div class="module-table__title-text">
                                <span class="table-col__title">{{ workshop.title }}</span>
                                <span class="table-col__subtitle">{{
                                        workshop.description && workshop.description.length > 0 ? workshop.description : 'без опису'
                                    }}</span>
                            </div>
                        </div>
                        <div class="module-table__col module-table__col--date">
                            <span class="table-col__time">{{ formatDate(workshop.date, "HH:mm") }}</span>
                            <span class="table-col__day">{{ formatDate(workshop.date, "d MMMM yyyy") }}</span>
                        </div>
                        <div class="module-table__col module-table__col--status">
                            <StatusBage :type="getWorkshopStatus(workshop).status"></StatusBage>
                            <b-button v-if="getWorkshopStatus(workshop).showLiveButton" class="workshop-join__btn"
                                      :href="workshop.link" target="_blank">Приєднатися
                            </b-button>
                            <b-button v-if="workshop.type === 'MASTERCLASS'" class="masterclass-view__btn"
                                      :href="workshop.link" target="_blank"
                            >Переглянути
                            </b-button>
                        </div>
                        <div class="module-table__col module-table__col--type">
                            {{ workshop.type === 'AUTO_WEBINAR' ? $t('WEBINAR') : $t(workshop.type) }}
                        </div>
                        <!--                  <div class="module-table__col module-table__col&#45;&#45;actions">-->
                        <!--                      <MeetingButton :link="workshop.link" tooltip-text="Кімната зустрічі"/>-->
                        <!--                  </div>-->
                    </div>
                </div>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
.workshops-list {
    .module-table__col--title {
        display: flex;
        width: 55%;
        align-items: center;
        padding-right: 16px;

        @media (max-width: 992px) {
            width: 100%;
        }

        .module-table__title-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6px;
            flex-wrap: nowrap;
            padding: 10px 16px;
            width: calc(100% - 125px);

            .table-col__title {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                color: #1E1E1E;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            .table-col__subtitle {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 200;
                line-height: 1.5;
                letter-spacing: 0;
                text-align: left;
                color: #1e1e1e;
                opacity: 0.5;
                display: -webkit-box ;
                display: -moz-box;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .module-table__col--date {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 10%;

        @media (max-width: 992px) {
            width: 50%;
        }
    }

    .module-table__col--status {
        width: 135px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        .workshop-join__btn {
            width: fit-content;
            padding: 4px 10px;
            border-radius: 45px;
            background: #DA1414;
            border: 1px solid #DA1414;
            font-family: e-Ukraine;
            font-size: 11px;
            font-weight: 300;
            line-height: 15.4px;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            gap: 6px;
            &::before {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: #FFFFFF;
            }
        }
        .masterclass-view__btn {
            width: 100%;
            padding: 4px 10px;
            border-radius: 45px;
            background: var(--green, #1db954);
            border: 1px solid var(--green, #1db954);
            font-family: e-Ukraine;
            font-size: 11px;
            font-weight: 300;
            line-height: 15.4px;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        @media (max-width: 992px) {
            width: 50%;
        }
    }

    .module-table__col--participants {
        width: 10%;
        display: flex;
        align-items: center;

        font-family: e-Ukraine;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;

        i {
            font-size: large;
            opacity: 0.5;
        }
    }

    .module-table__col--actions {
        width: 50px!important;
        i {
            font-size: large;
        }

    }

    .module-table__col--type {
        width: 10%;
        font-family: e-Ukraine;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;
    }

    .module-table__title-image {
        width: 128px;
        height: 72px;
        border-radius: 8px;
        border: 1px solid #EBEBEB;
        background: #F7F7F8;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 128px;
            max-height: 72px;
        }
    }
}
</style>
