<script setup>
import {computed, ref, triggerRef, watch} from "vue";
import {useI18n} from "vue-i18n";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";

const props = defineProps({
  modelValue: Boolean,
  speakers: Array,
  team: Object,
  type: {
    type: String,
    default: 'PRESENTER'
  }
});

const selectedUsers = ref([])

const emit = defineEmits([
  'onSave',
  'update:modelValue',
]);

const isVisible = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const {t} = useI18n();

watch(isVisible, (newValue, oldValue) => {
  if (newValue) selectedUsers.value = props.speakers.filter(speaker => speaker && speaker.role === props.type).map((el) => el.id)[0];
  else selectedUsers.value = [];
});

const handleUser = (id) => {
  selectedUsers.value = id !== selectedUsers.value ?  id : null;
  triggerRef(selectedUsers);
}
const handleSave = () => {
  emit('onSave', { id: selectedUsers.value, type: props.type });
  isVisible.value = !isVisible.value;
}

const excludeMember = computed(() => props.speakers.filter(speaker => speaker && speaker.role === (props.type === 'MODERATOR' ? 'PRESENTER' : 'MODERATOR')).map(speaker => speaker.id))

const filteredTeam = computed(() => props.team.filter(member => !excludeMember.value.includes(member.id) ))


</script>

<template>
  <b-modal v-model="isVisible" hide-footer hide-header centered no-close-on-backdrop>
    <div class="modal-body">
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="mb-3">{{t('t-add')}} {{ type === 'MODERATOR' ? 'модератора' : 'спікера'}}</h3>
          <h3 class="mb-3 fs-2 cursor-pointer"><i class="bi bi-x-lg" @click="isVisible = !isVisible"></i></h3>
        </div>
        <perfect-scrollbar v-if="filteredTeam?.length > 0">
          <div class="my-team-modal__user-list">
            <div
                v-for="user in filteredTeam"
                class="my-team-modal__user"
                :class="{'my-team-modal__user--selected': selectedUsers === user.id}"
                @click="handleUser(user.id)"
            >
              <div class="d-flex align-items-center">
                <b-avatar variant="light" :src="user.image ? user.image : noAvatar" class="my-team-modal__logo"></b-avatar>
                <span class="my-team-modal__name">{{ user.name }}</span>
              </div>
              <span class="my-team-modal__email">{{ user.email }}</span>
            </div>
          </div>
        </perfect-scrollbar>
        <div v-else>{{t('t-empty-team')}}</div>
        <div class="hstack gap-2 justify-content-end my-2">
          <b-button class="outline-dark" pill size="lg" @click="handleSave">
              {{t('t-save')}}
              <i class="ph-plus"></i>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.my-team-modal__user-list {
  height: 350px;

  .my-team-modal__user {
    padding: 16px;
    border-bottom: 1px solid var(--Grey, #C2C2C2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background: color-mix(in srgb, var(--tb-module-table-bage-bg), transparent 60%);
    }

    &:last-child {
      border: none;
    }

    .my-team-modal__logo {
      margin-right: 16px;
    }

    .my-team-modal__name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .my-team-modal__email {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      opacity: 0.5;
      text-overflow: ellipsis;
    }
  }

  .my-team-modal__user--selected {
    //background: var(--tb-module-table-bage-bg);
    background: color-mix(in srgb, var(--green, #1DB954), transparent 75%);

    &:hover {
      //background: color-mix(in srgb, var(--tb-module-table-bage-bg), transparent 25%);
      background: color-mix(in srgb, var(--green, #1DB954), transparent 50%);
    }
  }
}
</style>
