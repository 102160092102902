<script setup>
import { computed, ref } from "vue";
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";
import { router } from "@inertiajs/vue3";
import VideoPlayer from "@/Components/VideoPlayer.vue";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import InputGroup from "@/Components/InputGroup.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";

const props = defineProps({
    modelValue: Object,
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    "update:modelValue",
    "videoUploadComplete",
    "remove-lesson-file",
]);
const video = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const removeVideo = (id) => {
    console.log("removeVideo", id);
    console.log(router.page.url);
    router.visit(route("courses.remove_lesson_file"), {
        method: "post",
        data: { redirect: router.page.url, lessonTaskId: props.modelValue.id },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
            props.form.course.contentItems = reponse.props.course.contentItems;
        },
    });
};

const collapsePlayer = ref(false);

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formattedTime = "";

    if (hours > 0) {
        formattedTime += `${hours} год. `;
    }

    if (minutes > 0) {
        formattedTime += `${minutes} хв. `;
    }

    if (remainingSeconds > 0 || formattedTime === "") {
        formattedTime += `${remainingSeconds} сек.  `;
    }

    return formattedTime.trim();
}

const removeModal = ref(false);
</script>

<template>
    <div class="video-lesson">
        <div class="video-lesson__main">
            <div class="video-lesson__player">
                <div v-if="!video.video.status || video.video.status !== 'Finished'" class="video-lesson__player--processing">
                    <img
                        :src="video.video_preview"
                        title=""
                        alt=""
                        class=""
                    />
                    <div class="video-status__loader">
                        <div>
                            <b-spinner variant="primary"></b-spinner>
                        </div>
                        <div>
                            {{ video.video.status ? $t(video.video.status) : "Обробка" }}
                        </div>
                    </div>
                </div>
                <VideoPlayer v-else :video-src="video.video.path" />
            </div>
            <div class="video-lesson__form">
                <InputGroup
                    v-model="video.video.original_name"
                    label="Назва відео"
                    :disabled="disabled"
                    size="lg"
                    class="video-lesson__form-name"
                />
                <InputGroup
                    element="textarea"
                    v-model="video.video.description"
                    label="Опис відео"
                    :rows="2"
                    :max-rows="2"
                    :maxLength="255"
                    :disabled="disabled"
                    size="lg"
                    class="video-lesson__form-description"
                />
            </div>
        </div>
<!--        <div class="video-lesson__preview">-->
<!--            preview-button-->
<!--        </div>-->
        <div class="video-lesson__footer">
            <div class="video-lesson__footer--info">
                <div class="video-lesson__size">
                    Розмір: <span>{{ video.video.size }}</span>
                </div>
                <div v-if="video.video_length && video.video_length > 0" class="video-lesson__duration">
                    Тривалість: <span>{{ formatTime(video.video_length) }}</span>
                </div>
            </div>
            <div class="video-lesson__action">
                <button
                    :disabled="disabled"
                    @click="removeModal = !removeModal"
                >
                    <i class="bi bi-trash3"></i>
                </button>
                <ConfirmModal
                    v-model="removeModal"
                    title="Видалити відео"
                    decline-label="Скасувати"
                    accept-label="Так, видалити"
                    @accept-btn="() => emit('remove-lesson-file', video)"
                    @decline-btn="() => console.log('decline')"
                >
                    <template v-slot:description>
                        Видаливши відео, його не можна буде повернути. Видалити відео?
                    </template>
                </ConfirmModal>
            </div>
        </div>
    </div>
</template>

<style>
.video-lesson {
    font-family: e-Ukraine;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--tb-card-bg);
    border-radius: 8px;
    padding: 1.2rem;
    margin-bottom: 16px;
    .video-lesson__main {
        display: flex;
        gap: 24px;
        .video-lesson__player {
            width: 256px;
            height: 144px;
            border-radius: 8px;
            background: #f7f7f8;
            overflow: hidden;
            .video-js {
                width: 256px!important;
                height: 144px!important;
            }
            .video-lesson__player--processing {
                position: relative;
                width: 256px!important;
                height: 144px!important;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    opacity: 25%;
                }
                .video-status__loader {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    padding: 16px;
                    border-radius: 8px;
                    background-color: #fff;
                }
            }
        }
        .video-lesson__form {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .video-lesson__form-name, .video-lesson__form-description {
                margin: 0!important;
            }
        }
    }
    .video-lesson__preview {
        width: 256px;
    }
    .video-lesson__footer {
        display: flex;
        gap: 24px;
        .video-lesson__footer--info {
            width: 256px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            .video-lesson__size, .video-lesson__duration {
                font-family: e-Ukraine;
                font-size: 10px;
                font-weight: 300;
                line-height: 10px;
                text-align: left;
                color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
                span {
                    font-family: e-Ukraine;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 12px;
                    text-align: left;
                    color: var(--dark, #1E1E1E);
                }
            }
        }
        .video-lesson__action {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            button {
                width: 20px;
                height: 20px;
                text-align: center;
                i {
                    font-size: 17px;
                }
            }
        }
    }
}
</style>
