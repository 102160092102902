<script setup>
import InputGroup from "@/Components/InputGroup.vue";
import { computed, ref, triggerRef } from "vue";

const props = defineProps({
    modelValue: Boolean,
    formFields: Array,
});

const emit = defineEmits(["onSave", "update:modelValue"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const tempRegisterFields = ref([]);

function addNewCustomField() {
    tempRegisterFields.value.push({
        id: `cf_${new Date().valueOf()}`,
        label: "",
        value: "",
        required: false,
        default: false,
    });
    triggerRef(tempRegisterFields);
}

function removeTempFieldById(id) {
    const index = tempRegisterFields.value.findIndex((el) => el.id === id);

    if (index >= 0) tempRegisterFields.value.splice(index, 1);

    triggerRef(tempRegisterFields);
}

const saveTempCustomFields = () => {
    emit("onSave", tempRegisterFields.value);
};
</script>

<template>
    <b-modal v-model="isVisible" hide-footer hide-header centered>
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати реєстраційне поле</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <p class="text-black-50">
                *Додані власноруч поля не мають автоматичної перевірки, тому
                користувачі зможуть ввести будь-які символи. Переконайтеся, що
                поле зрозуміле та правильно заповнене.
            </p>
            <div
                class="d-flex align-items-baseline"
                v-for="field in tempRegisterFields"
            >
                <InputGroup
                    v-model="field.label"
                    placeholder="Введіть назву поля"
                    size="lg"
                    class="w-100"
                />
                <b-button
                    variant="text-variant"
                    @click="removeTempFieldById(field.id)"
                >
                    <i class="bi bi-x-lg"></i>
                </b-button>
            </div>
            <b-button
                variant="text-variant"
                size="md"
                class="mt-3 d-flex align-items-center text-primary"
                @click="addNewCustomField()"
            >
                Додати поле
            </b-button>
            <b-button
                variant="primary"
                size="md"
                pill
                class="mt-3 float-end"
                @click="saveTempCustomFields"
            >
                Зберегти
            </b-button>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped></style>
