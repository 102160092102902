<script setup>
const props = defineProps({
    discUsage: {
        type: Object,
        default: () => ({}) // Встановлюємо пустий об'єкт як значення за замовчуванням
    }
})
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";
const page = usePage()
// const storageStatistic = computed(() => page?.props?.auth?.storageStatistic ? page.props.auth.storageStatistic : {"balance": 0})
const storageStatistic = computed(() => {
    if (Object.keys(props.discUsage).length > 0) {
        return props.discUsage;
    } else {
        return page?.props?.auth?.storageStatistic || { balance: 0 };
    }
});

</script>

<template>
  <div class="disk-space">
    <span class="disk-space__title">Використано {{ storageStatistic.spaceUsage }} \ {{ storageStatistic.capacity }}</span>
    <b-progress :value="storageStatistic.percent" class="progress-sm disk-space__progress" variant="success"></b-progress>
  </div>

</template>

<style lang="scss">
.disk-space {
  margin-right: 20px;
}
.disk-space__title {
  display: flex;
  color: var(--dark, #1E1E1E);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.disk-space__progress {
  margin-top: 10px;
}
</style>
