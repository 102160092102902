<script setup>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import 'maz-ui/styles'
import {computed, ref, watch} from "vue";

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    required: {
        type: Boolean,
        default: false
    },
})
const emit = defineEmits(['update:modelValue', 'phone-validation']);

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
});

const preferredCountries = ['UA', 'PL', 'LV', 'LT', 'EE', 'RO', 'BG', 'DE', 'GB', 'IT', 'FR', 'ES', 'BE', 'NL', 'PT']
const ignoredCountries = ['RU','BY']

const translations = {
    countrySelector: {
        placeholder: 'Код країни',
        error: 'Оберіть країну',
        searchPlaceholder: 'Пошук країни',
    },
    phoneInput: {
        placeholder: 'Номер телефону',
        example: 'Приклад:',
    },
}

const results = ref()

const onInput = (event) => {
    let inputValue = event.target.value;
    let validValue = inputValue.replace(/[^\d+]/g, '');

    if (validValue.startsWith('+')) {
        validValue = '+' + validValue.substring(1).replace(/\+/g, '');
    } else {
        validValue = validValue.replace(/\+/g, '');
    }

    if (validValue.length > 15) {
        validValue = validValue.slice(0, 15);
    }

    if (inputValue !== validValue) {
        event.target.value = validValue;
        value.value = validValue;
    }
}

</script>

<template>
    <MazPhoneNumberInput
        v-model="value"
        show-code-on-list
        :preferred-countries="preferredCountries"
        :ignored-countries="ignoredCountries"
        :validate-on-blur="true"
        :translations="translations"
        countrySelectorWidth="130px"
        country-selector-display-name
        fetchCountry
        country-locale="uk-UK"
        class="phone-input"
        @input="onInput"
        :required="required"
        @data="results = $event"
        @blur="emit('phone-validation', results)"
    />
</template>

<style lang="scss">
body {
    font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
}
.phone-input {
    display: flex;
    width: 100%;
    .m-input {
        .m-input-wrapper {
            border: none;
            border-radius: 0!important;
            border-bottom: 1px solid var(--Grey, #C2C2C2);
            .m-input-wrapper-input {
                input {
                    font-size: 14px;
                }
            }
        }
        .m-input-label {
            font-size: 10px;
            line-height: 10px;
            color: #8F8F8F;
            font-weight: var(--tb-font-weight-normal);
            padding: 0 12px;
            transform: scale(1)!important;
            top: 0;
        }
        .m-select-input__toggle-button {
            align-items: flex-end;
        }
        &.--error {
            .m-input-wrapper {
                border-color: var(--Grey, #C2C2C2);//#DA1414;
            }
            .m-input-label {
                color: #8F8F8F;//#DA1414;
            }
        }
    }
    .m-phone-input {
        flex: 1;
        min-width: 0;
        .m-input-label {
            left: 0!important;
            padding:0 2px!important;
        }
    }
    .m-country-selector {
        flex: 0 0 auto;
    }
}
.is-focused {
    .phone-input {
        .m-input-wrapper {
            border-color: var(--tb-primary)!important;
        }
        .m-input-label {
            color: var(--tb-primary)!important;
        }
    }
}
.m-select-list-item {
    &.--is-selected {
        background-color: #1DB9540A!important;
        border: 1px solid var(--tb-primary)!important;
        color: black!important;
    }
}
.is-invalid {
    .m-input-wrapper {
        border-color: #DA1414!important;
    }
    .m-input-label {
        color: #DA1414!important;
    }
}
</style>
