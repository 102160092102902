<script setup>
import { useI18n } from "vue-i18n";
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { computed, nextTick, ref, watch } from "vue";
import SpeakerField from "@/Components/form/SpeakerField.vue";
import MyTeamModal from "@/Components/modals/MyTeamModal.vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import PublicLink from "@/Components/form/PublicLink.vue";
import { email, helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import DateTimeSelector from "@/Components/form/DateTimeSelector.vue";
import WorkshopTeamComponent from "@/Pages/Workshops/Partials/WorkshopTeamComponent.vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import WebinarButtonsCard from "@/Components/WebinarButtons/WebinarButtonsCard.vue";

const { t } = useI18n();

const props = defineProps({
    form: Object,
    workshopTypes: Object,
    registerLink: "",
    roles: "",
    link: "",
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const onSubmit = () => {
    v$.value["title"].$touch();
    if (!v$.value.$invalid) {
        emit("save");
    }
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["workShop-change", "save"]);

watch(
    () => props.form.workShop,
    (value) => {
        nextTick(() => emit("workShop-change", value));
    },
    { deep: true }
);

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        maxLength: helpers.withMessage("Не більше 90 символів", maxLength(90)),
    },
};

const v$ = useVuelidate(rules, props.form.workShop, { $lazy: true });
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="6">
                <b-form>
                    <FileUploader
                        :form="form"
                        :imagePreview="form.workShop.image"
                        :disabled="isReadOnly"
                        custom-title=" або перетягніть фото"
                    />
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <SelectInputGroup
                                label="Статус"
                                v-model="form.workShop.status"
                                :options="statuses"
                                :disabled="isReadOnly"
                                size="lg"
                                class="w-100"
                            />
                            <PublicLink :public-link="registerLink" />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light-subtle p-3"
                        >
                            <div class="card__title">Основні дані</div>
                            <InputGroup
                                label="Назва майстеркласу"
                                v-model="form.workShop.title"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :max-length="90"
                                :disabled="isReadOnly"
                                :is-error="v$.title?.$errors[0]?.$message"
                                :error-message="v$.title?.$errors[0]?.$message"
                                @blur="v$.title.$touch()"
                            />

                            <InputGroup
                                element="textarea"
                                label="Опис майстеркласу"
                                v-model="form.workShop.description"
                                :options="statuses"
                                :max-length="255"
                                :disabled="isReadOnly"
                                :rows="3"
                                :max-rows="10"
                                size="lg"
                                class="mt-3 w-100"
                            />
                        </b-card-body>
                    </b-card>
                    <!--          <b-card no-body class="overflow-hidden shadow-none mt-4">-->
                    <!--            <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">-->
                    <!--              <div class="card__title">Налаштування майстеркласу</div>-->
                    <!--              <b-row class="mt-2 date-time-duration__group">-->
                    <!--                <InputGroup-->
                    <!--                    element="date"-->
                    <!--                    label="Дата початку"-->
                    <!--                    v-model="form.workShop.config.date"-->
                    <!--                    :disabled="isReadOnly"-->
                    <!--                    size="lg"-->
                    <!--                />-->
                    <!--                <InputGroup-->
                    <!--                    element="time"-->
                    <!--                    label="Час початку"-->
                    <!--                    v-model="form.workShop.config.date"-->
                    <!--                    :disabled="isReadOnly"-->
                    <!--                    size="lg"-->
                    <!--                />-->
                    <!--                <InputGroup-->
                    <!--                    element="duration"-->
                    <!--                    label="Тривалість"-->
                    <!--                    type="duration"-->
                    <!--                    v-model="form.workShop.config.duration"-->
                    <!--                    :disabled="isReadOnly"-->
                    <!--                    size="lg"-->
                    <!--                    class="w-25"-->
                    <!--                />-->
                    <!--              </b-row>-->
                    <!--&lt;!&ndash;              <b-row>&ndash;&gt;-->
                    <!--&lt;!&ndash;                <b-col>&ndash;&gt;-->
                    <!--&lt;!&ndash;                  <b-form-group id="input-group-3" class="mt-4">&ndash;&gt;-->
                    <!--&lt;!&ndash;                    <b-form-checkbox v-model="form.workShop.config.is_chat_write" >&ndash;&gt;-->
                    <!--&lt;!&ndash;                      Дозволити писати в чаті&ndash;&gt;-->
                    <!--&lt;!&ndash;                    </b-form-checkbox>&ndash;&gt;-->
                    <!--&lt;!&ndash;                  </b-form-group>&ndash;&gt;-->
                    <!--&lt;!&ndash;                </b-col>&ndash;&gt;-->
                    <!--&lt;!&ndash;              </b-row>&ndash;&gt;-->
                    <!--            </b-card-body>-->
                    <!--          </b-card>-->
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <WorkshopTeamComponent
                                :form="form"
                                :team="team"
                                :team-presenter="teamPresenter"
                                :team-moderator="teamModerator"
                                :roles="roles"
                                without-moderator
                                is-master-class
                            />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Реєстраційні дані відвідувачів
                                <i
                                    id="tooltip-access"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                target="tooltip-access"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Оберіть, чи потрібно вам збирати та зберігати
                                реєстраційні дані учасників.
                            </b-tooltip>
                            <b-form-radio-group
                                v-model="form.workShop.config.access"
                                name="radio-sub-component"
                                class="mt-4"
                            >
                                <b-form-radio
                                    :value="false"
                                    :disabled="isReadOnly"
                                >
                                    Збирати
                                    <i
                                        id="toolooltip-data-store"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-radio>
                                <b-tooltip
                                    target="toolooltip-data-store"
                                    custom-class="webinar-tooltip"
                                    offset="5"
                                >
                                    Відвідувачі зможуть потрапити на трансляцію
                                    лише після заповнення реєстраційної форми.
                                    Всі дані будуть збережені та доступні для
                                    вас.
                                    <span class="text-primary">Реєстрація</span>
                                </b-tooltip>
                                <b-form-radio
                                    :value="true"
                                    :disabled="
                                        isReadOnly ||
                                        form.workShop.config.is_paid
                                    "
                                >
                                    Не збирати
                                    <i
                                        id="toolooltip-open-for"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-radio>
                                <b-tooltip
                                    target="toolooltip-open-for"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Поля реєстраційної форми не будуть
                                    обов'язковими для заповнення.
                                </b-tooltip>
                            </b-form-radio-group>
                        </b-card-body>
                    </b-card>
                    <WebinarButtonsCard
                        v-model="form.webinarButtons"
                        :form="form"
                        title="Маркетингові кнопки"
                    />
                    <b-button
                        :disabled="form.processing || isReadOnly"
                        variant="primary"
                        size="lg"
                        pill
                        class="mt-3"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.webinar-input-group {
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;
    i {
        font-size: medium;
    }
    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}
</style>
