<script setup>
import InputGroup from "@/Components/InputGroup.vue";
import { useForm } from "@inertiajs/vue3";
const form = useForm({
    name: "Богдан",
    email: "korotkijbogdan@gmailcom",
    phone: "+38 098 650-58-04",
});

const onSubmit = () => {
    form.post(route("register"), {});
};

const onReset = () => {
    form.reset();
};
</script>

<template>
    <div class="form-login-header text-center">
        <h1 class="text-md login-title">Реєстрація</h1>
    </div>

    <b-form @submit.prevent="onSubmit" @reset="onReset" class="login-form">
        <div class="my-5">
            <InputGroup
                v-model="form.name"
                label="Ваше ім'я"
                placeholder="Введіть ім'я"
                :error-message="form.errors.name"
                type="text"
                required
                autofocus
                autocomplete="name"
                :is-error="!!form.errors.name"
            />

            <InputGroup
                v-model="form.phone"
                label="Телефон"
                placeholder="Введіть телефон"
                :error-message="form.errors.phone"
                type="text"
                required
                autofocus
                autocomplete="name"
                :is-error="!!form.errors.phone"
            />

            <InputGroup
                v-model="form.email"
                label="Email"
                placeholder="Введіть Email"
                :error-message="form.errors.email"
                type="email"
                required
                autofocus
                autocomplete="username"
                :is-error="!!form.errors.email"
            />
        </div>

        <div
            class="d-flex flex-column align-items-center justify-content-end mt-4"
        >
            <b-button
                :disabled="form.processing"
                variant="primary"
                size="lg"
                pill
                @click="onSubmit"
            >
                Зареєструватись
            </b-button>
        </div>
    </b-form>
</template>

<style lang="scss"></style>
