<script setup>

</script>

<template>
  <b-button type="button" variant="link" class="upload-file__button">
    <i class="ph-arrow-circle-up upload-file__button--icon"></i>
    <span>Завантажити файл</span>
  </b-button>
</template>
<style lang="scss" scoped>
  .upload-file__button {
    color: var(--dark, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    display: flex;
    align-items: center;
    gap:6px;
    cursor: pointer;
    .upload-file__button--icon {
      font-size: 24px;
      color: var(--green, #1DB954);
    }
  }
</style>
