<script setup>
import {Head, router, useForm} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";

import TabPersonalData from "@/Pages/StudentProfile/Partials/TabPersonalData.vue";

const props = defineProps({
  studentProfile: Object,
})

const {t} = useI18n();

const page = {
  title: t('my-profile'),
  description: "Рекомедуємо використовувати реальні дані для зручної взаємодії з іншими користувачами платформи."
}

const form = useForm({
  studentProfile: props.studentProfile
});

function save(value) {
  router.visit(route('student.save.profile'),
      {
        method: 'post',
        data: form.studentProfile,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true
      })
}

</script>

<template>
  <div>
    <Head :title="t('my-profile')"/>

    <PageHeader :title="page.title" />
    <PageContentCard :title="page.title" :description="page.description">

      <template #header-end>
        <div class="header-end">

        </div>
      </template>

      <template #default>
        <b-tabs nav-class="page-tabs">
          <b-tab title="Особисті дані" active>
            <TabPersonalData
              :form="form"
              @save="save"
              @personal-data-change="updateForm"/>
          </b-tab>
          <template #tabs-end>
            <div class="tabs-end">
              <div class="logout-button">
                Вихід
                <i class="ph-power-bold"></i>
              </div>
            </div>
          </template>
        </b-tabs>
      </template>
    </PageContentCard>

  </div>
</template>

<style lang="scss" scoped>

.module-content {
  min-height: calc(100vh - 120px);
}

.header-end {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
}
.tabs-end {
  .logout-button {
    color: var(--purple, #F24822);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 8px 26px;
    display: flex;
    cursor: pointer;
    i {
      margin-left: 6px ;
      font-size: 20px;
    }
  }
}
</style>
