<script setup>
import { Head, useForm, usePage } from "@inertiajs/vue3";
import empty from "../../../images/empty-data/booking-empty.png";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import { ref } from "vue";

import axios from "axios";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";
import useRequestProgress from "@/composables/useRequestProgress.js";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { addMinutes, addMonths, format, parse } from "date-fns";

const props = defineProps({
    booking: Object,
    mentor: Object,
    programId: String,
});

const user = usePage().props.auth.user;

const { t } = useI18n();

const page = {
    title: t("booking"),
    breadcrumbs: [
        {
            text: "Персональні заняття",
            href: route("student.student_mentoring"),
        },
        {
            text: "Забронювати",
            active: true,
        },
    ],
};

const attributes = ref([
    {
        highlight: true,
        dates: null,
    },
]);

const dateRef = ref(null);
// const onDayClick = async (day) => {
//     console.log(day.id);
//     availableTime.value = null;
//     if (day.date === attributes.value[0].dates) attributes.value[0].dates = null;
//     else {
//         if (new Date().setHours(0, 0, 0, 0) <= new Date(day.date).setHours(0, 0, 0, 0)) {
//             attributes.value[0].dates = day.date;
//             availableTime.value = await getBookingMentorSchedule(day.id, props.mentor.id);
//             dateRef.value = day.id;
//         }
//     }
// }
const userTimezoneOffset = new Date().getTimezoneOffset();
const convertToLocalTimezone = (timeString) => {
    const time = parse(timeString, "HH:mm:ss", new Date());
    const localTime = addMinutes(time, -userTimezoneOffset);
    return format(localTime, "HH:mm:ss");
};

const getBookingMentorSchedule = async (date, mentorId) => {
    setIsLoading(true);
    console.log("getBookingMentorSchedule");
    return axios
        .post(route("getBookingMentorSchedule"), {
            date: date,
            time_zone: userTimezoneOffset / 60,
            mentorId: mentorId,
        })
        .then((response) => {
            console.log(response.data);
            return response.data.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        })
        .finally(() => setIsLoading(false));
};

const availableTime = ref(null);

const selectedInterval = ref({});

const selectInterval = (interval) => {
    if (interval === selectedInterval.value) selectedInterval.value = {};
    else selectedInterval.value = interval;
};

const bookingMentorSlot = async (date, mentorId, inerval) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const combinedStart = `${date} ${selectedInterval.value.from}`;
    const parsedStartDate = parse(
        combinedStart,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
    );
    const utcStartDate = addMinutes(parsedStartDate, timezoneOffset);

    const combinedEnd = `${date} ${selectedInterval.value.to}`;
    const parsedSEndDate = parse(
        combinedEnd,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
    );
    const utcEndDate = addMinutes(parsedSEndDate, timezoneOffset);

    setIsLoading(true);
    return axios
        .post(route("bookingMentorSlot"), {
            date: format(utcStartDate, "yyyy-MM-dd HH:mm:ss"),
            endDate: format(utcEndDate, "yyyy-MM-dd HH:mm:ss"),
            mentorId: mentorId,
            userId: user.id,
            programId: props.programId,
        })
        .then((response) => {
            console.log(response.data);
            toast.success("Ваше заняття успішно заброньовано!", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return response.data.data;
        })
        .catch((error) => {
            console.error(error);
            return [];
        })
        .finally(() => setIsLoading(false));
};

const handleBooking = async () => {
    console.log(selectedDate.value);
    // TODO Ajax post selected date and interval
    await bookingMentorSlot(selectedDate.value, props.mentor.id);
    const bookingSchedule = await getBookingMentorSchedule(
        selectedDate.value,
        props.mentor.id
    );

    availableTime.value = bookingSchedule.map((interval) => ({
        from: convertToLocalTimezone(interval.from),
        to: convertToLocalTimezone(interval.to),
    }));
};

const selectedDate = ref(null);

const onChange = async (selectedDates, dateStr, instance) => {
    const bookingSchedule = await getBookingMentorSchedule(
        selectedDate.value,
        props.mentor.id
    );

    availableTime.value = bookingSchedule.map((interval) => ({
        from: convertToLocalTimezone(interval.from),
        to: convertToLocalTimezone(interval.to),
    }));
};

const { isLoading, setIsLoading } = useRequestProgress();
</script>

<template>
    <div>
        <Head :title="page.title" />

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <div class="booking__container">
            <div class="booking__header">
                <div>Забронюйте персональне заняття з ментором</div>
                <p>
                    Перегляньте доступні дати та час для зустрічі з ментором.
                    Забронюйте слот, який підходить вам найкраще.
                </p>
            </div>
            <div class="booking__body">
                <div class="booking__calendar">
                    <div class="booking__user">
                        <b-avatar
                            variant="light"
                            :src="mentor.image ? mentor.image : noAvatar"
                        ></b-avatar>
                        <div>{{ mentor.name }}</div>
                    </div>
                    <div class="custom-calendar">
                        <!--                        <VCalendar locale="uk" borderless :attributes="attributes" @dayclick="onDayClick"/>-->
                        <flat-pickr
                            v-model="selectedDate"
                            :config="{
                                locale: Ukrainian,
                                inline: true,
                                minDate: new Date(),
                                maxDate: format(
                                    addMonths(new Date(), 2),
                                    'yyyy-MM-dd'
                                ),
                            }"
                            :disable="true"
                            class="booking-calendar_picker"
                            @on-change="onChange"
                        />
                    </div>
                </div>
                <div v-if="!selectedDate" class="booking__time-chooser">
                    <div class="booking__time-chooser__title">
                        Будь ласка оберіть день!
                    </div>
                </div>
                <div v-else class="booking__time-chooser">
                    <div class="booking__time-chooser__title">
                        {{
                            isLoading
                                ? "Будь ласка зачекайте!"
                                : !availableTime
                                ? "Нажаль немає вільних місць"
                                : "Доступний час"
                        }}
                    </div>
                    <div class="booking__time-chooser__items">
                        <perfect-scrollbar
                            v-if="availableTime && availableTime.length > 0"
                            class="time-chooser__scroll"
                        >
                            <div
                                class="time-interval"
                                v-for="interval in availableTime"
                                :class="{
                                    'time-interval--selected':
                                        interval === selectedInterval,
                                }"
                                @click="selectInterval(interval)"
                            >
                                {{
                                    interval.from.substring(
                                        0,
                                        interval.from.lastIndexOf(":")
                                    )
                                }}
                                -
                                {{
                                    interval.to.substring(
                                        0,
                                        interval.to.lastIndexOf(":")
                                    )
                                }}
                            </div>
                        </perfect-scrollbar>
                        <div
                            v-else
                            class="d-flex flex-column align-items-center gap-2 mx-auto w-75"
                        >
                            <div
                                v-if="!isLoading"
                                class="d-flex flex-column align-items-center"
                            >
                                <img
                                    :src="empty"
                                    alt="немає вільних годин на обраний день"
                                />
                                Отакої, нажаль в ментора немає вільних годин на
                                обраний день
                            </div>
                        </div>
                    </div>
                    <b-button
                        v-if="selectedDate"
                        variant="primary"
                        pill
                        class="booking__time-chooser__button"
                        :disabled="
                            isLoading ||
                            !(availableTime && availableTime.length > 0)
                        "
                        @click="handleBooking"
                    >
                        Забронювати
                    </b-button>
                </div>
                <!--                <div v-if="availableTime" class="booking__time-chooser">-->
                <!--                    <div class="booking__time-chooser__title">Тривалість заняття</div>-->
                <!--                    <div class="booking__time-chooser__duration">1 година</div>-->
                <!--                    <div class="booking__time-chooser__items">-->
                <!--                        <perfect-scrollbar class="time-chooser__scroll">-->
                <!--                            Оберіть зручний час-->
                <!--                            <div-->
                <!--                                class="time-interval"-->
                <!--                                v-for="interval in availableTime"-->
                <!--                                :class="{ 'time-interval&#45;&#45;selected' : interval === selectedInterval }"-->
                <!--                                @click="selectInterval(interval)"-->
                <!--                            >-->
                <!--                                {{ interval.from }} - {{ interval.to }}-->
                <!--                            </div>-->
                <!--                        </perfect-scrollbar>-->
                <!--                    </div>-->
                <!--                    <b-button variant="primary" pill class="booking__time-chooser__button" @click="handleBooking">-->
                <!--                        Забронювати-->
                <!--                    </b-button>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.booking__container {
    font-family: e-Ukraine;
    background: var(--white, #fff);
    border-radius: 12px;
    margin: 0 12px;
    display: flex;
    padding: 25px;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    @media only screen and (max-width: 1368px) {
        padding: 25px;
        gap: 25px;
    }

    .booking__header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 75%;
        @media only screen and (max-width: 1368px) {
            width: 75%;
        }
        div {
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            @media only screen and (max-width: 1368px) {
                font-size: 22px;
            }
        }

        p {
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            opacity: 0.5;
        }
    }

    .booking__body {
        //display: grid;
        //grid-template-columns: auto 1fr; /* Один стовпець */
        display: flex;
        flex-wrap: wrap;
        border-radius: 20px;
        border: 1px solid var(--stroke, #ebebeb);
        width: 50%;
        @media only screen and (max-width: 1368px) {
            width: 75%;
        }

        .booking__time-chooser {
            padding: 40px;
            border-left: 1px solid var(--stroke, #ebebeb);
            width: 50%;
            display: flex;
            flex-direction: column;

            .booking__time-chooser__button {
                display: block;
                margin: 0 auto;
                &:disabled,
                &[disabled] {
                    background: #c2c2c2;
                    border-color: #c2c2c2;
                }
            }

            .booking__time-chooser__duration {
                margin-top: 10px;
                padding: 16px 10px;
                border-radius: 4px;
                border: 1px solid var(--green, #1db954);
                background: rgba(29, 185, 84, 0.1);
                color: var(--dark, #1e1e1e);
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
            }

            .booking__time-chooser__title {
                color: var(--dark, #1e1e1e);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
            }

            .booking__time-chooser__items {
                margin: 32px 0;
                text-align: center;
                flex: 1;
                display: flex;
                flex-direction: column;
                max-height: 270px;
                justify-content: center;
                @media only screen and (max-width: 1368px) {
                    max-height: 250px;
                }

                .time-chooser__scroll {
                    max-height: 300px;
                }

                .time-interval {
                    display: flex;
                    padding: 16px 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    border: 1px solid var(--stroke, #ebebeb);
                    background: var(--white, #fff);
                    margin: 4px 0;

                    &:hover {
                        border: 1px solid var(--green, #1db954);
                    }
                }

                .time-interval--selected {
                    border: 1px solid var(--green, #1db954);
                    background: rgba(29, 185, 84, 0.1);
                }
            }
        }

        .booking__calendar {
            padding: 40px;
            width: 50%;
        }

        .booking__user {
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-bottom: 20px;

            img {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
        }
    }
}

.custom-calendar {
    display: flex;
    justify-content: center;
    .flatpickr-calendar {
        width: 400px !important;
        box-shadow: none !important;
        &::before,
        &::after {
            display: none !important;
        }
        .flatpickr-next-month,
        .flatpickr-prev-month {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }
        .dayContainer {
            //padding: 2px;
        }
        .flatpickr-day {
            background-color: transparent;
            border: 2px solid white !important;
            position: relative !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            &::before {
                content: "";
                display: block !important;
                position: absolute !important;
                border-radius: 4px !important;
                border: 1px solid white;
                width: 100%;
                height: 100%;
            }
            &:hover {
                border: 2px solid white !important;
            }
            &:hover::before {
                border: 1px solid var(--tb-primary);
            }
            &.today {
                &::before {
                    border: 1px solid var(--tb-primary);
                }
            }
            &.flatpickr-disabled {
                background: color-mix(
                    in srgb,
                    #1e1e1e,
                    transparent 95%
                ) !important;
                color: color-mix(in srgb, #1e1e1e, transparent 50%) !important;
                cursor: not-allowed !important;
                &:hover {
                    background: color-mix(
                        in srgb,
                        #1e1e1e,
                        transparent 95%
                    ) !important;
                }
                &:hover::before {
                    border: 1px solid white;
                }
            }
            &.selected {
                &::before {
                    border: none;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    //& :deep(.vc-weekday) {
    //    color: var(--white, #FFF);
    //    background: var(--dark, #1E1E1E);
    //    padding: 12px 0;
    //
    //    &:first-child {
    //        border-radius: 8px 0 0 0;
    //    }
    //
    //    &:last-child {
    //        border-radius: 0 8px 0 0;
    //    }
    //}
    //
    //& :deep(.vc-weeks) {
    //    border-radius: 8px;
    //}
    //
    //& :deep(.vc-day-content) {
    //    border-radius: 0;
    //    width: 60px;
    //    height: 60px;
    //    border: 1px solid var(--stroke, #EBEBEB);
    //}
    //
    //& :deep(.vc-highlight-content-solid) {
    //    background: var(--green, #1DB954);
    //}
    //
    //& :deep(.vc-header .vc-title) {
    //    background: transparent;
    //}
    //
    //& :deep(.vc-arrow) {
    //    border-radius: 8px;
    //    width: 50px;
    //
    //    svg {
    //        stroke: var(--green, #1DB954);
    //    }
    //}
    //
    //& :deep(.vc-weeks) {
    //    margin-top: 16px;
    //}
}
.booking-calendar_picker {
    display: none;
}
</style>
