<script setup>
import { computed, nextTick, onMounted, onUpdated, ref } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
    modelValue: Boolean,
    user: Object,
    emailWithTenant: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue", "addUser"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const form = ref({
    name: "",
    email: "",
    phone: "",
});

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    email: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        email: helpers.withMessage("Введіть дійсну електронну адресу", email),
    },
    phone: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
};

const v$ = useVuelidate(rules, form.value, { $lazy: true });

onUpdated(() => {
    if (props.user) {
        form.value.id = props.user.id;
        form.value.name = props.user.name;
        form.value.email = props.user.email;
        form.value.phone = props.user.phone;
    }
});

onMounted(() => {
    nextTick(() => {
        if (props.user) {
            form.value.id = props.user.id;
            form.value.name = props.user.name;
            form.value.email = props.user.email;
            form.value.phone = props.user.phone;
        }
    });
});

const handleSend = () => {
    v$.value.$touch();
    if (!v$.value.$invalid && !notNewEmail.value && !notValidPhone.value) {
        emit("addUser", form.value);
        isVisible.value = !isVisible.value;
    }
};

const verifyAvailability = async () => {
    let verifyProps = { column: "email", text: form.value.email };
    if (props.emailWithTenant) verifyProps.tenant = props.emailWithTenant;
    if (props.user) verifyProps["user_id"] = props.user.id;
    isVeryfy.value = true;
    try {
        const response = await axios.post("/verify-availability", verifyProps);
        isVeryfy.value = false;
        return response.data.exists;
    } catch (error) {
        isVeryfy.value = false;
        return false;
    }
};

const notNewEmail = ref(false);
const isVeryfy = ref(false);

const checkEmail = () => {
    v$.value["email"].$touch();
    if (!v$.value["email"].$invalid && form.value.email.length > 0) {
        (async () => {
            const result = await verifyAvailability();
            notNewEmail.value = result ? result : false;
        })();
    } else notNewEmail.value = false;
};

const notValidPhone = ref(false);
const phoneValidate = (data) => {
    v$.value["phone"].$touch();
    if (!v$.value["phone"].$invalid)
        notValidPhone.value = !data.isValid ? !data.isValid : false;
    else notValidPhone.value = false;
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
        class="add-user-modal"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати користувача</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <InputGroup
                label="Ім'я*"
                v-model="form.name"
                size="lg"
                class="modal__input-group"
                :is-error="v$.name.$invalid"
                :error-message="
                    v$.name?.$errors.find(
                        (error) => error['$validator'] === 'required'
                    )?.$message
                "
                @blur="v$.name.$touch()"
            />
            <InputGroup
                label="Email*"
                v-model="form.email"
                size="lg"
                class="modal__input-group"
                :is-loading="isVeryfy"
                :is-error="v$.email.$invalid || notNewEmail"
                :error-message="
                    notNewEmail
                        ? 'Ця електронна пошта вже зареєстрована!'
                        : v$.email?.$errors[0]?.$message
                "
                @blur="checkEmail"
            />
            <InputGroup
                element="phone"
                label="Телефон"
                v-model="form.phone"
                size="lg"
                :is-error="v$.phone.$invalid || notValidPhone"
                :error-message="
                    notValidPhone
                        ? 'Перевірте формат введення!'
                        : v$.phone?.$errors[0]?.$message
                "
                class="modal__input-group"
                @blur="phoneValidate"
            />
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3"
                    :disabled="isVeryfy"
                    @click="handleSend"
                >
                    {{ user ? "Зберегти" : "Додати" }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss">
.add-user-modal {
    .modal-dialog {
        max-width: 555px !important;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 28px;
        .modal__input-group {
            margin-top: 16px !important;
        }
    }
}
</style>
