<script setup>
import { computed } from "vue";
import InputGroup from "@/Components/InputGroup.vue";

const props = defineProps({
    modelValue: Boolean,
    formFields: Object,
});

const emit = defineEmits(["onSave", "update:modelValue"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const savePaymentMethod = () => {
    emit("onSave", props.formFields);
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати реквізити</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <p class="text-black-50">
                Реквізити потрібні, щоб користувачі мали змогу оплатити ваші
                навчальні матеріали. Для зручності рекомендуємо вказувати не
                більше трьох рахунків.
            </p>
            <p class="text-warning">
                Будь ласка, уважно перевірте введені дані. Платформа не може
                відповідати за помилки у реквізитах.
            </p>
            <b-card no-body class="shadow-none mt-4">
                <b-card-body
                    class="d-flex flex-column justify-content-between align-items-start border rounded-3 p-3"
                >
                    <InputGroup
                        label="Назва банку/криптовалюти"
                        v-model="formFields.name"
                        class="mt-3 w-100"
                    />
                    <InputGroup
                        label="Номер рахунку/адреса для поповнення"
                        v-model="formFields.value"
                        class="mt-3 w-100"
                    />
                </b-card-body>
            </b-card>
            <b-button
                variant="primary"
                size="md"
                pill
                class="mt-3 float-end"
                @click="savePaymentMethod"
            >
                Зберегти
            </b-button>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped></style>
