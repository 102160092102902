/**
 * Delete message by id (for_all or for_sender only).
 *
 * @param messageId
 * @param params
 * @returns {Promise<*>}
 */
export async function markAsRead(messagesIds, params) {
    return axios.post(`/notifications/mark-as-read`, {
        ids: messagesIds,
        ...params
    });
}

export async function getLatestNotifications(page) {
    return axios.get(`/notifications/latest`, {
        current_page: !!page ? page : 1,
    });
}
