<script setup>

import {ref} from "vue";

const props = defineProps({
  membershipOptions: Object
})

const customPay = ref(false);
const pay = ref('');
const setPay = (amount) => {
  if (pay.value !== amount) pay.value = amount;
  else pay.value = '';
}

const handleCustomPay = () => {
    pay.value = ''
    customPay.value = !customPay.value
}

</script>

<template>
  <div class="balance-management">
    <div class="balance-management__header">
      <div class="balance-management__title">Особистий рахунок CleverSton</div>
    </div>
    <div class="balance-management__content-wrapper">
      <div class="balance-management__content">
        <div class="balance-management__balance">${{ membershipOptions.plan.balance }}</div>
        <div class="balance-management__pay">
          <div class="balance-management__pay-label">
            Швидке поповнення
          </div>
          <div class="balance-management__pay-variant">
            <div class="pay-variant__chooser">
              <div v-if="!customPay" class="d-flex">
                <div
                    class="pay-variant__fixed"
                    :class="{ 'pay-variant__fixed--active': pay == '5' }"
                    @click="setPay('5')"
                >
                  $5
                </div>
                <div
                    class="pay-variant__fixed"
                    :class="{ 'pay-variant__fixed--active': pay == '50' }"
                    @click="setPay('50')"
                >
                  $50
                </div>
                <div
                    class="pay-variant__fixed"
                    :class="{ 'pay-variant__fixed--active': pay == '100' }"
                    @click="setPay('100')"
                >
                  $100
                </div>
                <div
                    class="pay-variant__fixed"
                    :class="{ 'pay-variant__fixed--active': pay == '500' }"
                    @click="setPay('500')"
                >
                  $500
                </div>
              </div>
              <b-form-input
                  v-else
                  v-model="pay"
                  class="w-75"
                  placeholder="Cума"
              >
              </b-form-input>
              <div v-if="!customPay" class="pay-variant__custom-pay" @click="handleCustomPay">Ввести суму</div>
              <div v-else class="pay-variant__custom-pay" @click="handleCustomPay">Закрити</div>
            </div>
            <div
                class="pay-variant__button"
                :class="{ 'pay-variant__button--active': pay !== '' }"
            >
              Поповнити баланс
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.balance-management {
  .balance-management__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    margin-bottom: 32px;
    .balance-management__title {
      color: var(--dark, #1E1E1E);
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
  }
  .balance-management__content-wrapper {
    border-radius: 8px;
    background: var(--fields_color, #F7F7F8);
    padding: 26px 24px;
    .balance-management__content {
      border-radius: 8px;
      background: var(--white, #FFF);
      padding: 26px 24px;
      .balance-management__balance {
        color: var(--green, #1DB954);
        font-size: 62px;
        font-style: normal;
        font-weight: 300;
        padding-bottom: 20px;
        border-bottom: 1px solid #EBEBEB;
      }
      .balance-management__pay {
        padding-top: 20px;
        width: 100%;
        .balance-management__pay-label {
          color: var(--dark, #1E1E1E);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
        .balance-management__pay-variant {
          margin-top: 13px;
          display: flex;
          align-items: center;
          width: 100%;
          .pay-variant__chooser {
            display: flex;
            align-items: center;
            flex: 1;
            .pay-variant__fixed {
              text-align: center;
              padding: 10px;
              border-radius: 58px;
              width: 85px;
              border: 1px solid var(--dark, #1E1E1E);
              opacity: 0.8;
              margin-right: 16px;
              cursor: pointer;
              font-size: 20px;
              &:last-child { margin-right: 0; }
              &:hover {
                background: var(--fields_color, #F7F7F8);
              }
            }
            .pay-variant__fixed--active {
              background-color: var(--green, #1DB954);
              border-color: var(--green, #1DB954);
              color: var(--white, #FFF);
              &:hover {
                background-color: var(--green, #1DB954);
                border-color: var(--green, #1DB954);
                color: var(--white, #FFF);
              }
            }
            .pay-variant__custom-pay{
              color: var(--green, #1DB954);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              text-decoration-line: underline;
              cursor: pointer;
              padding: 5px 16px;
            }
          }
          .pay-variant__button {
            padding: 20px 24px;
            gap: 8px;
            border-radius: 50px;
            background: rgba(30, 30, 30, 0.20);
            color: var(--white, #FFF);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
          }
          .pay-variant__button--active {
            background-color: var(--green, #1DB954);
          }
        }
      }
    }
  }
}
</style>
