<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import InputGroup from "@/Components/InputGroup.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import RegisterFormWrapper from "@/Pages/Auth/Partials/RegisterFormWrapper.vue";

defineOptions({ layout: LoginLayout });

const form = useForm({
    name: "",
    email: "",
    password: "",
    // password_confirmation: '',
});

const onSubmit = () => {
    form.post(route("register"), {
        onFinish: () => form.reset("password"),
    });
};

const onReset = () => {
    form.reset();
};
</script>

<template>
    <RegisterFormWrapper>
       <template #left>
        <div
            class="d-flex flex-column align-self-stretch justify-content-center align-items-center"
        >
            <Head title="Реєстрація на платформі" />

            <div class="form-login-header text-center">
                <h1 class="text-md login-title">Зареєструватись</h1>
                <div class="login-subtitle" style="max-width: 400px">
                    Створюйте свої навчальні продукти за кілька кліків. Курс,
                    вебінар, менторство — все на одній платформі.
                </div>
            </div>

            <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show">-->
            <b-form
                @submit.prevent="onSubmit"
                @reset="onReset"
                class="login-form"
            >
                <div class="my-5">
                    <InputGroup
                        v-model="form.name"
                        label="Ваше ім'я"
                        placeholder="Введіть ім'я"
                        :error-message="form.errors.name"
                        type="text"
                        required
                        autofocus
                        autocomplete="name"
                        :is-error="!!form.errors.name"
                    />

                    <InputGroup
                        v-model="form.email"
                        label="Email"
                        placeholder="Введіть Email"
                        :error-message="form.errors.email"
                        type="email"
                        required
                        autofocus
                        autocomplete="username"
                        :is-error="!!form.errors.email"
                    />

                    <InputGroup
                        v-model="form.password"
                        label="Пароль"
                        placeholder="Введіть пароль"
                        :error-message="form.errors.password"
                        type="password"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <ul class="list-inline password-recomendations-list">
                        <li class="list-inline-item">не менше 6 символів</li>
                        <li class="list-inline-item">
                            великі та маленькі літери
                        </li>
                        <li class="list-inline-item">цифри</li>
                    </ul>
                </div>

                <div class="login-bottom-text">
                    Натискаючи на «Зареєструватись», ви погоджуєтесь з нашими
                    <Link>Умовами використання</Link>,
                    <Link>Політикою конфіденційності</Link> і
                    <Link>Політикою щодо Cookies</Link>.
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-end mt-4"
                >
                    <b-button
                        :disabled="form.processing"
                        variant="success"
                        size="lg"
                        pill
                        @click="onSubmit"
                    >
                        Зареєструватись
                    </b-button>
                    <div class="my-4">
                        Вже є акаунт?
                        <Link :href="'/login'" class="text-sm"> Вхід </Link>
                    </div>
                </div>
            </b-form>
        </div>
       </template>
       <template #right>
        <div class="login-right-overlay"></div>
       </template>
    </RegisterFormWrapper>
</template>

<style lang="scss">
.password-recomendations-list {
    margin-top: 10px;
    color: #1e1e1e;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;

    .list-inline-item {
        margin: 0 15px 0 0;
        padding: 0 0 0 10px;
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 5px;
            height: 5px;
            background-color: var(--green, #1db954);
            border-radius: 50%;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}
</style>
