<script setup>
import {onMounted, ref, watch, getCurrentInstance, computed, Teleport} from 'vue';
import AppLogo from "@/Components/AppLogo.vue";
import horMenu from "@/Layouts/hor-menu.js";
import {Link, router} from "@inertiajs/vue3";
import AppMenuItem from "@/Components/AppMenuItem.vue";
import {useDateFormatter} from "@/composables/useDateFormatter.js";

const props = defineProps({
    menuItems: Object
})

const menuItemsTop = computed(() => props.menuItems?.filter((item) => !item.inFooter))
const menuItemsBottom = computed(() => props.menuItems?.filter((item) => !!item.inFooter))

const app = getCurrentInstance()

const submenuOpened = ref(false)

// const onRoutechange = (ele) => {
//     initActiveMenu('/' + ele.current());
// };

/*const setupHorizontalMenu = () => {
    // const navData = horMenu;
    // const isMoreMenu = ref(false);
    //
    // // Create the "More" menu item and add the hidden items to its subItems
    // const navbarNav = document.getElementById('navbar-nav');
    // const fullWidthOfMenu = navbarNav?.parentElement?.clientWidth;
    //
    // const menuWidth = fullWidthOfMenu || 0;
    // let totalItemsWidth = 0;
    // let visibleItems = [];
    // let hiddenItems = [];

    // for (let i = 0; i < navData.length; i++) {
    //     const itemWidth = navbarNav?.children[i]?.offsetWidth;
    //     totalItemsWidth += itemWidth;
    //     if (totalItemsWidth + 150 <= menuWidth) {
    //         visibleItems.push(navData[i]);
    //     } else {
    //         hiddenItems.push(navData[i]);
    //     }
    // }
    //
    // const moreMenuItem = {
    //     id: 'more',
    //     label: 't-more',
    //     icon: 'ri-briefcase-2-line',
    //     subItems: hiddenItems,
    //     link: 'sidebarMore',
    //     stateVariables: isMoreMenu.value,
    //     click: (e) => {
    //         e.preventDefault();
    //         isMoreMenu.value = !isMoreMenu.value;
    //     },
    // };
    //
    // const updatedMenuItems = [...visibleItems, moreMenuItem];
    // menuItems.value = navData;
};*/

/*const initActiveMenu = (ele) => {
    setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
            const activeLinks = document.querySelector("#navbar-nav").querySelectorAll("a.active")
            if (activeLinks && activeLinks.length > 0) {
                activeLinks.forEach((item) => {
                    item.classList.remove("active");
                })
            }

            let a = document.querySelector("#navbar-nav").querySelector('[to="' + ele + '"], [href="' + ele + '"]');
            if (a) {
                a.classList.add("active");
                let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
                if (parentCollapseDiv) {
                    parentCollapseDiv.classList.add("show");
                    parentCollapseDiv.parentElement.children[0].classList.add("active");
                    parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                    if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                            parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                    }
                }
            }
        }
    }, 1000);
};*/

const isContainActiveMenu = (activeRoute, subItems) => {
    if (!(subItems && subItems.length > 0)) return false;

    let isActive = subItems.every((item) => {
        let activeSub = false;
        if (item.subItems && item.subItems.length > 0) {
            activeSub = isContainActiveMenu(activeRoute, item.subItems);
        }

        if (!activeSub) {
            const foundItem = subItems.first((item) => item.link === activeRoute)
            return !!foundItem;
        }

        return activeSub
    })

    console.log('isContainActiveMenu', isActive)

    return isActive;
}

// const menuItem = (e) => {
//     // get the dropdown menu element
//     let dropdownMenu = e.target;
//     const subMenus = (dropdownMenu.nextElementSibling) ? dropdownMenu.nextElementSibling : dropdownMenu.parentElement.nextElementSibling;
//     if (dropdownMenu && subMenus) {
//         // get the position and dimensions of the dropdown menu
//         let dropdownOffset = subMenus.getBoundingClientRect();
//         let dropdownWidth = subMenus.offsetWidth;
//         let dropdownHeight = subMenus.offsetHeight;
//
//         // get the dimensions of the screen
//         let screenWidth = window.innerWidth;
//         let screenHeight = window.innerHeight;
//
//         // calculate the maximum x and y coordinates of the dropdown menu
//         let maxDropdownX = dropdownOffset.left + dropdownWidth;
//         let maxDropdownY = dropdownOffset.top + dropdownHeight;
//
//         // check if the dropdown menu goes outside the screen
//         let isDropdownOffScreen = (maxDropdownX > screenWidth) || (maxDropdownY > screenHeight);
//         if (isDropdownOffScreen) {
//             if (subMenus.classList.contains("menu-dropdown")) {
//                 subMenus.classList.add("dropdown-custom-right");
//             }
//         }
//     }
// };

// const menuPosSetOnClicknHover = () => {
//     const isElement = document.querySelectorAll("#navbar-nav > li.nav-item, #navbar-nav2 > li.nav-item");
//     Array.from(isElement).forEach(item => {
//         item.addEventListener("click", menuItem, false);
//         item.addEventListener("mouseover", menuItem, false);
//     });
// };

const currentActiveRoute = ref()

router.on('navigate', (event) => {
  // console.log('Navigate', event);
  currentActiveRoute.value = event?.detail?.page?.url
})

// watch(route, (eventNew) => {
//     if(eventNew) {
//       console.log('AppMenu eventNew', eventNew)
//       currentActiveRoute.value = eventNew.current()
//       console.log('AppMenu currentActiveRoute', currentActiveRoute.value)
//     }
// }, {
//     immediate: true,
//     deep: true
// })

onMounted(() => {
    // window.addEventListener('resize', setupHorizontalMenu);
    //
    // setupHorizontalMenu();
    //
    // menuPosSetOnClicknHover()
})

const { formatDate } = useDateFormatter()

</script>

<template>
    <!-- ========== App Menu ========== -->
    <div class="app-menu navbar-menu" id="appmenu">
        <AppLogo/>

        <perfect-scrollbar style="height: calc(100vh - 96px);">
            <b-container fluid class="navbar-menu__inner d-flex flex-column justify-content-between">
                <ul class="navbar-nav h-100" id="navbar-nav">
                    <AppMenuItem
                        v-for="(item, index) in menuItemsTop"
                        :key="index"
                        :item="item"
                        @submenu-toggled="(flag) => submenuOpened = flag === true"
                    />
                </ul>

                <div>
                    <ul class="navbar-nav h-100" id="navbar-nav2">
                        <AppMenuItem
                            v-for="(item, index) in menuItemsBottom"
                            :key="index"
                            :item="item"
                            @submenu-toggled="(flag) => submenuOpened = flag === true"
                        />
                    </ul>

                    <div class="navbar-menu__copyright text-center">
                        Bestclevers
                        LMS {{ new Date().getFullYear() }}
                    </div>
<!--                    <div class="copyright-link">-->
<!--                        <a href="https://zentixsoft.com" target="_blank">Dev by Zentix</a>-->
<!--                    </div>-->
                </div>
            </b-container>
            <!-- Sidebar -->
        </perfect-scrollbar>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
    </div>
</template>

<style scoped>
    .copyright-link {
        font-family: e-Ukraine;
        font-size: 10px;
        font-weight: 300;
        line-height: 10px;
        letter-spacing: -0.03em;
        text-align: center;
        color: #FFFFFF;
        opacity: 30%;
        margin-bottom: 15px;
        a {
            color: #FFFFFF;
        }
    }
</style>
