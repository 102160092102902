<script setup>

</script>

<template>
    <slot />
</template>

<style scoped lang="scss">

</style>
