<script setup>
import {Link, usePage} from '@inertiajs/vue3';
import {computed} from "vue";

const page = usePage()
const user = computed(() => page?.props?.auth?.user ? page.props.auth.user : {})

</script>

<template>
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <Link :href="user.current_account_type === 'ACCOUNT_TYPE_STUDENT' ? '/student/profile' : '/'" class="logo logo-dark">
            <span class="logo-sm">
              <img src="../../images/cl-logo.svg" alt="" height="64"/>
            </span>
            <span class="logo-lg">
              <img src="../../images/cl-logo.svg" alt="" height="64"/>
            </span>
        </Link>
        <!-- Light Logo-->
        <Link :href="user.current_account_type === 'ACCOUNT_TYPE_STUDENT' ? '/student/profile' : '/'" class="logo logo-light">
            <span class="logo-sm">
              <img src="../../images/logo_white.svg" alt="" height="64"/>
            </span>
            <span class="logo-lg">
              <img src="../../images/logo_white.svg" alt="" height="64"/>
            </span>
        </Link>
        <b-button type="button" size="sm" variant="white"
                  class="p-0 fs-3xl header-item float-end btn-vertical-sm-hover"
                  id="vertical-hover">
            <i class="ri-record-circle-line"></i>
        </b-button>
    </div>
</template>
