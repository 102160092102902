<script setup>
import {computed, watch} from "vue";
  import { useClipboard } from '@vueuse/core'
  import {toast} from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';


  const props = defineProps({
    modelValue: Boolean,
    sharedLink: String
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const isVisible = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })

  const { text, copy, copied, isSupported } = useClipboard({
    legacy: true
  })

  watch(copied, (newValue, oldValue) => {
    if (newValue) {
      toast.success('Cкопійовано', {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  });


</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>Поділитись подією</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <div class="shared-modal__label">Публічне посилання</div>
      <div class="shared-modal__link">{{ sharedLink }}</div>
      <div class="shared-modal__actions">
        <b-button variant="primary" size="md" pill class="mt-3" @click="copy(sharedLink)">
          Скопіювати
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
  .shared-modal__link {
    display: flex;
    padding: 20px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--green, #1DB954);
    background: #f6fcf8;
    color: var(--dark, #1E1E1E);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 14px */
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 100;
    &::after {
      content: '';
      display: block;
      height: 40px;
      width: 30px;
      position: absolute;
      right: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 40%,
        rgba(246, 252, 248, 0.5) 40%,
        rgba(246, 252, 248, 0.5) 80%,
        #f6fcf8 80%,
        #f6fcf8 100%
      );
      z-index: 101;
    }
  }
  .shared-modal__label {
    color: var(--dark, #1E1E1E);
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    margin: 24px 0 12px 0;
  }
  .shared-modal__actions {
    display: flex;
    justify-content: center;
  }
</style>
