import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {useLocalStorage} from "@vueuse/core";

export const useNotificationsStore = defineStore('notificationsStore', () => {
    const notifications = ref([])
    const notificationsCount = ref(0)
    const countUnread = computed(() => notificationsCount.value);

    const toastsEnabled = useLocalStorage('notificationToastsEnabled', true);
    const soundsEnabled = useLocalStorage('notificationSoundsEnabled', true);

    const toggleToasts = (flag) => {
        toastsEnabled.value = flag !== undefined ? flag : !toastsEnabled.value;
    }

    const toggleSounds = (flag) => {
        soundsEnabled.value = flag !== undefined ? flag : !soundsEnabled.value;
    }

    const addNotification = (message) => {
        if (!notifications.value.some(msg => msg.id === message.id)) {
            notifications.value.push(message);
            notificationsCount.value++;
        }
    }

    const setNotifications = (messagesData) => {
        notifications.value = messagesData
    }

    const removeNotification = (id) => {
        const index = notifications.value.findIndex(
            (notification) => notification.id === id
        );
        if (index !== -1) {
            notifications.value.splice(index, 1);
        }
    }

    const setNotificationsCount = (count) => {
        notificationsCount.value = count;
    }

    const incrementNotificationsCount = () => {
        notificationsCount.value++
    }

    return {
        notifications,
        notificationsCount,
        countUnread,
        toastsEnabled,
        soundsEnabled,
        addNotification,
        removeNotification,
        toggleToasts,
        toggleSounds,
        setNotificationsCount,
        setNotifications,
        incrementNotificationsCount,
    };
})
