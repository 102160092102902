<script setup>
import { computed, onMounted, ref, watch } from "vue";
import {
    compareNewTimeWithSpecifiedTime,
    diffToMilliseconds,
    fromMillisecondsToObjectTime,
    fromObjectTimeToMilliseconds,
    validURL,
} from "./helpers.js";
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import {
    helpers,
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
} from "@vuelidate/validators";

import { useVuelidate } from "@vuelidate/core";
import axios from "axios";
import { nextTick } from "vue";

const props = defineProps({
    modelValue: Boolean,
    autowebinar: Object,
    currentPage: Number,
    startEventTime: String,
    finishEventTime: String,
    eventTypes: Object,
    actionStatuses: Object,
    actionStatusesForChangeLayout: Object,
    createEventStatuses: Array,
    buttonStatuses: Array,
    presentationList: Array,
    // roleStatuses: Object,
});

const emit = defineEmits([
    "update:modelValue",
    "setEvents",
    "setIsLoading",
    "setPresentationList",
    "setPenultimateEventTime",
    "setTotalItems",
]);

const presentationPartPath = import.meta.env.VITE_AWS_CDN;

const isNewTimeExceedingFinishTime = ref(false);

const form = ref({
    isPlusEvent: true,
    hours: "",
    minutes: "",
    seconds: "",
    milliseconds: "",
    message: "",
    type: "",
    button: "",
    author_name: "",
    isAdmin: false,

    selectedPresentationId: "",
    selectedSlideIndex: "",
});

const numberValidator = helpers.withMessage(
    "Це поле повинно бути числом",
    (value) => !isNaN(Number(value))
);

const between = (min, max) =>
    helpers.withParams(
        { type: "between", min, max },
        (value) => Number(value) >= min && Number(value) <= max
    );

// const validYoutubeUrl = helpers.withMessage(
//     "Це повинно бути YouYube посиланням",
//     (value) => validURL(value)
// );
const rules = {
    isPlusEvent: {},
    hours: {
        // required: helpers.withMessage("Це поле є обов'язковим", required),
        number: numberValidator,
        between: helpers.withMessage(
            "Значення повинно бути між 0 та 23",
            between(0, 23)
        ),
    },
    minutes: {
        // required: helpers.withMessage("Це поле є обов'язковим", required),
        number: numberValidator,
        between: helpers.withMessage(
            "Значення повинно бути між 0 та 59",
            between(0, 59)
        ),
    },
    seconds: {
        // required: helpers.withMessage("Це поле є обов'язковим", required),
        number: numberValidator,
        between: helpers.withMessage(
            "Значення повинно бути між 0 та 59",
            between(0, 59)
        ),
    },
    milliseconds: {
        // required: helpers.withMessage("Це поле є обов'язковим", required),
        number: numberValidator,
        between: helpers.withMessage(
            "Значення повинно бути між 0 та 999",
            between(0, 999)
        ),
    },
    message: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        minLength: helpers.withMessage("Не менше 1 символа", minLength(1)),
    },

    author_name: {
        minLength: helpers.withMessage("Не менше 3 символів", minLength(3)),
    },
    isAdmin: {},
    // broadcast: {
    //     required: helpers.withMessage("Це поле є обов'язковим", required),
    //     youtube: validYoutubeUrl,
    //     maxLength: helpers.withMessage(
    //         "Не більше 255 символів",
    //         maxLength(255)
    //     ),
    // },
};

const v$ = useVuelidate(rules, form, { $lazy: true });
const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const handleSend = async () => {
    if (!form.value.type) return;

    let newMillisecondsDiff = fromObjectTimeToMilliseconds({
        hours: Number(form.value.hours),
        minutes: Number(form.value.minutes),
        seconds: Number(form.value.seconds),
        milliseconds: Number(form.value.milliseconds),
    });
    newMillisecondsDiff = form.value.isPlusEvent
        ? +newMillisecondsDiff
        : -newMillisecondsDiff;

    if (
        compareNewTimeWithSpecifiedTime(
            newMillisecondsDiff,
            props.startEventTime,
            props.finishEventTime
        )
    ) {
        isNewTimeExceedingFinishTime.value = true;
        setTimeout(() => {
            isNewTimeExceedingFinishTime.value = false;
        }, 3000);
        return;
    }

    const data = {
        newMillisecondsDiff: newMillisecondsDiff,
        page: props.currentPage,
        type: form.value.type,
    };

    if (form.value.type === props.eventTypes?.["MESSAGE"]?.value) {
        if (!form.value.message.trim()) return;
        data.message = form.value.message;
    }

    if (
        form.value.type === props.eventTypes?.["BUTTON_SHOW"]?.value ||
        form.value.type === props.eventTypes?.["BUTTON_HIDE"]?.value
    ) {
        if (!form.value.button) return;
        data.button = form.value.button;
    }

    if (!!form.value.author_name && form.value.author_name.trim().length < 3) {
        return;
    }

    if (
        form.value.type === props.eventTypes.CHANGE_PRESENTATION.value ||
        form.value.type === props.eventTypes.CHANGE_SLIDE_PRESENTATION.value
    ) {
        if (
            !form.value.selectedPresentationId ||
            !form.value.selectedSlideIndex
        ) {
            return;
        }

        data.presentation = form.value.selectedPresentationId;
        data.current_slide = form.value.selectedSlideIndex;

        data.current_slide_url =
            currentPresentation.value.slides[form.value.selectedSlideIndex];
    }

    if (form.value.type === props.eventTypes.CHANGE_ACTION.value) {
        if (!form.value.action_status) {
            return;
        }

        data.action_status_for_change_action = form.value.action_status;
    }

    data.author_name = form.value.author_name;

    data.isAdmin = form.value.isAdmin;

    emit("setIsLoading", true);
    const res = await axios.post(
        route("workshops.event-store", {
            id: props.autowebinar.id,
            _query: {
                page: props.currentPage,
                perPage: props.perPage,
            },
        }),
        {
            data,
        }
    );

    emit("setEvents", res.data.editorWebinarEvent.data);
    if (!!res.data?.penultimateEvent) {
        emit("setPenultimateEventTime", res.data.penultimateEvent.created_at);
    }
    emit("setTotalItems", res.data.editorWebinarEvent.total);

    isVisible.value = !isVisible.value;
    emit("setIsLoading", false);
};

// const presentationList = ref(null);

const currentPresentation = ref(null);

const fetchPresentations = async () => {
    if (!props.presentationList.length) {
        const presentationsRes = await axios.get(
            route("workshops.get-tenant-presentations", {
                tenantId: props.autowebinar.tenantId,
            })
        );

        emit("setPresentationList", presentationsRes.data.presentations);
    }

    nextTick(() => {
        currentPresentation.value = props.presentationList.find(
            (presentation) =>
                presentation.id === form.value.selectedPresentationId
        );
    });
};

onMounted(async () => {
    if (
        form.value.type === props.eventTypes.CHANGE_PRESENTATION.value ||
        form.value.type === props.eventTypes.CHANGE_SLIDE_PRESENTATION.value
    ) {
        await fetchPresentations();
    }
    emit("setIsLoading", false);
});

watch(
    () => form.value.type,
    async (newType, oldType) => {
        if (
            !!form.value.button &&
            newType !== props.eventTypes["BUTTON_SHOW"].value &&
            newType !== props.eventTypes["BUTTON_HIDE"].value
        ) {
            form.value.button = "";
        }
        if (
            !!form.value.message &&
            newType !== props.eventTypes["MESSAGE"].value
        ) {
            form.value.message = "";
        }

        if (
            (!!form.value.selectedSlideIndex ||
                !!form.value.selectedPresentationId) &&
            (newType !== props.eventTypes.CHANGE_PRESENTATION.value ||
                newType !== props.eventTypes.CHANGE_SLIDE_PRESENTATION.value)
        ) {
            form.value.selectedSlideIndex = "";
            form.value.selectedPresentationId = "";
        }

        if (
            newType === props.eventTypes.CHANGE_PRESENTATION.value ||
            newType === props.eventTypes.CHANGE_SLIDE_PRESENTATION.value
        ) {
            emit("setIsLoading", true);
            await fetchPresentations();
            emit("setIsLoading", false);
        }
    }
);

watch(
    () => form.value.selectedPresentationId,
    () => {
        currentPresentation.value = props.presentationList.find(
            (presentation) =>
                presentation.id === form.value.selectedPresentationId
        );
        if (!!form.value.selectedSlideIndex) {
            form.value.selectedSlideIndex = "";
        }
    }
);
</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
    >
        <div
            v-if="isNewTimeExceedingFinishTime"
            class="modal-body time-exceeding"
        >
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Помилка</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <p class="time-exceeding__title">Упс...</p>
            <p class="time-exceeding__text">
                Ви не можете встановити час події пізніше, ніж час події
                завершення.
            </p>
        </div>
        <div v-else class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Створення івенту</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <div>
                <b-form-radio-group
                    v-model="form.isPlusEvent"
                    name="radio-sub-component"
                    class="mt-4"
                >
                    <b-form-radio :value="false">
                        Час до початку вебінару
                    </b-form-radio>
                    <b-form-radio :value="true">
                        Час після початку вебінару
                    </b-form-radio>
                </b-form-radio-group>
                <div class="d-flex gap-2">
                    <InputGroup
                        label="Години"
                        type="number"
                        v-model.number="form.hours"
                        size="lg"
                        class="mt-3 w-100"
                        :max-length="2"
                        :is-error="v$.hours.$error"
                        :error-message="v$.hours?.$errors[0]?.$message"
                        @blur="v$.hours.$touch()"
                    />
                    <InputGroup
                        label="Хвилини"
                        type="number"
                        v-model.number="form.minutes"
                        size="lg"
                        class="mt-3 w-100"
                        :max-length="2"
                        :is-error="v$.minutes.$error"
                        :error-message="v$.minutes?.$errors[0]?.$message"
                        @blur="v$.minutes.$touch()"
                    />
                    <InputGroup
                        label="Секунди"
                        type="number"
                        v-model.number="form.seconds"
                        size="lg"
                        class="mt-3 w-100"
                        :max-length="2"
                        :is-error="v$.seconds.$error"
                        :error-message="v$.seconds?.$errors[0]?.$message"
                        @blur="v$.seconds.$touch()"
                    />
                    <InputGroup
                        label="Миллисекунди"
                        type="number"
                        v-model.number="form.milliseconds"
                        size="lg"
                        class="mt-3 w-100"
                        :max-length="3"
                        :is-error="v$.milliseconds.$error"
                        :error-message="v$.milliseconds?.$errors[0]?.$message"
                        @blur="v$.milliseconds.$touch()"
                    />
                </div>
            </div>
            <SelectInputGroup
                label="Тип івенту"
                v-model="form.type"
                :options="createEventStatuses"
                size="lg"
                class="w-100 pt-3"
            />
            <div
                v-if="
                    !!form.type &&
                    props.eventTypes['MESSAGE'].value === form.type
                "
            >
                <InputGroup
                    label="Им'я"
                    type="text"
                    v-model="form.author_name"
                    size="lg"
                    class="mt-3 w-100"
                    :max-length="255"
                    :is-error="v$.author_name.$error"
                    :error-message="v$.author_name?.$errors[0]?.$message"
                    @blur="v$.author_name.$touch()"
                />

                <b-form-checkbox
                    v-model="form.isAdmin"
                    :unchecked-value="false"
                >
                    Від модератора
                </b-form-checkbox>

                <div class="d-flex gap-2">
                    <InputGroup
                        element="textarea"
                        label="Повідомлення"
                        v-model="form.message"
                        :rows="3"
                        :max-rows="10"
                        size="lg"
                        class="mt-3 w-100"
                        :is-error="v$.message.$error"
                        :error-message="v$.message?.$errors[0]?.$message"
                        @blur="v$.message.$touch()"
                    />
                </div>
            </div>
            <div
                v-if="
                    !!form.type &&
                    (props.eventTypes['BUTTON_HIDE'].value === form.type ||
                        props.eventTypes['BUTTON_SHOW'].value === form.type)
                "
            >
                <SelectInputGroup
                    label="Кнопка"
                    v-model="form.button"
                    :options="buttonStatuses"
                    size="lg"
                    class="w-100 pt-3"
                />
            </div>
            <div
                class="mt-2 d-flex justify-content-end"
                v-if="
                    (form.type === eventTypes.CHANGE_PRESENTATION.value ||
                        form.type ===
                            eventTypes.CHANGE_SLIDE_PRESENTATION.value) &&
                    !!presentationList.length
                "
            >
                <div class="fs my-3 card__title">
                    Вікно вебінару
                    <i
                        id="create-modal-presentation-warning"
                        class="bi bi-info-circle"
                    ></i>
                </div>
                <b-tooltip
                    class="text-left"
                    target="create-modal-presentation-warning"
                    custom-class="custom-tooltip"
                    offset="5"
                    triggers="hover"
                    :no-fade="true"
                    :delay="{ show: 50, hide: 50 }"
                >
                    Перевірте, щоб для попередньої події "Зміна вікна вебінару"
                    було встановлено значення "Презентація" або "Презентація та
                    трансляція".
                </b-tooltip>
            </div>
            <div
                class="presentation__wrapper-select mt-4"
                v-if="
                    (form.type === eventTypes.CHANGE_PRESENTATION.value ||
                        form.type ===
                            eventTypes.CHANGE_SLIDE_PRESENTATION.value) &&
                    !!presentationList.length
                "
            >
                <button
                    v-for="presentation in presentationList"
                    :key="presentation.id"
                    class="presentation__button-select"
                    :class="{
                        presentation__selected:
                            presentation.id === form.selectedPresentationId,
                    }"
                    @click="
                        () => {
                            form.selectedPresentationId = presentation.id;
                        }
                    "
                >
                    <div class="presentation__wrapper-image">
                        <img
                            class="presentation__image"
                            :src="`${presentationPartPath}/${presentation.slides['1']}`"
                            alt=""
                        />
                        <p class="presentation__name">
                            {{ presentation.name.slice(0, 15)
                            }}{{ presentation.name.length > 15 ? "..." : "" }}
                        </p>
                    </div>
                </button>
            </div>
            <div
                class="presentation__wrapper-select mt-4"
                v-if="
                    (form.type === eventTypes.CHANGE_PRESENTATION.value ||
                        form.type ===
                            eventTypes.CHANGE_SLIDE_PRESENTATION.value) &&
                    !!currentPresentation
                "
            >
                <button
                    v-for="(value, key) in currentPresentation?.slides"
                    :key="value"
                    class="presentation__button-select"
                    :class="{
                        presentation__selected: key === form.selectedSlideIndex,
                    }"
                    @click="
                        () => {
                            form.selectedSlideIndex = key;
                        }
                    "
                >
                    <div class="presentation__wrapper-image">
                        <img
                            class="presentation__image"
                            :src="`${presentationPartPath}/${value}`"
                            alt=""
                        />
                        <p class="presentation__name">
                            номер слайду: {{ key }}
                        </p>
                    </div>
                </button>
            </div>
            <div
                class="mt-4"
                v-if="form.type === eventTypes.CHANGE_ACTION.value"
            >
                <SelectInputGroup
                    label="Вікно вебінару"
                    v-model="form.action_status"
                    :options="actionStatusesForChangeLayout"
                    size="lg"
                    class="w-100"
                />
            </div>
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3"
                    @click="handleSend"
                >
                    Відправити
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped>
.presentation__wrapper-select {
    height: 150px;
    width: 100%;
    overflow: auto hidden;
    display: flex;
    gap: 1rem;
    padding-bottom: 10px;
}

.presentation__wrapper-select::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fefefe;
}

.presentation__wrapper-select::-webkit-scrollbar {
    height: 10px;
    background-color: #fefefe;
}

.presentation__wrapper-select::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

    background-color: var(--primary, #1db954);
}
.presentation__button-select {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
}
.presentation__selected {
    border: 2px solid var(--primary, #1db954);
}

.presentation__wrapper-image {
    height: 100%;
    padding: 16px 20px;
}
.presentation__image {
    display: block;
    height: 80%;
}
.presentation__name {
    padding: 0;
    margin: 0;
    height: 20%;
    padding-top: 8px;
}
</style>
