<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref, watch } from "vue";

import TabFormGeneralInfo from "@/Pages/MasterClass/Partials/TabFormGeneralInfo.vue";
import TabFormContent from "@/Pages/MasterClass/Partials/TabFormContent.vue";
import TabFormRegisterInfo from "@/Pages/MasterClass/Partials/TabFormRegisterInfo.vue";
import TabFormUsers from "@/Pages/Workshops/Partials/TabFormUsers.vue";
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";
import DownloadXlsxButton from "@/Components/buttons/DownloadXlsxButton.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
// Todo
const props = defineProps({
    user: Object,
    masterClass: Object,
    workshopTypes: Object,
    registerLink: "",
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
    webinarButtons: Object,
    roles: "",
    link: "",
});

const getButtonsFields = (webinarButtons) => {
    const buttons = [...webinarButtons];

    if (buttons.length === 0) {
        return [
            { title: "", url: "" },
            { title: "", url: "" },
        ];
    }

    if (buttons.length === 1) {
        if (buttons[0].order)
            buttons.push({ title: "", url: "", order: buttons[0].order + 1 });
        else buttons.push({ title: "", url: "" });
    }
    return buttons;
};

const form = useForm({
    workShop: props.masterClass,
    filePreview: null,
    publicLink: "",
    webinarButtons: getButtonsFields(props.webinarButtons || []),
});

const newUsersCount = computed(
    () => form.workShop.users.filter((user) => !user.access).length
);

const { t } = useI18n();

const state = computed(() => {
    return props.masterClass && props.masterClass.id > 0 ? "new" : "edit";
});

const page = ref({
    title: t("MASTERCLASS") + ": " + props.masterClass.title,
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route("dashboard"),
        },
        {
            text: "Воркшопи",
            href: route("workshops.workshops"),
        },
        {
            text: t("MASTERCLASS") + ": " + props.masterClass.title,
            active: true,
        },
    ],
});

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

function save() {
    console.log("Save masterClass");
    router.visit(
        route("workshops.update", {
            type: "masterclass",
            id: form.workShop.id,
        }),
        {
            method: "post",
            data: form,
            preserveState: false,
            preserveScroll: true,
            forceFormData: true,
        }
    );
}

watch(
    () => form.masterClass,
    (value) => {
        console.log("change edit");
    },
    { deep: true }
);

watch(
    () => form.workShop.title,
    (value) => {
        console.log("change edit");
        page.value.title = t("masterclass") + ": " + value;
        page.value.breadcrumbs[page.value.breadcrumbs.length - 1].text =
            t("masterclass") + ": " + value;
    },
    { deep: true }
);

const tabIndex = ref(0);

const selectedUsers = ref([]);

const downloadUsersXlsx = () => {
    console.log("handle download users: ", selectedUsers.value);
};

const shareLinkModal = ref(false);
const shareLink = ref("");

function handleShare() {
    shareLinkModal.value = !shareLinkModal.value;
    shareLink.value = form.masterClass.image; //TODO: get share link from masterClass
}
function isStartTimeGrant() {
    // const inputDateStr = form.workShop.config.date;
    // const hoursToAdd = 3;
    // const inputDate = new Date(inputDateStr);
    // const currentDate = new Date();
    // const futureDate = new Date(currentDate.getTime() + hoursToAdd * 60 * 60 * 1000);
    //
    // console.log(inputDateStr);
    //
    // if (inputDate > futureDate) {
    //     console.log('Вхідна дата пізніше за поточну дату плюс задану кількість годин.');
    //     return true;
    // } else {
    //     console.log('Вхідна дата раніше або дорівнює поточній даті плюс задану кількість годин.');
    //     return false;
    // }
    //
    // return false;
    return true;
}
</script>

<template>
    <div>
        <Head title="Воркшопи" />
        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                    <!--          <DownloadXlsxButton v-if="tabIndex === 3" @click="downloadUsersXlsx"/>-->
                    <b-button
                        v-if="form.workShop.status === 'PUBLISH'"
                        class="outline-dark"
                        pill
                        size="lg"
                        @click="handleShare"
                    >
                        Поділитися подією
                        <i class="ph-share"></i>
                    </b-button>
                    <ShareModal
                        v-model="shareLinkModal"
                        :shared-link="registerLink"
                    />
                </div>
            </template>

            <template #default>
                <b-tabs v-model="tabIndex" nav-class="page-tabs">
                    <b-tab title="Основна інформація" active>
                        <TabFormGeneralInfo
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :roles="roles"
                            :team="team"
                            :team-presenter="teamPresenter"
                            :team-moderator="teamPresenter"
                            :register-link="registerLink"
                        />
                    </b-tab>
                    <b-tab title="Контент майстеркласу ">
                        <TabFormContent :form="form" @save="save" />
                    </b-tab>
                    <b-tab
                        v-if="!form.workShop.config.access"
                        title="Реєстрація"
                        :disabled="form.workShop.config.access"
                    >
                        <TabFormRegisterInfo @save="save" :form="form" />
                    </b-tab>
                    <b-tab title="Користувачі">
                        <template #title>
                            Користувачі
                            <div
                                v-if="newUsersCount > 0"
                                class="nav-link__counter"
                            >
                                {{ newUsersCount }}
                            </div>
                        </template>
                        <TabFormUsers v-model="selectedUsers" :form="form" />
                    </b-tab>

                    <template #tabs-end>
                        <div class="tabs-end">
                            <PreviewButton
                                v-if="form.workShop.status === 'DRAFT'"
                                :href="
                                    route('workshops.masterclass.view', {
                                        token: form.workShop.id,
                                    })
                                "
                                target="_blank"
                            />
                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
.module-content {
    min-height: calc(100vh - 120px);
}

.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}
</style>
