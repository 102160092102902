<script setup>
import ChatApp from "@/Components/chat/ChatApp.vue";
import {useI18n} from 'vue-i18n';
import {Head, usePage} from '@inertiajs/vue3';
import PageHeader from '@/Components/PageHeader.vue';

const props = defineProps({
  conversations: Object,
  currentChatId: Number,
  messages: Object,
  contactsGroups: Object,
})

const { t } = useI18n();

const page = {
  title: "Чати",
  breadcrumbs: [
    {
      text: "Дашборд",
      href: "/",
    },
    {
      text: "Чати",
      active: true,
    },
  ],
}

</script>

<template>
  <Head :title="page.title"/>

  <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

  <ChatApp
      :user-profile="$page.props?.auth?.user"
      :conversations="conversations"
      :contacts-groups="contactsGroups"
  />
</template>

<style scoped lang="scss">

</style>
