<script setup>
import {ref} from 'vue';
import {router, Head} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";
import CourseItem from "@/Pages/StudentCourses/Partials/CourseItem.vue";
import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import useRequestProgress from '@/composables/useRequestProgress.js';

defineProps({
    user: Object,
    courses: Object,
})

const { t } = useI18n();

const {
  isLoading: isRequestProgressLoading,
  setIsLoading: setRequestProgressLoading
} = useRequestProgress()

const page = {
    title: t('courses-title'),
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route('dashboard'),
        },
        {
            text: t('courses-title'),
            active: true,
        },
    ],
}

const statuses = [
    { value: "all", text: t('course-status-all') },
    { value: 'draft', text: t('course-status-draft') },
    { value: 'published', text: t('course-status-published') },
    { value: 'archived', text: t('course-status-archived') },
];

const status = ref("all");

</script>

<template>
    <div>
        <Head :title="$t('courses-title')"/>

        <PageContentCard :title="$t('courses-title')">
            <template #header-start>

            </template>

            <template #default>
                <b-row>
                    <b-col
                        v-for="course of courses.data"
                        lg="3" md="4" sm="6"
                        :key="courses.id"
                    >
                        <CourseItem :course="course" @click="() => router.get(`/student/courses/items/${course.id}`)" />
                    </b-col>
                </b-row>
            </template>
        </PageContentCard>
    </div>
</template>
