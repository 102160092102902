<script setup>
import ChatListFilter from "@/Components/chat/ChatListFilter.vue";
import ChatContactsList from "@/Components/chat/ChatContactsList.vue";
import {computed, ref} from "vue";

const props = defineProps({
    conversations: Object,
    currentConversationId: String,
    currentChatId: String,
})

const emit = defineEmits(["action-clicked"])

const showConversationsFilter = ref(false)

const conversationsGroups = ref([])

const typesOptions = [
    { title: 'Всі', value: null },
    { title: 'Курси', value: 'course' },
    { title: 'Менторські програми', value: 'mentor-program' }
];

const contactsEntitiesOptions = computed(() => {
    return props.conversations
        ? Object.keys(props.conversations).map((itemKey) => {
            const item = props.conversations[itemKey]
            return {
                value:  item.entity_id,
                title:  item.title,
                type:  item.entity_type,
            }
        })
        : []
});

const clickAction = (data) => {
    emit("action-clicked", data);
}

</script>

<template>
    <div>
        <div class="py-3 px-3">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                    <h5 class="mb-0">Чати</h5>
                </div>
<!--                <div class="flex-shrink-0">-->
<!--                    <b-button type="link" variant="subtle-primary" size="sm" @click="showConversationsFilter = !showConversationsFilter">-->
<!--                        <i class="bi bi-filter-circle-fill align-bottom"></i>-->
<!--                    </b-button>-->
<!--                </div>-->
            </div>
            <ChatListFilter
                v-show="showConversationsFilter"
                :types="typesOptions"
                :entities-options="contactsEntitiesOptions"
            />
        </div>
        <perfect-scrollbar class="chat-room-list">
            <!-- Regular chats -->
            <div class="chat-message-list">
                <div v-for="(chtContact, index) of conversations" :key="index" class="mt-0">
                    <div v-b-toggle="`conversation-group-${ index }`" class="contact-list-title d-flex align-items-start cursor-pointer">
                        <div class="contact-list__dropdown-icon d-inline-block">
                            <i class="bi bi-chevron-down"></i>
                        </div>
                        <div class="d-inline-block flex-grow-1 align-left">({{ chtContact.conversations.data ? chtContact.conversations.data.length : 0}}) {{ chtContact.title }}</div>
                        <div class="flex-shrink-0 text-body-secondary">
                            <template v-if="chtContact.entity_type === 'course'">
                                Курс
                            </template>
                            <template v-else-if="chtContact.entity_type === 'workshop'">
                                ВШ
                            </template>
                            <template v-else-if="chtContact.entity_type === 'mentor-program'">
                                МП
                            </template>
                        </div>
                    </div>
                    <b-collapse :id="`conversation-group-${ index }`">
                        <ul
                            v-if="chtContact.conversations.data"
                            class="list-unstyled contact-list"
                        >
                            <li
                                v-for="(conversation, index) of chtContact.conversations.data"
                                :key="index"
                                :id="`contact-id-${conversation.id}`"
                                data-name="direct-message"
                                class="contact-list__item"
                                :class="{ 'active': `${chtContact.entity_type}_${chtContact.entity_id}_${conversation.userId}` === currentChatId }"
                                @click="clickAction({ action: 'open', data: conversation.id })"
                            >
<!--                                {{ conversation }}-->
                                <b-link :class="{ 'unread-msg-user': conversation.Channels }">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 chat-user-img align-self-center me-2 ms-0"
                                             :class="{ 'online': conversation.online, 'away': !conversation.online }">
                                            <div class="avatar-xs" v-if="conversation.image">
                                                <img :src="conversation.image"
                                                     class="rounded-circle img-fluid userprofile" alt="">
                                                <span class="user-status"></span>
                                            </div>
                                            <div class="avatar-xs" v-if="conversation.isImg">
                                                <div
                                                    class="avatar-title rounded-circle bg-primary text-white fs-xxs">
                                                    {{ conversation.profile }}
                                                </div>
                                                <span class="user-status"></span>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h6 class="text-truncate mb-0">{{ conversation.name }}</h6>
                                            <div class="mt-1" style="font-size: 10px!important; font-weight: 400;">
                                                <template v-if="conversation.lastMessage && conversation.lastMessage.length > 0">
                                                    {{ conversation.lastMessage }}
                                                </template>
                                                <span v-else class="text-body-secondary">
                                                                Поки повідомлень нема.
                                                            </span>
                                            </div>
                                        </div>
                                        <div class="ms-auto text-center">
                                            <div class="" style="font-size: 10px!important; font-weight: 400;">11:21</div>
                                            <span v-if="conversation.countUnread > 0" class="badge rounded-pill bg-success px-2 mt-1">{{ conversation.countUnread }}</span>
                                        </div>
                                    </div>
                                </b-link>
                            </li>
                            <li
                                v-for="(c, key) of chtContact.contacts"
                                :key="key"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <p class="text-truncate contactlist-name mb-0">
                                            {{ c.name }}
                                        </p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <div class="dropdown">
                                            <b-link
                                                href="#"
                                                class="text-muted"
                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false"
                                                @click="() => getOrCreateConversation(chtContact.entity_type, chtContact.entity_id, c.user_id)"
                                            >
                                                <i class="bi bi-send-plus"></i>
                                                <!--                                                                <i class="bi bi-three-dots-vertical"></i>-->
                                            </b-link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </b-collapse>
                </div>
            </div>
            <!-- ./Regular chats -->

            <!--  Groups  -->
            <div>
                <div v-if="conversationsGroups && conversationsGroups.length > 0" class="d-flex align-items-center px-4 mt-4 mb-2">
                    <div class="flex-grow-1">
                        <h4 class="mb-0 fs-xs text-muted text-uppercase">Групові чати</h4>
                    </div>
                </div>

                <div v-if="conversationsGroups && conversationsGroups.length > 0" class="chat-message-list">
                    <ul class="list-unstyled chat-list chat-user-list mb-0" id="channelList">
                        <li
                            v-for="(channel, index) of conversationsGroups"
                            :key="index"
                            :id="`contact-id-${channel.id}`"
                            @click="selectConversation(channel.name, channel.image, 'channel', channel.id)"
                            data-name="channel"
                            :class="(username == channel.id) ? 'active' : ''"
                        >
                            <b-link href="#" :class="{ 'unread-msg-user': channel.Channels }">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 chat-user-img align-self-center me-2 ms-0">
                                        <div class="avatar-xxs">
                                            <div class="avatar-title bg-light rounded-circle text-body">#</div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden">
                                        <p class="text-truncate mb-0">{{ channel.name }}</p>
                                    </div>
                                    <div>
                                        <div class="flex-shrink-0 ms-2">
                                                    <span
                                                        class="badge bg-dark-subtle text-dark rounded p-1"
                                                    >
                                                        {{ channel.Channels }}
                                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </b-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!--  ./Groups  -->

        </perfect-scrollbar>
    </div>
</template>

<style scoped lang="scss">

</style>
