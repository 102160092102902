<script setup>

import {Link} from "@inertiajs/vue3";
import {useNotificationsStore} from "@/stores/notificationsStore.js";
import {BButton} from "bootstrap-vue-next";
import {onMounted, ref, watch} from "vue";
import {toast} from "vue3-toastify";
import alertSound from "../../sounds/mixkit-light-button-2580.wav";
import {getLatestNotifications, markAsRead} from "@/api/notifications.js";
import { formatRelative } from 'date-fns';
import { uk } from 'date-fns/locale';

const props = defineProps({
    isPreview: {
        type: Boolean,
        default: false
    },
})

const notificationsStore = useNotificationsStore()

const toastsEnabledModel = ref(notificationsStore.toastsEnabled)
const soundsEnabledModel = ref(notificationsStore.soundsEnabled)

const triggerTestMessage = () => {
    toast.success(
        '<strong>Тема сповіщення</strong>\nДемонстраційне сповіщення🤘👋🙂', {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            limit: 3,
            dangerouslyHTMLString: true,
            icon: "✉️",
        });
}

const triggerTestSound = () => {
    const audio = new Audio(alertSound);
    audio.volume = 0.2;
    audio.play().catch(error => {
        console.error('Error playing sound:', error);
    });
};

const latestNotificationsLoading = ref(false);
const loadLatestNotifications = async () => {
    if (!latestNotificationsLoading.value) {
        latestNotificationsLoading.value = true
        const response = await getLatestNotifications(1)

        if (response.data) {
            notificationsStore.setNotifications(response.data?.notifications?.data)
            notificationsStore.setNotificationsCount(response.data?.totalCount)
        }
        latestNotificationsLoading.value = false
    }
}

const markListAsRead = async () => {
    if (!latestNotificationsLoading.value) {
        latestNotificationsLoading.value = true
        const ids = notificationsStore.notifications?.map((notification) => notification.id)
        const response = await markAsRead(ids)

        if (response.data) {
            notificationsStore.setNotifications(response.data?.notifications?.data)
            notificationsStore.setNotificationsCount(response.data?.totalCount)
        }
        latestNotificationsLoading.value = false
    }
}

watch(() => toastsEnabledModel.value, (newVal) => {
    notificationsStore.toggleToasts(newVal)
    if (newVal) {
        triggerTestMessage();
    }
})

watch(() => soundsEnabledModel.value, (newVal) => {
    notificationsStore.toggleSounds(newVal)
    if (newVal) {
        triggerTestSound();
    }
})

onMounted(async () => {
    await loadLatestNotifications()
})

</script>

<template>
    <div class="dropdown topbar-head-dropdown ms-1 header-item">
        <b-button
            type="button" variant="ghost-dark"
            class="btn-icon btn-topbar rounded-circle"
            :disabled="isPreview"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <i class="bi bi-bell-fill fs-4"></i>
            <span v-if="notificationsStore.countUnread > 0" class="position-absolute topbar-badge cartitem-badge fs-3xs translate-middle badge rounded-pill bg-danger">
                <span class="notification-badge">
                    {{ notificationsStore.countUnread }}
                </span>
                <span class="visually-hidden">unread messages</span>
            </span>
        </b-button>
        <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-cart-dropdown"
        >
            <div class="p-3 border-bottom">
                <b-row class="align-items-center">
                    <b-col>
                        <h6 class="m-0 fs-lg fw-semibold">Останні сповіщення</h6>
                    </b-col>
                </b-row>
            </div>
            <div class="messagesDropdown__header p-2">
                <BFormCheckbox v-model="toastsEnabledModel" switch inline>
                    Вспливаючі повідомлення
                </BFormCheckbox>
                <BFormCheckbox v-model="soundsEnabledModel" switch inline>
                    Звукові сповіщення
                </BFormCheckbox>
            </div>
            <hr class="m-0">
            <perfect-scrollbar :style="{minHeight: '100px', maxHeight: '300px'}">
                <div class="p-0">
                    <div v-if="notificationsStore.notifications?.length <= 0" class="text-center empty-cart">
                        <div class="avatar-md mx-auto my-3">
                            <div class="avatar-title bg-info-subtle text-info fs-2 rounded-circle">
                                <i class="ph-chat-circle-text-fill"></i>
                            </div>
                        </div>
                        <h5 class="mb-3">Нових сповіщень поки немає.</h5>
                    </div>
                    <div v-else class="messages-list">
                        <Link
                            v-for="(message, index) of notificationsStore.notifications"
                            :key="message.id"
                            :href="message.data?.action ? message.data?.action?.href : '#'"
                            class="d-block dropdown-item product text-wrap p-2 border-bottom"
                        >
                            <div class="d-flex">
<!--                                <div class="avatar-sm me-3 flex-shrink-0">-->
<!--                                    <div class="avatar-title bg-light rounded">-->
<!--                                        <img :src="noAvatar" class="rounded-circle header-profile-user" alt="user-pic"/>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="flex-grow-1">
                                    <h6 class="mt-0 mb-1 fs-md">
                                        <span class="text-reset">
                                            {{ message.data?.title }}
                                        </span>
                                    </h6>
                                    <p class="mb-1 fs-sm text-muted">
                                        {{ message.data?.description }}
                                    </p>
                                    <p class="mb-1 fs-sm text-bold">
                                        {{ formatRelative(new Date(message.created_at), new Date(), { locale: uk }) }}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </perfect-scrollbar>

            <BButton v-if="notificationsStore.notificationsCount > 0" class="btn btn-md btn-ghost-success w-100 p-3" role="button" target="_self" @click="markListAsRead" >
                Відмітити прочитаними
            </BButton>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
