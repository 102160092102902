<script setup>
import {computed, ref, watch, watchEffect} from 'vue';
import {Head, router, usePage} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";
import CourseItem from "@/Pages/Courses/Partials/CourseItem.vue";
import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import {useRoleChecker} from "@/composables/useRoleChecker.js";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";

const props = defineProps({
  user: Object,
  courses: Object,
  courseStatuses: Object,
})

const {t} = useI18n();
const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const page = {
  title: t('courses-title'),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route('dashboard'),
    },
    {
      text: t('courses-title'),
      active: true,
    },
  ],
}

const statuses = computed(() => {
  // console.log(props);
  return (props.courseStatuses && props.courseStatuses.length > 0) ? props.courseStatuses.map((item) => {
    return {value: item, text: t(item)};
  }) : [];
});

const status = ref("ALL");
watchEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  status.value = urlParams.get('status') || 'ALL';
});

watch(status, value => {
  router.visit(route('courses.courses', {"status": status.value}),
      {
        method: 'get',
        preserveState: true
      })
});

</script>

<template>
  <div>
    <Head :title="$t('courses-title')"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>

    <PageContentCard :title="$t('courses-title')">
      <template #header-start>
        <b-form-select
            v-model="status"
            :placeholder="''"
            :options="statuses"
            size="sm"
            ref="input"
            class="ms-3"
            v-uid
        />
      </template>

      <template #header-end>
        <div class="header-end">
          <DiskUsageProgress />
          <b-button v-if="!isReadOnly" class="outline-dark" pill size="lg" href="/courses/create">
            {{ $t('btn-create') }}
            <i class="ph-plus-bold"></i>
          </b-button>
        </div>
      </template>

      <template #default>
        <b-row>
          <b-col
              v-for="course of courses"
              lg="3" md="4" sm="6"
              :key="courses.id"
          >
            <CourseItem :course="course"/>
          </b-col>
        </b-row>
      </template>
    </PageContentCard>
  </div>
</template>
