;
<script setup>
import { computed, ref, triggerRef } from "vue";
import CommentButton from "@/Components/buttons/CommentButton.vue";
import UserDetailsModal from "@/Components/modals/UserDetailsModal.vue";
import { router, usePage } from "@inertiajs/vue3";
import useRequestProgress from "@/composables/useRequestProgress.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    form: Object,
    modelValue: Array,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isFinished = computed(
    () => props.form?.workShop?.config?.workShopStatus === "FINISHED"
);

const userRoles = pageData?.props?.auth?.roles || [];
const workshopRoles = props.form?.workShop?.roles || [];
const isReadOnly =
    hasReadOnlyAccess(userRoles, workshopRoles, "PRESENTER") || isFinished;

const emit = defineEmits(["update:modelValue"]);

const selectedUsers = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const handleUserSelect = (id) => {
    const index = selectedUsers.value.findIndex((el) => el === id);
    if (index >= 0) selectedUsers.value.splice(index, 1);
    else selectedUsers.value.push(id);
    triggerRef(selectedUsers);
};

const userDetailsModal = ref(false);

const tmpUser = ref(null);

const handleUserDetails = (id) => {
    const index = props.form.workShop.users.findIndex((el) => el.id === id);
    if (index >= 0) {
        tmpUser.value = props.form.workShop.users[index];
        userDetailsModal.value = !userDetailsModal.value;
    }
};

const selectedRegisterId = ref(null);

const { setIsLoading } = useRequestProgress();

function removeUser(userId) {
    setIsLoading(true);
    axios
        .post(route("workshops.remove-user"), {
            registerId: userId,
            webinarId: props.form.workShop.id,
        })
        .then((response) => {
            console.log("status: ", response.data.status);
            if (response.data.status) {
                const index = props.form.workShop.users.findIndex(
                    (item) => item.id === userId
                );
                if (index !== -1) {
                    props.form.workShop.users.splice(index, 1);
                }
            }
        })
        .catch((error) => {
            console.error("There was an error!", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
}

function grantAccess(userId) {
    setIsLoading(true);
    axios
        .post(route("workshops.grant-access"), {
            registerId: selectedRegisterId.value,
            webinarId: props.form.workShop.id,
        })
        .then((response) => {
            if (response.data.status) {
                const item = props.form.workShop.users.find(
                    (item) => item.id === selectedRegisterId.value
                );
                if (item) {
                    item.access = true;
                }
            }
        })
        .catch((error) => {
            console.error("There was an error!", error);
        })
        .finally(() => {
            selectedRegisterId.value = null;
            setIsLoading(false);
        });
}

const deleteUserModal = ref(false);
</script>

<template>
    <!--    {{isReadOnly}} {{props.form.workShop.roles}} {{userRoles}}-->
    <b-container fluid class="tab-form">
        <b-form>
            <div class="module-table workshop-users">
                <div class="module-table__header border-bottom pb-2">
                    <div
                        class="module-table__col module-table__col--status text-uppercase opacity-50"
                    >
                        Статус
                    </div>
<!--                    <div-->
<!--                        v-for="field of form.workShop.registerFields.filter(item => ['pib', 'email', 'phone'].includes(item.name))"-->
<!--                        :key="field.id"-->
<!--                        class="module-table__col text-uppercase opacity-50"-->
<!--                        :class="'module-table__col&#45;&#45;' + field.name"-->
<!--                    >-->
<!--                        {{ field.label }}-->
<!--                    </div>-->
                    <div class="module-table__col module-table__col--custom-fields text-uppercase opacity-50">
                        <div
                            v-for="field of form.workShop.registerFields"
                            :key="field.id"
                            class="custom-fields__item"
                        >
                            {{ field.label }}
                        </div>
                    </div>
                    <div
                        v-if="form.workShop.config.is_paid"
                        class="module-table__col module-table__col--payment text-uppercase opacity-50"
                    >
                        Чек
                    </div>
                    <div
                        class="module-table__col module-table__col--actions text-uppercase opacity-50 pe-2"
                    >
                        Дії
                    </div>
                </div>
                <div
                    v-for="(user, index) of form.workShop.users"
                    :key="user.id"
                    class="module-table__row border-bottom py-3"
                    :class="{
                        'module-table__row--selected-user':
                            selectedUsers.includes(user.id),
                    }"
                    @click.self="handleUserSelect(user.id)"
                >
                    <div class="module-table__col module-table__col--status">
                        <span
                            class="module-table__access-bage"
                            :class="[
                                user.access === false
                                    ? 'module-table__access-bage-new'
                                    : '',
                            ]"
                        >
                            {{
                                user.access === false ? "новий" : "перевірений"
                            }}
                        </span>
                    </div>
<!--                    <div-->
<!--                        v-for="field of form.workShop.registerFields.filter(item => ['pib', 'email', 'phone'].includes(item.name))"-->
<!--                        :key="field.id"-->
<!--                        class="module-table__col"-->
<!--                        :class="'module-table__col&#45;&#45;' + field.name"-->
<!--                        :id="field.name + '_' + index"-->
<!--                    >-->
<!--                        {{ user.fields.find(item => item.name === field.name)?.value || '' }}-->
<!--                        <b-popover :target="field.name + '_' + index" triggers="hover" placement="topleft" :no-fade="true" :delay="{ show: 50, hide: 50 }">-->
<!--                            {{ user.fields.find(item => item.name === field.name)?.value || '' }}-->
<!--                        </b-popover>-->
<!--                    </div>-->
                    <div class="module-table__col module-table__col--custom-fields">
                        <div
                            v-for="field of form.workShop.registerFields"
                            :key="field.id"
                            class="custom-fields__item"
                            :id="'custom_' + field.name + '_' + index"
                        >
                            {{ user.fields?.find(item => item.name === field.name)?.value || '' }}
                            <b-popover :target="'custom_' + field.name + '_' + index" triggers="hover" placement="topleft" :no-fade="true" :delay="{ show: 50, hide: 50 }">
                                {{ user.fields?.find(item => item.name === field.name)?.value || '' }}
                            </b-popover>
                        </div>
                    </div>
                    <div
                        v-if="form.workShop.config.is_paid"
                        class="module-table__col module-table__col--payment"
                    >
                        <i class="ph-arrow-down-bold"></i>
                        <a target="_blank" :href="user.payment_check"
                            >Завантажити</a
                        >
                    </div>
                    <div class="module-table__col module-table__col--actions">
                        <!--                        <b-button variant="success" pill v-if="user.access === false" v-b-modal.grantAccessModal-->
                        <!--                                  @click="selectedRegisterId = user.id">-->
                        <!--                            Надати доступ-->
                        <!--                        </b-button>-->
                        <!--                        <i class="bi bi-x-lg" @click="removeUser(user.id)"></i>-->
                        <div class="dropdown flex-shrink-0">
                            <button
                                size="sm"
                                class="btn-icon dropdown-menu__button"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                :disabled="isReadOnly"
                            >
                                <i class="bi bi-three-dots-vertical"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li v-if="user.access === false">
                                    <span
                                        class="dropdown-item"
                                        v-b-modal.grantAccessModal
                                        @click="selectedRegisterId = user.id"
                                    >
                                        Надати доступ
                                    </span>
                                </li>
                                <li>
                                    <span
                                        class="dropdown-item"
                                        @click="deleteUserModal = true"
                                    >
                                        <i
                                            class="bi bi-trash3 align-baseline me-1"
                                        ></i>
                                        Видалити
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <ConfirmModal
                            v-model="deleteUserModal"
                            title="Видалення студента"
                            decline-label="Скасувати"
                            accept-label="Так, видалити"
                            @accept-btn="() => removeUser(user.id)"
                            @decline-btn="() => console.log('decline')"
                        >
                            <template v-slot:description>
                                Ви впевнені, що хочете видалити цього студента?
                            </template>
                        </ConfirmModal>
                    </div>
                </div>
                <!--        </b-form-checkbox-group>-->
            </div>
            <UserDetailsModal
                v-model="userDetailsModal"
                :user="tmpUser"
                :ignored-fields="['id', 'comment', 'logo']"
            />
            <b-modal id="grantAccessModal" hide-footer hide-header centered>
                <div class="modal-body">
                    <div class="grand-modal-title">
                        <i class="ph-warning"></i>
                        <h4>
                            <!-- Ви надаєте доступ до воркшопу:
                            {{ form.workShop.title }} -->
                            Підтвердіть надання доступу
                        </h4>
                    </div>
                    <p class="grand-modal-desc">
                        Якщо ви надасте студенту доступ до воркшопу, його вже не
                        можна буде скасувати.
                    </p>
                    <div class="grand-modal-actions">
                        <b-button
                            class="outline-dark"
                            pill
                            v-b-modal.grantAccessModal
                            @click="selectedRegisterId = null"
                        >
                            Скасувати
                        </b-button>
                        <b-button
                            class="grand-modal-accept m-2"
                            pill
                            v-b-modal.grantAccessModal
                            @click="grantAccess"
                        >
                            Надати доступ
                        </b-button>
                    </div>
                </div>
            </b-modal>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.workshop-users {
    font-family: e-Ukraine;
    .module-table__col--status {
        display: flex;
        min-width: 85px;
        .module-table__access-bage {
            min-width: 85px;
            font-size: smaller;
            text-align: center;
            background-color: var(--green, #1db954);
            color: white;
            padding: 2px;
            border-radius: 5px;
        }

        .module-table__access-bage-new {
            background: var(--purple, #f24822);
        }
    }
    .module-table__col--email {
        width: 15%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        cursor: pointer;
        @media only screen and (max-width: 1368px) {
            width: 10%;
        }
    }
    .module-table__col--pib {
        width: 15%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        cursor: pointer;
        @media only screen and (max-width: 1368px) {
            width: 10%;
        }
    }

    .module-table__col--phone {
        width: 8%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        cursor: pointer;
        @media only screen and (max-width: 1368px) {
            width: 10%;
        }
    }

    .module-table__col--payment {
        width: 120px!important;
    }

    .module-table__row--selected-user {
        border-color: #1db954;
        border-style: solid;
        border-width: 1px 0 1px 0;
    }

    .module-table__col--actions {
        width: 50px !important;
        .dropdown-menu__button {
            margin: 0!important;
            padding: 0!important;
        }
    }
    .module-table__col--custom-fields {
        display: flex;
        justify-content: space-between;
        flex: 1;
        gap: 8px;
        .custom-fields__item {
            flex: 1;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            cursor: pointer;
        }
    }
}
.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

#input-1 {
    background-color: #1db9540a;
    border: 1px solid #1db954;
    border-radius: 4px;
}
</style>
