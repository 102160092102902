<script setup>

import ChatListFilter from "@/Components/chat/ChatListFilter.vue";
import {computed, ref} from "vue";

const props = defineProps({
    contactsGroups: Object,
    currentConversationId: String,
    currentChatId: String,
})

const emit = defineEmits(["action-clicked"])

const showConversationsFilter = ref(false)

const typesOptions = [
    { title: 'Всі', value: null },
    { title: 'Курси', value: 'course' },
    { title: 'Менторські програми', value: 'mentor-program' }
];

const contactsEntitiesOptions = computed(() => {
    return props.contactsGroups
        ? Object.keys(props.contactsGroups).map((itemKey) => {
            const item = props.contactsGroups[itemKey]
            return {
                value:  item.entity_id,
                title:  item.title,
                type:  item.entity_type,
            }
        })
        : []
});

const clickAction = (data) => {
    emit("action-clicked", data);
}

// todo: update conversations list

</script>

<template>
    <div>
        <div class="py-3 px-3">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                    <h5 class="mb-0">Контакти</h5>
                </div>
<!--                <div class="flex-shrink-0">-->
<!--                    <b-button type="link" variant="subtle-primary" size="sm" @click="showConversationsFilter = !showConversationsFilter">-->
<!--                        <i class="bi bi-filter-circle-fill align-bottom"></i>-->
<!--                    </b-button>-->
<!--                </div>-->
            </div>
            <ChatListFilter
                v-show="showConversationsFilter"
                :types="typesOptions"
                :entities-options="contactsEntitiesOptions"
            />
        </div>
        <div>
            <perfect-scrollbar class="chat-room-list">
                <div class="sort-contact">
                    <div v-for="(chtContact, index) of contactsGroups" :key="index" class="mt-0">
                        <div v-b-toggle="`contact-group-${ index }`" class="contact-list-title d-flex align-items-start cursor-pointer">
                            <div class="contact-list__dropdown-icon d-inline-block">
                                <i class="bi bi-chevron-down"></i>
                            </div>
                            <div class="d-inline-block flex-grow-1 align-left">({{chtContact.contacts.length}}) {{ chtContact.title }}</div>
                            <div variant="light" class="flex-shrink-0 text-body-secondary">
                                <template v-if="chtContact.entity_type === 'course'">
                                    Курс
                                </template>
                                <template v-else-if="chtContact.entity_type === 'workshop'">
                                    ВШ
                                </template>
                                <template v-else-if="chtContact.entity_type === 'mentor-program'">
                                    МП
                                </template>
                            </div>
                        </div>
                        <b-collapse :id="`contact-group-${ index }`">
                            <ul class="list-unstyled contact-list">
                                <li
                                    v-for="(c, key) of chtContact.contacts"
                                    :key="`${chtContact.entity_type}_${chtContact.entity_id}_${c.user_id}`"
                                    @click="() => clickAction({ action: 'get-conversation', data: {
                                        entity_type: chtContact.entity_type,
                                        entity_id: chtContact.entity_id,
                                        user_id: c.user_id}
                                    })"
                                    class="contact-list__item"
                                    :class="{ 'active': `${chtContact.entity_type}_${chtContact.entity_id}_${c.user_id}` === currentChatId }"
                                >
                                    <!--                                                @click="chatUsername(innerContact.name, innerContact.image, 'users')"-->
                                    <!--                                            >-->
                                    <div class="d-flex align-items-center">
                                        <!--                                                    <div class="flex-shrink-0 me-2">-->
                                        <!--                                                        <div class="avatar-xxs" v-if="innerContact.isImg">-->
                                        <!--                                                        <span-->
                                        <!--                                                            class="avatar-title rounded-circle bg-primary fs-xxs"-->
                                        <!--                                                        >-->
                                        <!--                                                            {{ innerContact.na }}-->
                                        <!--                                                        </span>-->
                                        <!--                                                        </div>-->
                                        <!--                                                        <div class="avatar-xxs" v-if="innerContact.image">-->
                                        <!--                                                            <img :src="innerContact.image"-->
                                        <!--                                                                 class="img-fluid rounded-circle" alt="">-->
                                        <!--                                                        </div>-->
                                        <!--                                                    </div>-->
                                        <div class="flex-grow-1">
                                            <p class="text-truncate contactlist-name mb-0">
                                                {{ c.name }}
                                            </p>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <div class="dropdown">
                                                <b-link
                                                    class="text-muted"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i class="bi bi-send-plus"></i>
                                                    <!--                                                                <i class="bi bi-three-dots-vertical"></i>-->
                                                </b-link>
                                                <!--                                                            <div class="dropdown-menu dropdown-menu-end">-->
                                                <!--                                                                <b-link class="dropdown-item" href="#"><i-->
                                                <!--                                                                    class="bi bi-pencil text-muted me-2 align-bottom"></i>Edit-->
                                                <!--                                                                </b-link>-->
                                                <!--                                                                <b-link class="dropdown-item" href="#"><i-->
                                                <!--                                                                    class="bi bi-slash-circle text-muted me-2 align-bottom"></i>Block-->
                                                <!--                                                                </b-link>-->
                                                <!--                                                                <b-link class="dropdown-item" href="#"><i-->
                                                <!--                                                                    class="bi bi-trash3 text-muted me-2 align-bottom"></i>Remove-->
                                                <!--                                                                </b-link>-->
                                                <!--                                                            </div>-->
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </b-collapse>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
