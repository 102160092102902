<script setup>
import {computed, ref} from "vue";
import {router} from "@inertiajs/vue3";
import {useDateFormatter} from "@/composables/useDateFormatter.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const props = defineProps({
  modelValue: Object,
    disabled: {
        type: Boolean,
        default: false
    }
})
const emit = defineEmits([
  'update:modelValue', 'remove-lesson-file'
]);
const files = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const { formatDate } = useDateFormatter()

function getExtensionFile(path) {
  const parts = path.split('.');
  return parts[parts.length - 1];
}

const removeVideo = (id) => {
  console.log('removeVideo', id);
  console.log( router.page.url);
  router.visit(route('courses.remove_lesson_file'),
      {
        method: 'post',
        data: {'redirect': router.page.url,
          'lessonTaskId': props.modelValue.id,
        },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
          props.form.course.contentItems = reponse.props.course.contentItems;
        }
      }
  )
}

const removeModal = ref(false)

</script>

<template>
  <div class="lesson-files">
    <div class="lesson-file">
<!--      {{files.file}}-->
<!--      <div class="lesson-file__props">{{ files.file.file_type }}</div>-->
<!--      <b-form-input class="lesson-file__title" v-model="files.file.title"></b-form-input>-->
      <div class="lesson-file__title">{{ files.file.original_name }}</div>
      <div class="lesson-file__props">{{ files.file.size }}</div>
      <div class="lesson-file__props">{{ formatDate(files.file.created_at, 'dd.MM.yyyy') }}</div>
      <button :disabled="disabled" @click="removeModal = !removeModal">
          <i class="bi bi-trash"></i>
      </button>
    </div>
      <ConfirmModal
          v-model="removeModal"
          title="Видалити файл"
          decline-label="Скасувати"
          accept-label="Так, видалити"
          @accept-btn="() => emit('remove-lesson-file', files)"
          @decline-btn="() => console.log('decline')"
      >
          <template v-slot:description>
              Видаливши відео, його не можна буде повернути. Видалити відео?
          </template>
      </ConfirmModal>
  </div>
</template>

<style scoped>
.lesson-files {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 20px;

  .lesson-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px 0;
    border-bottom: 1px solid #EBEBEB;

    .lesson-file__title, .lesson-file__props {
      font-family: e-Ukraine;
      font-size: 13px;
      font-weight: 300;
      line-height: 16px;
    }

    .lesson-file__title {
      flex: 1;
      color: #1E1E1E;
    }

    .lesson-file__props, .remove-btn {
      color: #C2C2C2;
    }

    .remove-btn {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }
}
</style>
