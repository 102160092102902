<script setup>
import { computed, ref } from "vue";
import PlanManagementModal from "@/Components/modals/PlanManagementModal.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { format, parseISO } from "date-fns";
import { uk } from "date-fns/locale";

const props = defineProps({
    membershipOptions: Object,
});

const planManagementModal = ref(false);
const confirmModal = ref(false);

const cancelPlan = () => {
    confirmModal.value = !confirmModal.value;
    console.log(confirmModal.value);
};

function formatLocalDate(date) {
    const parsedDate = parseISO(date);
    return format(parsedDate, "dd.MM.yy", { locale: uk });
}
</script>

<template>
    <div class="membership-options">
        <div class="membership-options__header">
            <div class="membership-options__title">
                План: {{ membershipOptions.plan.name }}
            </div>
            <div class="membership-options__valid-badge">
                Активний протягом днів: {{ membershipOptions.plan.activeLeft }}
            </div>
        </div>
        <div class="membership-options__content">
            <div class="membership-options__content--title">
                Що входить до плану
            </div>
            <div class="membership-options__plan-info">
                <div class="membership-options__plan-details">
                    <div>
                        <div
                            class="membership-options__plan-details-item--label"
                        >
                            Диск:
                        </div>
                        <div
                            class="membership-options__plan-details-item--data"
                        >
                            {{ membershipOptions.discUsage.capacity }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="membership-options__plan-details-item--label"
                        >
                            К-ть учасників:
                        </div>
                        <div
                            class="membership-options__plan-details-item--data"
                        >
                            {{ membershipOptions.plan.participantsCount }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="membership-options__plan-details-item--label"
                        >
                            Вартість
                        </div>
                        <div
                            class="membership-options__plan-details-item--data"
                        >
                            {{ membershipOptions.plan.price }}
                        </div>
                    </div>
                    <div>
                        <div
                            class="membership-options__plan-details-item--label"
                        >
                            Термін дії
                        </div>
                        <div
                            v-if="
                                membershipOptions.plan &&
                                membershipOptions.plan.validUntil
                            "
                            class="membership-options__plan-details-item--data"
                        >
                            До
                            {{
                                formatLocalDate(
                                    membershipOptions.plan.validUntil
                                )
                            }}
                        </div>
                    </div>
                </div>
                <div class="membership-options__management">
                    <div class="disk-usage">
                        <div>
                            <div class="disk-usage__label">Вільне місце</div>
                            <div class="disk-usage__data">
                                <span class="disk-usage__data--used">{{
                                    membershipOptions.discUsage.spaceUsage
                                }}</span>
                                <span class="opacity-50"> / </span>
                                <span>{{
                                    membershipOptions.discUsage.capacity
                                }}</span>
                            </div>
                        </div>
                        <div class="disk-usage-progress">
                            <div class="disk-usage-progress__label">
                                {{ membershipOptions.discUsage.percent }}%
                            </div>
                            <div class="disk-usage-progress__bar">
                                <b-progress
                                    :value="membershipOptions.discUsage.percent"
                                    height="16px"
                                    variant="success"
                                ></b-progress>
                            </div>
                        </div>
                    </div>
                    <b-button
                        variant="primary"
                        pill
                        size="lg"
                        @click="planManagementModal = !planManagementModal"
                    >
                        Керувати планом
                    </b-button>
                </div>
            </div>
        </div>
    </div>
    <PlanManagementModal
        v-model="planManagementModal"
        @cancel-plan="confirmModal = !confirmModal"
    />
    <ConfirmModal
        v-model="confirmModal"
        object-id="tempId"
        title="Після скасування плану ви втрачаєте доступ до: "
        warning-text="Залишилося 24 дн. у вашій передплаті. Після 15 жовт. 2023р. ви втратите доступ до користування lms."
        decline-label="Всеодно скасувати"
        accept-label="Залишити план"
        swap-buttons
        @accept-btn="(id) => console.log('accept', id)"
        @decline-btn="(id) => console.log('decline', id)"
    >
        <template v-slot:description>
            <ul>
                <li>
                    створення та редагування курсів, воркшопів, вебінарів,
                    автовебінарів і менторських програм;
                </li>
                <li>20 Гб простору на Диску;</li>
                <li>команди та комунікації зі студентами;</li>
                <li>статистики та прогресу;</li>
                <li>розкладу.</li>
            </ul>
        </template>
    </ConfirmModal>
</template>

<style lang="scss" scoped>
.membership-options {
    .membership-options__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        margin-bottom: 32px;

        .membership-options__title {
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }

        .membership-options__valid-badge {
            display: flex;
            padding: 13px 40px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid var(--green, #1db954);
            background: linear-gradient(
                    0deg,
                    rgba(29, 185, 84, 0.04) 0%,
                    rgba(29, 185, 84, 0.04) 100%
                ),
                #fff;
            color: var(--dark, #1e1e1e);
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
        }
    }

    .membership-options__content {
        display: flex;
        padding: 26px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--fields_color, #f7f7f8);

        .membership-options__content--title {
            color: var(--dark, #1e1e1e);
            font-family: e-Ukraine;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
        }

        .membership-options__plan-info {
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            border-radius: 8px;
            background: var(--white, #fff);

            .membership-options__plan-details {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-bottom: 24px;
                border-bottom: 1px solid #ebebeb;

                .membership-options__plan-details-item--label {
                    color: color-mix(
                        in srgb,
                        var(--dark, #1e1e1e),
                        transparent 50%
                    );
                    font-family: e-Ukraine;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 12px;
                    margin-bottom: 8px;
                }

                .membership-options__plan-details-item--data {
                    color: var(--dark, #1e1e1e);
                    font-family: e-Ukraine;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                }
            }
        }

        .membership-options__management {
            padding-top: 24px;
            width: 100%;
            display: flex;

            .disk-usage {
                display: flex;
                align-items: flex-end;
                flex: 1;

                .disk-usage__label {
                    color: var(--dark, #1e1e1e);
                    font-family: e-Ukraine;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 12px;
                }

                .disk-usage__data {
                    color: var(--dark, #1e1e1e);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    .disk-usage__data--used {
                        color: var(--green, #1db954);
                    }
                }

                .disk-usage-progress {
                    margin: 0 12px 0 20px;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    width: 200px;

                    .disk-usage-progress__label {
                        color: color-mix(
                            in srgb,
                            var(--dark, #1e1e1e),
                            transparent 50%
                        );
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                    }

                    .disk-usage-progress__bar {
                        flex: 1;
                        width: 100%;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
}
</style>
