<script setup>
  import InputGroup from '@/Components/InputGroup.vue';

  const props = defineProps({
    formField: Object,
  })

  const emit = defineEmits([
    'removeById',
  ]);

</script>

<template>
<!--  <InputGroup-->
<!--    :label="formField && formField['is_default'] && formField['is_required'] ? formField.label+'*' : formField.label"-->
<!--    v-model="formField.value"-->
<!--    size="lg"-->
<!--    class="w-100"-->
<!--  />-->
  <div class="custom-form-field" :class="{ 'not-default-field' : formField && formField['is_default'] }">
    {{formField && formField['is_default'] && formField['is_required'] ? formField.label+'*' : formField.label}}
  </div>
  <div v-if="!formField.is_default" class="d-flex align-items-center">
    <input :id='formField.id' type="checkbox" name="checkbox" v-model="formField.is_required" :unchecked-value="false" :value="true"/>
    <b-button variant="text-variant fs-5 p-0 ms-2" @click="() => emit('removeById', formField.id)">
      <i class="bi bi-x-lg"></i>
    </b-button>
    <b-tooltip class="text-left" :target="formField.id" custom-class="webinar-tooltip" offset="5" triggers="hover">
      Зробити поле обов'язковим
    </b-tooltip>
  </div>
</template>
<style lang="scss" scoped>
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "*";
  color: var(--dark, #1E1E1E);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 21.6px */
}
input[type="checkbox"]:checked::before {
  color: red;
}
input[type="checkbox"]:hover::before {
  color: var(--green, #1DB954);
}
.custom-form-field {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #C2C2C2;
  font-family: e-Ukraine;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
  margin-bottom: 16px;
}
.not-default-field {
  color: color-mix(in srgb, #323232, transparent 50%);
}
</style>
