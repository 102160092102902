<script setup>

import InputGroup from '@/Components/InputGroup.vue';
import {useForm} from '@inertiajs/vue3';

const props = defineProps({
  register: Object,
  registerFields: Object,
  work_shop_id: null,
  isPaid: false
})

const form = useForm({
  regFields: props.registerFields,
  workShopId: props.work_shop_id,
  isPaid: props.isPaid,
  testData:'test'
});

const onSubmit = () => {
  // console.log(route('register-to-work-shop_store'));
  form.post(route('register-to-work-shop_store'), {});
};

const onReset = () => {
  form.reset();
};
</script>

<template>
  <div class="form-login-header text-center">
    <h1 class="text-md login-title">
      Реєстрація
    </h1>
  </div>

  <b-form @submit.prevent="onSubmit" @reset="onReset" class="login-form">

    <div class="my-5">
      <InputGroup v-for="field in form.regFields"
                  v-model="field.value"
                  :label="field.label"
                  :placeholder="field.label"
                  :error-message="form.errors.name"
                  type="text"
                  required
                  autofocus
                  :autocomplete="field.name"
                  :is-error="!!form.errors.name"
      />
    </div>

    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button type="submit" variant="primary" size="lg" pill>
        Зареєструватись
      </b-button>
    </div>
  </b-form>
</template>

<style lang="scss">

</style>
