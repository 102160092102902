<script setup>

  const props = defineProps({
    formField: Object,
    disabled: {
        type: Boolean,
        default: false
    }
  })

  const emit = defineEmits([
    'removeById',
    'edit',
  ]);

</script>

<template>
  <div class="w-100 d-flex align-items-center">
    <div class="p-2 my-1 bg-white rounded-3 w-100 d-flex align-items-center me-2">
      <div class="payment-method__name">{{ formField.name }}</div>
      <div class="payment-method__value">{{ formField.value }}</div>
    </div>
    <div class="d-flex payment-method__action">
        <button :disabled="disabled" @click="() => emit('edit', formField)">
            <i class="bi bi-pencil-square fs-4 me-2"></i>
        </button>
        <button :disabled="disabled" @click="() => emit('removeById', formField.id)">
            <i class="bi bi-trash fs-4"></i>
        </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.payment-method__name {
  width: 30%;
}
.payment-method__name::after {
  content: ":";
}
.payment-method__value {
  width: 100%;
}
.payment-method__action {
 i {
   cursor: pointer;
 }
  i:hover {
    color: var(--green, #1DB954);
  }
}
</style>
