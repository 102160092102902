<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref, watch } from "vue";

import TabFormGeneralInfo from "@/Pages/Webinars/Partials/TabFormGeneralInfo.vue";
import TabFormSettings from "@/Pages/Webinars/Partials/TabFormSettings.vue";
import TabFormRegisterInfo from "@/Pages/Webinars/Partials/TabFormRegisterInfo.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";

const props = defineProps({
    user: Object,
    webinar: Object,
    workshopTypes: Object,
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
    webinarButtons: Object,
});

const { t } = useI18n();

const state = computed(() => {
    return "new";
});

const page = {
    title: t("" + state.value) + " " + t("webinar"),
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route("dashboard"),
        },
        {
            text: "Воркшопи",
            href: route("workshops.workshops"),
        },
        {
            text: "Новий вебінар",
            //href: (state === 'new') ? route('workshops.webinar.create') : '',
            active: true,
        },
    ],
};

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const emptyButtons = [
    { title: "", url: "" },
    { title: "", url: "" },
];

const form = useForm({
    workShop: props.webinar,
    filePreview: null,
    publicLink: "",
    webinarButtons: props.webinarButtons || emptyButtons,
});
function updateWebinar(value) {}

function save() {
    console.log("Save");
    router.visit(route("workshops.store", { type: "webinar" }), {
        method: "post",
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
    });
}

watch(
    () => form.workShop,
    (value) => {
        console.log("change edit");
    },
    { deep: true }
);

const shareLinkModal = ref(false);
const shareLink = ref("");

function handleShare() {
    shareLinkModal.value = !shareLinkModal.value;
    shareLink.value = form.workShop.image; //TODO: get share link from webinar
}
</script>

<template>
    <div>
        <Head title="Воркшопи" />

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                </div>
            </template>

            <template #default>
                <b-tabs nav-class="page-tabs">
                    <b-tab title="Основна інформація" active>
                        <TabFormGeneralInfo
                            @webinar-change="updateWebinar"
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                        />
                    </b-tab>
                    <b-tab title="Налаштування кімнати">
                        <TabFormSettings
                            @webinar-change="updateWebinar"
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :team="team"
                            :team-presenter="teamPresenter"
                            :team-moderator="teamModerator"
                        />
                    </b-tab>
                    <b-tab
                        v-if="!form.workShop.config.access"
                        title="Реєстрація"
                        :disabled="form.workShop.config.access"
                    >
                        <TabFormRegisterInfo @save="save" :form="form" />
                    </b-tab>
                    <!--          <b-tab title="Користувачі">-->
                    <!--            <TabFormUsers :form="form"/>-->
                    <!--          </b-tab>-->

                    <template #tabs-end>
                        <div class="tabs-end">

                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
#webinar-room {
    width: 50px;
    height: 50px;
    text-align: center;
    margin-right: 10px;
    border: 2px solid transparent;
    box-sizing: border-box;

    .webinar-room-icon {
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        transform: translate(-50%, -25%);
        transition: font-size 500ms ease;
    }

    .webinar-room-icon__hovered {
        color: red;
        font-size: 30px;
        position: absolute;
        opacity: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
        transition: font-size 500ms ease;
    }
}

#webinar-room:hover {
    border: 2px solid red;
    border-radius: 50%;

    .webinar-room-icon {
        opacity: 0;
        font-size: 20px;
    }

    .webinar-room-icon__hovered {
        opacity: 1;
        font-size: 20px;
    }
}
</style>
