<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref } from "vue";
import TabFormRegisterInfo from "@/Pages/Autowebinars/Partials/TabFormRegisterInfo.vue";
import TabFormGeneralInfo from "@/Pages/Autowebinars/Partials/TabFormGeneralInfo.vue";
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";
import DownloadXlsxButton from "@/Components/buttons/DownloadXlsxButton.vue";
import TabFormContent from "@/Pages/Autowebinars/Partials/TabFormEventEditor.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import useRequestProgress from "@/composables/useRequestProgress.js";
import TabFormSettings from "@/Pages/Webinars/Partials/TabFormSettings.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";

const props = defineProps({
    user: Object,
    autowebinar: Object,
    workshopTypes: Object,
    roles: Object,
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
    workShops: Array,
    webinarButtons: Object,
});

const { t } = useI18n();

const page = {
    title: "Новий автовебінар",
    breadcrumbs: [
        {
            text: "Всі воркшопи",
            href: route("workshops.workshops"),
        },
        {
            text: "Новий автовебінар",
            active: true,
        },
    ],
};

const tabIndex = ref(0);

// const eventLogs = ref([]);

const parentWebinar = ref(null);

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const state = computed(() => {
    return "new";
});

function save() {
    console.log("Save Auto web");
    router.visit(route("workshops.store", { type: "auto_webinar" }), {
        method: "post",
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
    });
}
const getButtonsFields = (webinarButtons) => {
    const buttons = [...webinarButtons];

    if (buttons.length === 0) {
        return [
            { title: "", url: "" },
            { title: "", url: "" },
        ];
    }

    if (buttons.length === 1) {
        buttons.push({ title: "", url: "" });
    }
    return buttons;
};

const form = useForm({
    workShop: props.autowebinar,
    filePreview: null,
    publicLink: "",
    webinarButtons: getButtonsFields(props.webinarButtons || []),
});

const { setIsLoading } = useRequestProgress();

const getParentWebinarButtons = async (id) => {
    try {
        const response = await axios.post("/workshops/get-buttons", { id: id });
        if (response.data) {
            form.webinarButtons = getButtonsFields(response.data.buttons);
        }
        return response.data.exists;
    } catch (error) {
        return false;
    }
};

const getParentWebinarInfo = async (id) => {
    try {
        const response = await axios.post("/workshops/configuration", {
            id: id,
        });
        if (response.data) {
            parentWebinar.value = response.data.webinar;
            form.workShop.completionRedirectUrl =
                response.data.webinar.completion_redirect_url;

            form.workShop.config.access = response.data.webinar.config.access;
        }
        return response.data.exists;
    } catch (error) {
        return false;
    }
};

const getParentInfo = async (id) => {
    setIsLoading(true);
    await getParentWebinarButtons(id);
    await getParentWebinarInfo(id);
    setIsLoading(false);
};

const shareLinkModal = ref(false);
const shareLink = ref("");

function handleShare() {
    shareLinkModal.value = !shareLinkModal.value;
    shareLink.value = form.autowebinar.image; //TODO: get share link from autowebinar
}

const initialBaseWorkShopId = computed(() => {
    return props.autowebinar.baseWorkShopId;
});
</script>

<template>
    <div>
        <Head title="Автовебінар" />
        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                    <!--                    <MeetingButton tooltip-text="Кімната зустрічі" />-->
                </div>
            </template>

            <template #default>
                <b-tabs v-model="tabIndex" nav-class="page-tabs">
                    <b-tab title="Основні налаштування" active>
                        <!-- <TabFormGeneralInfo
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :team="team"
                            :team-presenter="teamPresenter" :team-moderator="teamModerator"
                            :roles="roles"
                            :workshops="workShops.data"
                            @get-webinar-buttons="getParentWebinarButtons"
                        /> -->
                        <TabFormGeneralInfo
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :team="team"
                            :team-presenter="teamPresenter"
                            :team-moderator="teamModerator"
                            :roles="roles"
                            :register-link="registerLink"
                            :workshops="workShops.data"
                            :initialBaseWorkShopId="initialBaseWorkShopId"
                            :parentWebinar="parentWebinar"
                            @get-parent-info="getParentInfo"
                        />
                    </b-tab>
                    <!--                    <b-tab title="Контент автовебінару">-->
                    <!--                        <TabFormContent-->
                    <!--                            @save="save"-->
                    <!--                            :form="form"-->
                    <!--                            :workshop-types="workshopTypes"-->
                    <!--                        />-->
                    <!--                    </b-tab>-->
                    <b-tab
                        v-if="!form.workShop.config.access"
                        title="Реєстрація"
                        :disabled="form.workShop.config.access"
                    >
                        <TabFormRegisterInfo @save="save" :form="form" />
                    </b-tab>
                    <!--          <b-tab title="Користувачі">-->
                    <!--            <TabFormUsers :users="usersList" :form="form"/>-->
                    <!--          </b-tab>-->

                    <template #tabs-end>
                        <div class="tabs-end">
<!--                            <PreviewButton href="" />-->
                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
//
</style>
