import {ref} from 'vue';

export default (function () {

  const isLoading = ref(false)

  const isError = ref(false)

  const errorMessage = ref(null)

  const setIsLoading = (state) => {
    isLoading.value = state === true
  }

  const setErrorMessage = (errorsString) => {
    isError.value = !!errorsString
    errorMessage.value = errorsString
  }

  return () => {
    return {
      isLoading,
      isError,
      errors: errorMessage,
      setIsLoading,
      setErrorMessage
    }
  }
})()
