import {createI18n} from 'vue-i18n'

import uk from './lang/uk.json'
import en from './lang/en.json'
import ru from './lang/ru.json'


const messages = {
    en,
    uk,
    ru
}

const locale = sessionStorage.getItem("locale");

const i18n = createI18n({
    legacy: false,
    locale: locale || "en",
    fallbackLocale: "en",
    messages: messages
});
export default i18n;
