<script setup>
import { computed, nextTick, ref, watch } from "vue";
import SpeakerField from "@/Components/form/SpeakerField.vue";
import MyTeamModal from "@/Components/modals/MyTeamModal.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { helpers, maxLength, url } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import WebinarButtonsCard from "@/Components/WebinarButtons/WebinarButtonsCard.vue";
import WorkshopTeamModal from "@/Components/modals/WorkshopTeamModal.vue";
import WorkshopTeamComponent from "@/Pages/Workshops/Partials/WorkshopTeamComponent.vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    form: Object,
    workshopTypes: Object,
    teamPresenter: Object,
    teamModerator: Object,
    roles: Object,
    link: "",
});

const onSubmit = () => {
    emit("save");
};

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isExpired = computed(
    () => props.form?.workShop?.config?.workShopStatus === "EXPIRED" && props.form?.workShop?.type !== "MASTERCLASS"
);
const isFinished = computed(
    () => props.form?.workShop?.config?.workShopStatus === "FINISHED"
);

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles) || isFinished.value || isExpired.value;

const onReset = () => {
    form.reset();
};

const rules = {
    completionRedirectUrl: {
        url: helpers.withMessage("Повино бути URL", url),
        maxLength: helpers.withMessage(
            "Не більше 255 символів",
            maxLength(255)
        ),
    },
};

const v$ = useVuelidate(rules, props.form.workShop, { $lazy: true });

const emit = defineEmits(["webinar-change", "save"]);

watch(
    () => props.form.workShop,
    (value) => {
        nextTick(() => emit("webinar-change", value));
    },
    { deep: true }
);
</script>

<template>
    <b-container id="main-form" fluid class="tab-form">
        <b-row>
            <b-col cols="6">
                <b-form>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <WorkshopTeamComponent
                                :form="form"
                                :team-presenter="teamPresenter"
                                :team-moderator="teamModerator"
                                :roles="roles"
                            />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">Налаштування ефіру</div>
                            <b-row class="date-time-duration__group">
                                <InputGroup
                                    element="date"
                                    label="Дата початку"
                                    v-model="form.workShop.config.date"
                                    size="lg"
                                    :disabled="isReadOnly"
                                />
                                <InputGroup
                                    element="time"
                                    label="Час початку"
                                    v-model="form.workShop.config.date"
                                    size="lg"
                                    :disabled="isReadOnly"
                                />
                                <InputGroup
                                    element="duration"
                                    label="Тривалість"
                                    type="duration"
                                    v-model="form.workShop.config.duration"
                                    size="lg"
                                    :disabled="isReadOnly"
                                />
                            </b-row>
                            <!--              <b-row>-->
                            <!--                <div class="checkbox__group">-->
                            <!--                  <b-form-group class="mt-4">-->
                            <!--                    <b-form-checkbox :unchecked-value="false" :value="true" v-model="form.workShop.config.is_chat_write">-->
                            <!--                      Дозволити писати в чаті-->
                            <!--                    </b-form-checkbox>-->
                            <!--                  </b-form-group>-->
                            <!--                  <b-form-group class="mt-4">-->
                            <!--                    <b-form-checkbox :unchecked-value="false" :value="true" v-model="form.workShop.config.is_chat_record">-->
                            <!--                      Записувати чат-->
                            <!--                    </b-form-checkbox>-->
                            <!--                  </b-form-group>-->
                            <!--                  <b-form-group class="mt-4">-->
                            <!--                    <b-form-checkbox :unchecked-value="false" :value="true" v-model="form.workShop.config.is_video_record">-->
                            <!--                      Зробити запис відео <i id="tooltip-2" class="bi bi-info-circle"></i>-->
                            <!--                    </b-form-checkbox>-->
                            <!--                    <b-tooltip target="tooltip-2" custom-class="custom-tooltip" offset="5" :no-fade="true" :delay="{ show: 50, hide: 50 }">-->
                            <!--                      Трансляція буде збережена на курсі. Студенти в будь-який час зможуть переглянути її повторно в-->
                            <!--                      режимі запису.-->
                            <!--                    </b-tooltip>-->
                            <!--                  </b-form-group>-->
                            <!--                </div>-->
                            <!--              </b-row>-->
                            <!--              <b-row>-->
                            <!--                <div class="d-flex gap-2">-->
                            <!--                  <b-form-group class="mt-4">-->
                            <!--                    <b-form-checkbox :unchecked-value="false" :value="true" v-model="form.workShop.config.is_save_to_auto_webinar">-->
                            <!--                      Зберегти запис до автовебінарів <i id="tooltip-1" class="bi bi-info-circle"></i>-->
                            <!--                    </b-form-checkbox>-->
                            <!--                    <b-tooltip target="tooltip-1" custom-class="custom-tooltip" offset="5" :no-fade="true" :delay="{ show: 50, hide: 50 }">-->
                            <!--                      Трансляція буде збережена в розділ <span class="text-success">Cloud.-->
                            <!--                          </span> Ви можете подавати данний матеріал повторно, як урок, що не прив'язаний до курсу.-->
                            <!--                    </b-tooltip>-->
                            <!--                  </b-form-group>-->
                            <!--                </div>-->
                            <!--              </b-row>-->
                        </b-card-body>
                    </b-card>
                    <WebinarButtonsCard
                        v-model="form.webinarButtons"
                        :form="form"
                    />
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Реєстраційні дані
                                <i id="tooltip-3" class="bi bi-info-circle"></i>
                            </div>
                            <b-row>
                                <b-form-radio-group
                                    id="radio-group-2"
                                    name="radio-sub-component"
                                    class="mt-4"
                                    v-model="form.workShop.config.access"
                                >
                                    <b-form-radio :value="false"
                                        >Збирати
                                        <i
                                            id="toolooltip-data-store"
                                            class="bi bi-info-circle"
                                        ></i>
                                    </b-form-radio>

                                    <b-tooltip
                                        target="toolooltip-data-store"
                                        custom-class="webinar-tooltip"
                                        offset="5"
                                    >
                                        Відвідувачі зможуть потрапити на
                                        трансляцію лише після заповнення
                                        реєстраційної форми. Всі дані будуть
                                        збережені та доступні для вас.
                                    </b-tooltip>
                                    <b-form-radio
                                        :value="true"
                                        :disabled="
                                            isReadOnly ||
                                            form.workShop.config.is_paid
                                        "
                                        >Не збирати
                                        <i
                                            id="tooltip-4"
                                            class="bi bi-info-circle"
                                        ></i>
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-row>
                            <b-tooltip
                                target="tooltip-3"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Визначте, чи будете збирати реєстраційні дані
                                учасників.
                            </b-tooltip>
                            <b-tooltip
                                target="tooltip-4"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Поля реєстраційної форми не будуть обов'язковими
                                для заповнення.
                            </b-tooltip>
                        </b-card-body>

                        <b-card no-body class="shadow-none mt-4">
                            <b-card-body
                                class="d-flex flex-column justify-content-between align-items-start gap-3 bg-light p-3"
                            >
                                <div class="card__title">
                                    Вебінарні конфігурації
                                </div>
                                <div>
                                    <b-form-checkbox
                                        v-model="
                                            form.workShop.config.is_chat_write
                                        "
                                        :unchecked-value="false"
                                        :disabled="isReadOnly"
                                    >
                                        Увімкнути чат
                                        <i
                                            id="configuration-chat"
                                            class="bi bi-info-circle pt-3"
                                        ></i>
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-model="
                                            form.workShop.config.moderate_access
                                        "
                                        :unchecked-value="false"
                                        :disabled="isReadOnly"
                                        >Підтверджувати користувачів
                                        <i
                                            id="configuration-confirm-user"
                                            class="bi bi-info-circle pt-3"
                                        ></i>
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-model="
                                            form.workShop.config
                                                .link_message_status
                                        "
                                        :unchecked-value="false"
                                        :disabled="isReadOnly"
                                    >
                                        Дозволити посилання в чаті
                                        <i
                                            id="configuration-link"
                                            class="bi bi-info-circle pt-3"
                                        ></i>
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-model="
                                            form.workShop.config
                                                .moderate_message
                                        "
                                        :unchecked-value="false"
                                        :disabled="isReadOnly"
                                    >
                                        Модерувати чат
                                        <i
                                            id="configuration-moderation-chat"
                                            class="bi bi-info-circle pt-3"
                                        ></i>
                                    </b-form-checkbox>
                                    <b-tooltip
                                        class="text-left"
                                        target="configuration-chat"
                                        custom-class="custom-tooltip"
                                        offset="5"
                                        triggers="hover"
                                        :no-fade="true"
                                        :delay="{ show: 50, hide: 50 }"
                                    >
                                        Дозволяє учасникам писати повідомлення в
                                        чат під час вебінару.
                                    </b-tooltip>
                                    <b-tooltip
                                        class="text-left"
                                        target="configuration-confirm-user"
                                        custom-class="custom-tooltip"
                                        offset="5"
                                        triggers="hover"
                                        :no-fade="true"
                                        :delay="{ show: 50, hide: 50 }"
                                    >
                                        Ви будете підтверджувати кожного
                                        учасника, перш ніж він увійде на
                                        вебінар.
                                    </b-tooltip>
                                    <b-tooltip
                                        class="text-left"
                                        target="configuration-link"
                                        custom-class="custom-tooltip"
                                        offset="5"
                                        triggers="hover"
                                        :no-fade="true"
                                        :delay="{ show: 50, hide: 50 }"
                                    >
                                        Учасники зможуть ділитися посиланнями в
                                        чаті під час вебінару.
                                    </b-tooltip>
                                    <b-tooltip
                                        class="text-left"
                                        target="configuration-moderation-chat"
                                        custom-class="custom-tooltip"
                                        offset="5"
                                        triggers="hover"
                                        :no-fade="true"
                                        :delay="{ show: 50, hide: 50 }"
                                    >
                                        Всі повідомлення учасників перед
                                        публікацією буде схвалювати модератор.
                                    </b-tooltip>
                                </div>
                            </b-card-body>
                        </b-card>

                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light-subtle p-3"
                        >
                            <InputGroup
                                label="Посилання, за яким будуть перенаправлені глядачі після вебінару"
                                v-model="form.workShop.completionRedirectUrl"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :maxLength="255"
                                :disabled="isReadOnly"
                                :is-error="
                                    v$.completionRedirectUrl?.$errors[0]
                                        ?.$message
                                "
                                :error-message="
                                    v$.completionRedirectUrl?.$errors[0]
                                        ?.$message
                                "
                                @blur="v$.completionRedirectUrl.$touch()"
                            />
                        </b-card-body>
                    </b-card>
                    <b-button
                        :disabled="form.processing || isReadOnly"
                        variant="primary"
                        size="lg"
                        pill
                        class="mt-3"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.webinar-tooltip {
    border-radius: 16px;
    background: var(--dark, #1e1e1e);
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.5);
}

.webinar-input-group {
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;
    overflow: hidden;
    i {
        font-size: medium;
    }

    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

#input-1 {
    background-color: #1db9540a;
    border: 1px solid #1db954;
    border-radius: 4px;
}
</style>
