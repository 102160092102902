<script setup>
import receipt from "../../../../../images/tmp/autowebinars/receipt.png";
import {router, useForm} from '@inertiajs/vue3';
const form = useForm({
  name: '',
  email: '',
  phone: '',
});

const onSubmit = () => {
  form.post(route('register'), {

  });
};

const onReset = () => {
  form.reset();
};

const webmoney = 'Z324039475235';
const PrivatBank = '5658 9445 5481 6368';
const Binance = '1N4Qbzg6LSXUXyXu2MDuGfzxwMA7do8AyL';
</script>

<template>
  <div class="form-login-header text-center">
    <p>Останній крок! Оплатіть курс на суму зазначену нижче, та прикріпіть скрін оплати</p>
  </div>

  <b-form class="login-form">

    <div class="d-flex justify-content-center payment-amount">
      <span>28 999₴</span>
    </div>

    <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-3">
      <div class="mb-1">WebMoney</div>
      <b-input-group class="payment-input-group">
        <b-form-input v-model="webmoney" class="payment-form-input"></b-form-input>
        <b-input-group-append>
          <b-button variant="text">
            <i class="ri-file-copy-line"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
    <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-1">
      <div class="mb-1">PrivatBank</div>
      <b-input-group class="payment-input-group">
        <b-form-input v-model="PrivatBank" class="payment-form-input"></b-form-input>
        <b-input-group-append>
          <b-button variant="text">
            <i class="ri-file-copy-line"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
    <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-1">
      <div class="mb-1">Binance</div>
      <b-input-group class="payment-input-group">
        <b-form-input v-model="Binance" class="payment-form-input"></b-form-input>
        <b-input-group-append>
          <b-button variant="text">
            <i class="ri-file-copy-line"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
    <b-img :src="receipt" title="title" class="mt-2 w-75 d-flex" alt="title"></b-img>
    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button variant="primary" size="lg" pill @click="() => router.get('/autowebinars/done')">
        Відправити
      </b-button>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button variant="text-variant" size="md" class="d-flex align-items-center justify-content-center" pill @click="() => router.get('/autowebinars/verification')">
        <i class="ri-arrow-left-s-line"></i> Назад
      </b-button>
    </div>
  </b-form>
</template>

<style lang="scss">
.form-login-header {
  p {
    color: var(--Grey, #C2C2C2);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 22.4px */
  }
}
.payment-amount {
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  background: var(--white, #FFF);
  color: black;
  width: fit-content;
  padding: 10px 18px;
  margin: 0 auto;
  font-size: large;
}
.payment-input-group {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  i {
    font-size: medium;
  }
  .payment-form-input {
    background: transparent;
    border: none;
    color: #1E1E1E;
    opacity: 0.5;
  }
}
</style>
