<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CustomScheduleModal from "@/Components/Schedule/CustomScheduleModal.vue";
import useRequestProgress from "@/composables/useRequestProgress.js";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import WeekDayItem from "@/Components/Schedule/WeekDayItem.vue";
import { useI18n } from "vue-i18n";
import WaitModal from "@/Components/modals/WaitModal.vue";

const props = defineProps({
    buttonText: String,
    specificDatesSchedules: {
        type: Object,
        default: {},
    },
    noModal: {
        type: Boolean,
        default: false,
    },
    accountType: null,
    student: false,
    isShowDescription:{
        type:Boolean,
        default:false,
    }
});

const emit = defineEmits(["customButtonClick"]);

const customButtonClick = () => {
    emit("customButtonClick");
};

const customScheduleModal = ref(false);

const handleDayClick = (day) => {
    selectedDate.value = day.dateStr;
    if (!props.noModal) customScheduleModal.value = true;
    else {
        const dayEl = day.dayEl;
        const moreButton = dayEl.querySelector(".fc-more-link");
        if (moreButton) {
            moreButton.click();
        }
    }
};

const selectedDate = ref(null);

const fullCalendar = ref(null);

const { setIsLoading } = useRequestProgress();

const { formatDate } = useDateFormatter();

const calendarOptions = {
    height: 800,
    dayPopoverFormat: { day: "2-digit", weekday: "short", omitCommas: true },
    locales: allLocales,
    locale: "uk",
    timeZone: "local",
    firstDay: 1,
    plugins: [dayGridPlugin, interactionPlugin],
    themeSystem: "standard",
    fixedWeekCount: false, // показуємо тільки тижні місяця який активний
    //showNonCurrentDates: false, // можна прибрати дні які не з активного місяця
    buttonText: {
        today: "Cьогодні",
    },
    customButtons: {
        schedule: {
            text: props.buttonText,
            click: customButtonClick,
        },
    },
    headerToolbar: {
        left: "prev,next",
        center: "title",
        right: props.buttonText ? "today,schedule" : "today",
    },
    dayMaxEvents: true,
    dayMaxEventRows: 3,
    //eventClassNames: '',
    allDay: false,
    displayEventTime: true,
    eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        meridiem: false,
    },
    eventContent: function (arg, createElement) {
        const title =
            arg?.event?.extendedProps?.type === "oneToOne"
                ? arg.event.extendedProps.program_name
                : arg.event.title;
        let innerText =
            formatDate(arg.event.start.toISOString(), "HH:mm") + " " + title;

        return createElement("span", {}, innerText);
    },
    moreLinkContent: function (arg, createElement) {
        let innerText = "+" + arg.num + " події";

        return createElement("span", {}, innerText);
    },
    events: function (info, successCallback, failureCallback) {
        const start = info.startStr;
        const end = info.endStr;
        setIsLoading(true);
        // console.log("11111 events", start, end)
        // todo отримати кого цей календар ментора чи студента
        let url = "/mentoring/getMentorSchedule";
        if (props.student === true) {
            url = "/getStudentSchedule";
        }
        axios
            .get(url, {
                params: { start, end },
            })
            .then((response) => {
                successCallback(response.data);
            })
            .catch((error) => failureCallback(error))
            .finally(() => setIsLoading(false));
    },
    //select: handleDayClick,
    dateClick: handleDayClick,
    eventClick: function (info) {
        info.jsEvent.preventDefault();
        if (props.noModal) {
            showEventDetails.value = true;
            eventData.value = info.event;
            // console.log('event: ', info.event.classNames)
            if (info.event.url) {
                // window.open(info.event.url, '_blank');
            }
            // alert('Event: ' + info.event.title);
            // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            // alert('View: ' + info.view.type);
        }
    },
};
onMounted(() => {
    fullCalendar.value.getApi().on("datesSet", () => {
        fullCalendar.value.getApi().refetchEvents();
    });

    updateCalendarHeight();
    window.addEventListener("resize", updateCalendarHeight);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", updateCalendarHeight);
    fullCalendar.value.getApi().destroy();
});

const updateCalendarHeight = () => {
    // Автоматичне перерахування висота календаря на основі ширини. Якщо потрібно можна змінити пропорцію.
    const width = fullCalendar.value.$el.offsetWidth;
    fullCalendar.value
        .getApi()
        .setOption("height", Math.floor((width * 3) / 4));
};

const showEventDetails = ref(false);
const eventData = ref(null);

const { t } = useI18n();

const waitModal = ref(false);
let timeoutId;
let oneToOneUrl = null

async function joinStudent(start) {
    if (oneToOneUrl) {
        try {
            waitModal.value = start;
            axios.get(oneToOneUrl)
                .then(response => {
                    if (response.data.success === true) {
                        // Відкрити нову вкладку з отриманим URL
                        oneToOneUrl = null
                        window.open(response.data.url, '_blank');
                        waitModal.value = false;
                        clearTimeout(timeoutId)
                    } else {
                        // Продовжувати надсилати запити кожні 5 секунд
                        // setTimeout(joinStudent, 5000);
                        scheduleJoinStudent();
                    }
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.error('Помилка при надсиланні запиту:', error);
            // Продовжувати надсилати запити кожні 5 секунд у випадку помилки
            // setTimeout(joinStudent, 5000);
            scheduleJoinStudent();
        }
    }
}

function scheduleJoinStudent() {
    if (waitModal.value) {
        timeoutId = setTimeout(() => {
            joinStudent(waitModal.value);
        }, 5000);
    } else clearTimeout(timeoutId);
}

const handleJoinOneToOne = (eventData) => {
    if(props.accountType === 'ACCOUNT_TYPE_STUDENT') {
        oneToOneUrl = eventData.url;
        joinStudent(true)
    } else {
        window.open(eventData.url, '_blank');
    }
}
</script>

<template>
    <p v-if="isShowDescription" class="description">
        Оберіть день і налаштуйте графік індивідуальних менторських сесій
    </p>
    <FullCalendar ref="fullCalendar" :options="calendarOptions"> </FullCalendar>
    <b-modal
        v-if="showEventDetails"
        v-model="showEventDetails"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        no-close-on-esc
        modal-class="event-info__modal"
    >
        <div class="modal-body">
<!--                        <pre>{{eventData}}</pre>-->
            <div class="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <div class="event-info__label">
                        {{ t(eventData.extendedProps.type) }}
                    </div>
                    <div class="event-info__title">
                        {{
                            eventData.extendedProps.type === "liveLesson"
                                ? eventData.extendedProps.courseTitle
                                : eventData.title
                        }}
                    </div>
                </div>
                <div class="fs-3">
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="showEventDetails = !showEventDetails"
                    ></i>
                </div>
            </div>
            <div
                v-if="
                    eventData.extendedProps.type === 'liveLesson' &&
                    eventData.title
                "
                class="event-info__row"
            >
                Урок: <span>{{ eventData.title }}</span>
            </div>
            <div
                v-if="
                    eventData.extendedProps.type === 'oneToOne' &&
                    eventData.extendedProps.program_name
                "
                class="event-info__row"
            >
                Менторська програма: «<span>{{ eventData.extendedProps.program_name }}</span>»
            </div>
            <div v-if="eventData.start" class="event-info__row">
                Дата:
                <span>{{ formatDate(eventData.start.toISOString()) }}</span>
            </div>
            <div v-if="eventData.start" class="event-info__row">
                Початок:
                <span>{{
                    formatDate(eventData.start.toISOString(), "HH:mm")
                }}</span>
            </div>
            <div
                v-if="eventData.extendedProps.eventDuration"
                class="event-info__row"
            >
                Тривалість:
                <span>{{ eventData.extendedProps.eventDuration }} год</span>
            </div>
            <b-button
                v-if="eventData.url && eventData.extendedProps.type !== 'oneToOne' && eventData.extendedProps.type !== 'liveLesson'"
                variant="primary"
                :href="eventData.url"
                target="_blank"
                size="md"
                pill
                class="mt-3 float-end"
            >
                Відкрити
            </b-button>
            <!-- TODO: додати перевірку на тип юзера. У студента має бути вейт модал з очікуванням лайвсессії у не студента конфірм на старт БББ, так саме перевірити для курсіів -->
            <b-button
                v-if="eventData.url && (eventData.extendedProps.type === 'oneToOne' || eventData.extendedProps.type === 'liveLesson')"
                variant="primary"
                size="md"
                pill
                class="mt-3 float-end"
                @click="handleJoinOneToOne(eventData)"
            >
                Відкрити
            </b-button>
        </div>
    </b-modal>
    <CustomScheduleModal
        v-if="
            customScheduleModal &&
            accountType &&
            accountType !== 'ACCOUNT_TYPE_STUDENT' &&
            specificDatesSchedules
        "
        :specificDatesSchedules="specificDatesSchedules"
        v-model="customScheduleModal"
        :selected-date="selectedDate"
    />
    <WaitModal v-model="waitModal"/>
</template>
<style lang="scss">

.description{
    color: var(--dark, #1e1e1e);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        opacity: 0.5;
        padding: 16px 0;
        margin: 0;
}


.fc .fc-toolbar.fc-header-toolbar {
    border: none;
}

.fc td,
.fc th,
.fc .fc-scrollgrid {
    border: 0.5px solid var(--stroke, #ebebeb) !important;
}

.fc th {
    border: none !important;
}

.fc .fc-scrollgrid {
    border-radius: 8px !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}

.fc-col-header-cell {
    background: #0f241a !important;

    .fc-col-header-cell-cushion {
        color: var(--white, #fff) !important;
        padding: 16px 60px !important;
        text-align: center;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
}

.fc-button-group {
    .fc-button-primary {
        background: var(--white, #fff);
        border: none;

        .fc-icon {
            color: var(--green, #1db954);
        }

        &:not(:disabled):active {
            background-color: rgba(29, 185, 84, 0.05) !important;
            border-color: var(--stroke, #ebebeb) !important;
            color: var(--green, #1db954) !important;
        }
    }

    .fc-prev-button {
        border-radius: 8px 0 0 8px;
        border-width: 2px;
        border-color: var(--stroke, #ebebeb);
        border-style: solid;
        margin-right: -1px;

        &:hover,
        &:active,
        &:focus {
            border-radius: 8px 0 0 8px;
            border: 1px solid var(--green, #1db954);
            background: rgba(29, 185, 84, 0.05);
            outline: none;
            box-shadow: none;
        }
    }

    .fc-next-button {
        border-radius: 0 8px 8px 0;
        border-width: 2px;
        border-color: var(--stroke, #ebebeb);
        border-style: solid;
        margin-left: -1px;

        &:hover,
        &:active,
        &:focus {
            border-radius: 0 8px 8px 0;
            border: 1px solid var(--green, #1db954);
            background: rgba(29, 185, 84, 0.05);
            outline: none;
            box-shadow: none;
        }
    }

    .fc-today-button {
        display: flex;
        padding: 16px 24px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 50px !important;
        border: 1px solid var(--dark, #1e1e1e) !important;
        background: var(--white, #fff) !important;
        color: var(--dark, #1e1e1e) !important;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-right: 10px !important;
        cursor: pointer;

        &:hover {
            color: var(--green, #1db954) !important;
            border-color: var(--green, #1db954) !important;
        }
    }

    .fc-schedule-button {
        display: flex;
        padding: 16px 24px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 50px !important;
        background: var(--green, #1db954);
        color: var(--white, #fff);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        cursor: pointer;

        &:hover {
            font-weight: 600;
            background: var(--green, #1db954);
        }
    }
}

.fc-header-toolbar {
    .fc-toolbar-chunk {
        .fc-today-button {
            display: flex;
            padding: 16px 24px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 50px !important;
            border: 1px solid var(--dark, #1e1e1e) !important;
            background: var(--white, #fff) !important;
            color: var(--dark, #1e1e1e) !important;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-right: 10px !important;
            cursor: pointer;

            &:hover {
                color: var(--green, #1db954) !important;
                border-color: var(--green, #1db954) !important;
            }
        }
    }

    .fc-toolbar-chunk:nth-child(2) {
        flex: 1;
        padding-left: 28px;

        .fc-toolbar-title {
            color: var(--dark, #1e1e1e);
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            text-transform: capitalize;
        }
    }
}

.fc-scrollgrid {
    border-radius: 8px;
    overflow: hidden;
    border-style: hidden;
    box-shadow: 0 0 0 0.5px #ebebeb;
}

.fc-day-today {
    background: transparent !important;

    .fc-daygrid-day-frame {
        border: 1px solid var(--green, #1db954) !important; //var(--purple, #F24822) !important;
    }

    .fc-daygrid-day-number {
        color: var(--dark, #1e1e1e) !important;
        background: transparent !important;
    }
}

.fc-daygrid-day {
    .fc-daygrid-day-frame {
        box-sizing: border-box;

        .fc-daygrid-day-top {
            flex-direction: row;
            font-family: e-Ukraine;
        }
        .fc-more-link {
            font-family: e-Ukraine;
            color: var(--dark, #1e1e1e) !important;
        }
    }

    &:not(.fc-day-past) {
        .fc-daygrid-day-frame:hover {
            border: 1px solid var(--purple, #f24822);
        }
    }

    &.fc-day-past {
        opacity: 0.5;
    }
}

.fc-scrollgrid-sync-table {
    tbody tr:last-child {
        .fc-daygrid-day:first-child {
            &:not(.fc-day-past) {
                .fc-daygrid-day-frame:hover {
                    border: 1px solid var(--purple, #f24822);
                    border-bottom-left-radius: 8px;
                }
            }
        }

        .fc-daygrid-day:last-child {
            &:not(.fc-day-past) {
                .fc-daygrid-day-frame:hover {
                    border: 1px solid var(--purple, #f24822);
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}

.fc-event {
    font-family: e-Ukraine;
    color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 30%);
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    cursor: pointer !important;
    // border: 1px solid red!important;
    margin: 0 !important;
    padding: 4px 0 !important;
    border-radius: 4px;
    span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &::before {
            content: "" !important;
            display: inline-block !important;
            width: 4px !important;
            height: 4px !important;
            border-radius: 4px !important;
            background: #1db954 !important;
            //margin-right: 4px!important;
            margin: 3px 5px !important;
        }
    }

    &:hover,
    &:focus {
        background: #1db954 !important;
        color: #ffffff;
    }
}

.fc-popover-header {
    background-color: #fff !important;
}
.event-info__modal {
    font-family: e-Ukraine;
    .modal-dialog {
        width: 480px;
        .modal-body {
            .event-info__label {
                font-size: 14px;
                font-weight: 300;
                line-height: 22.4px;
                text-align: left;
                color: color-mix(
                    in srgb,
                    var(--dark, #1e1e1e),
                    transparent 50%
                );
            }
            .event-info__title {
                font-size: 22px;
                font-weight: 500;
                line-height: 26.4px;
                text-align: left;
                color: #1e1e1e;
            }
            .event-info__row {
                font-size: 13px;
                font-weight: 300;
                line-height: 13px;
                text-align: left;
                color: #1e1e1e;
                margin-bottom: 12px;
                span {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 12px;
                    text-align: left;
                    color: color-mix(
                        in srgb,
                        var(--dark, #1e1e1e),
                        transparent 50%
                    );
                }
            }
        }
    }
}
</style>
