<script setup>

import MeetingButton from '@/Components/buttons/MeetingButton.vue';
import CommentButton from "@/Components/buttons/CommentButton.vue";
import SessionCommentModal from "@/Components/modals/SessionCommentModal.vue";
import {ref} from "vue";
import {useDateFormatter} from "@/composables/useDateFormatter.js";
import noAvatar from "../../../../images/tmp/ProductOwnerProfile/avatar.png";
import {router} from "@inertiajs/vue3";
import ConfirmModal from '@/Components/modals/ConfirmModal.vue';

const props = defineProps({
    session: Object,
    disable: {
        type: Boolean,
        default: false
    }
})

const confirmModal = ref(false);

const sessionCommentModal = ref(false)
const { formatDate } = useDateFormatter()

const updateComment = (data) => {
    console.log(data);
    // return;
    router.visit(route('mentoring.program.updateComment',{id:props.session.id}),
        {
            method: 'post',
            data:{comment:data},
            preserveState: true,
            preserveScroll: true,
            forceFormData: true
        });
}

</script>

<template>
  <div class="session" :class="{ 'session--disable': disable }">
<!--    <div-->
<!--      class="session__label session__label&#45;&#45;green"-->
<!--    >-->
<!--        &lt;!&ndash; :class="{ 'session__label&#45;&#45;blue': session.labelColor === 'blue', 'session__label&#45;&#45;green': session.labelColor === 'green'}" &ndash;&gt;-->
<!--      {{ session.program_name }}-->
<!--    </div>-->
    <div class="session__user">
      <b-avatar
            variant="light"
            :src="session.image ? session.image : noAvatar"
            class="session__avatar"
      ></b-avatar>
      <div>
        <div class="session__name">{{ session.name }}</div>
        <div>
            <span class="session__date">{{ formatDate(session.date, "d MMMM yyyy") }}</span>
            <span class="session__time">{{ formatDate(session.date, "HH:mm") }}</span>
        </div>
      </div>
    </div>
    <div class="session__program-name">
        <span class="program-name__label">Менторська програма</span>
        <div class="program-name__title">{{ session.program_name }}</div>
    </div>
    <CommentButton
        :has-comment="session.comment.length > 0"
        class="session__comment-button"
        @click="sessionCommentModal = !sessionCommentModal"
    />
    <MeetingButton :confirm="true" :link="session.url" tooltip-text="Кімната зустрічі"
    @openConfirm="()=>confirmModal=true">
        <template v-slot:confirm_modal>
            <ConfirmModal
                    v-if="confirmModal"
                    v-model="confirmModal"
                    title="Розпочати індивідуальну сесію"
                    decline-label="Скасувати"
                    accept-label="Так, розпочати"
                    :redirectUrl="session.url"
                >
                    <template v-slot:description> Ви дійсно хочете розпочати індивідуальну менторську сесію? </template>
                </ConfirmModal>
  </template>
        </MeetingButton>
  </div>
  <SessionCommentModal
      v-if="updateComment"
      v-model="sessionCommentModal"
      :comment="session.comment"
      @commentUpdate="updateComment"
  />
</template>

<style lang="scss">

</style>
