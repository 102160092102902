<script setup>
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import { router } from "@inertiajs/vue3";
import noAvatar from "../../../../images/tmp/ProductOwnerProfile/avatar.png";
import {useDateFormatter} from "@/composables/useDateFormatter.js";
import {ref} from "vue";
import WaitModal from "@/Components/modals/WaitModal.vue";

const props = defineProps({
    session: Object,
    disable: {
        type: Boolean,
        default: false
    }
});

const { formatDate } = useDateFormatter()

const waitModal = ref(false);

let timeoutId;

async function joinStudent(start) {
    try {
        waitModal.value = start;
        axios.get(props.session.url)
            .then(response => {
                if (response.data.success === true) {
                    // Відкрити нову вкладку з отриманим URL
                    window.open(response.data.url, '_blank');
                    waitModal.value = false;
                } else {
                    // Продовжувати надсилати запити кожні 5 секунд
                    // setTimeout(joinStudent, 5000);
                    scheduleJoinStudent();
                }
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        console.error('Помилка при надсиланні запиту:', error);
        // Продовжувати надсилати запити кожні 5 секунд у випадку помилки
        // setTimeout(joinStudent, 5000);
        scheduleJoinStudent();
    }
}

function scheduleJoinStudent() {
    if (waitModal.value) {
        timeoutId = setTimeout(() => {
            joinStudent(waitModal.value);
        }, 5000);
    } else clearTimeout(timeoutId);
}

</script>

<template>
    <div class="session" :class="{ 'session--disable': disable }">
<!--        <div-->
<!--            class="session__label"-->
<!--            :class="{-->
<!--                'session__label&#45;&#45;blue': session.labelColor === 'blue',-->
<!--                'session__label&#45;&#45;green': session.labelColor === 'green',-->
<!--            }"-->
<!--        >-->
<!--            {{ session.label }}-->
<!--        </div>-->
        <div class="session__user">
            <b-avatar
                variant="light"
                :src="session.image ? session.image : noAvatar"
                class="session__avatar"
            ></b-avatar>
            <div>
                <div class="session__name">{{ session.name }}</div>
                <div>
                    <span class="session__date">{{ formatDate(session.date, "d MMMM yyyy") }}</span>
                    <span class="session__time">{{ formatDate(session.date, "HH:mm") }}</span>
                </div>
            </div>
        </div>
        <div class="session__program-name">
            <span class="program-name__label">Менторська програма</span>
            <div class="program-name__title">{{ session.program_name }}</div>
        </div>
<!--        <b-button-->
<!--            variant="outline-primary"-->
<!--            size="md"-->
<!--            pill-->
<!--            class="me-2"-->
<!--            @click="() => router.get(`/student/booking`)"-->
<!--        >-->
<!--            Запланувати-->
<!--        </b-button>-->
        <MeetingButton tooltip-text="Кімната зустрічі" @click="joinStudent(true)" />
    </div>
    <WaitModal v-model="waitModal"/>
</template>

<style lang="scss"></style>
