<script setup>
import {Head, router} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";

import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import Session from "@/Pages/Students/Partials/Session.vue";
import TabFormSessions from "@/Pages/Students/Partials/TabFormSessions.vue";
import TabMentoringPrograms from "@/Pages/Students/Partials/TabMentoringPrograms.vue";


defineProps({
    mentoring: Object,
    sessions: Object,
    // mentorprograms: {
    //     type: Array,
    //     default: [ { "id": "a10fd228-fd29-449c-8a9a-8917c07daabc", "name": "Тестова мп", "title": "", "description": "Опис МП", "summary": "Ви навчитеся використовувати програми та сервіси, які є необхідними для роботи веб-дизайнера. Ви освоїте популярні інструменти, такі як Adobe Photoshop, Figma, Sketch.", "status": "DRAFT", "start_date": "2024-08-07T21:00:00.000000Z", "duration": "00:00", "modules": 5 } ]
    // }
    mentorprograms: Object
})

const { t } = useI18n();

const page = {
    title: 'Менторство',
}

</script>

<template>
    <div>
        <Head title="Менторство"/>
        <PageHeader :title="page.title" />
        <PageContentCard :title="page.title">
            <template #default>
                <b-tabs nav-class="page-tabs">
                    <b-tab title="Сесії" active>
<!--                        <pre>{{sessions}}</pre>-->
                        <TabFormSessions :sessions="sessions"/>
                    </b-tab>
                    <b-tab title="Менторські програми">
                        <TabMentoringPrograms :mentorprograms="mentoring"/>
                    </b-tab>
                    <template #tabs-end>
                        <div class="tabs-end">

                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>

    </div>
</template>

<style lang="scss">
    .fc .fc-scroller-liquid-absolute {
      position: relative!important;
    }
    //.fc-daygrid-day .fc-daygrid-day-frame {
    //  height: 100px;
    //}
    .module-content {
        min-height: calc(100vh - 120px);
    }
    .header-end {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
    }
    .schedule-сanvas {
      width: 30%!important;
      transition: width 0.5s ease-in-out;
      .schedule-сanvas__title {
        color: var(--dark, #1E1E1E);
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      .schedule-сanvas__close-btn {
        font-size: 16px;
      }
    }
    .schedule-canvas--calendar {
      width: 60%!important;
      transition: width 0.5s ease-in-out;
    }
    .schedule-toggle {
      display: flex;
      padding: 6px;
      align-items: flex-start;
      gap: 12px;
      border-radius: 7px;
      border: 1px solid var(--green, #1DB954);
      background: rgba(29, 185, 84, 0.05);
      width: fit-content;
      .schedule-toggle__item {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        cursor: pointer;
        color: var(--dark, #1E1E1E);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
      }
      .schedule-toggle__item--active {
        background: var(--green, #1DB954);
        color: var(--white, #FFF)
      }
    }
</style>
