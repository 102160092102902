<script setup>
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import PublicLink from "@/Components/form/PublicLink.vue";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    form: Object,
    statuses: Object,
    link: "#",
});

const emit = defineEmits(["save"]);

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const onSubmit = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        emit("save");
    }
};

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        maxLength: helpers.withMessage("Не більше 90 символів", maxLength(90)),
    },
};

const v$ = useVuelidate(rules, props.form.course, { $lazy: true });

watch(
    () => props.form.course.config.is_manual_module_open,
    (newValue) => {
        if (newValue) {
            props.form.course.config.is_automatic_module_open = false;
        }
    }
);

watch(
    () => props.form.course.config.is_automatic_module_open,
    (newValue) => {
        if (newValue) {
            props.form.course.config.is_manual_module_open = false;
        }
    }
);

watch(
    () => props.form.course.config.is_all_lesson_open,
    (newValue) => {
        if (newValue) {
            props.form.course.config.is_after_watch_lesson_open = false;
        }
    }
);

watch(
    () => props.form.course.config.is_after_watch_lesson_open,
    (newValue) => {
        if (newValue) {
            props.form.course.config.is_all_lesson_open = false;
        }
    }
);
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="5">
                <b-form>
                    <FileUploader
                        :form="form"
                        :imagePreview="form.course.image"
                        :disabled="isReadOnly"
                        customTitle=" або перетягніть обкладинку"
                    />
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <SelectInputGroup
                                label="Статус*"
                                v-model="form.course.status"
                                :options="statuses"
                                :disabled="isReadOnly"
                                size="lg"
                                class="mt-0 w-100"
                            />
                            <!--              <Publi    cLink :public-link="link"/>-->
                        </b-card-body>
                    </b-card>

                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">Основні дані</div>

                            <InputGroup
                                label="Назва курсу*"
                                v-model="form.course.title"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :maxLength="90"
                                :disabled="isReadOnly"
                                :is-error="v$.title?.$errors[0]?.$message"
                                :error-message="v$.title?.$errors[0]?.$message"
                                @blur="v$.title.$touch()"
                            />

                            <InputGroup
                                element="textarea"
                                label="Опис"
                                v-model="form.course.description"
                                :options="statuses"
                                :rows="3"
                                :max-rows="10"
                                :maxLength="255"
                                :disabled="isReadOnly"
                                size="lg"
                                class="mt-3 w-100"
                            />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Доступи до модулів та окремих уроків
                            </div>

                            <b-form-group class="mt-4 modules-open-checkboxes">
                                <b-form-checkbox
                                    v-model="
                                        form.course.config.is_manual_module_open
                                    "
                                    :disabled="
                                        form.course.config
                                            .is_manual_module_open || isReadOnly
                                    "
                                >
                                    Відкривати власноруч для кожного студента
                                    <i
                                        id="tooltip-manualy"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-checkbox>
                                <b-tooltip
                                    target="tooltip-manualy"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    При встановлені цього параметру ви
                                    самостійно відкриваєте модулі та уроки поза
                                    модулями для кожного студента: послідовно
                                    або всі відразу.
                                </b-tooltip>
                                <b-form-checkbox
                                    v-model="
                                        form.course.config
                                            .is_automatic_module_open
                                    "
                                    :disabled="
                                        form.course.config
                                            .is_automatic_module_open ||
                                        isReadOnly
                                    "
                                >
                                    Відкриваються автоматично
                                    <i
                                        id="tooltip-auto"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-checkbox>
                                <b-tooltip
                                    target="tooltip-auto"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Кожен наступний модуль або урок поза модулем
                                    буде відкриватись студенту автоматично після
                                    проходження всіх уроків до цього.
                                </b-tooltip>
                            </b-form-group>
                        </b-card-body>
                    </b-card>

                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Доступи до уроків в модулі
                                <!-- <i
                                    id="tooltip-date"
                                    class="bi bi-info-circle"
                                ></i> -->
                            </div>
                            <!-- <b-tooltip
                                target="tooltip-date"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Встановіть алгоритм відкривання уроків<br />
                                та модулів, для студента.<br /><br />
                                Якщо не вказати жоден параметр, всі<br />
                                уроки стануть доступні по замовчуванню.
                            </b-tooltip> -->

                            <b-form-group class="mt-4 lessons-open-checkboxes">
                                <b-form-checkbox
                                    v-model="
                                        form.course.config.is_all_lesson_open
                                    "
                                    :disabled="
                                        form.course.config.is_all_lesson_open ||
                                        isReadOnly
                                    "
                                >
                                    Відкриваються відразу
                                    <i
                                        id="tooltip-by-date"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-checkbox>
                                <b-tooltip
                                    target="tooltip-by-date"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Після початку роботи з модулем студенту
                                    будуть доступні відразу всі уроки в цьому
                                    модулі.
                                </b-tooltip>
                                <b-form-checkbox
                                    v-model="
                                        form.course.config
                                            .is_after_watch_lesson_open
                                    "
                                    :disabled="
                                        form.course.config
                                            .is_after_watch_lesson_open ||
                                        isReadOnly
                                    "
                                >
                                    Відкриваються послідовно
                                    <i
                                        id="tooltip-first"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-checkbox>
                                <b-tooltip
                                    target="tooltip-first"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Після початку роботи з модулем студенту
                                    відкриється лише перший урок. Кожен
                                    наступний урок буде відкриватись після
                                    проходження попереднього.
                                </b-tooltip>
                            </b-form-group>
                        </b-card-body>
                    </b-card>

                    <b-button
                        :disabled="form.processing || isReadOnly"
                        variant="primary"
                        size="lg"
                        pill
                        class="mt-3"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.modules-open-checkboxes,
.lessons-open-checkboxes {
    .form-check-input:disabled {
        opacity: 1;
    }
    .form-check-input[disabled] ~ .form-check-label,
    .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }
}
</style>
