<script>
import {Link} from '@inertiajs/vue3';

export default {
    components: {Link},
    props: {
        title: {
            type: String,
            default: "",
        },
        breadcrumbs: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>

<template>
    <b-row>
        <b-col v-if="breadcrumbs && breadcrumbs.length > 0" cols="12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li
                            class="breadcrumb-item"
                            v-for="(item, index) in breadcrumbs"
                            :key="index"
                            :class="{ active: item.active }"
                        >
                            <Link
                                v-if="item.href"
                                :href="item.href"
                            >
                                {{ item.text }}
                            </Link>
                            <span v-else>
                                {{ item.text }}
                            </span>
                        </li>
                    </ol>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
