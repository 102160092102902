<script setup>
import { computed, ref, triggerRef, watch } from "vue";
import WeekDayItem from "@/Components/Schedule/WeekDayItem.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";
import { router } from "@inertiajs/vue3";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { addMinutes, subMinutes, parse, format } from "date-fns";

const props = defineProps({
    modelValue: Object,
});

const emit = defineEmits(["update:modelValue"]);

const userTimezoneOffset = new Date().getTimezoneOffset();

// Функція для конвертації в локальний час
const convertToLocalTimezone = (schedule) => {
    const updatedSchedule = {};

    for (const day in schedule) {
        if (schedule.hasOwnProperty(day)) {
            if (
                schedule[day].availableHours &&
                schedule[day].availableHours.length > 0
            ) {
                updatedSchedule[day] = {
                    ...schedule[day],
                    availableHours: schedule[day].availableHours.map((hour) => {
                        const fromTime = addMinutes(
                            parse(hour.from, "HH:mm", new Date()),
                            -userTimezoneOffset
                        );
                        const toTime = addMinutes(
                            parse(hour.to, "HH:mm", new Date()),
                            -userTimezoneOffset
                        );
                        return {
                            from: format(fromTime, "HH:mm"),
                            to: format(toTime, "HH:mm"),
                        };
                    }),
                };
            } else {
                updatedSchedule[day] = {
                    ...schedule[day],
                    availableHours: [],
                };
            }
        }
    }

    return updatedSchedule;
};

// Функція для конвертації в UTC
const convertToUTC = (schedule) => {
    const updatedSchedule = {};

    for (const day in schedule) {
        if (schedule.hasOwnProperty(day)) {
            if (
                schedule[day].availableHours &&
                schedule[day].availableHours.length > 0
            ) {
                updatedSchedule[day] = {
                    ...schedule[day],
                    availableHours: schedule[day].availableHours.map((hour) => {
                        const fromTime = subMinutes(
                            parse(hour.from, "HH:mm", new Date()),
                            -userTimezoneOffset
                        );
                        const toTime = subMinutes(
                            parse(hour.to, "HH:mm", new Date()),
                            -userTimezoneOffset
                        );
                        return {
                            from: format(fromTime, "HH:mm"),
                            to: format(toTime, "HH:mm"),
                        };
                    }),
                };
            } else {
                updatedSchedule[day] = {
                    ...schedule[day],
                    availableHours: [],
                };
            }
        }
    }

    return updatedSchedule;
};

const model = ref(convertToLocalTimezone(props.modelValue));

// Синхронізація рефу з пропсами
watch(
    () => props.modelValue,
    (newValue) => {
        model.value = convertToLocalTimezone(newValue);
    },
    { immediate: true }
);

// const model = computed({
//     get() {
//         return convertToLocalTimezone(props.modelValue);
//     },
//     set(value) {
//         const utcValue = convertToUTC(value);
//         emit('update:modelValue', utcValue);
//     }
// });

const saveWeeklySchedule = () => {
    //console.log('saveWeeklySchedule');
    const utcSchedule = convertToUTC(model.value);
    router.visit(route("mentoring.saveWeeklySchedule"), {
        method: "post",
        data: { schedule: utcSchedule },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {},
    });
};

const resetAvailableHours = () => {
    for (let day in model.value) {
        if (model.value.hasOwnProperty(day)) {
            model.value[day].availableHours = [];
        }
    }
};

const clearModal = ref(false);
</script>

<template>
    <div class="week-schedule">
        <p>
            Задайте свій щотижневий графік з часовими інтервалами на кожний день
            <i id="schedule-intervals-info" class="bi bi-info-circle"></i>
        </p>
        <b-tooltip
            target="schedule-intervals-info"
            custom-class="custom-tooltip"
            offset="5"
            :no-fade="true"
            :delay="{ show: 50, hide: 50 }"
        >
            Додавайте окремі часові інтервали (слоти), щоб точніше планувати
            заняття та уникати накладок. Наприклад: понеділок 8:00–9:00,
            10:00–11:00; вівторок 13:00–14:00. Чим більше слотів, тим більше
            можливостей для ваших студентів вибрати зручний час.
        </b-tooltip>
        <!--      <pre>{{model}}</pre>-->
        <WeekDayItem v-for="(value, name) in model" :key="name" :day="value" />
        <!--    <label class="form-label mt-3 fw-normal">Публічне посилання</label>-->
        <!--    <b-input-group class="webinar-input-group">-->
        <!--      <b-form-input class="webinar-form-input" v-model="publickLink"></b-form-input>-->
        <!--      <b-input-group-append>-->
        <!--        <b-button variant="text">-->
        <!--          <i class="bi bi-pencil-square"></i>-->
        <!--        </b-button>-->
        <!--        <b-button variant="text">-->
        <!--          <i class="bi bi-front"></i>-->
        <!--        </b-button>-->
        <!--      </b-input-group-append>-->
        <!--    </b-input-group>-->
        <!--    <p class="fs-6">Щоб студенти могли бронювати час на ваші заняття надсилайте їм це публічне посилання. </p>-->
        <div class="d-flex align-items-center justify-content-between mt-2">
            <div class="week-schedule__clear" @click="clearModal = !clearModal">
                Очистити
            </div>
            <!--      <PreviewButton href=""/>-->
            <div class="week-schedule__save" @click="saveWeeklySchedule">
                Зберегти
            </div>
        </div>
    </div>
    <ConfirmModal
        v-model="clearModal"
        title="Очистити розклад"
        decline-label="Скасувати"
        accept-label="Так, очистити"
        @accept-btn="() => resetAvailableHours()"
        @decline-btn="() => console.log('decline')"
    >
        <template v-slot:description> Очистити розклад на тиждень? </template>
    </ConfirmModal>
</template>

<style lang="scss" scoped>
.week-schedule {
    p {
        color: var(--dark, #1e1e1e);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        opacity: 0.5;
        padding: 16px 0;
    }

    .week-schedule__clear,
    .week-schedule__save {
        display: flex;
        padding: 16px 24px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 50px;
        color: var(--white, #fff);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        cursor: pointer;
    }

    .week-schedule__clear {
        background: var(--purple, #f24822);
    }

    .week-schedule__save {
        background: var(--green, #1db954);
    }
}
</style>
