const horMenu = [
    {
        roles: [
            "OWNER",
            "DIRECTOR",
            "EXPERT",
            "CURATOR",
            "PRESENTER",
            "MODERATOR",
            "MENTOR",
        ],
        id: "dashboard",
        label: "t-dashboards",
        icon: "ri-dashboard-fill",
        link: "/",
    },
    {
        roles: [
            "OWNER",
            "DIRECTOR",
            "EXPERT",
            "CURATOR",
            "PRESENTER",
            "MODERATOR",
            "MENTOR",
        ],
        id: "learning",
        label: "t-learning",
        icon: "ri-book-mark-fill",
        link: "/learning",
        subItems: [
            {
                roles: ["OWNER", "DIRECTOR", "EXPERT", "CURATOR"],
                id: "courses",
                label: "t-courses",
                icon: "ri-movie-line",
                link: "/courses",
                parentId: "learning",
                actions: [
                    {
                        roles: ["OWNER", "DIRECTOR", "EXPERT"],
                        id: "coursesCreate",
                        label: "Створити курс",
                        icon: "ph-plus-light",
                        link: "/courses/create",
                        parentId: "courses",
                    },
                ],
            },
            {
                roles: [
                    "OWNER",
                    "DIRECTOR",
                    "EXPERT",
                    "PRESENTER",
                    "MODERATOR",
                ],
                id: "workshops",
                label: "t-workshops",
                icon: "ri-play-circle-line",
                link: "/workshops",
                parentId: "learning",
                actions: [
                    {
                        roles: ["OWNER", "DIRECTOR", "EXPERT"],
                        id: "workshopCreate",
                        label: "Створити вебінар",
                        icon: "ph-plus-light",
                        link: "/workshops/create/webinar",
                        parentId: "workshops",
                    },
                    {
                        roles: ["OWNER", "DIRECTOR", "EXPERT"],
                        id: "workshopCreate",
                        label: "Створити автовебінар",
                        icon: "ph-plus-light",
                        link: "/workshops/create/auto-webinar",
                        parentId: "workshops",
                    },
                    {
                        roles: ["OWNER", "DIRECTOR", "EXPERT"],
                        id: "workshopCreate",
                        label: "Створити майстерклас",
                        icon: "ph-plus-light",
                        link: "/workshops/create/masterclass",
                        parentId: "workshops",
                    },
                ],
            },
            {
                roles: ["OWNER", "DIRECTOR", "EXPERT", "MENTOR"],
                id: "mentoring",
                label: "t-mentoring",
                icon: "ri-book-open-line",
                link: "/mentoring",
                parentId: "learning",
            },
        ],
    },
    {
        roles: [
            "OWNER",
            "DIRECTOR",
            "EXPERT",
            "CURATOR",
            "PRESENTER",
            "MODERATOR",
            "MENTOR",
        ],
        id: "support",
        label: "t-support",
        icon: "ri-questionnaire-fill",
        link: "/support",
        inFooter: true,
        subItems: [
            {
                roles: [
                    "OWNER",
                    "DIRECTOR",
                    "EXPERT",
                    "CURATOR",
                    "PRESENTER",
                    "MODERATOR",
                    "MENTOR",
                ],
                id: "knowledge-base",
                label: "t-base-knowledge",
                icon: "ri-book-mark-line",
                thirdPartyLink:
                    "https://help.bestcleverslms.com/docs/knowledge-base/",
                parentId: "support",
            },
            {
                roles: [
                    "OWNER",
                    "DIRECTOR",
                    "EXPERT",
                    "CURATOR",
                    "PRESENTER",
                    "MODERATOR",
                    "MENTOR",
                ],
                id: "video-instructions",
                label: "t-video-instructions",
                icon: "ri-play-list-2-line",
                parentId: "support",
                thirdPartyLink: "https://www.youtube.com/@Bestclevers",
            },

            {
                roles: [
                    "OWNER",
                    "DIRECTOR",
                    "EXPERT",
                    "CURATOR",
                    "PRESENTER",
                    "MODERATOR",
                    "MENTOR",
                ],
                id: "contact-support",
                label: "t-contact-support",
                icon: "ri-customer-service-2-line",
                parentId: "support",
                isSupportChat: true,
            },
            {
                roles: [
                    "OWNER",
                    "DIRECTOR",
                    "EXPERT",
                    "CURATOR",
                    "PRESENTER",
                    "MODERATOR",
                    "MENTOR",
                ],
                id: "contacts",
                label: "t-join-community",
                isContacts: true,
                inFooter: true,
                parentId: "support",
                contacts: [
                    {
                        id: "instagram-contact",
                        icon: "ri-instagram-line",
                        url: "https://www.instagram.com/bestclevers.lms/",
                    },
                    {
                        id: "youtube-contact",
                        icon: "ri-youtube-line",
                        url: "https://www.youtube.com/@Bestclevers",
                    },
                    {
                        id: "telegram-contact",
                        icon: "ri-telegram-line",
                        url: "https://t.me/bestcleverslms/",
                    },
                ],
            },
        ],
    },
    {
        roles: ["OWNER", "DIRECTOR"],
        id: "students",
        label: "t-students",
        icon: "ri-account-pin-box-fill",
        link: "/students",
    },
    {
        roles: ["OWNER", "DIRECTOR"],
        id: "team",
        label: "t-team",
        icon: "ri-team-fill",
        link: "/team",
    },
    {
        roles: [
            "OWNER",
            "DIRECTOR",
            "EXPERT",
            "CURATOR",
            "PRESENTER",
            "MODERATOR",
            "MENTOR",
        ],
        id: "schedule",
        label: "t-schedule",
        icon: "ri-calendar-todo-fill",
        link: "/schedule",
    },
    {
        roles: ["OWNER", "DIRECTOR", "EXPERT"],
        id: "disk",
        label: "t-cloud",
        icon: "ri-upload-cloud-2-fill",
        link: "/cloud/disk",
    },
    // {
    //     id: "feedback",
    //     label: "Відгук",
    //     icon: "ph-star",
    //     link: "/feedback",
    //     inFooter: true,
    // },
    // {
    //     id: "knowledgeBase",
    //     label: "База знань",
    //     icon: "ph-circle-wavy-question",
    //     link: "/knowledge-base",
    //     inFooter: true,
    // },
    {
        id: "chats",
        label: "t-chat",
        icon: " ri-message-2-fill",
        link: "/chat",
    },

    // {
    //   label: "t-menu",
    //   isHeader: true,
    // },
    // {
    //   id: "dashboard",
    //   label: "t-dashboards",
    //   icon: "ph-gauge",
    //   link: "sidebarDashboards",
    //   subItems: [
    //     { id: "analytics", label: "t-analytics", link: "#", parentId: "dashboard", },
    //     { id: "crm", label: "t-crm", link: "#", parentId: "dashboard", },
    //     { id: "ecommerce", label: "t-ecommerce", link: "/", parentId: "dashboard", },
    //     { id: "learning", label: "t-learning", link: "#", parentId: "dashboard", },
    //     { id: "real-estate", label: "t-real-estate", link: "#", parentId: "dashboard", },
    //   ],
    // },
    // {
    //   label: "t-apps",
    //   isHeader: true,
    // },
    // { id: "apps-calendar", label: "t-calendar", icon: "ph-calendar", link: "#", },
    // { id: "apps-chat", label: "t-chat", icon: "ph-chats", link: "#", },
    // { id: "apps-email", label: "t-email", icon: "ph-envelope", link: "#", },
    // {
    //   id: "ecommerce",
    //   label: "t-ecommerce",
    //   icon: "ph-storefront",
    //   link: "sidebarEcommerce",
    //   subItems: [
    //     { id: 1, label: "t-products", link: "#", parentId: "ecommerce" },
    //     { id: 2, label: "t-products-grid", link: "#", parentId: "ecommerce" },
    //     { id: 2, label: "t-products-Details", link: "#", parentId: "ecommerce" },
    //     { id: 3, label: "t-create-product", link: "#", parentId: "ecommerce" },
    //     { id: 4, label: "t-orders", link: "#", parentId: "ecommerce" },
    //     { id: 5, label: "t-order-overview", link: "#", parentId: "ecommerce" },
    //     { id: 6, label: "t-customers", link: "#", parentId: "ecommerce" },
    //     { id: 7, label: "t-shopping-cart", link: "#", parentId: "ecommerce" },
    //     { id: 8, label: "t-checkout", link: "#", parentId: "ecommerce" },
    //     { id: 9, label: "t-sellers", link: "#", parentId: "ecommerce" },
    //     { id: 10, label: "t-sellers-overview", link: "#", parentId: "ecommerce" },
    //   ],
    // },
    // { id: "apps-file-manager", label: "t-file-manager", icon: "ph-folder-open", link: "#", },
    // {
    //   id: "learning",
    //   label: "t-learning",
    //   icon: "ph-graduation-cap",
    //   link: "sidebarLearning",
    //   subItems: [
    //     {
    //       id: "courses",
    //       label: "t-courses",
    //       link: "sidebarCourses",
    //       parentId: "learning",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-list-view", link: "#", parentId: "learning" },
    //         { id: 2, label: "t-grid-view", link: "#", parentId: "learning" },
    //         { id: 3, label: "t-category", link: "#", parentId: "learning" },
    //         { id: 4, label: "t-overview", link: "#", parentId: "learning" },
    //         { id: 5, label: "t-create-course", link: "#", parentId: "learning" },
    //       ]
    //     },
    //     {
    //       id: "students",
    //       label: "t-students",
    //       link: "sidebarStudent",
    //       parentId: "learning",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-my-subscriptions", link: "#", parentId: "learning" },
    //         { id: 2, label: "t-my-courses", link: "#", parentId: "learning" },
    //       ]
    //     },
    //     {
    //       id: "instructors",
    //       label: "t-instructors",
    //       link: "sidebarInstructors",
    //       parentId: "learning",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-list-view", link: "#", parentId: "learning" },
    //         { id: 2, label: "t-grid-view", link: "#", parentId: "learning" },
    //         { id: 3, label: "t-overview", link: "#", parentId: "learning" },
    //         { id: 4, label: "t-create-instructors", link: "#", parentId: "learning" },
    //       ]
    //     },
    //   ],
    // },
    // {
    //   id: "invoice",
    //   label: "t-invoices",
    //   icon: "ph-file-text",
    //   link: "sidebarInvoices",
    //   subItems: [
    //     { id: "listview", label: "t-list-view", link: "#", parentId: "invoice", },
    //     { id: "overview", label: "t-overview", link: "#", parentId: "invoice", },
    //     { id: "createinvoice", label: "t-create-invoice", link: "#", parentId: "invoice", },
    //   ],
    // },
    // {
    //   id: "support-ticket",
    //   label: "t-support-tickets",
    //   icon: "ph-ticket",
    //   link: "sidebarTickets",
    //   subItems: [
    //     { id: "listview", label: "t-list-view", link: "#", parentId: "support-ticket", },
    //     { id: "overview", label: "t-overview", link: "#", parentId: "support-ticket", },
    //   ],
    // },
    // {
    //   id: "real-estate",
    //   label: "t-real-estate",
    //   icon: "ph-buildings",
    //   link: "sidebarRealeEstate",
    //   subItems: [
    //     { id: "sign in", label: "t-listing-grid", link: "#", parentId: "real-estate", },
    //     { id: "listinglist", label: "t-listing-list", link: "#", parentId: "real-estate", },
    //     { id: "listingmap", label: "t-listing-map", link: "#", parentId: "real-estate", },
    //     { id: "property-overview", label: "t-property-overview", link: "#", parentId: "real-estate", },
    //     {
    //       id: "agent",
    //       label: "t-agent",
    //       link: "sidebarAgent",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-list-view", link: "#" },
    //         { id: 2, label: "t-grid-view", link: "#" },
    //         { id: 3, label: "t-overview", link: "#" },
    //       ]
    //     },
    //     {
    //       id: "agencies",
    //       label: "t-agencies",
    //       link: "sidebarAgencies",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-list-view", link: "#" },
    //         { id: 3, label: "t-overview", link: "#" },
    //       ]
    //     },
    //     {
    //       id: "add-property",
    //       label: "t-add-property",
    //       link: "#",
    //       parentId: "real-estate",
    //     },
    //     {
    //       id: "earnings",
    //       label: "t-earnings",
    //       link: "#",
    //       parentId: "real-estate",
    //     },
    //   ],
    // },
    // {
    //   label: "t-pages",
    //   isHeader: true,
    // },
    // {
    //   id: "authentication",
    //   label: "t-authentication",
    //   icon: "ph-user-circle",
    //   link: "sidebarAuth",
    //   subItems: [
    //     { id: "signin", label: "t-signin", link: "#", parentId: "authentication" },
    //     { id: "sign up", label: "t-signup", link: "#", parentId: "authentication" },
    //     { id: "password-reset", label: "t-password-reset", link: "#", parentId: "authentication" },
    //     { id: "password-create", label: "t-password-create", link: "#", parentId: "authentication" },
    //     { id: "lockscreen", label: "t-lock-screen", link: "#", parentId: "authentication" },
    //     { id: "logout", label: "t-logout", link: "#", parentId: "authentication" },
    //     { id: "successmessage", label: "t-success-message", link: "#", parentId: "authentication" },
    //     { id: "verification", label: "t-two-step-verification", link: "#", parentId: "authentication" },
    //     {
    //       id: "error",
    //       label: "t-errors",
    //       link: "sidebarErrors",
    //       parentId: "authentication",
    //       isChildItem: true,
    //       subItems: [
    //         { id: 1, label: "t-404-error", link: "#", parentId: "authentication" },
    //         { id: 2, label: "t-500", link: "#", parentId: "authentication" },
    //         { id: 3, label: "t-503", link: "#", parentId: "authentication" },
    //         { id: 4, label: "t-offline-page", link: "#", parentId: "authentication" },
    //       ]
    //     },
    //   ],
    // },
    // {
    //   id: "pages",
    //   label: "t-pages",
    //   icon: "ph-address-book",
    //   link: "sidebarPages",
    //   subItems: [
    //     { id: "starter", label: "t-starter", link: "#", parentId: "pages" },
    //     { id: "profile", label: "t-profile", link: "#", parentId: "pages" },
    //     { id: "profilesettings", label: "t-profile-setting", link: "#", parentId: "pages" },
    //     { id: "contacts", label: "t-contacts", link: "#", parentId: "pages" },
    //     { id: "timeline", label: "t-timeline", link: "#", parentId: "pages" },
    //     { id: "faqs", label: "t-faqs", link: "#", parentId: "pages" },
    //     { id: "pricing", label: "t-pricing", link: "#", parentId: "pages" },
    //     { id: "maintenace", label: "t-maintenance", link: "#", parentId: "pages" },
    //     { id: "comingsoon", label: "t-coming-soon", link: "#", parentId: "pages" },
    //     { id: "privacypolicy", label: "t-privacy-policy", link: "#", parentId: "pages" },
    //     { id: "terms", label: "t-term-conditions", link: "#s", parentId: "pages" }
    //   ]
    // },
    // {
    //   label: "t-components",
    //   isHeader: true,
    // },
    // {
    //   id: "bootstrapui",
    //   label: "t-bootstrap-ui",
    //   icon: "ph-bandaids",
    //   link: "sidebarUI",
    //   subItems: [
    //     { id: 1, label: "t-alerts", link: "#", parentId: "boostrapui" },
    //     { id: 2, label: "t-badges", link: "#", parentId: "boostrapui" },
    //     { id: 3, label: "t-buttons", link: "#", parentId: "boostrapui" },
    //     { id: 4, label: "t-colors", link: "#", parentId: "boostrapui" },
    //     { id: 5, label: "t-cards", link: "#", parentId: "boostrapui" },
    //     { id: 6, label: "t-carousel", link: "#", parentId: "boostrapui" },
    //     { id: 7, label: "t-dropdowns", link: "#", parentId: "boostrapui" },
    //     { id: 8, label: "t-grid", link: "#", parentId: "boostrapui" },
    //     { id: 9, label: "t-images", link: "#", parentId: "boostrapui" },
    //     { id: 10, label: "t-tabs", link: "#", parentId: "boostrapui" },
    //     { id: 11, label: "t-accordion-collapse", link: "#", parentId: "boostrapui" },
    //     { id: 12, label: "t-modals", link: "#", parentId: "boostrapui" },
    //     { id: 13, label: "t-offcanvas", link: "#", parentId: "boostrapui" },
    //     { id: 14, label: "t-placeholders", link: "#", parentId: "boostrapui" },
    //     { id: 15, label: "t-progress", link: "#", parentId: "boostrapui" },
    //     { id: 16, label: "t-media-object", link: "#", parentId: "boostrapui" },
    //     { id: 17, label: "t-embed-video", link: "#", parentId: "boostrapui" },
    //     { id: 18, label: "t-typography", link: "#", parentId: "boostrapui" },
    //     { id: 19, label: "t-lists", link: "#", parentId: "boostrapui" },
    //     { id: 20, label: "t-links", link: "#", parentId: "boostrapui" },
    //     { id: 21, label: "t-general", link: "#", parentId: "boostrapui" },
    //     { id: 22, label: "t-utilities", link: "#", parentId: "boostrapui" },
    //   ],
    // },
    // {
    //   id: "advanceui",
    //   label: "t-advance-ui",
    //   icon: "bi bi-layers",
    //   link: "sidebarAdvanceUI",
    //   subItems: [
    //     { id: 1, label: "t-sweet-alerts", link: "#", parentId: "advanceui" },
    //     { id: 2, label: "t-scrollbar", link: "#", parentId: "advanceui" },
    //     { id: 3, label: "t-swiper-slider", link: "#", parentId: "advanceui" },
    //     { id: 4, label: "t-ratings", link: "#", parentId: "advanceui" },
    //     { id: 5, label: "t-highlight", link: "#", parentId: "advanceui" },
    //   ],
    // },
    // {
    //   id: "customui",
    //   label: "t-custom-ui",
    //   icon: "ph-wrench",
    //   link: "customUI",
    //   subItems: [
    //     { id: 1, label: "t-ribbons", link: "#", parentId: "customui" },
    //     { id: 2, label: "t-profile", link: "#", parentId: "customui" },
    //     { id: 3, label: "t-counter", link: "#", parentId: "customui" },
    //   ],
    // },
    // {
    //   id: "widgets",
    //   label: "t-widgets",
    //   icon: "ph-paint-brush-broad",
    //   link: "#",
    // },
    // {
    //   id: "forms",
    //   label: "t-forms",
    //   icon: "ri-file-list-3-line",
    //   link: "sidebarForms",
    //   subItems: [
    //     { id: 1, label: "t-basic-elements", link: "#", parentId: "forms" },
    //     { id: 2, label: "t-form-select", link: "#", parentId: "forms" },
    //     { id: 3, label: "t-checkboxes-radios", link: "#", parentId: "forms" },
    //     { id: 4, label: "t-pickers", link: "#", parentId: "forms" },
    //     { id: 5, label: "t-input-masks", link: "#", parentId: "forms" },
    //     { id: 6, label: "t-advanced", link: "#", parentId: "forms" },
    //     { id: 7, label: "t-range-slider", link: "#", parentId: "forms" },
    //     { id: 8, label: "t-validation", link: "#", parentId: "forms" },
    //     { id: 9, label: "t-wizard", link: "#", parentId: "forms" },
    //     { id: 10, label: "t-editors", link: "#", parentId: "forms" },
    //     { id: 11, label: "t-file-uploads", link: "#", parentId: "forms" },
    //     { id: 12, label: "t-form-layouts", link: "#", parentId: "forms" },
    //   ],
    // },
    // {
    //   id: "tables",
    //   label: "t-tables",
    //   icon: "ph-table",
    //   link: "sidebarTables",
    //   subItems: [
    //     { id: 1, label: "t-basic-tables", link: "#", parentId: "tables" },
    //   ],
    // },
    // {
    //   id: "apexcharts",
    //   label: "t-apexcharts",
    //   icon: "ph-chart-pie-slice",
    //   link: "sidebarCharts",
    //   subItems: [
    //     { id: 1, label: "t-line", link: "#", parentId: "apexcharts" },
    //     { id: 2, label: "t-area", link: "#", parentId: "apexcharts" },
    //     { id: 3, label: "t-column", link: "#", parentId: "apexcharts" },
    //     { id: 4, label: "t-bar", link: "#", parentId: "apexcharts" },
    //     { id: 5, label: "t-mixed", link: "#", parentId: "apexcharts" },
    //     { id: 6, label: "t-timeline", link: "#", parentId: "apexcharts" },
    //     { id: 7, label: "t-candlstick", link: "#", parentId: "apexcharts" },
    //     { id: 8, label: "t-boxplot", link: "#", parentId: "apexcharts" },
    //     { id: 9, label: "t-bubble", link: "#", parentId: "apexcharts" },
    //     { id: 10, label: "t-scatter", link: "#", parentId: "apexcharts" },
    //     { id: 11, label: "t-heatmap", link: "#", parentId: "apexcharts" },
    //     { id: 12, label: "t-treemap", link: "#", parentId: "apexcharts" },
    //     { id: 13, label: "t-pie", link: "#", parentId: "apexcharts" },
    //     { id: 14, label: "t-radialbar", link: "#", parentId: "apexcharts" },
    //     { id: 15, label: "t-radar", link: "#", parentId: "apexcharts" },
    //     { id: 16, label: "t-polar-area", link: "#", parentId: "apexcharts" },
    //   ],
    // },
    // {
    //   id: "icons",
    //   label: "t-icons",
    //   icon: "ph-traffic-cone",
    //   link: "sidebarIcons",
    //   subItems: [
    //     { id: 1, label: "t-remix", link: "#", parentId: "icons" },
    //     { id: 2, label: "t-boxicons", link: "#", parentId: "icons" },
    //     { id: 3, label: "t-material-design", link: "#", parentId: "icons" },
    //     { id: 4, label: "t-bootstrap", link: "#", parentId: "icons" },
    //     { id: 5, label: "t-phosphor", link: "#", parentId: "icons" },
    //   ],
    // },
    // {
    //   id: "maps",
    //   label: "t-maps",
    //   icon: "ph-map-trifold",
    //   link: "sidebarMaps",
    //   subItems: [
    //     { id: 1, label: "t-google", link: "#", parentId: "maps", },
    //     { id: 2, label: "t-amcharts", link: "#", parentId: "maps" }
    //   ],
    // },

    // {
    //   id: "multilevel", label: "t-multi-level", icon: "bi bi-share", link: "sidebarMultilevel",
    //   subItems: [
    //     { id: "level1.1", label: "t-level-1.1", link: "/#", parentId: "multilevel" },
    //     {
    //       id: "level1.2", label: "t-level-1.2", link: "sidebarAccount", isChildItem: true,
    //       childItems: [
    //         { id: 1, label: "t-level-2.1", link: "/#" },
    //         {
    //           id: "level2.2", label: "t-level-2.2", link: "sidebarCrm", isChildItem: true,
    //           childItems: [
    //             { id: 1, label: "t-level-3.1", link: "/#" },
    //             { id: 2, label: "t-level-3.2", link: "/#" },
    //           ]
    //         },
    //       ]
    //     },
    //   ],
    // },
];

export default horMenu;
