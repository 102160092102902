<script setup>

import InputGroup from '@/Components/InputGroup.vue';
import {router, useForm} from '@inertiajs/vue3';
const form = useForm({
  name: '',
  email: '',
  phone: '',
});

const onSubmit = () => {
  form.post(route('register'), {

  });
};

const digit1 = 1;
const digit2 = 6;
const digit3 = 5;
const digit4 = 3;

</script>

<template>
  <div class="form-login-header text-center">
    <h1 class="text-md login-title">
      Введіть код підтввердження
    </h1>
    <p>Відправлений на korotkijbogdan@gmail.com</p>
  </div>

  <b-form class="login-form">

    <div class="d-flex">
      <b-form-input
        type="text"
        v-model="digit1"
        class="verification-digit"
        placeholder="0" maxLength="1"
      />
      <b-form-input
        type="text"
        v-model="digit2"
        class="verification-digit"
        placeholder="0" maxLength="1"
      />
      <b-form-input
        type="text"
        v-model="digit3"
        class="verification-digit"
        placeholder="0" maxLength="1"
      />
      <b-form-input
        type="text"
        v-model="digit4"
        class="verification-digit"
        placeholder="0" maxLength="1"
      />
    </div>

    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button variant="primary" size="lg" pill @click="() => router.get('/autowebinars/payment')">
        Підтвердити
      </b-button>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button variant="text-variant" size="md" class="d-flex align-items-center justify-content-center" pill @click="() => router.get('/autowebinars/paid')">
        <i class="ri-arrow-left-s-line"></i> Назад
      </b-button>
    </div>
  </b-form>
</template>

<style lang="scss">
.form-login-header {
  p {
    color: var(--Grey, #C2C2C2);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 22.4px */
  }
}
.verification-digit {
  text-align: center;
  display: flex;
  padding: 28px 18px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  background: var(--white, #FFF);
  color: black;
}
</style>
