<script setup>
import { router } from "@inertiajs/vue3";
import { ref } from "vue";

const props = defineProps({
    form: Object,
});

const selectedRegisterId = ref(null);

function grantAccess(userId) {
    console.log(userId);
    router.visit(route("workshops.auto-webinar.grant-access"), {
        method: "post",
        data: {
            registerId: selectedRegisterId.value,
            webinarId: props.form.workShop.id,
        },
        preserveState: false,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: () => {
            console.log("sss");
        },
    });
    selectedRegisterId.value = null;
}

function removeUser(userId) {
    router.visit(route("workshops.remove-user"), {
        method: "post",
        data: {
            registerId: userId,
            webinarId: props.form.workShop.id,
        },
        preserveState: false,
        preserveScroll: true,
        forceFormData: true,
    });
    selectedRegisterId.value = null;
    // props.form.webinar = value;
}
</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div v-if="!users || users.length > 0" class="module-table">
                <div class="module-table__header border-bottom pb-2">
                    <div
                        class="module-table__col module-table__col--email text-uppercase opacity-50"
                    >
                    Email
                    </div>
                    <div
                        class="module-table__col module-table__col--name text-uppercase opacity-50"
                    >
                        ім'я
                    </div>
                    <div
                        class="module-table__col module-table__col--phone text-uppercase opacity-50"
                    >
                        телефон
                    </div>
                    <div
                        class="module-table__col module-table__col--payment text-uppercase opacity-50"
                    >
                        оплата
                    </div>
                    <div
                        class="module-table__col module-table__col--actions text-uppercase opacity-50"
                    ></div>
                </div>
                <div
                    v-for="user of form.workShop.users"
                    :key="user.id"
                    class="module-table__row border-bottom py-3"
                >
                    <div class="module-table__col module-table__col--email">
                        <!--            <StatusBage :type="user.access" />-->
                        <span
                            class="module-table__email-bage"
                            :class="[
                                user.access === false
                                    ? 'module-table__email-bage-new'
                                    : '',
                            ]"
                        >
                            {{ user.access === false ? "new" : "approved" }}
                        </span>
                        {{ user.email }}
                    </div>
                    <div class="module-table__col module-table__col--name">
                        {{ user.name }}
                    </div>
                    <div class="module-table__col module-table__col--phone">
                        {{ user.phone }}
                    </div>
                    <div class="module-table__col module-table__col--payment">
                        <i class="ph-arrow-down-bold"></i>
                        <a target="_blank" :href="user.payment_check"
                            >download</a
                        >
                    </div>
                    <div class="module-table__col module-table__col--actions">
                        <b-button
                            variant="success"
                            pill
                            v-if="user.access === false"
                            v-b-modal.grantAccessModal
                            @click="selectedRegisterId = user.id"
                        >
                            Надати доступ
                        </b-button>
                        <i class="bi bi-x-lg" @click="removeUser(user.id)"></i>
                    </div>
                </div>
            </div>
            <div v-else>
                <h3 class="text-center m-5 text-black-50">Ще нікого нема</h3>
            </div>
            <b-modal id="grantAccessModal" hide-footer hide-header centered>
                <div class="modal-body">
                    <div class="grand-modal-title">
                        <i class="ph-warning"></i>
                        <h4>Підтвердіть надання доступу</h4>
                    </div>
                    <p class="grand-modal-desc">
                        Якщо ви надасте студенту доступ до автовебінару, його вже не можна буде скасувати.
                    </p>
                    <div class="grand-modal-actions">
                        <b-button
                            class="outline-dark"
                            pill
                            v-b-modal.grantAccessModal
                            @click="selectedRegisterId = null"
                        >
                            Скасувати
                        </b-button>
                        <b-button
                            class="grand-modal-accept m-2"
                            pill
                            v-b-modal.grantAccessModal
                            @click="grantAccess"
                        >
                            Надати доступ
                        </b-button>
                    </div>
                </div>
            </b-modal>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.grand-modal-title {
    color: #da7f14;
    display: flex;
    flex-direction: row;

    i {
        font-size: xxx-large;
    }

    h4 {
        color: #da7f14;
    }
}

.grand-modal-desc {
    margin-left: 50px;
    color: dimgray;
}

.grand-modal-accept {
    background-color: var(--green, #1db954);
    border: none;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.module-table__col--email {
    display: flex;
    width: 30%;
}

.module-table__col--name {
    width: 15%;
}

.module-table__col--phone {
    width: 10%;
}

.module-table__col--payment {
    width: 10%;

    i {
        color: green;
    }
}

.module-table__col--actions {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    //i {
    //  margin: 0 10px;
    //  font-size: large;
    //  color: #C2C2C2;
    //}
}
</style>
