<script setup>
import {computed} from "vue";
import CommentField from "@/Components/form/CommentField.vue";

  const props = defineProps({
    modelValue: Boolean,
    user: Object,
    ignoredFields: {
      type: Array,
      default: []
    }
  });

  const emit = defineEmits([
    'update:modelValue',
    'sendInput'
  ]);

  const isVisible = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })



</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>Деталі користувача</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <div v-for="(value, key) in user" :key="key">
        <div
            v-if="!ignoredFields.includes(key)"
            class="user-field"
        >
          {{ value }}
        </div>
      </div>
      <CommentField v-model="user.comment" />
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.user-field {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grey, #C2C2C2);
  background: var(--white, #FFF);
  color: var(--Grey, #C2C2C2);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 20px;
}
</style>
