<script setup>

    import MasterclassLayout from "@/Layouts/MasterclassLayout.vue";
    import logo from "@/../images/landing/logo_dark.svg"
    import selectedItem from "@/../images/selected-item.svg"
    import {nextTick, onBeforeUnmount, onMounted, ref} from "vue";
    import VideoPlayer from "@/Components/VideoPlayer.vue";

    const props = defineProps({
        masterclass: Object
    })

    defineOptions({layout: MasterclassLayout})

    const masterclassPlayerContainer = ref(null)
    const masterclassPlayList = ref(null)
    const masterclassPlayListStyle = ref({})
    const masterclassPlayListScrollStyle = ref({})

    const selectedVideo = ref(null)

    const updatePlaylistHeight = () => {
        const isMobile = window.matchMedia('(max-width: 1024px)').matches;
        if (isMobile) {
            if (masterclassPlayList.value) {
                const masterclassPlayListRect = masterclassPlayList.value.getBoundingClientRect();
                const elementTopPosition = masterclassPlayListRect.top;
                const availableHeight = window.innerHeight - elementTopPosition;

                masterclassPlayListStyle.value = {
                    height: `${availableHeight}px`,
                };
                masterclassPlayListScrollStyle.value = {
                    height: `${availableHeight - 56}px`,
                };
            }
        } else {
            if (masterclassPlayerContainer.value) {
                const masterclassPlayerContainerHeight = masterclassPlayerContainer.value.offsetHeight
                masterclassPlayListStyle.value = {
                    height: `${masterclassPlayerContainerHeight}px`,
                };
                masterclassPlayListScrollStyle.value = {
                    height: `${masterclassPlayerContainerHeight - 56}px`,
                };
            }
        }
    }

    onMounted(() => {
        if(props.masterclass && props.masterclass.data && props.masterclass.data.videosArray && props.masterclass.data.videosArray.length > 0){
            selectedVideo.value = props.masterclass.data.videosArray[0]
        }
        nextTick(() => {
            updatePlaylistHeight();
        })
        window.addEventListener("resize", updatePlaylistHeight);
    })

    onBeforeUnmount(() => {
        window.removeEventListener("resize", updatePlaylistHeight);
    });

</script>

<template>
    <div class="masterclass-container">
        <div class="masterclass-header">
            <div class="masterclass-header__bar">
                <img
                    :src="logo"
                    alt="logo"
                    height="44"
                />
            </div>
        </div>
        <div class="masterclass-content">
            <div ref="masterclassPlayerContainer" class="masterclass__player-container">
                <div v-if="selectedVideo" class="masterclass__player">
                    <div v-if="!selectedVideo.status || selectedVideo.status !== 'Finished'" class="video-status">
                        <img :src="selectedVideo.preview" title="" alt="" class="video-status__preview"/>
                        <div class="video-status__loader">
                            <div>
                                <b-spinner variant="primary"></b-spinner>
                            </div>
                            <div>
                                {{ selectedVideo.status ? $t(selectedVideo.status) : 'Обробка' }}
                            </div>
                        </div>
                    </div>
                    <VideoPlayer v-else :video-src="selectedVideo.file" />
                </div>
                <div class="masterclass__video-info">
                    <div v-if="selectedVideo && selectedVideo.name" class="masterclass__video-info--title">{{selectedVideo.name}}</div>
                </div>
                <div class="masterclass__buttons">
                    <b-button
                        v-if="masterclass.data.buttons && masterclass.data.buttons.length > 0"
                        v-for="btn in masterclass.data.buttons"
                        :key="btn.id"
                        :href="btn.url"
                        target="_blank"
                        type="button"
                        variant="primary"
                        pill
                        class="px-3"
                    >
                        {{btn.title}}
                    </b-button>
                </div>
            </div>
            <div ref="masterclassPlayList" class="masterclass__play-list" :style="masterclassPlayListStyle">
                <div class="play-list__header">
                    Мій плейлист
                </div>
                <perfect-scrollbar :style="masterclassPlayListScrollStyle">
                    <div class="play-list__content" :style="masterclassPlayListStyle">
                            <div
                                v-for="video in masterclass.data.videosArray"
                                :key="video.id"
                                class="play-list__item"
                                :class="{'active' : selectedVideo && video.id === selectedVideo.id}"
                                @click="selectedVideo = video"
                            >
                                <div class="play-list__item--content">
                                    <img :src="selectedItem" alt="current item" class="selected-icon" />
                                    <div class="play-list__item--preview">
                                        <img :src="video.preview ? video.preview : noImage" alt="current item" />
                                    </div>
                                    <div class="play-list__item--info">
                                        <div class="play-list__item-title">{{video.name}}</div>
                                        <div><span>Завантажено: </span>{{video.updated_at_date}}</div>
                                        <div><span>Тривалість: </span>{{video.length}}</div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
<!--    <pre>{{masterclass}}</pre>-->
<!--    <div class="masterclass-page">-->
<!--        <div class="masterclass-page__header">-->
<!--            {{masterclass.data.name}}-->
<!--            <div v-if="selectedVideo && selectedVideo.name" class="header-counter">Назва: {{selectedVideo.name}}</div>-->
<!--        </div>-->
<!--        <div class="masterclass-page__content">-->
<!--            <div v-if="selectedVideo" class="masterclass-video-container">-->
<!--                <div v-if="!selectedVideo.status || selectedVideo.status !== 'Finished'" class="video-status">-->
<!--                    <img :src="selectedVideo.preview" title="" alt="" class="video-status__preview"/>-->
<!--                    <div class="video-status__loader">-->
<!--                        <div>-->
<!--                            <b-spinner variant="primary"></b-spinner>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            {{ selectedVideo.status ? $t(selectedVideo.status) : 'Обробка' }}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <VideoPlayer v-else :video-src="selectedVideo.file" />-->
<!--                <div-->
<!--                    v-if="masterclass.data.videosArray"-->
<!--                    class="masterclass-page__playlist"-->
<!--                >-->
<!--                    <perfect-scrollbar>-->
<!--                        <div v-for="video in masterclass.data.videosArray" :key="video.id" class="playlist__video&#45;&#45;item" :class="{'active' : selectedVideo && video.id === selectedVideo.id}">-->
<!--                            <div class="playlist__video&#45;&#45;preview" @click="selectedVideo = video">-->
<!--                                <img-->
<!--                                    :src="video.preview ? video.preview : noImage"-->
<!--                                    :class="{'playlist__video&#45;&#45;preview__img' : video.preview}"-->
<!--                                    alt="Превью"-->
<!--                                />-->
<!--                            </div>-->
<!--                            <div class="playlist__video&#45;&#45;name">-->
<!--                                {{video.name ? video.name : 'Без назви'}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </perfect-scrollbar>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div v-else class="masterclass-video-container">-->
<!--                Оберіть відео-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="masterclass-page__footer">-->
<!--            <b-button-->
<!--                v-if="masterclass.data.buttons && masterclass.data.buttons.length > 0"-->
<!--                v-for="btn in masterclass.data.buttons"-->
<!--                :key="btn.id"-->
<!--                :href="btn.url"-->
<!--                target="_blank"-->
<!--                type="button"-->
<!--                variant="primary"-->
<!--                pill-->
<!--                class="px-3"-->
<!--            >-->
<!--                {{btn.title}}-->
<!--            </b-button>-->
<!--        </div>-->
<!--    </div>-->
</template>

<style lang="scss">
.masterclass-container {
    width: 100%;
    background: #F7F7F8;
    height: 100vh;

    .masterclass-header {
        height: 64px;
        background: #FFFFFF;
        .masterclass-header__bar {
            max-width: 1920px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 16px;
        }
    }

    .masterclass-content {
        max-width: 1920px;
        margin: 0 auto;
        padding: 12px;
        display: flex;
        gap: 12px;
        max-height: calc(100vh - 64px);

        .masterclass__player-container {
            flex: 1;
            max-width: 1280px;
            min-width: 0;
            display: flex;
            flex-direction: column;
            gap: 12px;
            //max-height: 100%;
            max-height: fit-content;
            .masterclass__player {
                width: 100%;
                aspect-ratio: 16/9;
                background-color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 90%);
                border-radius: 16px;
                overflow: hidden;
                    .video-js {
                        width: 100%!important;
                        height: 100%!important;
                        aspect-ratio: 16/9!important;
                    }
                    .video-status {
                        width: 100%;
                        height: 100%;
                    }
            }

            .masterclass__video-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #FFFFFF;
                border-radius: 12px;
                padding: 12px 16px;
                .masterclass__video-info--title {
                    font-family: e-Ukraine;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 32px;
                    text-align: left;
                    color: #1E1E1E;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .masterclass__buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
            }
        }

        .masterclass__play-list {
            flex: 1;
            max-width: calc(100% - 1280px - 12px);
            background-color: #e0e0e0;
            box-sizing: border-box;
            min-width: 360px;
            border-radius: 16px;
            max-height: 100%;
            overflow: hidden;
            position: relative;
            .play-list__header {
                background: var(--Dark-green, #0F271C);
                padding: 16px 20px;
                font-family: e-Ukraine;
                font-size: 13px;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: -0.02em;
                text-align: center;
                color: #FFFFFF;
            }
            .play-list__content {
                box-sizing: border-box;
                position: relative;
                //max-height: calc(100vh - 128px);
                .play-list__item {
                    padding: 12px;
                    background-color: white;
                    cursor: pointer;
                    border-bottom: 1px;
                    border-top: 1px;
                    border-style: solid;
                    border-color: white;
                    .play-list__item--content {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        .selected-icon {
                            width: 20px;
                            height: 20px;
                            visibility: hidden;
                        }
                        .play-list__item--preview {
                            height: 77px;
                            width: 136px;
                            border-radius: 4px;
                            background: #f7f7f8;
                            border: 1px solid #f7f7f8;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                        .play-list__item--info {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            .play-list__item-title {
                                font-family: e-Ukraine;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 14.4px;
                                text-align: left;
                                color: #1E1E1E;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            div {
                                font-family: e-Ukraine;
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 10px;
                                text-align: left;
                                color: #1E1E1E;
                                span {
                                    font-family: e-Ukraine;
                                    font-size: 10px;
                                    font-weight: 200;
                                    line-height: 10px;
                                    text-align: left;
                                    color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
                                }
                            }
                        }
                    }
                    &.active, &:hover {
                        .selected-icon {
                            visibility: visible;
                        }
                        .play-list__item--preview {
                            border: 1px solid #1db954;
                        }
                        border-bottom: 1px;
                        border-top: 1px;
                        border-style: solid;
                        border-color: #1db954;
                    }
                }
            }
            .ps__thumb-y {
                background-color: #1db954!important;
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;

            .masterclass__player-container {
                max-width: 100%;
                margin-bottom: 16px;
            }

            .masterclass__play-list {
                max-width: 100%;
                min-width: 100%;
            }
        }
    }
}


    //
    //.masterclass-page {
    //    display: flex;
    //    flex-direction: column;
    //    height: 100vh;
    //    background: #f7f7f8;
    //    .masterclass-page__header {
    //        height: 85px;
    //        width: 100%;
    //        display: flex;
    //        align-items: center;
    //        justify-content: space-between;
    //        font-family: e-Ukraine;
    //        font-size: 20px;
    //        font-weight: 400;
    //        line-height: 32px;
    //        text-align: left;
    //        color: #1E1E1E;
    //        padding: 0 16px;
    //        .header-counter {
    //            padding: 14px 24px;
    //            gap: 8px;
    //            border-radius: 50px;
    //            background: #FFFFFF;
    //            font-family: e-Ukraine;
    //            font-size: 14px;
    //            font-weight: 400;
    //            line-height: 22.4px;
    //            text-align: left;
    //            color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
    //        }
    //    }
    //    .masterclass-page__content {
    //        flex: 1;
    //        padding: 16px;
    //        display: flex;
    //        justify-content: center;
    //        align-items: center;
    //        .masterclass-video-container {
    //            background: color-mix(in srgb, var(--dark, #1E1E1E), transparent 90%);
    //            border-radius: 16px;
    //            height: calc(100vh - 202px);
    //            width: calc((100vh - 202px)  * (16 / 9));
    //            display: flex;
    //            align-items: center;
    //            justify-content: center;
    //            font-family: e-Ukraine;
    //            color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
    //            font-size: 20px;
    //            position: relative;
    //            .video-js {
    //                height: calc(100vh - 202px)!important;
    //                width: calc((100vh - 202px)  * (16 / 9))!important;
    //            }
    //            .video-status {
    //                width: 100%;
    //                height: 100%;
    //            }
    //            border: 1px solid red;
    //            .masterclass-page__playlist {
    //                position: absolute;
    //                top: 0;
    //                left: -168px;
    //                width: 160px;
    //                height: calc(100vh - 202px);
    //                padding: 0 4px;
    //                display: flex;
    //                flex-direction: column;
    //                gap: 4px;
    //                .playlist__video--item {
    //                    margin-bottom: 4px;
    //                    &.active {
    //                        .playlist__video--preview {
    //                            border: 1px solid var(--green, #1db954);
    //                        }
    //                        .playlist__video--name {
    //                            color: var(--green, #1db954);
    //                        }
    //                    }
    //                    &:last-child {
    //                        margin-bottom: 0;
    //                    }
    //                    &:hover {
    //                        .playlist__video--preview {
    //                            border: 1px solid var(--green, #1db954);
    //                        }
    //                        .playlist__video--name {
    //                            color: var(--green, #1db954);
    //                        }
    //                    }
    //                    .playlist__video--name {
    //                        font-family: e-Ukraine;
    //                        font-size: 12px;
    //                        font-weight: 400;
    //                        line-height: 16px;
    //                        text-align: left;
    //                        color: #1E1E1E;
    //                        overflow: hidden;
    //                        white-space: nowrap;
    //                        text-overflow: ellipsis;
    //                    }
    //                    .playlist__video--preview {
    //                        cursor: pointer;
    //                        width: 100%;
    //                        border: 1px solid color-mix(in srgb, var(--dark, #1E1E1E), transparent 75%);
    //                        background: #f7f7f8;
    //                        overflow: hidden;
    //                        border-radius: 8px;
    //                        aspect-ratio: 16 / 9;
    //                        display: flex;
    //                        justify-content: center;
    //                        align-items: center;
    //                        .playlist__video--preview__img {
    //                            width: 100%;
    //                            height: 100%;
    //                            object-fit: contain;
    //                            object-position: center;
    //                        }
    //                    }
    //                }
    //            }
    //        }
    //    }
    //    .masterclass-page__footer {
    //        height: 85px;
    //        width: 100%;
    //        background: #ffffff;
    //        display: flex;
    //        gap: 16px;
    //        align-items: center;
    //        justify-content: center;
    //    }
    //}
</style>
