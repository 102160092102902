<script setup>

import {useMessagesNotificationsStore} from "@/stores/messagesNotificationsStore.js";
import {BButton} from "bootstrap-vue-next";
import {Link} from "@inertiajs/vue3";
import noAvatar from '../../images/tmp/ProductOwnerProfile/avatar.png';
import {toast} from "vue3-toastify";
import {onMounted, ref, watch} from "vue";
import alertSound from '../../sounds/mixkit-light-button-2580.wav';
import {getLatestMessages} from "@/api/chat.js";
// import alertSound from '../../sounds/mixkit-tile-game-reveal-960.wav';

const props = defineProps({
    isPreview: {
        type: Boolean,
        default: false
    },
})

const messagesStore = useMessagesNotificationsStore()

const toastsEnabledModel = ref(messagesStore.toastsEnabled)
const soundsEnabledModel = ref(messagesStore.soundsEnabled)

const triggerTestMessage = () => {
    toast.success(
        '<strong>Імʼя Відправника</strong>\nДемонстраційне повідомлення🤘👋🙂', {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
            limit: 3,
            dangerouslyHTMLString: true,
            icon: "✉️",
        });
}

const triggerTestSound = () => {
    const audio = new Audio(alertSound);
    audio.volume = 0.2;
    audio.play().catch(error => {
        console.error('Error playing sound:', error);
    });
};

const latestMessagesLoading = ref(false);
const loadLatestMessages = async () => {
    if (!latestMessagesLoading.value) {
        latestMessagesLoading.value = true
        const response = await getLatestMessages(1)

        if (response.data) {
            messagesStore.setMessages(response.data?.messages)
            messagesStore.setMessagesCount(response.data?.totalCount)
        }
        latestMessagesLoading.value = false
    }
}

watch(() => toastsEnabledModel.value, (newVal) => {
    messagesStore.toggleToasts(newVal)
    if (newVal) {
        triggerTestMessage();
    }
})

watch(() => soundsEnabledModel.value, (newVal) => {
    messagesStore.toggleSounds(newVal)
    if (newVal) {
        triggerTestSound();
    }
})

onMounted(async () => {
    await loadLatestMessages()
})

</script>

<template>
    <div class="dropdown topbar-head-dropdown ms-1 header-item" id="messagesDropdown">
        <b-button
            type="button" variant="ghost-dark"
            class="btn-icon btn-topbar rounded-circle"
            :disabled="isPreview"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <i class='ph-chat-circle-text-fill fs-3'></i>
            <span v-if="messagesStore.countUnread > 0" class="position-absolute topbar-badge cartitem-badge fs-3xs translate-middle badge rounded-pill bg-danger">
                <span class="notification-badge">
                    {{ messagesStore.countUnread }}
                </span>
                <span class="visually-hidden">unread messages</span>
            </span>
        </b-button>
        <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-cart-dropdown"
        >
            <div class="p-3 border-bottom">
                <b-row class="align-items-center">
                    <b-col>
                        <h6 class="m-0 fs-lg fw-semibold">Останні повідомлення</h6>
                    </b-col>
                </b-row>
            </div>
            <div class="messagesDropdown__header p-2">
                <BFormCheckbox v-model="toastsEnabledModel" switch inline>
                    Вспливаючі повідомлення
                </BFormCheckbox>
                <BFormCheckbox v-model="soundsEnabledModel" switch inline>
                    Звукові сповіщення
                </BFormCheckbox>
            </div>
            <hr class="m-0">
            <perfect-scrollbar :style="{minHeight: '100px', maxHeight: '300px'}">
                <div class="p-0">
                    <div v-if="messagesStore.messages?.length <= 0" class="text-center empty-cart">
                        <div class="avatar-md mx-auto my-3">
                            <div class="avatar-title bg-info-subtle text-info fs-2 rounded-circle">
                                <i class="ph-chat-circle-text-fill"></i>
                            </div>
                        </div>
                        <h5 class="mb-3">У вашій скриньці поки що немає повідомлень.</h5>
                    </div>
                    <div v-else class="messages-list">
                        <Link
                            v-for="(message, index) of messagesStore.messages"
                            :key="message.id"
                            :href="`/chat?conversation=${message.conversation_id}`"
                            class="d-block dropdown-item product text-wrap p-2 border-bottom"
                        >
                            <div class="d-flex">
                                <div class="avatar-sm me-3 flex-shrink-0">
                                    <div class="avatar-title bg-light rounded">
                                        <img :src="noAvatar" class="rounded-circle header-profile-user" alt="user-pic"/>
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <h6 class="mt-0 mb-1 fs-md">
                                        <span class="text-reset">
                                            {{ message.sender_name }}
                                        </span>
                                    </h6>
                                    <p class="mb-1 fs-sm text-muted">
                                        {{ message.content }}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </perfect-scrollbar>

            <Link :href="'/chat'" class="btn btn-md btn-ghost-success w-100 p-3" role="button" target="_self" >
                Перейти до чатів
            </Link>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
