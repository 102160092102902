<script setup>
    import { useClipboard } from '@vueuse/core'
    import {toast} from 'vue3-toastify';
    import 'vue3-toastify/dist/index.css';
    import {watch} from "vue";

    const props = defineProps({
        publicLink: {
            type: String,
            default: ''
        },
        noLabel: {
          type: Boolean,
          default: false
        }
    })

    const { text, copy, copied, isSupported } = useClipboard({
        legacy: true
    })

    watch(copied, (newValue, oldValue) => {
        if (newValue) {
            toast.success('Cкопійовано', {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    });

    const handleCopy = (text) => {
        if (text && text.length > 0) copy(text)
    }

</script>

<template>
    <label v-if="!noLabel" class="form-label mt-3 fw-normal">Публічне посилання</label>
    <div class="public-link">
      <div class="public-link__text">{{publicLink}}</div>
      <b-button variant="text" @click="handleCopy(publicLink)">
        <i class="bi bi-front"></i>
      </b-button>
    </div>
</template>

<style scoped lang="scss">
.public-link {
  border-radius: 4px;
  border: 1px solid #1DB954;
  background-color: #1DB9540A;
  display: flex;
  align-items: center;
  width: 100%;
  .public-link__text {
    width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    position: relative;
    color: #1E1E1E;
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
  }
  i {
    font-size: 16px;
  }
  .public-link-form-input {
    background: transparent;
    border: none;
    &:disabled {
      color: var(--tb-body-color);
    }
  }
}

</style>
