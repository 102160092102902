<script setup>
import dog from "../../../../images/tmp/autowebinars/dog.svg";
// import video from "../../../../images/tmp/masterClasss/video.png";
import {computed, onMounted, ref, watch} from 'vue';
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";

const props = defineProps({
  form: Object
});

const onSubmit = () => {
  emit('save');
};

const uploadVideoCount = computed(() => {
  let tmp = 0;
  for (const video of props.form.workShop.videos) {
    if (video.type === 'TYPE_UPLOAD') {
      tmp = tmp + 1;
    }
  }
  return tmp;
});

const recordVideoCount = computed(() => {
  let tmp = 0;
  for (const video of props.form.workShop.videos) {
    if (video.type === 'TYPE_WEBINAR_RECORD') {
      tmp = tmp + 1;
    }
  }
  return tmp;
});

watch(
    () => props.form.selectedVideo,
    (value) => {
      props.form.workShop.config.video_id = value.id;
    }
    , {deep: true}
);

onMounted(
    () => {
      for (const video of props.form.workShop.videos) {
        if (video.id === props.form.workShop.config.video_id) {
          props.form.selectedVideo = video;
        }
      }
    }
);

const emit = defineEmits(['save']);

const file = ref(null);
const fileInput = ref(null);
const videoUploadModal = ref(false)
const openFilePicker = () => {
  videoUploadModal.value = !videoUploadModal.value;
};

const handleFileChange = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    file.value = selectedFile;
    props.form.filePreview = selectedFile;
  }
};

const uploadComplete = (event) => {
  props.form.workShop.videos.push(event);
};


</script>

<template>
  <b-container fluid class="tab-form">
    <b-form>
      <b-row>
        <b-col cols="3" class="sidebar">
          <p class="cloud-info m-0">Cloud (тільки відео)</p>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="accordion-header">
                <div class="w-100 text-start">Завантажені відео ({{ uploadVideoCount }})</div>
                <b-button variant="danger" class="red-plus-button add-button" @click="openFilePicker">
                  <i class="bx bx-plus"></i>
                </b-button>
                <VideoUploadModal @uploadComplete="uploadComplete" v-model="videoUploadModal" :parent-id="form.workShop.id"
                                  :route-args="['workshops.upload-video']"/>
                <!--                <FileUploadModal v-model="videoUploadModal" :route-args="['test_add_video', { itemId: 1 }]" />-->
                <input ref="fileInput" type="file" style="display: none" @change="handleFileChange"/>
                <i class="bx bxs-down-arrow ms-3" role="tab" v-b-toggle.accordion-1></i>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <template v-for="video in props.form.workShop.videos" :key="video.id">
                    <div class="video-item" v-if="video.type === 'TYPE_UPLOAD'">
                      <b-form-radio v-model="props.form.selectedVideo" :value="video"
                                    name="selected-video"></b-form-radio>
                      <b-img :src="video.preview" title="title" class="ms-2" alt="title"></b-img>
                      <div class="video-item-description">
                        <div class="video-item-title">{{ video.name }}
                        </div>
                        <div class="video-item-date">Завантажено:
                          <span>{{ video.updated_at_date }} {{ video.updated_at_time }}</span></div>
                        <div class="video-item-duration">{{ video.length }}</div>
                      </div>
                    </div>
                  </template>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="accordion-header" role="tab" v-b-toggle.accordion-2>
                <div class="w-100 text-start">Записи вебінарів ({{ recordVideoCount }})</div>
                <i class="bx bxs-down-arrow ms-3"></i>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <template v-for="video in props.form.workShop.videos" :key="video.id">
                    <div class="video-item" v-if="video.type === 'TYPE_WEBINAR_RECORD'">
                      <b-form-radio v-model="props.form.selectedVideo" :value="video"
                                    name="selected-video"></b-form-radio>
                      <b-img :src="video.preview" title="title" class="ms-2" alt="title"></b-img>
                      <div class="video-item-description">
                        <div class="video-item-title">{{ video.name }}
                        </div>
                        <div class="video-item-date">Завантажено:
                          <span>{{ video.updated_at_date }} {{ video.updated_at_time }}</span></div>
                        <div class="video-item-duration">{{ video.length }}</div>
                      </div>
                    </div>
                  </template>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col cols="9">
          <div v-if="props.form.selectedVideo" class="d-flex flex-column align-items-center">
            <!--            <b-img :src="video" class="video-img"></b-img>-->
            <!--            <video src="{{video.file}}" class="video-img"></video>-->
            <video class="video-img" controls :src="props.form.selectedVideo.file"></video>
            <div class="d-flex video-info my-3 gap-2 align-items-center">
              <b-form-group label="Назва" class="video-title">
                <b-form-input v-model="props.form.selectedVideo.name"></b-form-input>
              </b-form-group>
              <div class="video-date d-flex">
                <i class="ph-calendar-check"></i>
                {{ props.form.selectedVideo.updated_at_date }}
              </div>
              <div class="video-time">{{ props.form.selectedVideo.updated_at_time }}</div>
              <div class="video-duration d-flex">
                <i class="ph-clock"></i>
                {{ props.form.selectedVideo.length }}
              </div>
              <div class="video-size d-flex">
                <i class="bx bx-tachometer"></i>
                {{ props.form.selectedVideo.size }}
              </div>
              <b-button variant="primary" size="md" pill class="ms-2" @click="onSubmit">
                Зберегти
              </b-button>
            </div>
          </div>
          <div v-else class="d-flex flex-column align-items-center">
            <b-img :src="dog" class="no-video-img  my-3"></b-img>
            <p class="no-video-title">Тут поки що нічого немає</p>
            <p class="no-video-description">Оберіть, або завантажте відео з панелі матеріалів, зліва</p>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.grand-modal-title {
  color: #da7f14;
  display: flex;
  flex-direction: row;

  i {
    font-size: xxx-large;
  }

  h4 {
    color: #da7f14;
  }
}

.grand-modal-desc {
  margin-left: 50px;
  color: dimgray;
}

.grand-modal-accept {
  background-color: var(--green, #1DB954);
  border: none;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1DB954);
  opacity: 0.8;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1DB954);
  opacity: 0.8;
}

.module-table__col--email {
  display: flex;
  width: 30%;
}

.module-table__col--name {
  width: 15%;
}

.module-table__col--phone {
  width: 10%;
}

.module-table__col--payment {
  width: 10%;

  i {
    color: green;
  }
}

.module-table__col--actions {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  //i {
  //  margin: 0 10px;
  //  font-size: large;
  //  color: #C2C2C2;
  //}
}

.sidebar {
  border-right: 1px solid var(--stroke, #EBEBEB);
  padding: 0;
  margin-left: -19px;
  margin-top: -19px;
}

.cloud-info {
  color: var(--dark, #1E1E1E);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-transform: uppercase;
  display: flex;
  padding: 32px 0 32px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--light_bg_grey, #F8FAF8);
}

.accordion-header {
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--dark, #1E1E1E);
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.26px;

  .add-button {
    width: 30px;
    height: 30px;
  }
}

.video-item {
  display: flex;
  margin-bottom: 20px;

  .video-item-description {
    padding-left: 14px;

    .video-item-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      align-self: stretch;
      overflow: hidden;
      color: var(--dark, #1E1E1E);
      text-overflow: ellipsis;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 12px */
      margin-bottom: 8px;
    }

    .video-item-date {
      color: var(--dark, #1E1E1E);
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 100%; /* 10px */
      margin-bottom: 8px;

      span {
        font-weight: 400;
      }
    }

    .video-item-duration {
      color: var(--dark, #1E1E1E);
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 100%; /* 10px */
    }
  }

  img {
    height: 65px;
    border-radius: 4px;
  }
}

.video-item:last-child {
  margin-bottom: 0;
}

.no-video-img {
  width: 300px
}

.no-video-title {
  color: var(--dark, #1E1E1E);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 26px */
}

.no-video-description {
  color: var(--dark, #1E1E1E);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  opacity: 0.4;
}

.video-img, .video-info {
  width: 75%;
}

.video-date, .video-time, .video-duration, .video-size {
  color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 00%);
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%;
  white-space: nowrap;
  display: flex;
  gap: 6px;
  align-items: center;
  i {
    color: black;
    font-size: 20px;
  }
}

.video-title {
  width: -webkit-fill-available;
}
</style>
