<script setup>
import { Head, router, usePage } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref } from "vue";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import TabFormSessions from "@/Pages/Mentoring/Partials/TabFormSessions.vue";
import TabMentoringPrograms from "@/Pages/Mentoring/Partials/TabMentoringPrograms.vue";
import CalendarComponent from "@/Components/Schedule/CalendarComponent.vue";
import InputModal from "@/Components/modals/InputModal.vue";
import WeekSchedule from "@/Components/Schedule/WeekSchedule.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    user: Object,
    mentoring: Object,
    mentorprograms: Object,
    weekSchedule: Object,
    specificDatesSchedules: Object,
    sessions: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const accountType = pageData?.props?.auth?.user?.current_account_type;
const isReadOnly = hasReadOnlyAccess(userRoles);

const page = {
    title: "Менторство",
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route("dashboard"),
        },
        {
            text: "Менторство",
            active: true,
        },
    ],
};

const tabIndex = ref(0);

const createLinks = [
    { id: "schedule", label: "Задати графік менторських сесій", link: "#" },
    ...(!isReadOnly
        ? [
              {
                  id: "createMentoringProgram",
                  label: "Створити менторську програму",
                  link: "#",
                  tooltip: false,
                  tooltipText: "Менторська програма",
              },
          ]
        : []),
];

const hash = computed(() => {
    if (window.location.hash === "#program") {
        return 1;
    }
    return 0;
});

const scheduleCanvas = ref(null);

const bOffCanvasShow = ref(false);

const handleDropdownButtonsClick = (button) => {
    if (button) {
        if (button === "schedule") bOffCanvasShow.value = !bOffCanvasShow.value;
        if (button === "createMentoringProgram") {
            router.visit(route("mentoring.program.create"), {
                method: "get",
                preserveState: true,
                preserveScroll: true,
                forceFormData: true,
            });
        }
    }
};

const scheduleActive = ref(true);
const handleChangeType = (type) => {
    if (type === "schedule") {
        scheduleActive.value = true;
    }
    if (type === "calendar") {
        scheduleActive.value = false;
    }
};

const scheduleClass = computed(() => {
    if (!scheduleActive.value)
        return "schedule-сanvas schedule-canvas--calendar";
    return "schedule-сanvas";
});

const inputModal = ref(false);
</script>

<template>
    <div>
        <Head title="Менторство" />
        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                    <DropdownButtons
                        :buttons="createLinks"
                        @on-click="handleDropdownButtonsClick"
                    >
                        <template v-slot:toggle-button>
                            <b-button pill size="lg" class="outline-dark">
                                {{ t("btn-create") }} <i class="ph-plus"></i>
                            </b-button>
                        </template>
                    </DropdownButtons>
                    <InputModal
                        v-model="inputModal"
                        modal-title="Назва програми"
                        input-label="Назва"
                        button-text="Створити"
                        @send-input="(str) => console.log(str)"
                    />
                </div>
            </template>

            <template #default>
                <b-tabs v-model="hash" nav-class="page-tabs">
                    <b-tab title="Індивідуальні сесії" active>
                        <TabFormSessions
                            :sessions="sessions"
                            @set-schedule="bOffCanvasShow = !bOffCanvasShow"
                        />
                    </b-tab>
                    <b-tab title="Менторські програми">
                        <TabMentoringPrograms
                            :mentorprograms="mentorprograms"
                        />
                    </b-tab>
                    <template #tabs-end>
                        <div class="tabs-end"></div>
                    </template>
                </b-tabs>
                <b-offcanvas
                    v-model="bOffCanvasShow"
                    :class="scheduleClass"
                    ref="scheduleCanvas"
                    headerClass="schedule-сanvas__title"
                    headerCloseClass="schedule-сanvas__close-btn"
                    placement="end"
                >
                    <template #title> Графік менторських сесій </template>
                    <div class="schedule-toggle">
                        <div
                            class="schedule-toggle__item"
                            :class="{
                                'schedule-toggle__item--active': scheduleActive,
                            }"
                            @click="handleChangeType('schedule')"
                        >
                            Графік
                        </div>
                        <div
                            class="schedule-toggle__item"
                            :class="{
                                'schedule-toggle__item--active':
                                    !scheduleActive,
                            }"
                            @click="handleChangeType('calendar')"
                        >
                            Календар
                        </div>
                    </div>
                    <div v-if="scheduleActive">
                        <!--            {{ weekSchedule }}-->
                        <WeekSchedule v-model="props.weekSchedule" />
                        <!--            <WeekSchedule v-model="WeekScheduleMock"/>-->
                    </div>
                    <div class="calendar-wrapper" v-else>
                        <CalendarComponent
                            :specificDatesSchedules="specificDatesSchedules"
                            button-text="Розклад"
                            :account-type="accountType"
                            @custom-button-click="() => router.get(`/schedule`)"
                        />
                    </div>
                </b-offcanvas>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss">
.fc .fc-scroller-liquid-absolute {
    position: relative !important;
}

//.fc-daygrid-day .fc-daygrid-day-frame {
//  height: 100px;
//}

.module-content {
    min-height: calc(100vh - 120px);
}

.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}

.schedule-сanvas {
    width: 30% !important;
    transition: width 0.5s ease-in-out;

    .schedule-сanvas__title {
        color: var(--dark, #1e1e1e);
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }

    .schedule-сanvas__close-btn {
        font-size: 16px;
    }
}

.schedule-canvas--calendar {
    width: 60% !important;
    transition: width 0.5s ease-in-out;
}

.schedule-toggle {
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 20px;
    border-radius: 7px;
    border: 1px solid var(--green, #1db954);
    background: rgba(29, 185, 84, 0.05);
    width: fit-content;

    .schedule-toggle__item {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        cursor: pointer;
        color: var(--dark, #1e1e1e);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
    }

    .schedule-toggle__item--active {
        background: var(--green, #1db954);
        color: var(--white, #fff);
    }
}
</style>
