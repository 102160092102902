<script setup>
import {Head, Link, useForm} from '@inertiajs/vue3';
import LoginLayout from "@/Layouts/LoginLayout.vue";
import InputGroup from "@/Components/InputGroup.vue";
import LoginFormWrapper from "@/Pages/Auth/Partials/LoginFormWrapper.vue";
import {ref} from "vue";

defineOptions({layout: LoginLayout})

defineProps({
  canResetPassword: {
    type: Boolean,
  },
  status: {
    type: String,
  },
  isTenantAccount: null
});

const showSuccessMessage = ref(false)

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const onSubmit = () => {
  form.post(route('login'), {
    onFinish: () => {
      form.reset('password')

      showSuccessMessage.value = true
    },
  });
};

const onReset = () => {
  form.reset();
};

</script>

<template>
  <LoginFormWrapper>
    <template #left>
        <div class="d-flex flex-column align-self-stretch justify-content-center align-items-center">
      <Head title="Вхід"/>

      <div class="form-login-header text-center">
        <h1 class="text-md login-title">Вхід</h1>
        <div class="login-subtitle">Вітаємо на платформі! Готові до нових звершень? Увійдіть і починаємо!</div>
      </div>

      <div v-if="status" class="mt-4 font-medium text-sm text-green-600">
        {{ status }}
      </div>

      <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show">-->
      <b-form @submit.prevent="onSubmit" @reset="onReset" class="login-form">

        <div class="my-5">
          <InputGroup
              v-model="form.email"
              label="Email"
              placeholder="Введіть email"
              :error-message="form.errors.email"
              type="email"
              required
              autofocus
              autocomplete="username"
              :is-error="!!form.errors.email"
          />

          <InputGroup
              v-model="form.password"
              label="Пароль"
              placeholder="Введіть пароль"
              :error-message="form.errors.password"
              type="password"
              required
              name="пароль"
              autofocus
              autocomplete="username"
          />
          <div class="w-100 text-end">
            <Link :href="route('password.request')" class="text-gray-300">Забули пароль?</Link>
          </div>

          <b-form-group id="input-group-3" class="mt-4">
            <b-form-checkbox v-model="form.remember" value="true" unchecked-value="false">
              Запам'ятати мене
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-end mt-4">
          <b-button :disabled="form.processing" variant="primary" size="lg" pill @click="onSubmit">
            Вхід
          </b-button>

          <div class="my-4" v-if="!isTenantAccount">
            Ще немає акаунта?
            <Link v-if="canResetPassword" :href="'/register'" class="text-sm">
              Створити
            </Link>
          </div>
        </div>
      </b-form>
    </div>
    </template>
    <template #right>
        <div class="login-right-overlay"></div>
    </template>
  </LoginFormWrapper>
</template>

<style lang="scss">

.login-form {
  width: 440px;
  max-width: calc(100% - 30px);
}

.login-right-overlay {
    width: 100%;
    height: 100%;
    background: url("../../../../images/auth/login-right-bg-2.jpg") no-repeat 50% 50%;
    background-size: cover;
}

</style>
