<script setup>

import MentorProgramModuleForm from "@/Components/form/MentorProgramModuleForm.vue";
import {useAPIForm} from "@/composables/useApiForm.js";
import useRequestProgress from '@/composables/useRequestProgress.js';
import {toast} from "vue3-toastify";
import {useI18n} from "vue-i18n";
import {usePage} from "@inertiajs/vue3";
import {useRoleChecker} from "@/composables/useRoleChecker.js";

const {
  setIsLoading
} = useRequestProgress()

const {t} = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const props = defineProps({
  program: Object,
  modules: Array
})

const removeModuleById = (id) => {
  deleteModule(id)
}

const form = useAPIForm({
  modules: props.modules
});

const module = useAPIForm({});

const addModule = () => {
  setIsLoading(true);
  module.get(route('mentoring.program.module.create', { programId: props.program.id }), {
    onSuccess: onModuleCreated,
    onFinish: () => setIsLoading(false)
  });
}

const onModuleCreated = (response) => {
  response.program_id = props.program.id;
  response.lessons = [];
  form.modules.push(response);
}

const saveModule = () => {
  setIsLoading(true);
  form.post(route('mentoring.program.module.store'), {
    onSuccess: onModuleStored,
    onError: onModuleStoredError,
    onFinish: () => setIsLoading(false)
  });
}

const onModuleStored = (response) => {
    toast.success(t('request-success'), {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_CENTER,
        multiple: false
    });
}
const onModuleStoredError = (response) => {
    toast.error(t('request-error'), {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_CENTER,
        multiple: false
    });
}

const deleteModule = (id) => {
  setIsLoading(true);
  form.post(route('mentoring.program.module.delete', { id: id }), {
    onSuccess: onModuleDeleted,
    onFinish: () => setIsLoading(false)
  });
}

const onModuleDeleted = (response) => {
  if(response) {
    const index = form.modules.findIndex((el) => el.id === response);
    if (index >= 0) {
      form.modules.splice(index, 1)
    }
  }
}


</script>

<template>
  <b-container fluid class="tab-form">
    <b-form>
      <MentorProgramModuleForm
          v-for="( module, index ) in form.modules"
          :key="module.id"
          :form-fields="module"
          :index="index"
          @remove-by-id="removeModuleById"
      />
      <div class="w-50 mt-2 d-flex gap-2">
        <b-button class="outline-dark" pill size="lg" :disabled="isReadOnly" @click="addModule">
          Додати модуль
          <i class="ph-plus"></i>
        </b-button>
        <b-button variant="primary" size="lg" pill class="ms-2" :disabled="form.processing || isReadOnly" @click="saveModule">
          Зберегти
        </b-button>
      </div>
    </b-form>
  </b-container>

</template>

<style lang="scss" scoped>
  .program__text-area__counter {
    color: var(--dark, #1E1E1E);
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    opacity: 0.5;
  }
  .program__input__text {
    color: var(--dark, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }
</style>
