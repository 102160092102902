<script setup>
  import {computed} from "vue";
  import addFile from "../../../images/svg/lesson-icons/add-file.svg";
  import addVideo from "../../../images/svg/lesson-icons/add-video.svg";
  import testing from "../../../images/svg/lesson-icons/testing.svg";
  import matching from "../../../images/svg/lesson-icons/matching.svg";
  import trueFalse from "../../../images/svg/lesson-icons/true-false.svg";
  import {
    LESSON_TASK_FILE,
    LESSON_TASK_VIDEO,
    LESSON_TASK_TEST,
    LESSON_TASK_MATCHING,
    LESSON_TASK_TRUE_FALSE
  } from '../../Pages/Courses/Partials/lessonTypes.js';

  const props = defineProps({
    modelValue: Boolean,
    title: String
  });

  const emit = defineEmits([
    'update:modelValue',
    'add-lesson'
  ]);

  const isVisible = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })

  const addLesson = (type) => {
    emit('add-lesson', type);
    isVisible.value =! isVisible.value
  }

</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>{{ title }}</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <div class="d-flex gap-3">
        <div
          class="lesson-button lesson-button--red"
          @click="() => addLesson(LESSON_TASK_FILE)"
        >
          <img :src="addFile" title="Додати файл" alt="Додати файл" />
          Додати файл
        </div>
        <div
          class="lesson-button lesson-button--blue"
          @click="() => addLesson(LESSON_TASK_VIDEO)"
        >
          <img :src="addVideo" title="Додати відео" alt="Додати відео" />
          Додати відео
        </div>
      </div>
      <div class="d-flex gap-3 mt-3 coming_soon">
        <div
          class="lesson-button lesson-button--green"
          @click="() => addLesson(LESSON_TASK_TEST)"
        >
          <img :src="testing" title="Тестування" alt="Тестування" />
          Тестування
        </div>
        <div
          class="lesson-button lesson-button--dark-blue"
          @click="() => addLesson(LESSON_TASK_MATCHING)"
        >
          <img :src="matching" title="Matching" alt="Matching" />
          Matching
        </div>
        <div
          class="lesson-button lesson-button--yellow"
          @click="() => addLesson(LESSON_TASK_TRUE_FALSE)"
        >
          <img :src="trueFalse" title="True \ False" alt="True \ False" />
          True \ False
        </div>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
  .coming_soon {
    opacity: 0.25;
    position: relative;
    &:before {
      content: 'В розробці';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #1DB954;
      font-size: 30px;
      text-shadow: 1px 1px black;
    }
  }
  .lesson-button {
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid rgba(167, 20, 218, 0.10);
    background: var(--white, #FFF);
    cursor: pointer;
    max-width: 140px;

    &.lesson-button--red {
      color: #F24822;
      &:hover {
        border: 1px solid var(--purple, #F24822);
        background: rgba(142, 0, 254, 0.05);
      }
    }
    &.lesson-button--blue {
      color: #31A6D9;
      &:hover {
        border: 1px solid #31A6D9;
        background: rgba(142, 0, 254, 0.05);
      }
    }
    &.lesson-button--dark-blue {
      color: #2B7BC4;
      &:hover {
        border: 1px solid #2B7BC4;
        background: rgba(43, 123, 196, 0.05);
      }
    }
    &.lesson-button--green {
      color: #1DB954;
      &:hover {
        border: 1px solid var(--green, #1DB954);
        background: rgba(162, 241, 99, 0.05);
      }
    }
    &.lesson-button--yellow {
      color: #F1C963;
      &:hover {
        border: 1px solid #F1C963;
        background: rgba(241, 201, 99, 0.05);
      }
    }
  }
</style>
