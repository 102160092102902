<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import InputGroup from "@/Components/InputGroup.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import RegisterFormWrapper from "@/Pages/Auth/Partials/RegisterFormWrapper.vue";
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, minLength, required } from "@vuelidate/validators";
import { FormWizard, TabContent, WizardStep } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

defineOptions({ layout: LoginLayout });

const props = defineProps({
    tenantId: null,
    studentEmail: null,
});

const form = useForm({
    name: "",
    email: props.studentEmail,
    password: "",
    tenantId: props.tenantId,
    phone: "",
    skill: "",
    comment: "",
});

const containsUpperCase = (value) => /[A-ZА-ЯЁ]/.test(value);
const containsLowerCase = (value) => /[a-zа-яё]/.test(value);

const containsSpecialCharacters = (value) =>
    /[!@#$%^&*(),.?":{}|<>]/.test(value);

const containsNonCyrillic = (value) => !/[а-яА-ЯЁё]/u.test(value);

const containsDigit = (value) => /\d/.test(value);

const lettersValidator = helpers.withMessage(
    "Пароль повинен містити великі та маленькі літери",
    (value) => {
        if (!value) return true;
        return (
            containsUpperCase(value) &&
            containsLowerCase(value) &&
            containsNonCyrillic(value)
        );
    }
);

const digitValidator = helpers.withMessage(
    "Пароль повинен містити хоча б одну цифру",
    (value) => {
        if (!value) return true;
        return containsDigit(value);
    }
);

const hasSpecialCharacters = helpers.withMessage(
    "Поле повинно містити хоча б один спеціальний символ.",
    (value) => {
        if (!value) return true;
        return containsSpecialCharacters(value);
    }
);

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    password: {
        required: helpers.withMessage(
            "Це поле є обов'язковим",
            (value) => !!value
        ),
        minLength: helpers.withMessage("Не менше 6 символів", minLength(6)),
        lettersValidator,
        digitValidator,
        hasSpecialCharacters,
    },
    phone: {
        required: helpers.withMessage(
            "Це поле є обов'язковим",
            (value) => !!value
        ),
    },
    comment: {},
};

const formWizard = ref(null);
const currentStep = ref(0);

const v$ = useVuelidate(rules, form, { $lazy: true });

const onSubmit = () => {
    v$.value.$touch();
    if (!v$.value.$invalid && !notValidPhone.value) {
        form.post(route("register-student"), {
            onFinish: () => form.reset("password"),
        });
    }
};

const onReset = () => {
    form.reset();
};

const agreeRules = ref(false);

const notValidPhone = ref(false);
const phoneValidate = (data) => {
    v$.value["phone"].$touch();
    if (!v$.value["phone"].$invalid)
        notValidPhone.value = !data.isValid ? !data.isValid : false;
    else notValidPhone.value = false;
};
</script>

<template>
    <RegisterFormWrapper>
        <template #left>
            <div
            class="d-flex flex-column align-self-stretch justify-content-center align-items-center"
        >
            <Head title="Реєстрація на платформі" />

            <div class="form-login-header text-center">
                <h1 class="text-md login-title">Зареєструватись</h1>
                <div class="login-subtitle w-75 mx-auto">
                    Курс, вебінар, менторство — все на одній платформі
                </div>
            </div>
            <form class="login-form">
                <InputGroup
                    v-model="form.email"
                    label="Email"
                    placeholder="Введіть Email"
                    type="email"
                    required
                    readonly="readonly"
                    disabled="disabled"
                    autocomplete="username"
                />
                <InputGroup
                    v-model="form.name"
                    label="Ваше ім'я"
                    placeholder="Введіть ім'я"
                    :error-message="
                        form.errors.name || v$.name?.$errors[0]?.$message
                    "
                    type="text"
                    required
                    autocomplete="name"
                    :is-error="!!form.errors.name || v$.name.$error"
                    @blur="v$.name.$touch()"
                />
                <InputGroup
                    element="phone"
                    label="Телефон"
                    v-model="form.phone"
                    size="lg"
                    class="me-2 w-100"
                    :is-error="
                        !!form.errors.phone ||
                        v$.phone.$invalid ||
                        notValidPhone
                    "
                    :error-message="
                        form.errors.phone || notValidPhone
                            ? 'Перевірте формат введення!'
                            : v$.phone?.$errors[0]?.$message
                    "
                    @blur="phoneValidate"
                />
                <!--        <InputGroup-->
                <!--            v-model="form.skill"-->
                <!--            label="Ваша професія"-->
                <!--            placeholder="Введіть професі."-->
                <!--            :error-message="form.errors.skill || v$.skill?.$errors[0]?.$message"-->
                <!--            type="text"-->
                <!--            required-->
                <!--            autofocus-->
                <!--            autocomplete="skill"-->
                <!--            :is-error="!!form.errors.skill || v$.skill.$error"-->
                <!--            @blur="v$.skill.$touch()"-->
                <!--        />-->
                <!--        <InputGroup-->
                <!--            v-model="form.comment"-->
                <!--            label="Додаткова ініформація"-->
                <!--            placeholder="Введіть додаткову інформацію"-->
                <!--            :error-message="form.errors.comment || v$.comment?.$errors[0]?.$message"-->
                <!--            type="text"-->
                <!--            required-->
                <!--            autofocus-->
                <!--            autocomplete="comment"-->
                <!--            :is-error="!!form.errors.comment || v$.comment.$error"-->
                <!--            @blur="v$.comment.$touch()"-->
                <!--        />-->

                <InputGroup
                    v-model="form.password"
                    label="Пароль"
                    placeholder="Введіть пароль"
                    :error-message="
                        form.errors.password ||
                        v$.password?.$errors.find(
                            (error) => error['$validator'] === 'required'
                        )?.$message
                    "
                    type="password"
                    required
                    :is-error="!!form.errors.password || v$.password.$error"
                    @blur="v$.password.$touch()"
                />
                <ul class="list-inline password-recomendations-list">
                    <li
                        class="list-inline-item"
                        :class="{
                            invalid: v$.password?.$errors.some(
                                (error) => error['$validator'] === 'minLength'
                            ),
                        }"
                    >
                        Не менше 8 символів
                    </li>
                    <li
                        class="list-inline-item"
                        :class="{
                            invalid: v$.password?.$errors.some(
                                (error) =>
                                    error['$validator'] === 'lettersValidator'
                            ),
                        }"
                    >
                        Маленькі та великі латинські літери
                    </li>
                    <li
                        class="list-inline-item"
                        :class="{
                            invalid: v$.password?.$errors.some(
                                (error) =>
                                    error['$validator'] === 'digitValidator'
                            ),
                        }"
                    >
                        Цифри
                    </li>
                    <li
                        class="list-inline-item"
                        :class="{
                            invalid: v$.password?.$errors.some(
                                (error) =>
                                    error['$validator'] ===
                                    'hasSpecialCharacters'
                            ),
                        }"
                    >
                        Спеціальний символ
                    </li>
                </ul>
                <div class="login-bottom-text mt-4 d-flex align-items-center">
                    <b-form-checkbox
                        v-model="agreeRules"
                        name="agreeRules"
                        size="lg"
                        :unchecked-value="false"
                        class="agree-rules__checkbox"
                    >
                    </b-form-checkbox>
                    <div style="text-align: left; margin-left: 10px">
                        Натискаючи на «Зареєструватись», ви погоджуєтесь з
                        нашими
                        <a
                            href="https://www.bestcleverslms.com/pravyla-vykorystannia/"
                            target="_blank"
                        >
                            Умовами використання</a
                        >,
                        <a
                            href="https://www.bestcleverslms.com/polityka-konfidentsiynosti/"
                            target="_blank"
                            >Політикою конфіденційності</a
                        >
                        та
                        <a
                            href="https://www.bestcleverslms.com/oferta/"
                            target="_blank"
                            >Публічним договором оферти</a
                        >
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <b-button
                        type="button"
                        size="md"
                        variant="primary"
                        pill
                        :disabled="!agreeRules || form.processing"
                        @click="onSubmit"
                    >
                    Зареєструватись
                    </b-button>
                </div>
            </form>
        </div>
       </template>
       <template #right>
        <div class="login-right-overlay"></div>
       </template>

    </RegisterFormWrapper>
</template>

<style lang="scss">
/* Змінюємо колір фону прогресбару для неактивних кроків */
.wizard-progress-with-circle {
    background-color: #f3f2ee;
}

/* Змінюємо колір фону кружечків (індикаторів) для неактивних кроків */
.wizard-progressbar-step:not(.wizard-step-active) .wizard-progressbar-circle {
    background-color: grey !important;
}

.wizard-step__error {
    .wizard-icon-circle {
        border: 2px solid #f24822 !important;
    }
}

.password-recomendations-list {
    margin-top: 10px;
    color: #1e1e1e;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;

    .list-inline-item {
        margin: 0 15px 0 0;
        padding: 0 0 0 10px;
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 5px;
            height: 5px;
            background-color: var(--green, #1db954);
            border-radius: 50%;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &.invalid {
            color: #f24822;

            &:before {
                background-color: var(--green, #f24822);
            }
        }
    }
}
</style>
