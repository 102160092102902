<script setup>

    const props = defineProps({
        status: String
    })

    const statuses = {
        ERROR: "bi bi-exclamation-circle-fill payment-status__icon payment-status__icon--red--inv",
        pending: "bi bi-three-dots payment-status__icon payment-status__icon--yellow",
        success: "bi bi-check-circle-fill payment-status__icon payment-status__icon--green",
        NEW: "bi bi-h-circle-fill payment-status__icon payment-status__icon--yellow--inv",
        canceled: "bi bi-x-circle-fill payment-status__icon payment-status__icon--red",
    }
</script>

<template>
    <div v-if="status" class="payment-status__bage">
        <i :class="statuses[status]"></i>{{$t(`billing.payment_status.${status}`)}}
    </div>
</template>

<style scoped lang="scss">
.payment-status__bage {
    display: flex;
    .payment-status__icon {
        font-size: 20px;
        margin-right: 8px;
    }
    .payment-status__icon--red--inv {
        color: #F24822;
        border-radius: 20px;
    }
    .payment-status__icon--red {
        color: var(--purple, #F24822);
    }
    .payment-status__icon--yellow {
        color: #FFF;
        background-color: #F1C963;
        border-radius: 20px;
    }
    .payment-status__icon--yellow--inv {
        color: #F1C963;
        background-color: #FFF;
        border-radius: 20px;
    }
    .payment-status__icon--green {
        color: var(--green, #1DB954);
    }
}

</style>
