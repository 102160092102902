<script setup>

import InputGroup from '@/Components/InputGroup.vue';
import {useForm} from '@inertiajs/vue3';
import {computed, ref, watch} from "vue";
import {email, helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const props = defineProps({
  register: Object,
  registerFields: Object,
  work_shop_id: null,
  isPaid: false,
  access: false
})

const form = useForm({
  regFields: props.registerFields,
  workShopId: props.work_shop_id,
  isPaid: props.isPaid,
  testData:'test'
});

const result = props.registerFields.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
}, {});

const formValidate = useForm({
    regFields: result,
    workShopId: props.work_shop_id,
    isPaid: props.isPaid,
    testData:'test'
});

watch(
    () => formValidate.regFields,
    (newRegFields) => {
        form.regFields.forEach((field) => {
            if (newRegFields.hasOwnProperty(field.name)) {
                field.value = newRegFields[field.name];
            }
        });
    },
    { deep: true }
);

const onSubmit = () => {
  if(props.access) {
        v$.value.regFields['pib'].$touch();
        if(!v$.value.$invalid) {
            form.post(route('register-to-work-shop_store'), {});
        }
  } else {
      form.regFields.forEach(field => {
          v$.value.regFields[field.name].$touch();
      })
      if (!v$.value.$invalid && !notValidPhone.value) {
          form.post(route('register-to-work-shop_store'), {});
      }
  }
};

const onReset = () => {
  form.reset();
  formValidate.reset();
};

const noCyrillic = helpers.withMessage(
    'Електронна адреса не повинна містити кириличні символи',
    (value) => !/[а-яА-ЯЁёЇїІіЄєҐґ]/.test(value)
);


const rules = computed(() => {
    const fieldRules = {}

    form.regFields.forEach(field => {
        fieldRules[field.name] = {}

        if (field['is_required']) {
            fieldRules[field.name].required = helpers.withMessage(`Поле ${field.label} є обов'язковим`, required)
        }

        switch(field.name) {
            case 'email':
                fieldRules[field.name].email = helpers.withMessage('Введіть дійсну електронну адресу', email),
                fieldRules[field.name].noCyrillic = noCyrillic
                break
        }
    })

    return { regFields: fieldRules }
})

const v$ = useVuelidate(rules.value, formValidate, { $lazy: true })

const fieldValidate = (data) => {
    v$.value.regFields[data.name].$touch();
}

const notValidPhone = ref(false);
const isError = (field) => {
    return  field.name === 'phone' ?
        !!formValidate.errors[field.name] || v$.value.regFields[field.name].$invalid || notValidPhone.value :
        !!formValidate.errors[field.name] || v$.value.regFields[field.name].$invalid
}

const errorMessage = (field) => {
    return field.name === 'phone' ?
        !!formValidate.errors[field.name] || notValidPhone.value ? 'Перевірте формат введення!' : v$.value.regFields[field.name]?.$errors[0]?.$message :
        !!formValidate.errors[field.name] || v$.value.regFields[field.name]?.$errors[0]?.$message;
}

const phoneValidate = (data) => {
    v$.value.regFields['phone'].$touch();
    if (!v$.value.regFields['phone'].$invalid) notValidPhone.value = !data.isValid ? !data.isValid : false
    else notValidPhone.value = false
}

</script>

<template>
  <div class="form-login-header text-center">
    <h1 class="text-md login-title">
      {{ access ? "Вхід" : "Реєстрація" }}
    </h1>
  </div>
  <b-form @submit.prevent="onSubmit" @reset="onReset" class="login-form">
    <div class="my-5">
        <template v-for="field in form.regFields" :key="field.id" v-if="!access">
            <InputGroup
                v-if="field.name === 'phone'"
                v-model="formValidate.regFields[field.name]"
                :element="field.name ==='phone' ? 'phone' : ''"
                :label="`${field.label}${field['is_required'] ? '*' : ''}`"
                :placeholder="field.label"
                :error-message="errorMessage(field)"
                type="text"
                :autocomplete="field.name"
                :is-error="isError(field)"
                @blur="phoneValidate"
            />
            <InputGroup
                v-else
                v-model="formValidate.regFields[field.name]"
                :label="`${field.label}${field['is_required'] ? '*' : ''}`"
                :placeholder="field.label"
                :error-message="errorMessage(field)"
                type="text"
                :autocomplete="field.name"
                :is-error="isError(field)"
                @blur="fieldValidate(field)"
            />
        </template>
        <template v-for="field in form.regFields" :key="field.id + 'access'" v-else>
            <InputGroup
                v-if="field.name === 'pib'"
                v-model="formValidate.regFields[field.name]"
                :element="field.name ==='phone' ? 'phone' : ''"
                :label="`${field.label}${field['is_required'] ? '*' : ''}`"
                :placeholder="field.label"
                :error-message="errorMessage(field)"
                type="text"
                :autocomplete="field.name"
                :is-error="isError(field)"
                @blur="fieldValidate(field)"
            />
        </template>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
      <b-button type="submit" variant="primary" size="lg" pill :disabled="form.processing">
          {{ access ? "Увійти" : "Зареєструватись" }}
      </b-button>
    </div>
  </b-form>
</template>

<style lang="scss">

</style>
