<script setup>
import { onMounted, ref, watch, computed } from "vue";
import WebinarEvent from "@/Components/WebinarEvents/WebinarEvent.vue";
import WebinarEventEditModal from "@/Components/WebinarEvents/WebinarEventEditModal.vue";
import WebinarEventCreateModal from "@/Components/WebinarEvents/WebinarEventCreateModal.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

import axios from "axios";
import { useI18n } from "vue-i18n";
import useRequestProgress from "@/composables/useRequestProgress";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker";

const props = defineProps({
    autowebinar: Object,
    workShopEventActionStatuses: Object,
    workShopEventEnum: Object,
    buttons: Array,
    roles: Array,
    authLink: String,
    isOldValue: Boolean,
});
const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const getEvents = async (page, perPage) => {
    return await axios.get(
        route("workshops.events", {
            id: props.autowebinar.id,
            _query: {
                page: page,
                perPage: perPage,
            },
        })
    );
};

const emit = defineEmits(["save"]);

const { t } = useI18n();

const actionStatuses = computed(() => {
    return props.workShopEventActionStatuses &&
        props.workShopEventActionStatuses.length > 0
        ? props.workShopEventActionStatuses.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const actionStatusesForChangeLayout = computed(() => {
    return props.workShopEventActionStatuses &&
        props.workShopEventActionStatuses.length > 0
        ? props.workShopEventActionStatuses.map((item) => {
              return {
                  value: item,
                  text: t(item),
                  disabled: item === "PREVIEW",
              };
          })
        : [];
});

const createEventStatuses = computed(() => {
    if (props.workShopEventEnum && props.workShopEventEnum.length > 0) {
        const statuses = [];
        props.workShopEventEnum.forEach((item) => {
            switch (item) {
                case "MESSAGE":
                    statuses.push({ value: item, text: t(item) });
                    break;
                case "BUTTON_SHOW":
                    statuses.push({ value: item, text: t(item) });
                    break;
                case "BUTTON_HIDE":
                    statuses.push({ value: item, text: t(item) });
                    break;
                case "CHANGE_PRESENTATION":
                    statuses.push({ value: item, text: t(item) });
                    break;
                case "CHANGE_SLIDE_PRESENTATION":
                    statuses.push({ value: item, text: t(item) });
                    break;
                case "CHANGE_ACTION":
                    statuses.push({ value: item, text: t(item) });
                    break;
                default:
                    break;
            }
        });

        return statuses;
    } else {
        return [];
    }
});

const eventTypes = computed(() => {
    if (props.workShopEventEnum && props.workShopEventEnum.length > 0) {
        const types = {};
        props.workShopEventEnum.map((item) => {
            types[item] = { value: item, text: t(item) };
        });
        return types;
    } else {
        return {};
    }
});

const buttonStatuses = computed(() => {
    if (props.buttons && props.buttons.length > 0) {
        return props.buttons.map((item) => {
            return { value: item.id, text: item.title };
        });
    } else {
        return [];
    }
});

const roleTypes = computed(() => {
    const rolesObj = {};
    props.roles.forEach((role) => {
        rolesObj[role] = role;
    });
    return rolesObj;
});

const webinarEventEditModal = ref(false);

const webinarEventCreateModal = ref(false);

const removeEventModal = ref(false);

const currentEditEvent = ref(null);

const openWebinarEventEditModal = (event) => {
    setIsLoading(true);
    webinarEventEditModal.value = true;
    currentEditEvent.value = JSON.parse(JSON.stringify(event));
};

const openWebinarEventCreateModal = () => {
    webinarEventCreateModal.value = true;
};

const openRemoveEventModal = (event) => {
    removeEventModal.value = true;
    currentEditEvent.value = JSON.parse(JSON.stringify(event));
};

const events = ref([]);

const setEvents = (newValue) => {
    events.value = newValue;
};

const presentationList = ref([]);

const setPresentationList = (newValue) => {
    presentationList.value = newValue;
};

const replacementEvent = (updatedEvent) => {
    const index = events.value.findIndex((i) => i.id === updatedEvent.id);
    if (index !== -1) {
        events.value[index] = updatedEvent;
    }
};

const currentPage = ref(1);

const perPage = ref(10);

const totalItems = ref(null);

const setTotalItems = (value) => {
    totalItems.value = value;
};

const startEventTime = ref(null);

const setStartEventTime = (value) => {
    startEventTime.value = value;
};

const penultimateEventTime = ref(null);

const setPenultimateEventTime = (value) => {
    penultimateEventTime.value = value;
};

const finishEventTime = ref(null);

const setFinishEventTime = (value) => {
    finishEventTime.value = value;
};

const { setIsLoading } = useRequestProgress();
// setIsLoading(true);

const saveEventsHandler = async () => {
    setIsLoading(true);

    try {
        const res = await axios.get(
            route("workshops.events-save", {
                id: props.autowebinar.id,
            })
        );

        if (res.data.success) {
            toast.success("Збереженно", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    } catch (error) {
        toast.error("Щось сталося...", {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
        });
    } finally {
        setIsLoading(false);
    }
};

const saveAndWatchPreviewEventsHandler = async () => {
    setIsLoading(true);
    try {
        const res = await axios.get(
            route("workshops.events-save", {
                id: props.autowebinar.id,
                _query: {
                    preview: true,
                },
            })
        );
        if (res.data.success) {
            window.open(
                `${props.authLink}&dry_run=true&version_preview=true`,
                "_blank"
            ); // Correct redirection
        }
    } catch (error) {
        toast.error("Щось сталося...", {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
        });
    } finally {
        setIsLoading(false);
    }
};

const removeEventHandle = async () => {
    setIsLoading(true);

    const response = await axios.post(
        route("workshops.event-edit", {
            id: currentEditEvent.value.id,
            _query: {
                page: currentPage.value,
                perPage: perPage.value,
            },
        }),
        {
            changeData: {
                deleted: true,
            },
        }
    );

    replacementEvent(response.data.event);

    if (!!response.data?.penultimateEvent) {
        setPenultimateEventTime(response.data.penultimateEvent.created_at);
    }

    if (!!response.data?.editorWebinarEvent?.data) {
        setEvents(response.data.editorWebinarEvent.data);
    }
    if (!!response.data?.editorWebinarEvent?.total) {
        setTotalItems(response.data.editorWebinarEvent.total);
    }

    setIsLoading(false);
};

const watchPreviewEventsHandler = () => {
    window.open(`${props.authLink}&dry_run=true`, "_blank"); // Correct redirection
};

const loadAndSetEvents = async () => {
    try {
        setIsLoading(true);

        const res = await getEvents(currentPage.value, perPage.value);

        setEvents(res.data.editorWebinarEvent.data);

        setTotalItems(res.data.editorWebinarEvent.total);
        setStartEventTime(res.data.startEvent.created_at);
        setPenultimateEventTime(res.data.penultimateEvent.created_at);
        setFinishEventTime(res.data.finishEvent.created_at);
    } catch (error) {
        toast.error("Щось сталося...", {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
        });
    } finally {
        setIsLoading(false);
    }
};

onMounted(async () => {
    if (props.autowebinar.baseWorkShopId) {
        loadAndSetEvents();
    }
});

watch(
    () => webinarEventEditModal.value,
    (value) => {
        if (value === false && currentEditEvent.value !== null) {
            currentEditEvent.value = null;
        }
    }
);

watch(
    () => removeEventModal.value,
    (value) => {
        if (value === false && currentEditEvent.value !== null) {
            currentEditEvent.value = null;
        }
    }
);
watch(
    () => [
        props.autowebinar.baseWorkShopId,
        props.autowebinar.eventLogVersionId,
    ],
    async () => {
        if (props.autowebinar.baseWorkShopId) {
            loadAndSetEvents();
        }
    }
);

watch(
    () => currentPage.value,
    async (value) => {
        setIsLoading(true);

        const res = await getEvents(value, perPage.value);

        events.value = res.data.editorWebinarEvent.data;

        setIsLoading(false);
    }
);

defineExpose({
    saveAndWatchPreviewEventsHandler,
    watchPreviewEventsHandler,
});
</script>

<template>
    <b-container v-if="!isOldValue" fluid class="tab-form">
        <b-form>
            <b-row>
                <template v-if="events">
                    <div class="events">
                        <div class="events__wrapper">
                            <template v-for="event in events" :key="event.id">
                                <WebinarEvent
                                    :event="event"
                                    :startEventTime="startEventTime"
                                    :finishEventTime="finishEventTime"
                                    :actionStatuses="actionStatuses"
                                    :eventTypes="eventTypes"
                                    @setIsLoading="setIsLoading"
                                    @replacementEvent="replacementEvent"
                                    @setPenultimateEventTime="
                                        setPenultimateEventTime
                                    "
                                    @openWebinarEventEditModal="
                                        () => {
                                            openWebinarEventEditModal(event);
                                        }
                                    "
                                    @openRemoveEventModal="
                                        () => {
                                            openRemoveEventModal(event);
                                        }
                                    "
                                />
                            </template>
                        </div>

                        <div class="events__buttons">
                            <b-button
                                class="events__create-button"
                                @click="openWebinarEventCreateModal"
                                :disabled="isReadOnly"
                            >
                                <span> Створити </span>
                                <i class="bi bi-plus-circle"></i>
                            </b-button>
                            <b-button
                                class="events__save-button"
                                @click="saveEventsHandler"
                                :disabled="isReadOnly"
                            >
                                <span> Зберегти копію автовебінару </span>
                                <i class="bi bi-cloud-download"></i>
                            </b-button>
                        </div>
                    </div>
                    <b-pagination
                        class="mt-4"
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </template>
                <WebinarEventEditModal
                    v-if="webinarEventEditModal"
                    v-model="webinarEventEditModal"
                    :currentEvent="currentEditEvent"
                    :penultimateEventTime="penultimateEventTime"
                    :startEventTime="startEventTime"
                    :finishEventTime="finishEventTime"
                    :currentPage="currentPage"
                    :perPage="perPage"
                    :actionStatuses="actionStatuses"
                    :actionStatusesForChangeLayout="
                        actionStatusesForChangeLayout
                    "
                    :eventTypes="eventTypes"
                    :roleTypes="roleTypes"
                    :autowebinar="autowebinar"
                    :presentationList="presentationList"
                    @setPresentationList="setPresentationList"
                    @setEvents="setEvents"
                    @setIsLoading="setIsLoading"
                    @setStartEventTime="setStartEventTime"
                    @setPenultimateEventTime="setPenultimateEventTime"
                    @setFinishEventTime="setFinishEventTime"
                    @setTotalItems="setTotalItems"
                />
                <WebinarEventCreateModal
                    v-if="webinarEventCreateModal"
                    v-model="webinarEventCreateModal"
                    :createEventStatuses="createEventStatuses"
                    :eventTypes="eventTypes"
                    :currentPage="currentPage"
                    :startEventTime="startEventTime"
                    :finishEventTime="finishEventTime"
                    :buttonStatuses="buttonStatuses"
                    :autowebinar="autowebinar"
                    :presentationList="presentationList"
                    :actionStatusesForChangeLayout="
                        actionStatusesForChangeLayout
                    "
                    @setPresentationList="setPresentationList"
                    @setEvents="setEvents"
                    @setIsLoading="setIsLoading"
                    @setPenultimateEventTime="setPenultimateEventTime"
                    @setTotalItems="setTotalItems"
                />
                <ConfirmModal
                    v-if="removeEventModal"
                    v-model="removeEventModal"
                    title="Видалити івент"
                    decline-label="Скасувати"
                    accept-label="Так, видалити"
                    @accept-btn="removeEventHandle"
                >
                    <template v-slot:description> Видалити івент? </template>
                </ConfirmModal>
            </b-row>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.grand-modal-title {
    color: #da7f14;
    display: flex;
    flex-direction: row;

    i {
        font-size: xxx-large;
    }

    h4 {
        color: #da7f14;
    }
}

.grand-modal-desc {
    margin-left: 50px;
    color: dimgray;
}

.grand-modal-accept {
    background-color: var(--green, #1db954);
    border: none;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.module-table__col--email {
    display: flex;
    width: 30%;
}

.module-table__col--name {
    width: 15%;
}

.module-table__col--phone {
    width: 10%;
}

.module-table__col--payment {
    width: 10%;

    i {
        color: green;
    }
}

.module-table__col--actions {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    //i {
    //    margin: 0 10px;
    //    font-size: large;
    //    color: #c2c2c2;
    //}
}

.sidebar {
    border-right: 1px solid var(--stroke, #ebebeb);
    padding: 0;
    margin-left: -19px;
    margin-top: -19px;
}

.cloud-info {
    color: var(--dark, #1e1e1e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
    display: flex;
    padding: 32px 0 32px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: var(--light_bg_grey, #f8faf8);
}

.accordion-header {
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--dark, #1e1e1e);
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 184.615% */
    letter-spacing: -0.26px;

    .add-button {
        width: 30px;
        height: 30px;
    }
}

.video-item {
    display: flex;
    margin-bottom: 20px;

    .video-item-description {
        padding-left: 14px;

        .video-item-title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;
            overflow: hidden;
            color: var(--dark, #1e1e1e);
            text-overflow: ellipsis;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 12px */
            margin-bottom: 8px;
        }

        .video-item-date {
            color: var(--dark, #1e1e1e);
            font-size: 10px;
            font-style: normal;
            font-weight: 200;
            line-height: 100%; /* 10px */
            margin-bottom: 8px;

            span {
                font-weight: 400;
            }
        }

        .video-item-duration {
            color: var(--dark, #1e1e1e);
            font-size: 10px;
            font-style: normal;
            font-weight: 200;
            line-height: 100%; /* 10px */
        }
    }

    img {
        height: 65px;
        border-radius: 4px;
    }
}

.video-item:last-child {
    margin-bottom: 0;
}

.no-video-img {
    width: 300px;
}

.no-video-title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 26px */
}

.no-video-description {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    opacity: 0.4;
}

.video-img,
.video-info {
    width: 75%;
}

.video-date,
.video-time,
.video-duration,
.video-size {
    color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 00%);
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    white-space: nowrap;
    display: flex;
    gap: 6px;
    align-items: center;
    i {
        color: black;
        font-size: 20px;
    }
}

.video-title {
    width: -webkit-fill-available;
}

.page-link {
    line-height: 1.15;
}
.pagination {
    justify-content: center !important;
}

.events {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
}
.events__wrapper {
    width: 55%;
}

.events__buttons {
    position: sticky !important;
    top: 84px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.events__buttons button {
    background-color: var(--primary, #1db954);
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    &:hover {
        background-color: var(--primary, #1db954);
    }
}
</style>
