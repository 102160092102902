<script setup>
import broadcast from "../../../../images/svg/lesson-icons/broadcast.svg";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import matching from "../../../../images/svg/lesson-icons/matching.svg";
import testing from "../../../../images/svg/lesson-icons/testing.svg";
import addFile from "../../../../images/svg/lesson-icons/add-file.svg";
import trueFalse from "../../../../images/svg/lesson-icons/true-false.svg";
import simpleLesson from "../../../../images/svg/lesson-icons/book.svg";
import { computed, ref } from "vue";
import CourseLessonTaskItem from "@/Pages/Courses/Partials/CourseLessonTaskItem.vue";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import { router } from "@inertiajs/vue3";
import 'vue3-toastify/dist/index.css';

const props = defineProps({
    lesson: Object,
    active: Boolean,
    editing: Object,
    disabled: {
        type: Boolean,
        default: false,
    },
    courseStatus: String
});

const emit = defineEmits([
    "delete-lesson",
    "edit-lesson",
    "lesson-up",
    "lesson-down",
]);

const {
    setIsLoading
} = useRequestProgress()

const deleteLesson = () => {
    console.log("modulelessonitem delete");
    emit("delete-lesson", props.lesson.id);
};

const editLesson = () => {
    emit("edit-lesson", props.lesson.id);
};

const editMode = computed(
    () =>
        props.editing &&
        props.editing.id &&
        props.editing.id === props.lesson.id
);

const isLessonsVisible = ref(false);

const icon = {
    LESSON_TYPE_WITH_TRANSLATION: broadcast,
    LESSON_TYPE_WITHOUT_TRANSLATION: simpleLesson,
    LESSON_TASK_VIDEO: addVideo,
    LESSON_TASK_TEST: testing,
    LESSON_TASK_FILE: addFile,
    LESSON_TASK_TRUE_FALSE: trueFalse,
    LESSON_TASK_MATCHING: matching,
};

const joinMentor = async () => {
    console.log("join mentor");
    setIsLoading(true)
    // const response = await axios.post(route('joinMentor', {id: props.lesson.id}));
    return axios
        .post(route("joinMentor", { id: props.lesson.id }))
        .then((response) => {
            console.log('joinMentor', response)
            if(response.data.success) {
                props.lesson.live_status = "running";
                return window.open(response.data.url, "_blank");
            } else {
                toast.error(response.data.message, {
                    autoClose: 3000,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        })
        .catch((error) => {
            console.error(error);
            return false;
        })
        .finally(() => setIsLoading(false));
    // if (response.data.success === true) {
    //   window.open(response.data.url, '_blank');
    // }
};

const { formatDate } = useDateFormatter();
const stopLesson = (id) => {
    router.visit(route("stopLesson", { id: id }), {
        method: "post",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
            props.lesson.live_status = "finished";
        },
    });
};

const lessonUp = () => {
    emit("lesson-up", props.lesson.id, "up", 1);
};
const lessonDown = () => {
    emit("lesson-down", props.lesson.id, "down", 1);
};

import { useDisableBroadcast } from "@/composables/useDateFormatter.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import {toast} from "vue3-toastify";
import useRequestProgress from "@/composables/useRequestProgress.js";
const { disableBroadcast } = useDisableBroadcast();

const stopLessonModal = ref(false);
</script>

<template>
    <div class="module-lesson" :class="{ 'module-lesson--editing': editMode }">
        <div class="module-lesson__header">
            <img :src="icon[lesson.type]" title="" alt="" />
            <div
                class="module-lesson__body"
                @click="isLessonsVisible = !isLessonsVisible"
            >
                <div>
                    <div class="module-lesson__label">Назва уроку</div>
                    <div class="module-lesson__title">
                        {{ lesson.title }}
                        <i v-if="isLessonsVisible" class="ph-caret-up-fill"></i>
                        <i v-else class="ph-caret-down-fill"></i>
                    </div>
                </div>
            </div>
            <div class="module-lesson__controls">
                <div
                    class="d-flex align-items-center justify-content-end w-100"
                >
                    <div
                        v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'"
                        class="d-flex justify-content-end"
                    >
                        <b-button
                            :id="'tooltip-stop_' + lesson.id"
                            v-if="lesson.live_status === 'running'"
                            variant="link"
                            class="btn-icon broadcast-icon"
                            :disabled="disabled"
                            @click="stopLessonModal = true"
                        >
                            <i class="ph-stop-fill"></i>
                            <!--Завершити урок-->
                        </b-button>
                        <b-tooltip
                            :target="'tooltip-stop_' + lesson.id"
                            custom-class="custom-tooltip"
                            offset="5"
                            :no-fade="true"
                            :delay="{ show: 50, hide: 50 }"
                        >
                            Зупинити трансляцію.
                        </b-tooltip>
                        <b-button
                            :id="'tooltip-join_' + lesson.id"
                            variant="link"
                            class="btn-icon broadcast-icon"
                            :disabled="disableBroadcast(lesson) || courseStatus === 'DRAFT'"
                            @click="joinMentor"
                        >
                            live
                            <!--                            <i class="ph-broadcast-bold"></i>-->
                            <!--Приєднатись до уроку-->
                        </b-button>
                        <b-tooltip
                            :target="'tooltip-join_' + lesson.id"
                            custom-class="custom-tooltip"
                            offset="5"
                            :no-fade="true"
                            :delay="{ show: 50, hide: 50 }"
                        >
                            Приєднатися до трансляції.
                        </b-tooltip>
                        <ConfirmModal
                            v-model="stopLessonModal"
                            title="Зупинити?"
                            decline-label="Скасувати"
                            accept-label="Так, зупинити"
                            @accept-btn="() => stopLesson(lesson.id)"
                            @decline-btn="() => console.log('decline')"
                        >
                            <template v-slot:description>
                                Ви точно хочете зупинити пряму трансляцію?
                            </template>
                        </ConfirmModal>
                    </div>
                    <b-dropdown
                        size="lg"
                        variant="link"
                        :disabled="disabled"
                        :class="{ 'border-0': editMode }"
                        toggle-class="text-decoration-none text-black module-lesson__control--dropdown"
                        no-caret
                    >
                        <template #button-content>
                            <!--                            <b-button variant="link" class="btn-icon" :disabled="editMode" :class="{'border-0': editMode}">-->
                            <!--                                <i class="bi bi-three-dots-vertical"></i>-->
                            <!--                            </b-button>-->
                            <i class="bi bi-three-dots-vertical"></i>
                        </template>
                        <!-- <b-dropdown-text>Порядковість</b-dropdown-text> -->
                        <b-dropdown-item @click="lessonUp">
                            <i class="bi bi-arrow-up"></i>
                            Пересунути вгору
                        </b-dropdown-item>
                        <b-dropdown-item @click="lessonDown">
                            <i class="bi bi-arrow-down"></i>
                            Пересунути вниз
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            :disabled="editMode"
                            :class="{ 'border-0': editMode }"
                            @click="deleteLesson"
                        >
                            <i class="bi bi-trash3 align-baseline me-1"></i>
                            Видалити
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                        variant="link"
                        class="btn-icon"
                        :disabled="editMode"
                        :class="{ 'border-0': editMode }"
                        @click="editLesson"
                    >
                        <i
                            v-if="!disabled"
                            class="ph-note-pencil-bold fs-4"
                        ></i>
                        <i v-else class="mdi mdi-file-eye-outline fs-4"></i>
                    </b-button>
                </div>
            </div>
        </div>
        <!--        <div v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" class="w-100 d-flex">-->
        <!--            <button class="join-btn" @click="joinMentor">Приєднатись до уроку</button>-->
        <!--            <span>{{ lesson.live_status }}</span>-->
        <!--            <button class="join-btn"-->
        <!--                    @click="stopLesson(lesson.id)">Завершити урок-->
        <!--            </button>-->
        <!--        </div>-->
        <b-collapse v-model="isLessonsVisible">
            <div class="d-flex flex-column gap-3 module-lesson__collapse mt-2">
                <div v-if="lesson.description">
                    <div class="module-lesson__label">Опис уроку</div>
                    <div class="module-lesson__text">
                        {{ lesson.description }}
                    </div>
                </div>
                <div
                    v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'"
                    class="d-flex gap-3"
                >
                    <div>
                        <div class="module-lesson__label">Дата</div>
                        <div class="module-lesson__text">
                            {{ formatDate(lesson.date) }}
                        </div>
                    </div>
                    <div v-if="lesson.duration">
                        <div class="module-lesson__label">Тривалість</div>
                        <div class="module-lesson__text">
                            {{ lesson.duration }}
                        </div>
                    </div>
                    <div>
                        <div class="module-lesson__label">Початок</div>
                        <div class="module-lesson__text">
                            {{ formatDate(lesson.date, "HH:mm") }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="lesson.tasks && lesson.tasks.length > 0"
                    class="module-lesson__label"
                >
                    Матеріали
                </div>
                <CourseLessonTaskItem
                    @delete-task="deleteTask(task.id)"
                    v-for="task in lesson.tasks"
                    :task="task"
                ></CourseLessonTaskItem>
            </div>
        </b-collapse>
    </div>
</template>

<style lang="scss">
.module-lesson {
    display: flex;
    flex-direction: column;
    padding: 12px;
    justify-content: center;
    align-items: flex-start;
    //gap: 15px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--stroke, #ebebeb);
    background: var(--white, #fff);
    margin-top: 12px;
    position: relative;
    .btn-icon {
        color: var(--dark, #1e1e1e);
        font-size: large;
    }

    .module-lesson__header {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        align-self: stretch;
        .module-lesson__controls {
            position: absolute;
            right: 8px;
            top: 8px;
            //.module-lesson__control--dropdown {
            //    margin: 0;
            //    padding: 0;
            //}
            //.broadcast-icon {
            //    color: var(--purple, #F24822);
            //    height: 24px;
            //    width: 24px;
            //}
            //.btn-icon {
            //    height: 24px;
            //    width: 24px;
            //    margin: 2px;
            //}
            //.btn-group {
            //    .btn {
            //        height: 24px;
            //        width: 24px;
            //        i {
            //            height: 24px;
            //        }
            //    }
            //}
        }
    }

    .module-lesson__collapse {
        margin-left: 34px;
    }

    img {
        width: 21px;
    }

    .module-lesson__label {
        color: var(--dark, #1e1e1e);
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: 100%;
        opacity: 0.5;
    }

    .module-lesson__title {
        color: var(--dark, #1e1e1e);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        padding-right: 80px;
        i {
            font-size: 12px;
        }
    }

    .module-lesson__text {
        color: var(--dark, #1e1e1e);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
    }

    .module-lesson__body {
        display: flex;
        flex: 1;
        gap: 16px;
        cursor: pointer;
    }
}

.module-lesson--editing {
    border: 1px solid var(--green, #1db954);
}

.lesson-task {
    display: flex;
    gap: 12px;
    width: max-content;
    padding: 16px 0;
    border-bottom: 1px solid #ebebeb;

    &:last-child {
        border: none;
    }

    .lesson-task__lable {
        color: var(--dark, #1e1e1e);
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: 100%;
        opacity: 0.5;
    }

    .lesson-task__title {
        color: var(--dark, #1e1e1e);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
    }
}

.join-btn {
    color: var(--green, #1db954);
    font-family: e-Ukraine;
    font-weight: bold;
}
</style>
