<script setup>
import {computed, ref} from 'vue';
import {Head, router, useForm} from '@inertiajs/vue3';
import PageHeader from '@/Components/PageHeader.vue';
import PageContentCard from '@/Components/PageContentCard.vue';
import CourseParts from '@/Pages/Courses/Partials/CourseParts.vue';
import DiskUsageProgress from '@/Components/DiskUsageProgress.vue';
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const props = defineProps({
  course: Object,
  team: Object,
  courseStatuses: Object,
  certificates_type: Object,
  courseUsers: Object
})

const form = useForm({
  course: props.course,
  filePreview: null,
});

const pageTitle = computed(() => 'Новий курс')

const page = computed(() => {
  return {
    title: pageTitle.value,
    breadcrumbs: [
      {
        text: "Всі курси",
        href: route('courses.courses'),
        active: false,
      },
      {
        text: pageTitle.value,
        active: true,
      },
    ],
  }
})

const shareLinkModal = ref(false);
const shareLink = ref('');

function handleShare() {
  shareLinkModal.value = !shareLinkModal.value;
  shareLink.value = "http://some.link"; //TODO: get share link from course
}

const statuses = computed(() => {
  // console.log(props);
  return (props.courseStatuses && props.courseStatuses.length > 0) ? props.courseStatuses.map((item) => {
    return {value: item, text: t(item)};
  }) : [];
});

const save = () => {
  console.log('update');
  // props.form.post(route('courses.course_update'));
  router.visit(route('courses.store'),
      {
        method: 'post',
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true
      }
  )
};

</script>

<template>
  <div>
    <Head :title="page.title"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>

    <PageContentCard :title="page.title">
      <template #header-end>
        <div class="header-end">
          <DiskUsageProgress/>
          <!--          <b-button class="outline-dark" pill size="lg" @click="handleShare">-->
          <!--            Поділитися подією-->
          <!--            <i class="ph-upload-simple-bold"></i>-->
          <!--          </b-button>-->
          <!--          <ShareModal v-model="shareLinkModal" :shared-link="shareLink"/>-->
        </div>
      </template>

      <template #default>
        <CourseParts :form="form" :team="team" :statuses="statuses" :certificates_type="certificates_type"
                     :courseUsers="courseUsers" is-new @save="save"/>
      </template>
    </PageContentCard>
  </div>
</template>

<style lang="scss">
//
</style>
