<script setup>
import {computed, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import RegisterFormWrapper from "@/Pages/Auth/Partials/RegisterFormWrapper.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";

defineOptions({layout: LoginLayout})

const props = defineProps({
  status: {
    type: String,
  },
  sentAbove: Number,
  email: String,
});

const form = useForm({
  code: '',
});

const digits = reactive({
  digit1: null,
  digit2: null,
  digit3: null,
  digit4: null
})

const remainingTime = ref(props.sentAbove)

const codeComputed = computed(() => '' + (digits.digit1 ?? '') + (digits.digit2 ?? '') + (digits.digit3 ?? '') + (digits.digit4 ?? ''))

const isFinished = computed(() => codeComputed.value.length === 4)

const getInputElement = (index) => {
  return document.getElementById('digit' + index + '-input');
};

const moveToNext = (index) => {
  if (getInputElement(index).value.length === 1) {
    if (index !== 4) {
      digits['digit' + (index + 1)] = null
      getInputElement(index + 1).focus();
    } else {
      getInputElement(index).blur();
    }
  }
}

const moveToPrev = (index, event) => {
  if (event.key === 'Backspace' && getInputElement(index).value.length === 0) {
    event.preventDefault();
    digits['digit' + index] = null;
    if (index > 1) {
      getInputElement(index - 1).focus();
    }
  }
}

const submit = () => {
  form.transform((data) => ({
        code: codeComputed.value,
      }))
      .post(route('verification.verify'));
};

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');

const secondsToMinutesSeconds = (seconds) => {
  const totalSeconds = Math.floor(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

let intervalId = null

const startCountdown = () => {
  if (remainingTime.value > 0) {
    intervalId = setInterval(() => {
      remainingTime.value--
      if (remainingTime.value <= 0) {
        clearInterval(intervalId)
      }
    }, 1000)
  }
}

onMounted(() => {
  startCountdown()
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
})

watch(() => props.sentAbove, (newValue) => {
  remainingTime.value = newValue
  if (intervalId) {
    clearInterval(intervalId)
  }
  startCountdown()
})

</script>

<template>
  <RegisterFormWrapper>

    <template #left>
        <Head title="Email Verification"/>

<b-card no-body class="mb-0 border-0 shadow-none mb-0">
  <b-card-body class="p-sm-5 m-lg-4">
    <div>
      <div class="form-login-header text-center w-100">
        <h1 class="text-md mx-auto login-title" style="max-width: 300px">Введіть код підтвердження</h1>
        <div class="login-subtitle">Відправлений на <span class="fw-semibold">{{ email }}</span></div>
      </div>

      <b-form autocomplete="off" class="login-form mt-5 mx-auto w-full">
        <b-row>
          <b-col cols="3">
            <div class="mb-3">
              <label for="digit1-input" class="visually-hidden">Digit 1</label>
              <b-form-input type="text" v-model="digits.digit1"
                class="form-control form-control-lg text-center"
                placeholder="0"
                @input="moveToNext(1)"
                @keydown="moveToPrev(1, $event)"
                maxLength="1"
                id="digit1-input"
              />
            </div>
          </b-col>

          <b-col cols="3">
            <div class="mb-3">
              <label for="digit2-input" class="visually-hidden">Digit 2</label>
              <b-form-input type="text" v-model="digits.digit2"
                class="form-control form-control-lg text-center"
                placeholder="0"
                @input="moveToNext(2)"
                @keydown="moveToPrev(2, $event)"
                maxLength="1"
                id="digit2-input"
              />
            </div>
          </b-col>

          <b-col cols="3">
            <div class="mb-3">
              <label for="digit3-input" class="visually-hidden">Digit 3</label>
              <b-form-input type="text" v-model="digits.digit3"
                class="form-control form-control-lg text-center"
                placeholder="0"
                @input="moveToNext(3)"
                @keydown="moveToPrev(3, $event)"
                maxLength="1"
                id="digit3-input"
              />
            </div>
          </b-col>

          <b-col cols="3">
            <div class="mb-3">
              <label for="digit4-input" class="visually-hidden">Digit 4</label>
              <b-form-input type="text" v-model="digits.digit4"
                class="form-control form-control-lg text-center"
                placeholder="0"
                @input="moveToNext(4)"
                @keydown="moveToPrev(4, $event)"
                maxLength="1"
                id="digit4-input"
              />
            </div>
          </b-col>

            <div v-if="form.errors.code" class="text-danger py-3 text-center">
                {{ form.errors.code }}
            </div>

        </b-row>
      </b-form><!-- end form -->

      <div class="d-flex flex-column align-items-center justify-content-end mt-4">
        <b-button :disabled="form.processing || !isFinished" variant="success" size="lg" pill @click="submit">
          Далі
        </b-button>

        <div class="my-4 text-center">
          <p class="mb-0">
            Ви зможете надіслати код повторно через: {{ secondsToMinutesSeconds(remainingTime) }}
          </p>
          <div class="mt-2">
            <Link :href="route('verification.send')" class="">Я не отримав код</Link>
          </div>
        </div>
      </div>
    </div>
  </b-card-body>
</b-card>
       </template>
       <template #right>
        <div class="login-right-overlay"></div>
       </template>
  </RegisterFormWrapper>
</template>
