<template>
    <div>
        <video
            ref="videoPlayer"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
        ></video>
    </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import '@mycujoo/videojs-hls-quality-selector';

export default {
    name: 'VideoPlayer',
    props: {
        videoSrc: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const videoPlayer = ref(null);
        let player = null;

        const initializePlayer = () => {
            const options = {
                autoplay: false,
                controls: true,
                responsive: true,
                preload: 'auto',
                playbackRates: [0.5, 1.0, 1.5, 2.0],
                html5: {
                    hls: {
                        overrideNative: true,
                    },
                },
                sources: [
                    {
                        src: props.videoSrc,
                        type: 'application/x-mpegURL',
                    },
                ],
                plugins: {
                    qualityLevels: {},
                },
            };

            player = videojs(videoPlayer.value, options, function onPlayerReady() {
                // console.log('Player is ready');
                // this.play();
            });

            player.hlsQualitySelector({
                displayCurrentQuality: true,
            });
        };

        const updateVideoSource = (newSrc) => {
            if (player) {
                player.src({ type: 'application/x-mpegURL', src: newSrc });
                player.play();
            }
        };

        onMounted(() => {
            initializePlayer();
        });

        watch(() => props.videoSrc, (newSrc, oldSrc) => {
            if (newSrc !== oldSrc) {
                updateVideoSource(newSrc);
            }
        });

        onBeforeUnmount(() => {
            if (player) {
                player.dispose();
            }
        });

        return {
            videoPlayer
        };
    }
}
</script>

<style>
.video-js {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}
</style>
