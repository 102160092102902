<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref, watch } from "vue";
import TabFormProgramSettings from "@/Pages/Mentoring/Partials/TabFormProgramSettings.vue";
import TabFormProgramContent from "@/Pages/Mentoring/Partials/TabFormProgramContent.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import TabFormUsers from "@/Pages/Mentoring/Partials/TabFormUsers.vue";
import TeamMemberChooser from "@/Components/form/TeamMemberChooser.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";

const props = defineProps({
    user: Object,
    mentoring: Object,
    mentorprogram: Object,
    team: Object,
    workshopTypes: Object,
    modules: Object,
    students: Object,
    speakers: Object,
    mentorProgramUsers: Object,
    type: String,
    errors: Object,
});

const { t } = useI18n();

const pageTitle = computed(() =>
    props.type === "new"
        ? "Нова менторська програма"
        : props?.mentorprogram?.name || ""
);
const page = computed(() => {
    return {
        title: pageTitle.value,
        breadcrumbs: [
            {
                text: "Менторство",
                href: route("mentoring.mentoring"),
            },
            {
                text: "Менторські програми",
                href: route("mentoring.mentoring") + "#program",
            },
            {
                text: pageTitle.value,
                active: true,
            },
        ],
    };
});

const tabIndex = ref(0);

const shareLinkModal = ref(false);

const form = useForm({
    mentorprogram: props.mentorprogram,
    filePreview: null,
    speakers: props.speakers,
});

function save() {
    console.log("Save");
    router.visit(route("mentoring.program.store"), {
        method: "post",
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
    });
}

watch(
    () => props.mentorprogram,
    (newValue) => {
        form.mentorprogram = newValue;
    },
    { deep: true }
);

const newUsersCount = 0; //computed(()=> form.mentorprogram.users.filter(user => !user.access).length )
</script>

<template>
    <div>
        <Head title="Менторство" />
        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                    <div
                        v-if="form.mentorprogram.status === 'PUBLISH'"
                        class="mentoring-program__share-button"
                        @click="shareLinkModal = !shareLinkModal"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            class="me-2"
                        >
                            <path
                                d="M17.5 9.58887H15.625C15.4592 9.58887 15.3003 9.65472 15.1831 9.77193C15.0658 9.88914 15 10.0481 15 10.2139C15 10.3796 15.0658 10.5386 15.1831 10.6558C15.3003 10.773 15.4592 10.8389 15.625 10.8389H17.5V17.7139H2.5V10.8389H4.375C4.54076 10.8389 4.69973 10.773 4.81694 10.6558C4.93415 10.5386 5 10.3796 5 10.2139C5 10.0481 4.93415 9.88914 4.81694 9.77193C4.69973 9.65472 4.54076 9.58887 4.375 9.58887H2.5C2.16858 9.5892 1.85083 9.721 1.61648 9.95535C1.38213 10.1897 1.25033 10.5074 1.25 10.8389V17.7139C1.25033 18.0453 1.38213 18.363 1.61648 18.5974C1.85083 18.8317 2.16858 18.9635 2.5 18.9639H17.5C17.8314 18.9635 18.1492 18.8317 18.3835 18.5974C18.6179 18.363 18.7497 18.0453 18.75 17.7139V10.8389C18.7497 10.5074 18.6179 10.1897 18.3835 9.95535C18.1492 9.721 17.8314 9.5892 17.5 9.58887Z"
                                fill="#1DB954"
                            />
                            <path
                                d="M6.69201 6.28094L9.37513 3.59781V15.8391C9.37513 16.0048 9.44098 16.1638 9.55819 16.281C9.6754 16.3982 9.83437 16.4641 10.0001 16.4641C10.1659 16.4641 10.3249 16.3982 10.4421 16.281C10.5593 16.1638 10.6251 16.0048 10.6251 15.8391V3.59781L13.3083 6.28094C13.4261 6.39479 13.584 6.45778 13.7479 6.45636C13.9118 6.45494 14.0685 6.3892 14.1844 6.27332C14.3003 6.15744 14.366 6.00069 14.3674 5.83681C14.3689 5.67294 14.3059 5.51506 14.192 5.39719C14.0601 5.26469 10.5595 1.76094 10.4233 1.63469C10.3057 1.52409 10.1497 1.46355 9.98823 1.46589C9.8268 1.46822 9.67261 1.53323 9.55826 1.64719L5.80826 5.39719C5.69441 5.51506 5.63141 5.67294 5.63284 5.83681C5.63426 6.00069 5.69999 6.15744 5.81587 6.27332C5.93175 6.3892 6.08851 6.45494 6.25238 6.45636C6.41625 6.45778 6.57413 6.39479 6.69201 6.28094Z"
                                fill="#1DB954"
                            />
                        </svg>
                        Поділитись
                    </div>
                </div>
            </template>

            <template #default>
                <b-tabs v-model="tabIndex" nav-class="page-tabs">
                    <template #tabs-start>
                        <a :href="route('mentoring.mentoring') + '#program'">
                            <b-button class="back-to-mentoring">
                                <!-- href="/mentoring/programs"-->
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="26"
                                    viewBox="0 0 15 26"
                                    fill="none"
                                >
                                    <path
                                        d="M10.8213 5.60467C11.0203 5.60066 11.2155 5.66397 11.3799 5.78584C11.5443 5.9077 11.6698 6.0821 11.739 6.2849C11.8082 6.4877 11.8178 6.70887 11.7663 6.9178C11.7148 7.12674 11.6048 7.31311 11.4516 7.45113L5.58747 12.9078L11.4552 18.3645C11.5624 18.4495 11.6522 18.5576 11.719 18.6822C11.7858 18.8068 11.8281 18.9451 11.8433 19.0883C11.8585 19.2315 11.8462 19.3767 11.8071 19.5145C11.7681 19.6523 11.7032 19.7798 11.6166 19.889C11.53 19.9982 11.4234 20.0867 11.3038 20.1489C11.1841 20.2111 11.0538 20.2456 10.9212 20.2503C10.7887 20.2551 10.6566 20.2299 10.5334 20.1763C10.4103 20.1228 10.2986 20.0421 10.2056 19.9393L3.49755 13.707C3.39205 13.6091 3.30735 13.4874 3.24927 13.3505C3.19118 13.2135 3.16111 13.0645 3.16111 12.9137C3.16111 12.7629 3.19118 12.6139 3.24927 12.477C3.30735 12.34 3.39205 12.2184 3.49755 12.1204L10.2056 5.86845C10.3756 5.70396 10.5938 5.61047 10.8213 5.60467Z"
                                        fill="white"
                                    />
                                </svg>
                            </b-button>
                        </a>
                    </template>
                    <b-tab title="Основна інформація" active>
                        <TabFormProgramSettings
                            @save="save"
                            :form="form"
                            :errors="errors"
                            :workshop-types="workshopTypes"
                            :team="team"
                        />
                    </b-tab>
                    <b-tab title="Контент" :disabled="!modules">
                        <TabFormProgramContent
                            v-if="modules"
                            :program="mentorprogram"
                            :modules="modules"
                        />
                    </b-tab>
                    <b-tab title="Управління" :disabled="!modules">
                        <b-container fluid class="tab-form">
                            <b-row>
                                <b-col cols="6">
                                    <TeamMemberChooser
                                        :speaker="form.speakers"
                                        :team="team"
                                        type="MENTOR"
                                        @on-save="save"
                                    >
                                        <template #header
                                            >Додати ментора
                                            <i
                                                id="mentoring-mentor-role"
                                                class="bi bi-info-circle"
                                            ></i>
                                            <b-tooltip
                                                        class="text-left"
                                                        target="mentoring-mentor-role"
                                                        custom-class="custom-tooltip"
                                                        offset="5"
                                                        triggers="hover"
                                                        :no-fade="true"
                                                        :delay="{ show: 50, hide: 50 }"
                                                    >
                                                    Ментор має повне керування програмою, де він призначений ментором.
                                            </b-tooltip>
                                        </template>
                                        <template #description
                                            >Щоб додати ментора, натисніть
                                            кнопку «+» і оберіть потрібну людину
                                            зі списку вашої команди.</template
                                        >
                                    </TeamMemberChooser>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-tab>
                    <b-tab title="Користувачі" :disabled="!modules">
                        <template #title>
                            Користувачі
                            <div
                                v-if="newUsersCount > 0"
                                class="nav-link__counter"
                            >
                                {{ newUsersCount }}
                            </div>
                        </template>
                        <TabFormUsers
                            :form="form"
                            :users="mentorProgramUsers"
                            :students="students"
                        />
                    </b-tab>
                    <template #tabs-end>
                        <div class="tabs-end">
                            <PreviewButton
                                v-if="mentorprogram && mentorprogram.id && form.mentorprogram.status === 'DRAFT'"
                                :href="
                                    route('roadmap.view', {
                                        programId: mentorprogram.id,
                                    })
                                "
                                target="_blank"
                            />
                        </div>
                    </template>
                </b-tabs>
                <ShareModal
                    v-if="mentorprogram && mentorprogram.id"
                    v-model="shareLinkModal"
                    :shared-link="
                        route('roadmap.view', { programId: mentorprogram.id })
                    "
                />
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
.mentoring-program__share-button {
    display: flex;
    align-items: center;
    color: var(--green, #1db954);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    cursor: pointer;
}

.back-to-mentoring {
    display: flex;
    width: 49px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-right: 1px solid var(--stroke, #ebebeb);
    background: var(--purple, #f24822);
    cursor: pointer;
}

.module-content {
    min-height: calc(100vh - 120px);
}

.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}
</style>
