<template>
  <div class="countdown-timer">
    <div class="countdown-timer-days me-4">
      <div>{{days}}</div>
      <span>Днів</span>
    </div>
    <div class="countdown-timer-hours">
      <div>{{hours}}</div>
      <span>Годин</span>
    </div>
    <div class="countdown-timer-hours">
      <div> : </div>
      <span></span>
    </div>
    <div class="countdown-timer-minutes">
      <div>{{minutes}}</div>
      <span>Хвилин</span>
    </div>
    <div class="countdown-timer-minutes">
      <div> : </div>
      <span></span>
    </div>
    <div class="countdown-timer-seconds">
      <div>{{seconds}}</div>
      <span>Секунд</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  dateTime: {
    type: String,
    required: true
  }
})

const targetDate = new Date(props.dateTime)

const days = ref('00')
const hours = ref('00')
const minutes = ref('00')
const seconds = ref('00')

let timerId = null

onMounted(() => {
  timerId = setInterval(() => {
    const now = new Date().getTime()
    const distance = targetDate - now

    days.value = `${Math.floor(distance / (1000 * 60 * 60 * 24))}`
    hours.value = `${Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`
    minutes.value = `${Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))}`
    seconds.value = `${Math.floor((distance % (1000 * 60)) / 1000)}`

    if (days.value < 10) days.value = `0${days.value}`;
    if (hours.value < 10) hours.value = `0${hours.value}`;
    if (minutes.value < 10) minutes.value = `0${minutes.value}`;
    if (seconds.value < 10) seconds.value = `0${seconds.value}`;

    if (distance < 0) {
      clearInterval(timerId)
      days.value = hours.value = minutes.value = seconds.value = '00'
    }
  }, 1000)
})

onUnmounted(() => {
  clearInterval(timerId)
})
</script>
<style lang="scss">
  .countdown-timer {
    display: flex;
    width: fit-content;
    border-top: 1px solid #fff;
    .countdown-timer-days,
    .countdown-timer-hours,
    .countdown-timer-minutes,
    .countdown-timer-seconds{
      display: flex;
      flex-direction: column;
      div {
        color: var(--white, #FFF);
        text-align: center;
        font-size: 121px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 121px */
      }
      span {
        color: var(--white, #FFF);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%; /* 13px */
        opacity: 0.5;
      }
    }

  }
</style>
