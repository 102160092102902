<script setup>
import InputGroup from "@/Components/InputGroup.vue";
import SpeakerField from "@/Components/form/SpeakerField.vue";
import MyTeamModal from "@/Components/modals/MyTeamModal.vue";
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import TeamMemberChooser from "@/Components/form/TeamMemberChooser.vue";

const props = defineProps({
    form: Object,
    team: Object,
    certificates_type: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

// const form = useForm({
//   status: 'published',
//   publicLink: '',
//   title: '',
//   description: '',
//   openForStudentsByHand: false,
//   openByNextLesson: false,
//   openByDate: false,
//   monthes: 1,
//   selected: 'first',
// });

const statuses = [
    { value: null, text: "Оберіть статус" },
    { value: "draft", text: "Чернетка" },
    { value: "published", text: "Опублікований" },
    { value: "archived", text: "Архівний" },
];

const myTeamModal = ref(false);

const saveSpeakers = (ids) => {
    const resultArray = [];
    props.team.forEach((obj) => {
        if (ids.includes(obj.id)) {
            const index = props.form.course.speakers.findIndex(
                (el) => el.id === obj.id
            );
            resultArray.push({
                ...obj,
                label:
                    index >= 0
                        ? props.form.course.speakers[index].label
                        : "Спікер",
            });
        }
    });
    props.form.course.speakers = resultArray;
};

const emit = defineEmits(["save"]);
const save = () => {
    emit("save");
};

const certificatesType = {
    E_CERTIFICATE: "Електронний сертифікат",
    MANUAL_CERTIFICATE: "Я буду відправляти вручну",
};
//

const registrationLimitInput = computed({
    get() {
        return props.form.course.config.max_user_in_course === 0 ||
            props.form.course.config.max_user_in_course === "0"
            ? ""
            : props.form.course.config.max_user_in_course;
    },
    set(value) {
        props.form.course.config.max_user_in_course = value
            ? parseInt(value)
            : 0;
    },
});

const courseAvailable = computed({
    get() {
        return props.form.course.config.available === 0 ||
            props.form.course.config.available === "0"
            ? ""
            : props.form.course.config.available;
    },
    set(value) {
        props.form.course.config.available = value ? parseFloat(value) : 0;
    },
});
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="6">
                <!--        <b-card no-body class="overflow-hidden shadow-none mt-4">-->
                <!--          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">-->
                <!--            <div class="fs mb-2 card__title">Куратор курсу</div>-->
                <!--            <div class="d-flex justify-content-between align-items-end w-100">-->
                <!--              <div class="d-flex flex-wrap gap-3">-->
                <!--                <SpeakerField v-for="speaker in props.form.course.speakers" :speaker="speaker"/>-->
                <!--              </div>-->
                <!--              <b-button variant="danger" class="red-plus-button" :disabled="isReadOnly" @click="myTeamModal = !myTeamModal">-->
                <!--                <i class="bx bx-plus"></i>-->
                <!--              </b-button>-->
                <!--            </div>-->
                <!--            <MyTeamModal-->
                <!--                v-model="myTeamModal"-->
                <!--                :team="team"-->
                <!--                :speakers="props.form.course.speakers"-->
                <!--                @on-save="saveSpeakers"-->
                <!--            />-->
                <!--          </b-card-body>-->
                <!--        </b-card>-->

                <TeamMemberChooser
                    :speaker="props.form.course.speakers"
                    :team="team"
                    type="CURATOR"
                    class="w-100 p-0"
                >
                    <template #header>
                        Куратор курсу
                        <i
                            id="course-curator-role"
                            class="bi bi-info-circle">
                        </i>

                </template>

                    <!--              <template #description>Щоб додати куратора, будь ласка, натисніть на кнопку «+» та виберіть людину зі списку команди.</template>-->
                </TeamMemberChooser>
                <b-tooltip
                        class="text-left"
                        target="course-curator-role"
                        custom-class="custom-tooltip"
                        offset="5"
                        triggers="hover"
                        :no-fade="true"
                        :delay="{ show: 50, hide: 50 }"
                    >
                    Куратор може додавати або видаляти студентів, відкривати доступ до модулів курсу та слідкувати за активністю в чаті.
                    </b-tooltip>

                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-3"
                    >
                        <!-- <div class="card__title">Доступність курсу</div> -->

                        <!-- <b-row class="w-100">
                            <InputGroup
                                placeholder="MAX студентів на курсі"
                                v-model="registrationLimitInput"
                                type="number"
                                size="lg"
                                :disabled="isReadOnly"
                                class="mt-3 w-100"
                            />
                        </b-row> -->

                        <b-row class="w-100">
                            <b-col
                                cols="4"
                                class="card__title mt-4 d-flex gap-2 align-items-center"
                            >
                                <i
                                    id="course-availability-tooltip"
                                    class="bi bi-info-circle fs-5"
                                ></i>
                                Доступ після завершення
                            </b-col>
                            <b-col cols="8">
                                <InputGroup
                                    placeholder="Кількість днів"
                                    v-model="courseAvailable"
                                    type="number"
                                    size="lg"
                                    class="w-100"
                                    :disabled="isReadOnly"
                                    :postfix="courseAvailable ? 'Днів' : ''"
                                />
                            </b-col>
                        </b-row>
                        <b-tooltip
                            class="text-left"
                            target="course-availability-tooltip"
                            custom-class="custom-tooltip"
                            offset="5"
                            triggers="hover"
                        >
                            <p>
                                Введіть цифрами кількість днів, протягом яких
                                курс буде доступний після завершення.
                            </p>
                            <p>
                                Якщо хочете залишити доступ відкритим назавжди,
                                залиште поле незаповненим.
                            </p>
                        </b-tooltip>
                    </b-card-body>
                </b-card>

                <!--        <b-card no-body class="shadow-none mt-4">-->
                <!--          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">-->
                <!--            <div class="fs">Результат після проходження курсу <i id="result-tooltip" class="bi bi-info-circle"></i></div>-->
                <!--            <b-tooltip class="text-left" target="result-tooltip" custom-class="custom-tooltip"-->
                <!--                       offset="5" triggers="hover">-->
                <!--              Сертифікат, який отримають<Br /> студенти після проходження курсу-->
                <!--            </b-tooltip>-->
                <!--            <b-form-radio-group-->
                <!--                id="radio-group-2"-->
                <!--                v-model="props.form.course.config.certificate_type"-->
                <!--                :aria-describedby="ariaDescribedby"-->
                <!--                name="radio-sub-component"-->
                <!--                class="mt-4"-->
                <!--            >-->
                <!--              <b-form-radio v-for="cert in certificates_type" :value="cert.value">{{ certificatesType[cert.value] }}</b-form-radio>-->
                <!--            </b-form-radio-group>-->
                <!--          </b-card-body>-->
                <!--        </b-card>-->

                <b-button
                    :disabled="form.processing || isReadOnly"
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3"
                    @click="save"
                >
                    Зберегти
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.red-plus-button {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--purple, #f24822);
    font-size: 23px;
    line-height: 1;
}
</style>
