<script setup>
import LoginFormWrapper from "@/Pages/Auth/Partials/LoginFormWrapper.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { ref } from "vue";
import SuccessMessage from "@/Components/SuccessMessage.vue";

defineOptions({ layout: LoginLayout });

defineProps({
    status: {
        type: String,
    },
});

const showSuccess = ref(false);

const form = useForm({
    email: "",
});

const onSubmit = () => {
    form.post(route("password.email"), {
        onSuccess: () => {
            showSuccess.value = true;
        },
        onFinish: () => form.reset(),
    });
};

const onReset = () => {
    form.reset();
};
</script>

<template>
    <LoginFormWrapper>

        <template #left>
            <div
            class="d-flex flex-column align-self-stretch justify-content-center align-items-center"
        >
            <Head title="Нагадати пароль" />

            <div
                v-if="!showSuccess"
                class="d-flex flex-column align-items-center"
            >
                <div class="form-login-header text-center px-5">
                    <h1 class="text-md login-title">
                        Введіть свій email, і ми надішлемо вам код для
                        відновлення пароля
                    </h1>
                </div>

                <div
                    v-if="status"
                    class="mt-4 font-medium text-sm text-green-600"
                >
                    {{ status }}
                </div>

                <b-form
                    @submit.prevent="onSubmit"
                    @reset="onReset"
                    class="login-form"
                >
                    <div class="my-5">
                        <InputGroup
                            v-model="form.email"
                            label="Email"
                            placeholder="Введіть Email"
                            :error-message="form.errors.email"
                            type="email"
                            required
                            autofocus
                            autocomplete="username"
                            :is-error="!!form.errors.email"
                        />
                    </div>

                    <div
                        class="d-flex flex-column align-items-center justify-content-end mt-4"
                    >
                        <b-button
                            :disabled="form.processing"
                            variant="primary"
                            size="lg"
                            pill
                            @click="onSubmit"
                        >
                            Відправити
                        </b-button>

                        <div class="w-100 text-center mt-4">
                            <Link :href="route('login')" class="text-gray-300"
                                >я згадав пароль</Link
                            >
                        </div>
                    </div>
                </b-form>
            </div>
            <SuccessMessage
                v-else
                :title="'Готово!'"
                :subtitle="'Слідуйте інструкції, надісланій на вашу електронну пошту.'"
            />
        </div>
    </template>
    <template #right>
        <div class="login-right-overlay"></div>
    </template>
    </LoginFormWrapper>
</template>
