<script setup>
import {computed} from "vue";
import InputGroup from '@/Components/InputGroup.vue';
import {useForm} from "@inertiajs/vue3";
import {helpers, maxLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

const props = defineProps({
  modelValue: Boolean,
  title: String,
  file: {
    type: Object,
    required: true,
  }
});

const emit = defineEmits([
  'update:modelValue',
  'edit-file'
]);

const isVisible = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const form = useForm({
  name: props.file.original_name,
  description: props.file.description,
});

const rules = {
  name: {
    required: helpers.withMessage('Це поле є обов\'язковим', required),
  },
  description: {
    maxLength: helpers.withMessage(
        'Не більше 300 символів',
        maxLength(250)
    ),
  },
};

const v$ = useVuelidate(rules, form, { $lazy: true });

const addLesson = () => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    emit('edit-file', form);
  }
}
</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>{{ title }}</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <InputGroup
          label="Назва файлу"
          v-model="form.name"
          size="lg"
          class="mt-3 w-100"
          :is-error="v$.name?.$error"
          :error-message="v$.title?.$errors[0]?.$message"
          @blur="v$.name.$touch()"
      />
      <InputGroup
          element="textarea"
          label="Опис файлу"
          v-model="form.description"
          :rows="5"
          :max-rows="10"
          :max-length="250"
          size="lg"
          class="mt-3 w-100"
          :is-error="v$.description.$error"
          :error-message="v$.description.$errors[0]?.$message"
          @blur="v$.description.$touch()"
      />
      <div>
        <b-button variant="primary" size="md" pill class="mt-3 float-end" @click="addLesson">
          Зберегти
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>

</style>
