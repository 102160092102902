<script setup>
import {ref, computed, onMounted, onUnmounted} from 'vue';
import PhoneInput from "@/Components/form/PhoneInput.vue";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js'
import { formatISO } from 'date-fns';
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  element: {
    type: String,
    default: 'input' // 'textarea'
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: "text"
  },
  errorMessage: {
    type: String,
    default: null
  },
  isError: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: "text"
  },
  required: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  enableTime: {
    type: Boolean,
    default: true
  },
  rows: Number,
  maxRows: Number,
  maxLength: Number,
  postfix: String,
  dateConfig: {
      type: Object,
      default: {
          futureOnly: true
      }
  }
});

const emit = defineEmits(['update:modelValue', 'blur']);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const isValid = computed(() => !(!!props.errorMessage || props.isError));

const input = ref(null);

const isFocus = ref(false)

const togglePassword = ref(false);

const blur = () => {
    emit('blur');
};

const phoneValidate = (validate) => {
    emit('blur', validate);
}

const minDateWithGap = computed(() => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 15);
    return formatISO(now)
})

onMounted(() => {
    if(props.element === 'duration' && (props.modelValue === "" || props.modelValue === null)) value.value = '01:00'
    if(props.element === 'date' || props.element === 'time') {
        if(props.modelValue === "" || props.modelValue === null) {
            value.value = minDateWithGap.value
        }
    }

})
onUnmounted(() => {
    if (input.value && input.value.fp) {
        input.value.fp.destroy();
    }
});

</script>

<template>
  <b-form-group :label="label" v-uid :label-for="input?.id" class="mt-4" :state="isValid" :class="{ 'is-focused' : isFocus && isValid }">
    <div v-if="element === 'date'" class="date-picker-wrapper">
      <flat-pickr
        v-model="value"
        :config="{
          altFormat: 'd.m.Y',
          altInput: true,
          dateFormat: 'Z',
          enableTime: enableTime,
          time_24hr: enableTime,
          locale: Ukrainian,
          minuteIncrement: 1,
          defaultDate: minDateWithGap,
          onChange: (selectedDates, dateStr, instance) => {
            const today = new Date();
            const selectedDate = selectedDates[0];

            if (selectedDate) {
              const selectedDateOnly = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
              const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

              if (selectedDateOnly.getTime() === todayOnly.getTime()) {
                const selectedTime = selectedDate.getTime();
                const currentTime = today.getTime();

                if (selectedTime < currentTime) {
                  const newDate = new Date(today.getTime() + 15 * 60 * 1000);
                  instance.setDate(newDate, false);
                } else {
                  value = dateStr;
                }
              } else if (selectedDateOnly < todayOnly) {
                instance.setDate(value, false);
                  toast.info('Дата і час мають бути більше ніж зараз', {
                    autoClose: 2000,
                    position: toast.POSITION.BOTTOM_CENTER,
                    multiple: false
                  });
              } else {
                value = dateStr;
              }
            }
          },
          disableMobile: true//TODO: передивитися потім коли буде адаптив.
        }"
        :placeholder="placeholder"
        :required="required"
        :autofocus="autofocus"
        :state="isValid"
        ref="input"
        v-uid
        class="form-control form-control-date"
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @blur="blur"
      />
      <i class="ph-calendar-blank-bold date-picker-icon"></i>
    </div>
    <div v-else-if="element === 'time'" class="time-picker-wrapper">
      <flat-pickr
        v-model="value"
        :config="{
          altFormat: 'H:i',
          altInput: true,
          dateFormat: 'Z',
          enableTime: enableTime,
          noCalendar: true,
          time_24hr: true,
          locale: Ukrainian,
          minuteIncrement: 1,
          defaultDate: minDateWithGap,
          onChange: (selectedDates, dateStr, instance) => {
            const today = new Date();
            const selectedDate = selectedDates[0];

            if (selectedDate) {
              const selectedDateOnly = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
              const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

              if (selectedDateOnly.getTime() === todayOnly.getTime()) {
                const selectedTime = selectedDate.getTime();
                const currentTime = today.getTime();

                if (selectedTime < currentTime) {
                  const newDate = new Date(today.getTime() + 15 * 60 * 1000);
                  instance.setDate(newDate, false);
                } else {
                  value = dateStr;
                }
              } else if (selectedDateOnly < todayOnly) {
                instance.setDate(value, false);
                  toast.info('Дата і час мають бути більше ніж зараз', {
                    autoClose: 2000,
                    position: toast.POSITION.BOTTOM_CENTER,
                    multiple: false
                  });
              } else {
                value = dateStr;
              }
            }
          },
          disableMobile: true//TODO: передивитися потім коли буде адаптив.
        }"
        :placeholder="placeholder"
        :required="required"
        :autofocus="autofocus"
        :state="isValid"
        :default-time="null"
        ref="input"
        v-uid
        class="form-control form-control-time"
        @on-open="isFocus = true"
        @on-close="isFocus = false"
        @blur="blur"
      />
      <i class="ph-clock-bold time-picker-icon"></i>
    </div>
    <div v-else-if="element === 'duration'" class="time-picker-wrapper">
      <flat-pickr
          v-model="value"
          :config="{
          altFormat: 'H:i',
          altInput: true,
          dateFormat: 'H:i',
          enableTime: enableTime,
          noCalendar: true,
          time_24hr: true,
          defaultHour: '1',
          minuteIncrement: 1,
          locale: Ukrainian,
          disableMobile: true//TODO: передивитися потім коли буде адаптив.
          }"
          :placeholder="placeholder"
          :required="required"
          :autofocus="autofocus"
          :state="isValid"
          default-time="01:00"
          ref="input"
          v-uid
          class="form-control form-control-time"
          @on-open="isFocus = true"
          @on-close="isFocus = false"
          @blur="blur"
      />
      <i class="ph-clock-bold time-picker-icon"></i>
    </div>
    <PhoneInput
        v-else-if="element === 'phone'"
        v-model="value"
        v-uid
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @phone-validation="phoneValidate"
    />
    <b-form-textarea
        v-else-if="element === 'textarea'"
        v-model="value"
        :placeholder="placeholder"
        :required="required"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :state="isValid"
        :rows="rows"
        :max-rows="maxRows"
        :maxlength="maxLength"
        ref="input"
        v-uid
        @blur="blur"
        @focusin="isFocus = true"
        @focusout="isFocus = false"
    />
    <b-form-input
        v-else
        v-model="value"
        :placeholder="placeholder"
        :type="type === 'password' ? (togglePassword ? 'text' : 'password') : type"
        :required="required"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :state="isValid"
        :maxlength="maxLength"
        ref="input"
        v-uid
        @blur="blur"
        @focusin="isFocus = true"
        @focusout="isFocus = false"
    />
    <span v-if="postfix" class="input-group__postfix">
      {{ postfix }}
    </span>
    <b-button
      v-if="type === 'password'"
      variant="link"
      class="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
      type="button" id="password-addon"
      @click="togglePassword = !togglePassword"
    >
      <i v-if="!togglePassword" class="ri-eye-fill align-middle"></i>
      <i v-else class="ri-eye-off-fill align-middle"></i>
    </b-button>
    <b-spinner
        v-if="isLoading"
        variant="primary"
        class="position-absolute end-0 top-0 text-decoration-none"
    />
    <b-form-invalid-feedback :state="isValid" >
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <span v-if="maxLength" class="input-group__counter">
      {{ value?.length ? value?.length : '0' }}/{{ maxLength }}
    </span>
  </b-form-group>
</template>

<style lang="scss">
fieldset,
[role=group] {
  position: relative;

  &.is-invalid {
    .form-label {
      color: #DA1414;
    }
  }

  .form-label,
  .col-form-label {
    padding: 0 12px;
    margin-bottom: -10px;
    font-size: 10px;
    line-height: 10px;
    color: #8F8F8F;
    font-weight: var(--tb-font-weight-normal);
    transition: color 0.5s ease;
  }

  .invalid-feedback {
    margin-top: 0;
    padding: 6px 10px;
    background: #FEEFEF;
    color: #DA1414;
    font-size: 13px;
    font-style: normal;
    font-weight: var(--tb-font-weight-normal);
    line-height: 20px
  }
    &.is-focused {
      .form-control {
          border-color: var(--tb-primary)!important;
      }
      .form-label {
          color: var(--tb-primary)!important;
      }
      .input-group__counter {
        color: var(--tb-primary)!important;
      }
    }
}

.form-control {
  border-radius: 0;
  border: none;
  padding-top: 16px!important;
  padding-bottom: 8px!important;
  height: calc(12px * 2 + 22px);
  font-size: 14px;
  line-height: 1;
  border-bottom: 1px solid var(--Grey, #C2C2C2);
  background: var(--white, #FFF);
  font-weight: var(--tb-font-weight-normal);
  transition: border 0.5s ease;

  &.is-valid {
    background-image: none;
    border-bottom: 1px solid var(--Grey, #C2C2C2);
    padding-right: 1rem !important;
  }
}

textarea.form-control {
  height: initial;
  line-height: 1.3;
}
.input-group__counter {
  color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  transition: color 0.5s ease;
}
.input-group__postfix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #C2C2C2;
  padding-right: 1rem;
  font-family: e-Ukraine;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
}
</style>
