<script setup>
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useClipboard } from "@vueuse/core";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useI18n } from "vue-i18n";
import InputGroup from "@/Components/InputGroup.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email, url } from "@vuelidate/validators";
import PublicLink from "@/Components/form/PublicLink.vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    form: Object,
    team: Object,
    workshopTypes: Object,
    errors: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const { text, copy, copied, isSupported } = useClipboard({
    legacy: true,
});

watch(copied, (newValue, oldValue) => {
    if (newValue) {
        toast.success("Cкопійовано", {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
        });
    }
});

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const status = ref(null);

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    contact_url: {
        url: helpers.withMessage("Повино бути URL", url),
    },
};

const v$ = useVuelidate(rules, props.form.mentorprogram, { $lazy: true });

const onSubmit = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) emit("save");
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["save"]);
</script>

<template>
    <b-container fluid class="tab-form mentoring-form">
        <b-form>
            <FileUploader
                :form="form.mentorprogram"
                :imagePreview="form.mentorprogram.image"
                custom-title=" або перетягніть фото"
                :disabled="isReadOnly"
            />
            <b-card no-body class="overflow-hidden shadow-none mt-4">
                <b-card-body
                    class="d-flex flex-column justify-content-between align-items-start bg-light p-3 rounded-3 w-50"
                >
                    <SelectInputGroup
                        label="Статус"
                        v-model="form.mentorprogram.status"
                        :options="statuses"
                        :disabled="isReadOnly"
                        size="lg"
                        class="w-100"
                    />
                    <PublicLink
                        :public-link="
                            props.form.mentorprogram &&
                            props.form.mentorprogram.id
                                ? route('roadmap.view', {
                                      programId: props.form.mentorprogram.id,
                                  })
                                : ''
                        "
                    />
                </b-card-body>
            </b-card>
            <div class="w-50 d-flex gap-2 mt-2">
                <InputGroup
                    label="Тривалість навчання"
                    v-model="form.mentorprogram.duration"
                    :disabled="isReadOnly"
                    size="lg"
                    type="number"
                    class="w-50"
                />
                <SelectInputGroup
                    label="Одиниця часу"
                    v-model="form.mentorprogram.duration_type"
                    :options="[
                        { value: 'DAYS', text: 'День' },
                        { value: 'WEEKS', text: 'Тиждень' },
                        { value: 'MONTHS', text: 'Місяць' },
                    ]"
                    :disabled="isReadOnly"
                    size="lg"
                    class="mt-4 w-50"
                />
            </div>
            <div class="w-50 mt-2">
                <InputGroup
                    label="Назва менторської програми"
                    v-model="form.mentorprogram.name"
                    :options="statuses"
                    size="lg"
                    class="mt-3 w-100"
                    :max-length="90"
                    :disabled="isReadOnly"
                    required
                    :error-message="
                        errors && 'mentorprogram.name' in errors
                            ? errors['mentorprogram.name']
                            : '' ||
                              v$.name?.$errors.find(
                                  (error) => error['$validator'] === 'required'
                              )?.$message
                    "
                    :is-error="
                        (errors && 'mentorprogram.name' in errors) ||
                        v$.name.$invalid
                    "
                    @blur="v$.name.$touch()"
                />
            </div>
            <div class="w-50 mt-2">
                <InputGroup
                    element="textarea"
                    label="Опис менторської програми"
                    v-model="form.mentorprogram.description"
                    :options="statuses"
                    :disabled="isReadOnly"
                    :rows="4"
                    :max-rows="10"
                    :max-length="255"
                    size="lg"
                    class="mt-3 w-100"
                />
            </div>
            <div class="w-50 mt-2">
                <InputGroup
                    element="textarea"
                    label="Чого користувачі навчаться по завершенню програми?"
                    v-model="form.mentorprogram.summary"
                    :options="statuses"
                    :disabled="isReadOnly"
                    :rows="4"
                    :max-rows="10"
                    :max-length="255"
                    size="lg"
                    class="mt-3 w-100"
                />
            </div>
            <div class="w-50 mt-2">
                <InputGroup
                    label="Посилання для зв’язку зі студентами"
                    v-model="form.mentorprogram.contact_url"
                    placeholder="https://"
                    size="lg"
                    class="mt-3 w-100"
                    :max-length="255"
                    :disabled="isReadOnly"
                    :error-message="
                        errors && 'mentorprogram.contact_url' in errors
                            ? errors['mentorprogram.contact_url']
                            : '' || v$.contact_url?.$errors[0]?.$message
                    "
                    :is-error="
                        (errors && 'mentorprogram.contact_url' in errors) ||
                        v$.contact_url.$invalid
                    "
                    @blur="v$.contact_url.$touch()"
                />
            </div>
            <div class="w-50 mt-2">
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3"
                    :disabled="form.processing || isReadOnly"
                    @click="onSubmit"
                >
                    Зберегти
                </b-button>
            </div>
        </b-form>
    </b-container>
</template>

<style lang="scss"></style>
