<script setup>
import video from "../../../../images/svg/cloud-icons/video.svg";
import text from "../../../../images/svg/cloud-icons/text.svg";
import image from "../../../../images/svg/cloud-icons/image.svg";
import { computed, ref } from "vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { router, Link } from "@inertiajs/vue3";
import useRequestProgress from "@/composables/useRequestProgress.js";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import FileInfoEditModal from "@/Components/modals/FileInfoEditModal.vue";
import noImage from "../../../../images/no-image.png";
import EmptyData from "@/Components/EmptyData.vue";

const props = defineProps({
    files: Object,
    fileType: {
        type: String,
        required: true,
    },
});

console.log(props.files);

const emit = defineEmits(["remove-file", "add-file"]);

const fileIcon = {
    video: video,
    file: text,
    photo: image,
    'webinar_presentation': text,
};

const removeModal = ref(false);
const fileInfoEditModal = ref(false);
const tempId = ref(null);
const tempFile = ref(null);

const editing = ref(false);
const editedFileName = ref("");

const handleDeleteFile = (file) => {
    tempId.value = file.id;
    removeModal.value = true;
};
const handleEditFile = (file) => {
    tempFile.value = file;
    fileInfoEditModal.value = true;
};

const apiCallDeleteFile = (id) => {
    router.visit(route("cloud.remove_storage_file", { storageId: id }), {
        method: "post",
        // data: {'redirect': router.page.url},
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {

            // props.form.course.contentItems = response.props.course.contentItems;
            //router.visit(router.page.url);
            emit("remove-file", id);
        },
    });
};

const toggleEditMode = (value) => {
    // editing.value = true;
    // editedFileName.value = value;
    // Почати редагування
};
const handleEditFileInfo = (value) => {
    const { name, description } = value;

    const data = { name, description };
    router.visit(
        route("cloud.storage_change_file_name_description", {
            storageId: tempFile.value.id,
        }),
        {
            method: "post",
            data: data,
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: () => {
                tempFile.value = null;
                fileInfoEditModal.value = false;
            },
            onFinish: () => {
                tempFile.value = null;
                fileInfoEditModal.value = false;
            },
        }
    );
};

const { formatDate } = useDateFormatter();

// const sortedFiles = computed(() =>
//     [...props.files].sort((a, b) => new Date(b.date) - new Date(a.date))
// );

const sortedFiles = computed(() =>
    props.files
);
</script>


<!-- sortedFiles &&
sortedFiles.filter((item) => item.type.toLowerCase() === fileType)
    .length === 0 -->
<template>
    <div
        v-if="
            !sortedFiles || sortedFiles.length === 0
        "
        class="w-100 d-flex justify-content-center align-items-center"
    >
        <EmptyData type="cloud">
            <template v-slot:title>Тут поки що нічого немає</template>
            <template v-slot:description>
                <p>Завантажте або перетягніть файл (png, jpeg, mp4, doc)</p>
                <p>
                    Додавайте файли та використовуйте їх для створення курсів чи
                    менторських програм
                </p>
            </template>
            <template v-slot:action>
                <b-button
                    class="outline-dark d-flex justify-content-center align-items-center gap-2"
                    pill
                    size="lg"
                    @click="emit('add-file')"
                >
                    Завантажити файл<i class="ph-arrow-up text-primary"></i>
                </b-button>
            </template>
        </EmptyData>
    </div>
    <div v-else class="module-table team__container">
        <div class="module-table__header border-bottom pb-2">
            <div
                class="module-table__col module-table__col--title text-uppercase opacity-50"
            >
                Назва
            </div>
            <div
                class="module-table__col module-table__col--size text-uppercase opacity-50"
            >
                Розмір
            </div>
            <div
                class="module-table__col module-table__col--date text-uppercase opacity-50"
            >
                Дата додавання
            </div>
            <div
                class="module-table__col module-table__col--actions text-uppercase opacity-50"
            >
                Дії
            </div>
        </div>
        <template v-for="file of sortedFiles" :key="file.id">
            <div
                class="module-table__row border-bottom py-3"
            >
                <div
                    class="module-table__col module-table__col--title"
                    @click="handleEditFile(file)"
                >
                    <div class="module-table__title-image">
                        <svg
                            v-if="file.type.toLowerCase() === 'video'"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="svg-video-icon"
                        >
                            <g clip-path="url(#clip0_5870_14141)">
                                <path
                                    d="M13.2988 9.60809L9.1459 6.58923C9.07129 6.53483 8.98309 6.50214 8.89105 6.49476C8.79902 6.48739 8.70674 6.50562 8.62441 6.54743C8.54209 6.58924 8.47295 6.65302 8.42462 6.73169C8.37629 6.81036 8.35067 6.90087 8.35059 6.99321V13.0289C8.35031 13.1214 8.37572 13.2122 8.42399 13.291C8.47227 13.3699 8.5415 13.4338 8.62398 13.4756C8.70646 13.5175 8.79893 13.5356 8.89109 13.5279C8.98325 13.5202 9.07146 13.4871 9.1459 13.4323L13.2988 10.4161C13.363 10.37 13.4153 10.3094 13.4514 10.2391C13.4874 10.1689 13.5062 10.091 13.5062 10.0121C13.5062 9.93311 13.4874 9.85527 13.4514 9.78502C13.4153 9.71477 13.363 9.65412 13.2988 9.60809Z"
                                />
                                <path
                                    d="M10.0003 0C4.47639 0 0 4.47639 0 10.0003C0 15.5223 4.47639 19.9973 10.0003 19.9973C15.5229 19.9973 20 15.5216 20 10.0003C20.0007 4.47639 15.5229 0 10.0003 0ZM10.0003 18.3288C5.40015 18.3288 1.67049 14.6012 1.67049 10.0003C1.67049 5.40148 5.40015 1.66916 10.0003 1.66916C14.5998 1.66916 18.3288 5.40082 18.3288 10.0003C18.3295 14.6012 14.5998 18.3288 10.0003 18.3288Z"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5870_14141">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <b-img
                            v-else
                            :src="fileIcon[file.type.toLowerCase()]"
                            :title="file.original_name"
                            :alt="file.original_name"
                            rounded
                            fluid
                        ></b-img>
                    </div>
                    <div class="module-table__title-text">
                        <span class="table-col__title">{{
                            file.original_name
                        }}</span>
                        <span class="table-col__subtitle">{{
                            file.description && file.description.length > 0
                                ? file.description
                                : "без опису"
                        }}</span>
                    </div>
                </div>
                <div
                    class="module-table__col module-table__col--size text-gray-color"
                >
                    {{ file.size }}
                </div>
                <div
                    class="module-table__col module-table__col--date text-gray-color"
                >
                    {{ formatDate(file.date, "dd.MM.yyyy") }}
                </div>
                <div class="module-table__col module-table__col--actions">
                    <div class="dropdown flex-shrink-0">
                        <b-button
                            variant="link"
                            size="sm"
                            class="btn-icon dropdown-menu__button"
                            role="button"
                            data-bs-toggle="dropdown"
                            pill
                            aria-expanded="false"
                        >
                            <i class="bi bi-three-dots-vertical"></i>
                        </b-button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <span
                                    class="dropdown-item"
                                    @click="handleEditFile(file)"
                                >
                                    <i
                                        class="bi bi-pencil-square align-baseline me-1"
                                    ></i>
                                    Редагувати
                                </span>
                            </li>
                            <li>
                                <span
                                    class="dropdown-item"
                                    @click="handleDeleteFile(file)"
                                >
                                    <i
                                        class="bi bi-trash align-baseline me-1"
                                    ></i>
                                    Видалити
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <ConfirmModal
            v-model="removeModal"
            :object-id="tempId"
            title="Видалити файл"
            decline-label="Скасувати"
            accept-label="Так, видалити"
            @accept-btn="(id) => apiCallDeleteFile(id)"
            @decline-btn="(id) => console.log('decline', id)"
        >
            <template v-slot:description>
                Після видалення файлу його не можна буде повернути. Ви впевнені,
                що хочете продовжити?
            </template>
        </ConfirmModal>
        <FileInfoEditModal
            v-if="fileInfoEditModal"
            v-model="fileInfoEditModal"
            title="Редагувати файл"
            :file="tempFile"
            @edit-file="handleEditFileInfo"
        />
    </div>
</template>

<style lang="scss" scoped>
.team__container {
    .module-table__col--title {
        display: flex;
        width: 65%;
        align-items: center;
        padding-right: 16px;
        gap: 8px;
        .module-table__title-image {
            min-width: 20px;
        }
        .table-col__title {
            font-family: e-Ukraine;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #1e1e1e;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        .table-col__subtitle {
            font-family: e-Ukraine;
            font-size: 14px;
            font-weight: 200;
            line-height: 1.5;
            letter-spacing: 0;
            text-align: left;
            color: #1e1e1e;
            opacity: 0.5;
            display: -webkit-box ;
            display: -moz-box;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .module-table__col--size {
        width: 15%;
    }

    .module-table__col--date {
        width: 15%;
    }

    .module-table__col--actions {
        width: 50px !important;
    }
    .text-gray-color {
        color: #c2c2c2;
    }
    .module-table__row {
        .svg-video-icon {
            fill: #31a6d9;
        }
        &:hover {
            .svg-video-icon {
                fill: #1db954;
            }
        }
    }
}
</style>
