<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import useRequestProgress from "@/composables/useRequestProgress.js";
import ChatConversationsList from "@/Components/chat/ChatConversationsList.vue";
import ChatContactsList from "@/Components/chat/ChatContactsList.vue";
import ChatConversation from "@/Components/chat/ChatConversation.vue";
import {usePage} from "@inertiajs/vue3";
import {BLink} from "bootstrap-vue-next";

const props = defineProps({
    contactsGroups: {
        type: Object,
        default: () => {}
    },
    conversations: {
        type: Object,
        default: () => {}
    },
    userProfile: {
        type: Object,
        default: () => {}
    },
})

const page = usePage()

const username = computed(() => page?.props?.auth?.user?.name ? page.props.auth.user.name : 'No Name')
const userAvatar = computed(() => page?.props?.auth?.user?.imageUrl ? page.props.auth.user.imageUrl : null)
const userAvatarAcronym = computed(() => page?.props?.auth?.user?.avatar ? page.props.auth.user.avatar : 'IN')
const currentSelectedChat = ref("users")
const currentConversationId = ref(null)
const isUserChat = ref(true)
const isChannelChat = ref(false)
const chatContactData = ref([]) //chatContactData
const callsData = ref([]) //callsData
const bookmarkData = ref() //bookmarkData
const attachementsData = ref([]) //attachementsData
const messages = ref([]) //messages
const searchMessages = ref(null)

const isReplyClicked = ref(false)
const inviteMembersModal = ref(false)
const showConversationsFilter = ref(false)

const contactsList = ref([])

const typesOptions = [
    { title: 'Всі', value: null },
    { title: 'Курси', value: 'course' },
    { title: 'Воркшопи', value: 'workshop' },
    { title: 'Менторські програми', value: 'mp' }
];

const currentConversation = ref(null)

const conversationsGroups = computed(() => [])
// const conversationsGroups = computed(() => props.conversations.data.filter(item => item.type === "group"))
// const conversationsUsers = computed(() => props.conversations.data.filter(item => item.type === "user"))

// it's an identifier concatenated with group_type, group_id and user_id `${contactGroup.entity_type}_${contactGroup.entity_id}_${c.user_id}`
const currentChatId = computed(() => {
    const conversation = currentConversation.value?.currentConversation

    return conversation
        ? `${conversation.entityType}_${conversation.entityId}_${conversation.userId}`
        : null
})


const displayedUserMsg = computed(() => messages.value)
const resultQuery = computed(() => {
    if (searchMessages.value) {
        const search = searchMessages.value.toLowerCase();

        return messages.value.filter((data) => data.message.some((message) => message.msg.toLowerCase().includes(search)))
            .filter(element => {
                let newMsg = Object.assign({}, element);
                newMsg = newMsg.message.filter(message => message.msg.toLowerCase().includes(search));
                return newMsg;
            });
    } else {
        return messages.value;
    }
})

const {
    setIsLoading
} = useRequestProgress()

onMounted(() => {
    const This = this;
    // video call disconnect
    // Array.from(document.querySelectorAll('.call-disconnect')).forEach(function (elem) {
    //     elem.addEventListener('click', function () {
    //         if (document.querySelector('.user-chat .video-content').classList.contains('d-flex')) {
    //             document.querySelector('.user-chat .video-content').classList.remove('d-flex');
    //             document.querySelector('.user-chat .video-content').classList.add('d-none');
    //             document.querySelector('.user-chat .chat-content').classList.add('d-lg-flex');
    //             document.querySelector('.user-chat .chat-content').classList.remove('d-none');
    //             This.running = false;
    //             This.Dtime = 0;
    //         }
    //     });
    // });

    const conversationId = new URLSearchParams(window.location.search).get('conversation');
    if (conversationId) {
        selectConversation(conversationId);
    }
})

const selectConversation = (id) => {
    setIsLoading(true);
    currentConversationId.value = id

    // set `conversation` query argument to page url

    axios.get(`/chat/conversation/${id}`)
        .then(response => {
            console.log(response.data);
            currentConversation.value = response.data;
            currentConversationId.value = id;

            document.querySelector('.user-chat').classList.add('user-chat-show')
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => setIsLoading(false));
}

const getOrCreateConversation = (entityType, entityId, userId) => {
    setIsLoading(true);
    axios.post(`/chat/conversation-get`, {
        entityType: entityType,
        entityId: entityId,
        userId: userId,
    })
        .then(response => {
            console.log(response.data);
            currentConversation.value = response.data;
            messages.value = response.data.messages;
            currentConversationId.value = response.data.currentConversation.id;

            // set `conversation` query argument to page url

            document.querySelector('.user-chat').classList.add('user-chat-show')
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => setIsLoading(false));
}

const copyToClipboard = () => {
    // const itemList = document.querySelector(".chat-conversation-list");
    // const copyMessage = itemList.querySelectorAll(".copy-message");
    // copyMessage.forEach(function (item) {
    //     item.addEventListener("click", function () {
    //         if (item.closest('.chat-conversation-list').id === "users-conversation") {
    //             document.getElementById("copyClipBoard").style.display = "block";
    //         } else {
    //             document.getElementById("copyClipBoardChannel").style.display = "block";
    //         }
    //         const isText = item.closest(".ctext-wrap").children[ 0 ]
    //             ? item.closest(".ctext-wrap").children[ 0 ].children[ 0 ].innerText
    //             : "";
    //         navigator.clipboard.writeText(isText);
    //
    //         setTimeout(() => {
    //             if (item.closest('.chat-conversation-list').id === "users-conversation") {
    //                 document.getElementById("copyClipBoard").style.display = "none";
    //             } else {
    //                 document.getElementById("copyClipBoardChannel").style.display = "none";
    //             }
    //         }, 1000);
    //     });
    // });
}

</script>

<template>
    <div class="chat-wrapper d-lg-flex gap-1 mb-3 rounded-3">
        <!-- left side panel ( chats & contacts ) END -->
        <div class="chat-wrapper-menu p-3 d-flex flex-column rounded">
            <div class="mb-3 position-relative d-none d-lg-block">
                <img v-if="userAvatar" :src="userAvatar" alt="" class="avatar-sm rounded-circle"/>
                <div v-else>
                    {{ userAvatarAcronym }}
                </div>
<!--                <span class="position-absolute top-0 start-100  translate-middle badge border border-light rounded-circle bg-success p-1">-->
<!--                    <span class="visually-hidden">unread messages</span>-->
<!--                </span>-->
            </div>
            <ul class="chat-menu list-unstyled text-center nav nav-pills justify-content-center">
                <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Чати">
                    <b-link href="#pills-chats" data-bs-toggle="pill" class="nav-link active" id="tab-button-chats">
                        <i class="bi bi-chat-dots"></i>
                    </b-link>
                    <b-tooltip target="tab-button-chats" triggers="hover" placement="bottom">
                        Чати
                    </b-tooltip>
                </li>
                <li class="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-trigger="hover" title="Контакти">
                    <b-link href="#pills-contacts" data-bs-toggle="pill" class="nav-link" id="tab-button-contacts">
                        <i class="bi bi-people"></i>
                    </b-link>
                    <b-tooltip target="tab-button-contacts" triggers="hover" placement="bottom">
                        Контакти
                    </b-tooltip>
                </li>
            </ul>
<!--            <StatusDropdownMenu />-->
        </div>
        <!-- left side panel ( chats & contacts ) END -->

        <!-- chats & contacts panel -->
        <div class="chat-leftsidebar">
            <div class="tab-content">
                <div class="tab-pane fade show active" id="pills-chats">
                    <ChatConversationsList
                        :conversations="conversations"
                        :current-conversation-id="currentConversationId"
                        :current-chat-id="currentChatId"
                        @action-clicked="({ action, data }) => selectConversation(data)"
                    />
                </div>

                <div class="tab-pane fade" id="pills-contacts">
                    <ChatContactsList
                        :contacts-groups="contactsGroups"
                        :current-conversation-id="currentConversationId"
                        :current-chat-id="currentChatId"
                        @action-clicked="({ action, data }) => getOrCreateConversation(data.entity_type, data.entity_id, data.user_id)"
                    />
                </div>
            </div>
        </div>
        <!-- chats & contacts panel END -->

        <!-- ChatConversation block -->
        <ChatConversation
            v-if="currentConversation"
            :key="currentConversation.currentConversation.id"
            :currentConversation="currentConversation"
            :user-profile="userProfile"
        />
        <div v-else class="user-chat w-100 overflow-hidden d-flex justify-content-center align-items-center">
            <div class="fs-5 text-body-secondary text-center">
                <i class="bi bi-chat-square"></i>
                <div>Оберіть чат</div>
            </div>
        </div>
        <!-- ChatConversation block END -->
    </div>

    <!-- User or chat info offcavas -->
    <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="userProfileCanvasExample">
        <div class="offcanvas-body profile-offcanvas p-0">
            <div class="p-1 pt-0">
                <div class="team-settings">
                    <b-row class="g-0">
                        <b-col>
                            <div class="btn nav-btn">
                                <b-button type="button" variant="none" class="btn-close" data-bs-dismiss="offcanvas"
                                          aria-label="Close"></b-button>
                            </div>
                        </b-col>
                        <b-col cols="auto">
                            <div class="user-chat-nav d-flex">

                                <div class="dropdown">
                                    <b-link class="btn nav-btn" href="javascript:void(0);" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                        <i class="ri-more-2-fill"></i>
                                    </b-link>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <b-link class="dropdown-item" href="javascript:void(0);"><i
                                                class="ri-inbox-archive-line align-bottom text-muted me-2"></i>Archive
                                            </b-link>
                                        </li>
                                        <li>
                                            <b-link class="dropdown-item" href="javascript:void(0);"><i
                                                class="ri-mic-off-line align-bottom text-muted me-2"></i>Muted
                                            </b-link>
                                        </li>
                                        <li>
                                            <b-link class="dropdown-item" href="javascript:void(0);"><i
                                                class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>Delete
                                            </b-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>

            </div>
            <div class="p-3 text-center">
                <img :src="userAvatar" alt="" class="avatar-lg img-thumbnail rounded-circle mx-auto profile-img">
                <div class="mt-3">
                    <h5 class="fs-16 mb-1">
                        <b-link href="#" class="link-primary username">
                            {{ currentConversation?.currentConversation?.name }}
                        </b-link>
                    </h5>
                    <p v-if="false" class="text-muted">
                        <i class="ri-checkbox-blank-circle-fill me-1 align-bottom text-success"></i>
                        Online
                    </p>
                </div>

                <div v-if="false" class="d-flex gap-2 justify-content-center">
                    <button type="button" class="btn btn-ghost-secondary avatar-xs p-0">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-question-answer-line"></i>
                        </span>
                    </button>

                    <button type="button" class="btn btn-ghost-secondary avatar-xs p-0">
                            <span class="avatar-title rounded bg-light text-body">
                                <i class="ri-star-line"></i>
                            </span>
                    </button>

                    <button type="button" class="btn btn-ghost-secondary avatar-xs p-0">
                            <span class="avatar-title rounded bg-light text-body">
                                <i class="ri-phone-line"></i>
                            </span>
                    </button>

                    <div class="dropdown">
                        <button class="btn btn-ghost-secondary avatar-xs p-0" type="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="avatar-title bg-light text-body rounded">
                                    <i class="ri-more-fill"></i>
                                </span>
                        </button>

                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <b-link class="dropdown-item" href="javascript:void(0);"><i
                                    class="ri-inbox-archive-line align-bottom text-muted me-2"></i>Archive
                                </b-link>
                            </li>
                            <li>
                                <b-link class="dropdown-item" href="javascript:void(0);"><i
                                    class="ri-mic-off-line align-bottom text-muted me-2"></i>Muted
                                </b-link>
                            </li>
                            <li>
                                <b-link class="dropdown-item" href="javascript:void(0);"><i
                                    class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>Delete
                                </b-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

<!--            <div class="border-top border-top-dashed p-3">-->
<!--                <h5 class="fs-md mb-3">Персональні дані</h5>-->
<!--                <div class="mb-3">-->
<!--                    <p class="text-muted text-uppercase fw-medium mb-1">Номер</p>-->
<!--                    <h6>+(256) 2451 8974</h6>-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                    <p class="text-muted text-uppercase fw-medium mb-1">Email</p>-->
<!--                    <h6>lisaparker@gmail.com</h6>-->
<!--                </div>-->
<!--            </div>-->

            <div class="border-top border-top-dashed p-3">
                <h5 class="fs-md mb-3">Файли чату</h5>

                <div v-if="false" class="vstack gap-2">
                    <b-card no-body class="p-2 border mb-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 avatar-xs ms-1 me-3">
                                <div class="avatar-title bg-primary-subtle text-primary rounded-circle">
                                    <i class="bi bi-file-earmark-richtext"></i>
                                </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="fs-sm fw-medium text-truncate mb-1">App pages.zip</h5>
                                <p class="text-muted fs-xs mb-0">2.5 MB</p>
                            </div>

                            <div class="flex-shrink-0 ms-3">
                                <div class="d-flex gap-2">
                                    <div>
                                        <b-link href="#" class="text-muted px-1">
                                            <i class="bi bi-download"></i>
                                        </b-link>
                                    </div>
                                    <div class="dropdown">
                                        <b-link class="text-muted px-1" href="#" role="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                        </b-link>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Share <i class="bi bi-share ms-2 text-muted"></i></b-link>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Bookmark <i
                                                class="bi bi-bookmarks text-muted ms-2"></i></b-link>
                                            <div class="dropdown-divider"></div>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Delete <i class="bi bi-trash3 ms-2 text-muted"></i></b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>

                    <b-card no-body class="p-2 border mb-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 avatar-xs ms-1 me-3">
                                <div class="avatar-title bg-primary-subtle text-primary rounded-circle">
                                    <i class="bi bi-file-earmark-richtext"></i>
                                </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="fs-sm fw-medium text-truncate mb-1">Images.zip</h5>
                                <p class="text-muted fs-xs mb-0">1.2MB</p>
                            </div>

                            <div class="flex-shrink-0 ms-3">
                                <div class="d-flex gap-2">
                                    <div>
                                        <b-link href="#" class="text-muted px-1">
                                            <i class="bi bi-download"></i>
                                        </b-link>
                                    </div>
                                    <div class="dropdown">
                                        <b-link class="text-muted px-1" href="#" role="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                        </b-link>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Share <i class="bi bi-share ms-2 text-muted"></i></b-link>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Bookmark <i
                                                class="bi bi-bookmarks text-muted ms-2"></i></b-link>
                                            <div class="dropdown-divider"></div>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Delete <i class="bi bi-trash3 ms-2 text-muted"></i></b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>

                    <b-card no-body class="p-2 border mb-0">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 avatar-xs ms-1 me-3">
                                <div class="avatar-title bg-primary-subtle text-primary rounded-circle">
                                    <i class="bi bi-file-earmark-richtext"></i>
                                </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="fs-sm fw-medium text-truncate mb-1">bg-pattern.png</h5>
                                <p class="text-muted fs-xs mb-0">1.1MB</p>
                            </div>

                            <div class="flex-shrink-0 ms-3">
                                <div class="d-flex gap-2">
                                    <div>
                                        <b-link href="#" class="text-muted px-1">
                                            <i class="bi bi-download"></i>
                                        </b-link>
                                    </div>
                                    <div class="dropdown">
                                        <b-link class="text-muted px-1" href="#" role="button" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                        </b-link>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Share <i class="bi bi-share ms-2 text-muted"></i></b-link>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Bookmark <i
                                                class="bi bi-bookmarks text-muted ms-2"></i></b-link>
                                            <div class="dropdown-divider"></div>
                                            <b-link
                                                class="dropdown-item d-flex align-items-center justify-content-between"
                                                href="#">Delete <i class="bi bi-trash3 ms-2 text-muted"></i></b-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>

                    <div class="text-center mt-2">
                        <b-button type="button" variant="danger">Показати ще <i
                            class="ri-arrow-right-fill align-bottom ms-1"></i></b-button>
                    </div>
                </div>
                <div v-else>Ця функція стане доступною найближчим часом</div>
            </div>
        </div>
    </div>

    <!-- User or chat info offcavas -->
<!--    <b-modal v-model="inviteMembersModal" no-body hide-footer body-class="p-4" content-class="border-0"-->
<!--             header-class="p-3 ps-4 bg-primary-subtle" title="Add Participant" class="modal fade"-->
<!--             id="inviteMembersModal" tabindex="-1" aria-labelledby="inviteMembersModalLabel" aria-hidden="true">-->
<!--        <div class="search-box mb-3">-->
<!--            <input type="text" class="form-control bg-light border-light" placeholder="Search here...">-->
<!--            <i class="ri-search-line search-icon"></i>-->
<!--        </div>-->

<!--        <div class="mb-4 d-flex align-items-center">-->
<!--            <div class="me-2">-->
<!--                <h5 class="mb-0 fs-md">Members :</h5>-->
<!--            </div>-->
<!--            <div class="avatar-group justify-content-center">-->
<!--                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip"-->
<!--                   data-bs-trigger="hover" data-bs-placement="top" title="Tonya Noble">-->
<!--                    <div class="avatar-xs">-->
<!--                        <img :src="avatar10" alt="" class="rounded-circle img-fluid">-->
<!--                    </div>-->
<!--                </a>-->
<!--                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip"-->
<!--                   data-bs-trigger="hover" data-bs-placement="top" title="Thomas Taylor">-->
<!--                    <div class="avatar-xs">-->
<!--                        <img :src="avatar8" alt="" class="rounded-circle img-fluid">-->
<!--                    </div>-->
<!--                </a>-->
<!--                <a href="javascript: void(0);" class="avatar-group-item" data-bs-toggle="tooltip"-->
<!--                   data-bs-trigger="hover" data-bs-placement="top" title="Nancy Martino">-->
<!--                    <div class="avatar-xs">-->
<!--                        <img :src="avatar2" alt="" class="rounded-circle img-fluid">-->
<!--                    </div>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="mx-n4 px-4" style="max-height: 225px;">-->
<!--            <div class="vstack gap-3">-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <img :src="avatar2" alt="" class="img-fluid rounded-circle">-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Nancy-->
<!--                            Martino</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <div class="avatar-title bg-danger-subtle text-danger rounded-circle">-->
<!--                            HB-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Henry Baird</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <img :src="avatar3" alt="" class="img-fluid rounded-circle">-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Frank Hook</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <img :src="avatar4" alt="" class="img-fluid rounded-circle">-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Jennifer-->
<!--                            Carter</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <div class="avatar-title bg-success-subtle text-success rounded-circle">-->
<!--                            AC-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Alexis-->
<!--                            Clarke</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--                <div class="d-flex align-items-center">-->
<!--                    <div class="avatar-xs flex-shrink-0 me-2">-->
<!--                        <img :src="avatar3" alt="" class="img-fluid rounded-circle">-->
<!--                    </div>-->
<!--                    <div class="flex-grow-1">-->
<!--                        <h5 class="fs-md mb-0"><a href="javascript:void(0);" class="text-body d-block">Joseph-->
<!--                            Parker</a></h5>-->
<!--                    </div>-->
<!--                    <div class="flex-shrink-0">-->
<!--                        <button type="button" class="btn btn-light btn-sm">Add</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; end member item &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; end list &ndash;&gt;-->
<!--        </div>-->
<!--        <div class="mt-3 text-end">-->
<!--            <button type="button" class="btn btn-light w-xs me-2" data-bs-dismiss="modal">Cancel</button>-->
<!--            <button type="button" class="btn btn-primary w-xs">Add Participant</button>-->
<!--        </div>-->
<!--    </b-modal>-->
</template>

<style lang="scss">
.chat-leftsidebar {
    .contact-list__dropdown-icon {
        width: 18px;
        text-align: center;
        transition: 0.2s;
    }
    .not-collapsed > .contact-list__dropdown-icon {
        transform: rotate(-180deg);
    }
}
</style>
