<script setup>
import masterClass from "../../../../images/tmp/MasterClass/masterclass.png";
import CountdownTimer from "@/Components/CountdownTimer.vue";
import InputGroup from "@/Components/InputGroup.vue";

const description =
    "Спільно з New Strategies Group ми готуємо серію інструментальних заходів з користування інструментом Adobe Photoshop. Майстер-класи проходять в форматі онлайн, і є орієнтованими на тих, хто лише починає розбиратись з цим інструментом.";
</script>

<template>
    <div class="video-info">
        <b-img
            :src="masterClass"
            title="title"
            class="ms-2 w-50"
            alt="title"
        ></b-img>
        <div class="video-info-speaker mt-4">
            <b-avatar variant="info" text="BK"></b-avatar>
            <div class="d-flex flex-column align-items-start ms-2 h-100">
                <div class="video-info-speaker__label p-1">Спікер</div>
                <div class="video-info-speaker__name p-1">Bogdan Korotkyi</div>
            </div>
        </div>
        <div class="video-info-title my-4">
            Майстер-клас: Photoshop для початківців
        </div>
        <div class="video-info-description">
            <span>Що ви дізнаєтесь на майстеркласі?</span>
            <div>{{ description }}</div>
            <div class="description-fade"></div>
        </div>
        <div class="video-info-dates">
            <div>Середа, 6 вересня, 19:00</div>
            <div class="ms-3">Тривалість: 1:30 год</div>
        </div>
        <CountdownTimer :date-time="'2024.01.22 2:00:00'" class="mt-4" />
    </div>
</template>

<style lang="scss" scoped>
.video-info {
    color: var(--white, #fff);
    background: #0f241a;
    width: auto;
    height: 100%;
    padding: 80px 0 0 120px;
    .video-info-speaker {
        display: flex;
        .video-info-speaker__label {
            color: var(--white, #fff);
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 10px */
            opacity: 0.5;
        }
        .video-info-speaker__name {
            color: var(--white, #fff);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
        }
    }
    .video-info-title {
        color: var(--white, #fff);
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 130%;
    }
    .video-info-description {
        width: 65%;
        padding-bottom: 20px;
        border-bottom: 1px solid #fff;
        margin-bottom: 20px;
        position: relative;
        span {
            color: var(--white, #fff);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            opacity: 0.5;
        }
        div {
            color: var(--white, #fff);
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
        }
        .description-fade {
            position: absolute;
            bottom: 15px;
            height: 37px;
            background: linear-gradient(
                0deg,
                #0f241a 0%,
                rgba(15, 36, 26, 0) 100%
            );
            width: 100%;
        }
    }
    .video-info-dates {
        display: flex;
        color: var(--white, #fff);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%; /* 14px */
    }
}
</style>
