<script setup>

import dummyUserImage from "../../../images/users/user-dummy-img.jpg";
import {messageDate} from "@/mixins/messageDate.js";
import {ref} from "vue";

const props = defineProps({
    message: {
        type: Object,
        required: true,
    },
    my: {
        type: Boolean,
        default: true
    },
    allowedActions: {
        type: Object,
        default: () => {
            return {
                delete: false,
                edit: false,
            }
        }
    }
})

const emit = defineEmits(["message-action"]);

const userAvatar = ref()

</script>

<template>
    <li
        class="chat-list"
        :class="{
            right: my,
            left: !my,
        }"
        :id="message.id"
    >
        <div class="conversation-list">
            <div class="chat-avatar" v-if="!my">
                <img :src="userAvatar ? userAvatar : dummyUserImage" alt="">
            </div>
            <div class="user-chat-content">
                <div class="ctext-wrap">
                    <div class="ctext-wrap-content">
                        <strong v-if="!my" class="name text-bold">{{ message.user_name }}</strong>
                        <div
                            v-if="message.reply_username"
                            class="replymessage-block mb-0 d-flex align-items-start"
                        >
                            <div class="flex-grow-1">
                                <h5 class="conversation-name">
                                    {{ message.reply_username }}
                                </h5>
                                <p class="mb-0">{{ message.reply_msg }}</p>
                            </div>
                        </div>
                        <p class="mb-0 mt-1 ctext-content">{{ message.content }}</p>

                        <div v-if="message.attachments && message.attachments.length > 0" class="message-attachments">
                            <div v-for="attach of message.attachments" class="message-attachments_item">
                                <template v-if="attach.type === 'PHOTO'">
                                    <a :href="attach.url" target="_blank">
                                        <img
                                            :src="attach.url"
                                            :alt="attach.file.name"
                                            :title="attach.file.original_name"
                                            style="max-width: 200px"
                                        >
                                    </a>
                                </template>
                                <template v-else>
                                    <a :href="attach.url" target="_blank" style="color: #fff;">
                                        <i class="bx bx-file"></i> {{ attach.file.original_name }}
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-if="allowedActions.delete" class="dropdown align-self-start message-box-drop">
                        <b-link
                            class="dropdown-toggle" href="#" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i class="bi bi-three-dots-vertical"></i>
                        </b-link>
                        <div class="dropdown-menu" style="">
                            <!--                                                            <b-link class="dropdown-item reply-message" href="#">-->
                            <!--                                                                <i class="bi bi-reply me-2 text-muted"></i>Відповісти-->
                            <!--                                                            </b-link>-->
                            <!--                                                            <b-link class="dropdown-item copy-message" href="#">-->
                            <!--                                                                <i-->
                            <!--                                                                    class="bi bi-clipboard-check me-2 text-muted"></i>Копіювати текст-->
                            <!--                                                            </b-link>-->
                            <!--                              <b-link class="dropdown-item" href="#">-->
                            <!--                                <i class="bi bi-bookmarks me-2 text-muted"></i>Pin-->
                            <!--                              </b-link>-->
                            <b-link v-if="allowedActions.delete" class="dropdown-item delete-item" href="#" @click="() => $emit('message-action', {
                                action: 'delete',
                                data: message.id
                            })">
                                <i class="bi bi-trash3 me-2 text-muted"></i>Видалити
                            </b-link>
                        </div>
                    </div>
                </div>
                <div class="conversation-name">
                    <small class="text-muted time">{{ messageDate(message.created_at) }}</small>
                    <span class="text-success check-message-icon">
                        <i class="bx bx-check-double"></i>
                    </span>
                </div>
            </div>
        </div>
    </li>
</template>

<style scoped lang="scss">

</style>
