<script setup>
import {Link} from "@inertiajs/vue3";
import AppMenuSubmenu from "@/Components/AppMenuSubmenu.vue";
import {ref, watch, nextTick, computed} from "vue";

const props = defineProps({
    item: Object
})

const emit = defineEmits(['submenu-toggled'])

const isSubMenuVisible = ref(false);

const showSubmenu = () => {
  if (!isSubMenuVisible.value) {
    isSubMenuVisible.value = true
  }
};

const isContainActiveMenu = (activeRoute, subItems) => {
    if (!(subItems && subItems.length > 0)) return false;

    let isActive = subItems.every((item) => {
        let activeSub = false;
        if (item.subItems && item.subItems.length > 0) {
            activeSub = isContainActiveMenu(activeRoute, item.subItems);
        }

        if (!activeSub) {
            const foundItem = subItems.find((item) => item.link === activeRoute)
            return !!foundItem;
        }

        return activeSub
    })

    return isActive;
};

watch(() => isSubMenuVisible.value, (newVal) => {
  nextTick(() => emit('submenu-toggled', newVal))
})

const canBeBlocked = computed(() => props.item.id !== "dashboard" && props.item.id !== "support")

</script>

<template>
    <li class="menu-title" v-if="item.isHeader">
        <span :data-key="item.label">{{ $t(item.label) }}</span>
    </li>

    <!-- for without sub-items -->
    <li class="nav-item" v-if="!item.isHeader && !item.subItems" :class="{'nav-item-main' : !canBeBlocked}">
        <Link
            :href="item.link" class="nav-link menu-link"
            :class="{ 'active': $page.url === item.link}"
        >
            <i :class="item.icon"></i>
            <span :data-key="item.label" >
                {{ $t(item.label) }}
            </span>
        </Link>
    </li>

    <!-- for subitems -->
    <li class="nav-item" v-if="!item.isHeader && item.subItems" :class="{'nav-item-main' : !canBeBlocked}">
        <span
            class="nav-link menu-link"
            :class="{ 'active': $page.url === item.link || isContainActiveMenu($page.url, item.subItems)}"
            @click="() => showSubmenu()"
        >
            <i :class="item.icon" v-if="item.icon"></i>
            <span :data-key="item.label">{{ $t(item.label) }}</span>
        </span>
        <Teleport to="body">
            <AppMenuSubmenu :item="item" v-model="isSubMenuVisible" />
        </Teleport>
    </li>
</template>

<style scoped>

</style>
