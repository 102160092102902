<script setup>
import {
  LESSON_TASK_FILE,
  LESSON_TASK_MATCHING,
  LESSON_TASK_TEST,
  LESSON_TASK_TRUE_FALSE, LESSON_TASK_VIDEO
} from '@/Pages/Courses/Partials/lessonTypes.js';
import broadcast from "../../../../images/svg/lesson-icons/broadcast.svg";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import testing from "../../../../images/svg/lesson-icons/testing.svg";
import addFile from "../../../../images/svg/lesson-icons/add-file.svg";
import trueFalse from "../../../../images/svg/lesson-icons/true-false.svg";
import matching from "../../../../images/svg/lesson-icons/matching.svg";
import {useI18n} from "vue-i18n";

const icon = {
  'LESSON_TYPE_WITH_TRANSLATION': broadcast,
  'LESSON_TASK_VIDEO': addVideo,
  'LESSON_TASK_TEST': testing,
  'LESSON_TASK_FILE': addFile,
  'LESSON_TASK_TRUE_FALSE': trueFalse,
  'LESSON_TASK_MATCHING': matching,
}

const props = defineProps({
  task: Object,
})

const emit = defineEmits([
  'delete-task',
  'edit-task'
])

const {t} = useI18n();

const deleteTask = () => {
  emit('delete-task');
}

</script>

<template>
  <div class="lesson-task">
<!--    <div v-for="task in tasks" class="lesson-task">-->
      <img :src="icon[task.type]" title="" alt="" />
      <div>
        <div class="lesson-task__lable">{{t(task.type)}}</div> <!-- Завдання -->
        <div class="lesson-task__title">{{ task.title ? task.title : task.type === "LESSON_TASK_FILE" ? task.file['original_name'] || 'Без назви' : task.video['original_name'] || 'Без назви' }}</div>
<!--        <div class="lesson-task__title">{{ task.type === "LESSON_TASK_FILE" ? task.file['original_name'] : task.title }}</div>-->
      </div>
      <div class="course-lesson-task__menu">
<!--        <b-button variant="text">-->
<!--          <i class="bi bi-pencil-square"></i>-->
<!--        </b-button>-->
<!--        <b-button variant="text" @click="deleteTask">-->
<!--          <i class="bi bi-trash"></i>-->
<!--        </b-button>-->
<!--      </div>-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.lesson-task {
  font-family: e-Ukraine;
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #EBEBEB;
  img {
      width: 20px;
      height: 20px;
  }
  &:last-child {
    border:none;
  }
  .lesson-task__lable {
    color: var(--dark, #1E1E1E);
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    opacity: 0.5;
  }
  .lesson-task__title {
    color: var(--dark, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }
}
</style>
