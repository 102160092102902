<script setup>
import {computed, onMounted, ref, triggerRef, watch} from "vue";
import WeekDayItem from "@/Components/Schedule/WeekDayItem.vue";
import {router} from "@inertiajs/vue3";
import {useDateFormatter} from "@/composables/useDateFormatter.js";
import axios from "axios";
import {toast} from "vue3-toastify";
import useRequestProgress from "@/composables/useRequestProgress.js";

const props = defineProps({
    modelValue: Boolean,
    specificDatesSchedules: Object,
    selectedDate: {
        type: String,
        default: null
    }
});

const emit = defineEmits([
    'update:modelValue'
]);

const { setIsLoading, isLoading } = useRequestProgress()

const isVisible = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const firstLoad = ref(true)

const fields = ref({
    title: '',
    availableHours: props.specificDatesSchedules.fields || []
})

const getMentorSpecificDay = async () => {
    setIsLoading(true)
    return axios.post(route('mentoring.getMentorSpecificDay'), {
        date:  props.selectedDate
    })
        .then(response => {
            fields.value.availableHours = response.data.fields;
            return response.data.data;
        })
        .catch(error => {
            console.error(error);
            return [];
        })
        .finally(() => {
            setIsLoading(false)
            firstLoad.value = false
        })
}

onMounted(() => {
    getMentorSpecificDay();
});

const saveSpecificDates = () => {
    router.visit(route('mentoring.saveSpecificDates'),
        {
            method: 'post',
            data: {'attributes': props.selectedDate, 'fields': fields.value.availableHours},
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: () => {
                props.modelValue = !props.modelValue;
            }
        }
    );
}

const {formatDate} = useDateFormatter()

</script>

<template>
    <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3 class="fs-5">Налаштуйте свій розклад: {{ formatDate(selectedDate) }}</h3>
                <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
            </div>
            <p v-if="!firstLoad">
                {{
                    fields && fields.availableHours && !(fields.availableHours.length > 0) ? 'Якшо ви не додасте жодного слоту, то цей день буде вільним.' : ''
                }}
            </p>
            <p v-else>Почекайте, оновлюємо данні</p>
            <WeekDayItem v-if="!firstLoad" :day="fields"/>
            <b-button variant="primary" size="md" pill class="mt-3 float-end" :disabled="isLoading"
                      @click="saveSpecificDates">
                Зберегти
            </b-button>
        </div>
    </b-modal>
</template>
<style scoped lang="scss">
.custom-calendar {
    display: flex;
    justify-content: center;

    & :deep(.vc-weekday) {
        color: var(--white, #FFF);
        background: var(--dark, #1E1E1E);
        padding: 12px 0;

        &:first-child {
            border-radius: 8px 0 0 0;
        }

        &:last-child {
            border-radius: 0 8px 0 0;
        }
    }

    & :deep(.vc-weeks) {
        border-radius: 8px;
    }

    & :deep(.vc-day-content) {
        border-radius: 0;
        width: 60px;
        height: 60px;
        border: 1px solid var(--stroke, #EBEBEB);
    }

    & :deep(.vc-highlight-content-solid) {
        background: var(--green, #1DB954);
    }

    & :deep(.vc-header .vc-title) {
        background: transparent;
    }

    & :deep(.vc-arrow) {
        border-radius: 8px;
        width: 50px;

        svg {
            stroke: var(--green, #1DB954);
        }
    }

    & :deep(.vc-weeks) {
        margin-top: 16px;
    }
}
</style>
