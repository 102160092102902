<script setup>
import InputGroup from "@/Components/InputGroup.vue";
import {computed, ref, watch} from 'vue'
import useVuelidate from '@vuelidate/core'
import {helpers, required, url} from '@vuelidate/validators'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isReadOnly: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:item', 'remove'])

const form = ref({ title: '', url: '', ...props.item })

const urlValidator = helpers.withMessage("URL не є валідним", url)

const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const titleValidator = (value) => {
  if (form.value.url && urlRegex.test(form.value.url)) {
    return !!value.trim()
  }
  return true
}

const urlRequiredValidator = (value) => {
  if (form.value.title && !!form.value.title.trim()) {
    return !!value.trim()
  }
  return true
}

const customRequiredUrl = helpers.withMessage(
    'Це поле є обов\'язковим, коли назва заповнена',
    urlRequiredValidator
)

const rules = computed(() => ({
  url: {
    url: urlValidator,
    required: customRequiredUrl
  },
  title: {
    required: helpers.withMessage('Це поле є обов\'язковим, коли URL валідний', titleValidator)
  }
}))

const v$ = useVuelidate(rules, form)

const updateItem = (key, value) => {
  form.value[key] = value
  v$.value.$touch()
  if (!v$.value.$invalid) {
    emit('update:item', { ...form.value })
  }
}

watch(() => props.item, (newItem) => {
  form.value = { title: '', url: '', ...newItem }
})
</script>

<template>
  <div class="webinar-button">
    <InputGroup
        v-model="form.title"
        @update:modelValue="updateItem('title', $event)"
        label="Назва кнопки"
        class="webinar-button__input"
        size="lg"
        :disabled="isReadOnly"
        :is-error="v$.title.$errors.length > 0"
        :error-message="v$.title.$errors[0]?.$message"
    />
    <InputGroup
        v-model="form.url"
        @update:modelValue="updateItem('url', $event)"
        label="URL посилання"
        class="webinar-button__input"
        size="lg"
        :disabled="isReadOnly"
        :is-error="v$.url.$errors.length > 0"
        :error-message="v$.url.$errors[0]?.$message"
    />
<!--    <b-button variant="text-variant" size="sm" class="webinar-button__remove" role="button" pill aria-expanded="false" @click="$emit('remove')">-->
<!--      <i class="bi bi-trash"></i>-->
<!--    </b-button>-->
  </div>
</template>
<style scoped lang="scss">
.webinar-button {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 16px;
  & > * {
    flex: 1;
  }
  .webinar-button__remove {
    flex: 0;
    font-size: 20px;
    opacity: 0.8;
    transition: color 0.2s ease;
    &:hover {
      color: var(--purple, #F24822);
    }
  }
  .webinar-button__input {
    margin-top: 0!important;
  }
}
</style>
