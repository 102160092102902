import "./bootstrap";
// import '../css/app.css';

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { router } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "./vue.m";
import { Uid, UidPlugin } from "@shimyshack/uid";

import i18n from "./i18n";
import store from "./state/store";
import { vMaska } from "maska";
import "@vueform/multiselect/themes/default.css";

import layout from "./mixins/layouts.mixin";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from "@ckeditor/ckeditor5-vue";
import BootstrapVueNext from "bootstrap-vue-next";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "bootstrap/dist/js/bootstrap";
import CabinetVerticalLayout from "@/Layouts/CabinetVerticalLayout.vue";
// import '../scss/app.scss';
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { useSupportChatStore } from "./stores/supportChatStore";

polyfillCountryFlagEmojis();

const pinia = createPinia();

const appName = import.meta.env.VITE_APP_NAME || "Bestclevers LMS";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = page.default.layout || CabinetVerticalLayout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(store)
            .use(pinia)
            .use(VueApexCharts)
            .use(BootstrapVueNext)
            .use(i18n)
            .use(CKEditor)
            .use(vClickOutside)
            .use(PerfectScrollbar)
            .directive("maska", vMaska)
            .use(UidPlugin)
            .use(VCalendar, {});
        // .directive('uid', Uid)
        // .mixin(layout);

        if (
            import.meta.env.VITE_SENTRY_DSN_PUBLIC &&
            import.meta.env.VITE_SENTRY_DSN_PUBLIC.length > 0
        ) {
            console.log("Sentry.init", import.meta.env.VITE_SENTRY_DSN_PUBLIC);
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                integrations: [
                    // Sentry.browserTracingIntegration({ router }),
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration(),
                ],
                // Tracing
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/yourserver\.io\/api/,
                ],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }

        app.mount(el);

        return app;
    },
    progress: {
        color: "#4B5563",
        showSpinner: true,
    },
});

document.addEventListener("DOMContentLoaded", function () {
    const supportChatStore = useSupportChatStore();
    supportChatStore.setObserver();
});
