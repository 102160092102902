<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import InputGroup from "@/Components/InputGroup.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import CourseItemCard from "@/Pages/Courses/Partials/Registration/CourseItemCard.vue";
import { reactive } from "vue";

defineOptions({ layout: LoginLayout });

const props = defineProps({
    course: Object,
    isPaid: false,
});

const digits = reactive({
    digit1: null,
    digit2: null,
    digit3: null,
    digit4: null,
});

const form = useForm({
    regFields: props.course.registerFields.data,
    courseId: props.course.id,
    isPaid: props.isPaid,
    testData: "test",
});

const onSubmit = () => {
    form.post(route("courses.register-to-course-store"), {
        // onFinish: () => form.reset(),
    });
};

const onReset = () => {
    form.reset();
};
</script>

<template>
    <div class="course-registration">
        <div class="course-registration__form">
            <!--      <CoursePayment />-->
            <!--      <CourseVerification />-->
            <div
                class="d-flex flex-grow-1 flex-column align-self-stretch justify-content-center align-items-center mx-4"
            >
                <Head title="Реєстрація на курс" />

                <div class="form-login-header text-center">
                    <h1 class="text-md login-title">Зареєструватись</h1>
                </div>
                <div class="my-2">
                    <InputGroup
                        v-for="field in form.regFields"
                        v-model="field.value"
                        :label="field.label"
                        :placeholder="field.label"
                        :error-message="form.errors.name"
                        type="text"
                        required
                        autofocus
                        :autocomplete="field.name"
                        :is-error="!!form.errors.name"
                    />
                </div>
                <div class="d-flex justify-content-center my-4">
                    <b-button
                        :disabled="form.processing"
                        variant="primary"
                        size="lg"
                        pill
                        @click="onSubmit"
                    >
                        Далі
                    </b-button>
                </div>

                <div class="login-bottom-text">
                    Натискаючи на «Зареєструватись», ви погоджуєтесь з нашими
                    <Link>Умовами використання</Link>
                    ,
                    <Link>Політикою конфіденційності</Link>
                    і
                    <Link>Політикою щодо Cookies</Link>
                    .
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-end mt-2"
                >
                    <div class="my-4">
                        Вже є акаунт?
                        <Link :href="'/login'" class="text-sm"> Вхід </Link>
                    </div>
                </div>
            </div>
        </div>
        <div class="course-registration__info">
            <CourseItemCard :course="course" />
        </div>
    </div>
</template>

<style lang="scss">
.course-registration {
    display: flex;
    border-radius: 20px;
    background: var(--white, #fff);
    gap: 33px;
    padding: 34px 44px;
    width: 60%;
    margin: 20px auto;

    .course-registration__form,
    .course-registration__info {
        flex: 1;
    }

    .course-registration__form {
        display: flex;
        align-items: center;
    }
}
</style>
