<script setup>
import { useI18n } from "vue-i18n";
import IncrementInput from "@/Components/form/IncrementInput.vue";
import { computed, ref } from "vue";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import {router, usePage} from "@inertiajs/vue3";
import { addDays, format, addMonths, differenceInDays, parseISO } from "date-fns";
import { uk } from "date-fns/locale";
import card from "@/../images/empty-data/bank_card.png";
import useRequestProgress from "@/composables/useRequestProgress.js";
import shieldLock from "@/../images/billing/shieldLock.png";

const props = defineProps({
    plan: Object,
    tariffs: Object,
});

const { t } = useI18n();

const value = ref(100);

const pageData = usePage();

const isTariffActive = computed(() => pageData?.props?.auth?.isTariffActive && props?.plan?.plan?.isActive) //pageData?.props?.auth?.isTariffActive && props?.plan?.plan?.isActive
const nextPayment = computed(() => props?.plan?.plan?.next_payment)
const isActiveTariff = computed(() => props?.plan?.plan?.isActive)

const isTrial = computed(() => pageData?.props?.auth?.trial_status && pageData.props.auth.trial_status === "STARTED")
const trialStartDate = computed(() => pageData?.props?.auth?.trial_start)

const planInfo = computed(() =>
    props.tariffs.data.find((obj) => obj.id === props.plan.plan.tariff_id)
);

const { formatDate } = useDateFormatter();

const editMode = ref(false);

const countFormatter = (count) => {
    return count && count === -1 ? "Необмежено" : count;
};

const dopForm = ref({
    disk: props.plan.plan.dop.disk + props.plan.plan.diskSpace,
    student: props.plan.plan.dop.student + planInfo.value.student_count,
    manager: props.plan.plan.dop.manager + planInfo.value.manager_count,
    viewer: props.plan.plan.dop.viewer + planInfo.value.viewer_count,
    course: props.plan.plan.dop.course + planInfo.value.course_count,
    mentor_program:
        props.plan.plan.dop.mentor_program +
        planInfo.value.mentor_program_count,
    workshop: props.plan.plan.dop.workshop + planInfo.value.workshop_count,
});

const isMonth = computed(() => props.plan.plan.period === 1);

const planPrice = computed(() =>
    isMonth.value
        ? planInfo.value.price
        : Math.ceil(
              planInfo.value.price * 12 -
                  planInfo.value.price * 12 * (planInfo.value.discount12 / 100)
          )
);
const planPriceUa = computed(() =>
    Math.ceil(planPrice.value * props.plan.plan.courseExchange)
);

const dopClean = computed(() => {
    return {
        disk: dopForm.value.disk - props.plan.plan.diskSpace,
        student: dopForm.value.student - planInfo.value.student_count,
        manager: dopForm.value.manager - planInfo.value.manager_count,
        viewer: dopForm.value.viewer - planInfo.value.viewer_count,
        course: dopForm.value.course - planInfo.value.course_count,
        mentor_program:
            dopForm.value.mentor_program - planInfo.value.mentor_program_count,
        workshop: dopForm.value.workshop - planInfo.value.workshop_count,
    };
});

const dopPrice = computed(() => {
    return {
        disk:
            dopClean.value.disk > 0
                ? dopClean.value.disk *
                  props.plan.plan.dop_price.disk *
                  props.plan.plan.period
                : 0,
        student:
            dopClean.value.student > 0
                ? dopClean.value.student *
                  props.plan.plan.dop_price.student *
                  props.plan.plan.period
                : 0,
        manager:
            dopClean.value.manager > 0
                ? dopClean.value.manager *
                  props.plan.plan.dop_price.manager *
                  props.plan.plan.period
                : 0,
        viewer:
            dopClean.value.viewer > 0
                ? dopClean.value.viewer *
                  props.plan.plan.dop_price.viewer *
                  props.plan.plan.period
                : 0,
        course:
            dopClean.value.course > 0
                ? dopClean.value.course *
                  props.plan.plan.dop_price.course *
                  props.plan.plan.period
                : 0,
        mentor_program:
            dopClean.value.mentor_program > 0
                ? dopClean.value.mentor_program *
                  props.plan.plan.dop_price.mentor_program *
                  props.plan.plan.period
                : 0,
        workshop:
            dopClean.value.workshop > 0
                ? dopClean.value.workshop *
                  props.plan.plan.dop_price.workshop *
                  props.plan.plan.period
                : 0,
    };
});

const dopPriceSum = computed(() =>
    Object.values(dopPrice.value).reduce((acc, value) => acc + value, 0)
);

const dopPriceSumUa = computed(() =>
    Math.ceil(dopPriceSum.value * props.plan.plan.courseExchange)
);

const handleSaveDops = () => {
    console.log("Зберігти допи: ", dopClean.value);
    editMode.value = false; //TODO: прибрати коди буде додано обробку нижче
    router.visit(route('billing.saveDop', { tariffId: props.plan.plan.id }), //TODO: додати вірний роут та параметер якщо потрібно
        {
            method: 'post',
            data: dopClean.value,
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: (response) => {
                //TODO: зробити обобку якщо потрібно
                console.log('billing.subscribe', response)
                editMode.value = false
            }
        }
    )
};

const {
    setIsLoading
} = useRequestProgress()

const emailForCancelPlan = ref(null);

const handleCancelPlan = () => {
    setIsLoading(true);
    emailForCancelPlan.value = null;
    axios.post(route('billing.cancel', { tariffId: planInfo.value.id }))
        .then((response) => {
            if (response && response.data && response.data.email) emailForCancelPlan.value = response.data.email;
            cancelPlanModalDone.value = true
        })
        .catch((error) => {
            console.error("Error:", error);
        })
        .finally(() => {
            setIsLoading(false)
            cancelPlanModal.value = false;
        })

};

const cancelPlanModal = ref(false);
const cancelPlanModalDone = ref(false);

// const today = new Date();
// const targetDate = new Date(props.plan.plan.validUntil);
// const dateAfter30Days = addDays(targetDate, 30);

const maskedEmail = computed(() => {
    if (!emailForCancelPlan.value) {
        return null;
    }

    const [localPart, domain] = emailForCancelPlan.value.split('@');

    if (localPart.length <= 3) {
        return `${localPart}@${domain}`;
    }

    const maskedLocalPart = `${localPart.slice(0, 2)}****${localPart.slice(-1)}`;
    return `${maskedLocalPart}@${domain}`;
});

</script>

<template>
<!--        <pre>{{dopPrice}}</pre>-->
<!--        <pre>{{plan}}</pre>-->
    <!--    {{dopPriceSum}}-->
    <div class="current-plan">
        <div class="current-plan__card">
            <div class="plan-info">
                <div
                    class="d-flex flex-column align-items-center justify-content-center gap-4 h-100"
                >
                    <div class="d-flex flex-column align-items-center">
                        <div class="plan-label">Активний пакет</div>
                        <div class="plan-name">{{ planInfo.name }}</div>
                    </div>
                    <div v-if="isTariffActive" class="plan-info__body">
                        <div class="plan-info__price">
                            {{
                                isMonth
                                    ? "Вартість на місяць"
                                    : "Вартість на рік"
                            }}
                            <span>{{ planPrice }}$</span>
                            <span class="price__ua"
                                >({{ planPriceUa }}грн)</span
                            >
                        </div>
                        <div v-if="isTrial" class="plan-info_expiration-date d-flex flex-column align-items-center text-center">
                            Наразі діє пробний період. Наступне списання по тарифу:
                            <span>{{
                                    format(addDays(parseISO(trialStartDate), 14), "dd.MM.yyyy", { locale: uk })
                                }}</span>
                        </div>
                        <div v-else class="plan-info_expiration-date">
                            Оплачений до:
                            <span>{{
                                formatDate(plan.plan.validUntil, "dd.MM.yyyy")
                            }}</span>
                        </div>
                        <div v-if="dopPriceSum" class="plan-info__dops">
                            Вартість додаткових послуг:
                            <span
                                >{{ dopPriceSum }}$ ({{
                                    dopPriceSumUa
                                }}грн)*</span
                            >
                            <span class="dops__desc"
                                >*Під час Beta тестування безкоштовно</span
                            >
                        </div>
                    </div>
                    <div v-else class="plan-info__body">
                        <div v-if="!isActiveTariff" class="plan-info__expired">
                            У вас залишилося {{ differenceInDays(parseISO(nextPayment), new Date())}} дні передплати.
                            Після {{ format(parseISO(nextPayment), 'd MMMM yyyy', { locale: uk }) }} р. дані на платформі можуть бути незворотно видалені.
                            <div class="expired-divider w-100"></div>
                            Поновіть тариф, щоб зберегти всі дані
                        </div>
                        <div v-else class="plan-info__expired">
                            У вас залишилося {{ differenceInDays(addMonths(parseISO(nextPayment), 1), new Date())}} днів для оплати.
                            Після {{ format(addMonths(parseISO(nextPayment), 1), 'd MMMM yyyy', { locale: uk }) }} р. дані на платформі можуть бути незворотно видалені.
                            <div class="expired-divider w-100"></div>
                            Поновіть тариф, щоб зберегти всі дані
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-config">
                <div class="plan-config__header">
                    Можливості пакету:
                    <div class="plan-config__actions">
                        <!--                        <button v-if="!editMode" class="plan-config__btn" @click="editMode = true">-->
                        <!--                            <i class="bi bi-pencil-square"></i>-->
                        <!--                        </button>-->
                        <!--                        <button v-else class="plan-config__btn btn-green" @click="handleSaveDops">-->
                        <!--                            Збер.-->
                        <!--                        </button>-->
                        <!--                        <button class="plan-config__btn">-->
                        <!--                            <i class="bi bi-gear"></i>-->
                        <!--                        </button>-->
                        <b-button
                            v-if="!editMode"
                            variant="link"
                            size="sm"
                            class="btn-icon dropdown-menu__button"
                            role="button"
                            pill
                            aria-expanded="false"
                            :disabled="!isActiveTariff || !isTariffActive"
                            @click="editMode = true"
                        >
                            <i class="bi bi-pencil-square"></i>
                        </b-button>
                        <b-button
                            v-else
                            variant="link"
                            size="sm"
                            class="btn-icon dropdown-menu__button"
                            role="button"
                            pill
                            aria-expanded="false"
                            :disabled="!isActiveTariff || !isTariffActive"
                            @click="handleSaveDops"
                        >
                            Збер.
                        </b-button>
                        <div class="dropdown flex-shrink-0">
                            <b-button
                                variant="link"
                                size="sm"
                                class="btn-icon dropdown-menu__button"
                                role="button"
                                data-bs-toggle="dropdown"
                                pill
                                aria-expanded="false"
                                :disabled="!isActiveTariff"
                            >
                                <i class="bi bi-gear"></i>
                            </b-button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li class="opacity-50">
                                    <button
                                        class="dropdown-item"
                                        :disabled="!isActiveTariff"
                                        @click="cancelPlanModal = true"
                                    >
                                        <i
                                            class="bi bi-trash align-baseline me-1"
                                        ></i>
                                        Скасувати тариф
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <b-modal
                    v-if="cancelPlanModalDone"
                    v-model="cancelPlanModalDone"
                    hide-footer
                    hide-header
                    centered
                >
                    <div class="cancel-plan__modal">
                        <img :src="shieldLock" alt="shield" />
                        <div class="cancel-plan__title">
                            Ми надіслали лист з подальшими інструкціями для скасування підписки на вашу електронну пошту
                        </div>
                        <div class="cancel-plan__desc">
                            {{ emailForCancelPlan ? maskedEmail : ''}}
                        </div>
                        <b-button
                            variant="primary"
                            size="sm"
                            role="button"
                            pill
                            aria-expanded="false"
                            @click="cancelPlanModalDone = false"
                        >
                            ОК
                        </b-button>
                    </div>
                </b-modal>
                <b-modal
                    v-model="cancelPlanModal"
                    id="handleCancelPlanModal"
                    hide-footer
                    hide-header
                    centered
                >
                    <div class="confirm-modal">
                        <div class="confirm-modal__body">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                class="confirm-modal__icon"
                            >
                                <g clip-path="url(#clip0_994_71179)">
                                    <path
                                        d="M21.0002 28.7827C19.826 28.7827 18.8418 29.767 18.8418 30.9412C18.8418 32.1153 19.826 33.0996 21.0002 33.0996C22.1313 33.0996 23.1587 32.1153 23.1069 30.993C23.1587 29.7583 22.1831 28.7827 21.0002 28.7827Z"
                                        fill="#DA7F14"
                                    />
                                    <path
                                        d="M40.978 36.4327C42.3335 34.093 42.3421 31.3043 40.9952 28.9731L27.4748 5.55842C26.1366 3.2014 23.7191 1.80273 21.0081 1.80273C18.2971 1.80273 15.8797 3.21004 14.5414 5.54978L1.00371 28.9904C-0.34315 31.3474 -0.334517 34.1534 1.02962 36.4931C2.37648 38.807 4.7853 40.197 7.47903 40.197H34.4854C37.1878 40.197 39.6138 38.7897 40.978 36.4327ZM38.0425 34.7405C37.2914 36.0356 35.9618 36.804 34.4768 36.804H7.47039C6.00266 36.804 4.68169 36.0528 3.94782 34.7837C3.20532 33.4972 3.19669 31.9604 3.93919 30.6654L17.4769 7.23336C18.2108 5.94693 19.5231 5.18716 21.0081 5.18716C22.4845 5.18716 23.8054 5.95557 24.5393 7.242L38.0684 30.674C38.7936 31.9345 38.785 33.4541 38.0425 34.7405Z"
                                        fill="#DA7F14"
                                    />
                                    <path
                                        d="M20.4632 13.6308C19.4358 13.9243 18.7969 14.8568 18.7969 15.9878C18.8487 16.6698 18.8918 17.3605 18.9436 18.0426C19.0904 20.6414 19.2372 23.1883 19.384 25.7871C19.4358 26.6677 20.1178 27.3066 20.9985 27.3066C21.8791 27.3066 22.5698 26.6246 22.613 25.7353C22.613 25.2 22.613 24.7079 22.6648 24.1639C22.7598 22.4976 22.8634 20.8313 22.9583 19.165C23.0101 18.0858 23.1051 17.0066 23.1569 15.9273C23.1569 15.5388 23.1051 15.1935 22.9583 14.8481C22.518 13.8811 21.4906 13.389 20.4632 13.6308Z"
                                        fill="#DA7F14"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_994_71179">
                                        <rect
                                            width="42"
                                            height="42"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="confirm-modal__text">
                                <div class="confirm-modal__title">
                                    Після скасування плану ви втрачаєте доступ
                                    до:
                                </div>
                                <div class="confirm-modal__desc">
                                    <ul>
                                        <li>
                                            створення та редагування курсів,
                                            воркшопів, вебінарів, автовебінарів
                                            і менторських програм;
                                        </li>
                                        <li>
                                            <b>{{ dopForm.disk }} Гб</b>
                                            простору на <b>Диску</b>;
                                        </li>
                                        <li>
                                            команди та комунікації зі
                                            студентами;
                                        </li>
                                        <li>статистики та прогресу;</li>
                                        <li>розкладу.</li>
                                    </ul>
                                    <div class="warning-text">
                                        Ваш тариф активний до
                                        {{ formatDate(plan.plan.validUntil) }}
                                        р. Після
                                        {{
                                            format(
                                                addDays(
                                                    plan.plan.validUntil,
                                                    30
                                                ),
                                                "d MMMM yyyy",
                                                { locale: uk }
                                            )
                                        }}
                                        р. дані на платформі можуть бути
                                        незворотно видалені.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="confirm-modal__actions confirm-modal__actions--reverse"
                        >
                            <b-button
                                class="confirm-modal__decline"
                                @click="handleCancelPlan"
                            >
                                Все одно скасувати
                            </b-button>
                            <b-button
                                class="confirm-modal__accept"
                                pill
                                @click="cancelPlanModal = false"
                            >
                                Залишити план
                            </b-button>
                        </div>
                    </div>
                </b-modal>
                <div class="plan-config__body">
                    <div class="plan-config__body-left">
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.description") }}:
                                </div>
                                <div class="config-row__text">
                                    {{ planInfo.description }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.disc") }}:
                                </div>
                                <div v-if="!editMode" class="config-row__text">
                                    {{ dopForm.disk }}
                                </div>
                                <IncrementInput
                                    v-else
                                    v-model="dopForm.disk"
                                    :step="5"
                                    :min="plan.plan.diskSpace"
                                />
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.student_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode &&
                                        planInfo.student_count !== -1
                                    "
                                    v-model="dopForm.student"
                                    :step="5"
                                    :min="planInfo.student_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.student) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.manager_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode &&
                                        planInfo.manager_count !== -1
                                    "
                                    v-model="dopForm.manager"
                                    :step="1"
                                    :min="planInfo.manager_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.manager) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.conference_count") }}:
                                </div>
                                <div class="config-row__text">
                                    {{ countFormatter(planInfo.conference_count) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.viewer_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode && planInfo.viewer_count !== -1
                                    "
                                    v-model="dopForm.viewer"
                                    :step="10"
                                    :min="planInfo.viewer_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.viewer) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="plan-config__body-right">
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.support") }}:
                                </div>
                                <div class="config-row__text">Чат</div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.course_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode && planInfo.course_count !== -1
                                    "
                                    v-model="dopForm.course"
                                    :step="1"
                                    :min="planInfo.course_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.course) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.mentor_program_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode &&
                                        planInfo.mentor_program_count !== -1
                                    "
                                    v-model="dopForm.mentor_program"
                                    :step="1"
                                    :min="planInfo.mentor_program_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.mentor_program) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.workshop_count") }}:
                                </div>
                                <IncrementInput
                                    v-if="
                                        editMode &&
                                        planInfo.workshop_count !== -1
                                    "
                                    v-model="dopForm.workshop"
                                    :step="1"
                                    :min="planInfo.workshop_count"
                                />
                                <div v-else class="config-row__text">
                                    {{ countFormatter(dopForm.workshop) }}
                                </div>
                            </div>
                        </div>
                        <div class="plan-config__row">
                            <div class="config-row__data">
                                <div class="config-row__label">
                                    {{ t("billing.key_features") }}:
                                </div>
                                <div class="config-row__text">Необмежено</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment-info__card">
            <div class="card-skin">
                <img
                    :src="plan.cardPreview ? plan.cardPreview : card"
                    alt="card"
                />
                <div v-if="plan.cardNum" class="card-number">
                    {{ plan.cardNum }}
                </div>
            </div>
            <div class="card-desc">
                Ваша картка
                <i id="card-desc__tooltip" class="bi bi-info-circle"></i>
            </div>
            <b-tooltip
                class="text-left"
                target="card-desc__tooltip"
                custom-class="custom-tooltip"
                offset="5"
                triggers="hover"
                :no-fade="true"
                :delay="{ show: 50, hide: 50 }"
            >
                Наразі можливо інтегрувати лише сорочку картки Visa, в іншому
                випадку буде відображано стандартний вигляд картки.
            </b-tooltip>
        </div>
    </div>
</template>

<style scoped lang="scss">
.cancel-plan__modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 16px;
    .cancel-plan__title {
        font-family: e-Ukraine;
        font-size: 22px;
        font-weight: 500;
        line-height: 26.4px;
        text-align: center;
        color: #1E1E1E;
    }
    .cancel-plan__desc {
        font-family: e-Ukraine;
        font-size: 12px;
        font-weight: 300;
        line-height: 21.6px;
        text-align: center;
        color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
    }
    .btn {
        padding: 16px 24px;
        font-family: e-Ukraine;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: center;
        color: #FFF;
        min-width: 150px;
    }
}
.confirm-modal {
    padding: 20px;
    .confirm-modal__body {
        display: flex;
        .confirm-modal__icon {
            width: 42px;
            height: 42px;
        }
        .confirm-modal__text {
            margin-left: 20px;
            width: calc(100% - 62px);
            .confirm-modal__title {
                color: #da7f14;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 22px */
                margin-bottom: 12px;
            }
            .confirm-modal__desc {
                color: var(--dark, #1e1e1e);
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 160%; /* 22.4px */
                .warning-text {
                    padding: 12px 17px;
                    border-radius: 4px;
                    border: 1px solid #856404;
                    background: #fff3cd;
                    color: #856404;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 160%;
                }
            }
        }
    }
    .confirm-modal__actions {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        margin-top: 28px;
        &.confirm-modal__actions--reverse {
            justify-content: flex-start;
            flex-direction: row-reverse;
        }
        .confirm-modal__accept {
            margin: 2px;
            display: flex;
            padding: 16px 24px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 50px;
            border: 0;
            background: var(--green, #1db954);
            color: var(--white, #fff);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
        }
        .confirm-modal__accept:hover {
            background-color: var(--green, #1db954);
            opacity: 0.8;
        }
        .confirm-modal__decline {
            margin: 2px;
            display: flex;
            padding: 16px 24px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 50px;
            border: 2px solid var(--dark, #1e1e1e);
            background: var(--white, #fff);
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 13px */
        }
        .confirm-modal__decline:hover {
            opacity: 0.8;
        }
        .confirm-modal__decline:active {
            border: 2px solid var(--dark, #1e1e1e);
            background: var(--white, #fff);
            color: var(--dark, #1e1e1e);
        }
    }
}
</style>
