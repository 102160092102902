<script setup>
import {computed} from "vue";

  const props = defineProps({
    modelValue: Boolean
  });

  const emit = defineEmits([
    'update:modelValue',
    'cancelPlan'
  ]);

  const isVisible = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  });

const handleCancel = () => {
  emit('cancelPlan');
  isVisible.value = !isVisible.value;
}

</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" size="xl" hide-footer hide-header centeredn o-stacking>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>Керування планом</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <div class="d-flex">
        <div class="current-plan">
          <div class="current-plan__label">
            Ваш план
          </div>
          <div class="current-plan__title">
            Безкоштовний
          </div>
          <div class="current-plan__row">
            Диск: <span>до 20гб</span>
          </div>
          <div class="current-plan__row">
            К-ть учасників: <span>до 500 людей</span>
          </div>
          <div class="current-plan__row">
            Вартість <span>Безкоштовно</span>
          </div>
          <div class="current-plan__row">
            Термін дії <span>до 18.20.23</span>
          </div>
        </div>
        <div>
          <div class="find-better">
            <div class="icon-better">
              <i class="bi bi-box-arrow-up-left"></i>
            </div>
            <div class="w-50">
              <span>Знайти ліпший план</span>
              <div>Ваш план не включає достатню к-ть пам’яті на диску, або кількість місць для студентів ? Щомісячний платіж надто великий? Дозвольте нам допомогти вам знайти оптимальний план, який задовольнить ваші потреби, і зробити перехід швидким та простим.</div>
            </div>
            <b-button variant="primary" pill size="lg" class="w-25">
              Змінити план
            </b-button>
          </div>
          <div class="cancel-plan">
            <div class="icon-cancel">
              <i class="bi bi-box-arrow-down-right"></i>
            </div>
            <div class="w-50">
              <span>Скасувати план</span>
              <div>Іноді потрібно лише поставити крапку. Ми все розуміємо.</div>
            </div>
            <b-button class="outline-dark w-25" pill size="lg" @click="handleCancel">
              Скасувати план
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.current-plan {
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  background: var(--green, #1DB954);
  margin-right: 20px;
  .current-plan__label {
    color: var(--white, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
  }
  .current-plan__title {
    color: var(--white, #FFF);
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
  }
  .current-plan__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    span {
      font-size: 14px;
      font-weight: 700;
    }
  }
}
.find-better, .cancel-plan {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 28px 20px;
  gap: 17px;
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  margin-bottom: 8px;
  .icon-better {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    background: var(--green, #1DB954);
    color: var(--white, #FFF);
    font-size: 18px;
  }
  .icon-cancel {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    background: var(--purple, #F24822);
    color: var(--white, #FFF);
    font-size: 18px;
  }
  span {
    color: var(--dark, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}
</style>
