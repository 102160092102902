<script setup>
import { router, useForm } from "@inertiajs/vue3";
import FileUploader from "@/Components/form/FileUploader.vue";
import { reactive, ref, watch } from "vue";
import { toast } from "vue3-toastify";
import { useAPIForm } from "@/composables/useApiForm.js";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import PaidRegistration from "@/Pages/Workshops/Partials/Registration/PaidRegistration.vue";
import VideoInfo from "@/Pages/MasterClass/Partials/VideoInfo.vue";
import RegisterFormWrapper from "@/Pages/Autowebinars/Partials/RegisterFormWrapper.vue";
import { useClipboard } from "@vueuse/core";
import "vue3-toastify/dist/index.css";

defineOptions({ layout: LoginLayout });

const props = defineProps({
    workShopUser: Object,
    workShop: Object,
});

const form = useForm({
    file: "",
    workShopUserId: props.workShopUser.id,
    workShop: Object,
    paymentFields: props.workShop.paymentFields,
    filePreview: null,
});
const tmpPresign = ref(null);
const isLoading = ref(false);

const form1 = useForm({
    name: "",
    storageData: {},
    workShopUserId: props.workShopUser.id,
});
const onSubmit = async () => {
    isLoading.value = true;
    console.log(form);
    if (!form.file.name) {
        console.log("Завантажте файл");
        errors.code = "Завантажте файл";
        return false;
    }
    const presign = await getPresign(
        route("presigned", {
            type: "file",
            file: form.file.name,
            file_size: form.file.size,
            file_description: "check",
        })
    );
    tmpPresign.value = presign;
    console.log("presign", presign);
    console.log("start upload");

    await uploadToS3(form.file, presign.url);

    console.log("endUpload");
    // form.preview = base64;
    // form.duration = duration;
    console.log("Start update storage data");
    form1.storageData = presign.storageData;
    form1.storageData.type = "CHECK";
    form1.storageData.description = form.name;
    // if (props.routeArgs) {
    //     form.post(route(...props.routeArgs), {
    //         onSuccess: onFormSubmit
    //     });
    // } else {
    //     onFormSubmitEmpty();
    // }
    form1.post(route("workshops.workShopPaymentComplete"), {});
    console.log("End update storage data");
    // console.log(...props.routeArgs);

    // console.log(form);
    //
    // // form.post(route('workshops.workShopPaymentComplete'), {});
};
const onFormSubmitEmpty = () => {
    isLoading.value = false;
    isVisible.value = !isVisible.value;
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("upload-complete", tmpPresign.value);
};
const uploadToS3 = async (file, presign) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
        // const response = await axios.put(presign, file, {
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': file.type,
        //         'Content-Disposition': 'inline'
        //     },
        //     withCredentials: false,
        //     mode: 'no-cors',
        // });
        // isLoading.value = false;
        // if (response.status === 200) {
        //     console.log('File successfully uploaded to S3');
        //     return response.data;
        // } else {
        //     console.error('Error uploading file to S3:', response.statusText);
        //     return null;
        // }
        return axios
            .put(presign, file, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    "Content-Disposition": "inline",
                },
                withCredentials: false,
                mode: "no-cors",
            })
            .then((response) => {
                console.error(response);
            })
            .catch((error) => {
                console.error(error);
                return false;
            })
            .finally(() => (isLoading.value = false));
    } catch (error) {
        console.error("Error uploading file to S3:", error.message);
        return null;
    }
};
const getPresign = async (url) => {
    let response = await fetch(url);
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        if (response.status === 403) {
            toast.error("Ліміт диска вичерпано", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
        console.log("Ошибка HTTP: " + response.status);
        isVisible.value = !isVisible.value;
    }
};

const onReset = () => {
    form.reset();
};

const { text, copy, copied, isSupported } = useClipboard({
    legacy: true,
});

watch(copied, (newValue, oldValue) => {
    if (newValue) {
        toast.success("Cкопійовано", {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
        });
    }
});

const handleCopy = (text) => {
    if (text && text.length > 0) copy(text);
};

const errors = reactive({
    code: null,
});
</script>

<template>
    <RegisterFormWrapper>
        <template #left-side>
            <div
                class="d-flex flex-column align-self-stretch justify-content-center align-items-center left-side-block py-5"
            >
                <div class="form-login-header text-center">
                    <p>
                        Останній крок! Внесіть оплату за вебінар на зазначену
                        нижче суму та прикріпіть скрін оплати.
                    </p>
                </div>

                <b-form
                    class="login-form"
                    @submit.prevent="onSubmit"
                    @reset="onReset"
                >
                    <div class="d-flex justify-content-center payment-amount">
                        <span class="workshop-price"
                            >{{ workShop.config.price }}₴</span
                        >
                    </div>
                    <b-card-body
                        v-for="field in form.paymentFields.data"
                        class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-1"
                    >
                        <div class="mb-1">{{ field.name }}</div>
                        <b-input-group
                            class="payment-input-group"
                            v-model="field.value"
                            :error-message="form.errors.value"
                            type="text"
                            autofocus
                            readonly="readonly"
                            disabled="disabled"
                            :is-error="!!form.errors.value"
                        >
                            <b-form-input
                                v-model="field.value"
                                class="payment-form-input"
                                disabled
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    variant="text"
                                    @click="handleCopy(field.value)"
                                >
                                    <i class="ri-file-copy-line"></i>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-card-body>

                    <FileUploader
                        :form="form"
                        :imagePreview="form.workShop.image"
                        no-sub-title
                        custom-title=" підтвердження оплати в зручному вигляді"
                        class="register-file-uploader"
                    />
                    <div
                        v-if="errors.code"
                        class="text-danger py-3 text-center"
                    >
                        {{ errors.code }}
                    </div>
                    <div
                        class="d-flex flex-column align-items-center justify-content-end mt-4"
                    >
                        <b-button
                            variant="primary"
                            size="lg"
                            pill
                            type="submit"
                            :disabled="
                                form.processing || form1.processing || isLoading
                            "
                        >
                            Відправити
                        </b-button>
                    </div>
                    <div
                        class="d-flex flex-column align-items-center justify-content-end mt-4"
                    >
                        <b-button
                            variant="text-variant"
                            size="md"
                            class="d-flex align-items-center justify-content-center"
                            pill
                            @click="
                                () =>
                                    router.get(
                                        route('register-to-work-shop', {
                                            id: workShop.id,
                                        })
                                    )
                            "
                        >
                            <i class="ri-arrow-left-s-line"></i> Назад
                        </b-button>
                    </div>
                </b-form>
            </div>
        </template>
        <template #right-side>
            <VideoInfo :work-shop="workShop" />
        </template>
    </RegisterFormWrapper>
</template>

<style lang="scss">
.register-file-uploader {
    margin: 16px auto;
}
.form-login-header {
    width: 75%;
    p {
        color: var(--Grey, #c2c2c2);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%; /* 22.4px */
    }
}

.payment-amount {
    border-radius: 8px;
    border: 1px solid var(--stroke, #ebebeb);
    background: var(--white, #fff);
    color: black;
    width: fit-content;
    margin: 16px auto;
    font-family: e-Ukraine;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    padding: 14px 20px;
}

.payment-input-group {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    background-color: #fff;

    i {
        font-size: medium;
    }

    .payment-form-input {
        background: transparent;
        border: none;
        color: #1e1e1e;
        opacity: 0.5;
        padding: 12px !important;
    }
}
.left-side-block {
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 85%;
    margin: 20px auto;
}

.register-done {
    width: fit-content;
    text-align: center;
    background: #fff;
    border-radius: 20px;
    padding: 100px;
    margin: 20px auto;
}

.register-done-wrapper {
    height: calc(100vh - 130px);
}
</style>
