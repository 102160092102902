<script setup>

import {Head, router, useForm} from '@inertiajs/vue3';
import {computed, reactive} from "vue";
import VideoInfo from "@/Pages/MasterClass/Partials/VideoInfo.vue";
import PaidRegistration from "@/Pages/Workshops/Partials/Registration/PaidRegistration.vue";
import RegisterFormWrapper from "@/Pages/Autowebinars/Partials/RegisterFormWrapper.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";

defineOptions({layout: LoginLayout})

const props = defineProps({
    workShopUser: Object,
    workShop: Object,
    isPaid: false
})

const form = useForm({
    code: '',
    workShopUserId: props.workShopUser.id,
    errors: {code: null}
});

const digits = reactive({
    digit1: null,
    digit2: null,
    digit3: null,
    digit4: null
})

const errors = reactive({
    code: null
})

const codeComputed = computed(() => '' + (digits.digit1 ?? '') + (digits.digit2 ?? '') + (digits.digit3 ?? '') + (digits.digit4 ?? ''))

const isFinished = computed(() => codeComputed.value.length === 4)

const getInputElement = (index) => {
    return document.getElementById('digit' + index + '-input');
};

const moveToNext = (index) => {
  if (getInputElement(index).value.length === 1) {
    if (index !== 4) {
      digits['digit' + (index + 1)] = null
      getInputElement(index + 1).focus();
    } else {
      getInputElement(index).blur();
    }
  }
}

const moveToPrev = (index, event) => {
  if (event.key === 'Backspace' && getInputElement(index).value.length === 0) {
    event.preventDefault();
    digits['digit' + index] = null;
    if (index > 1) {
      getInputElement(index - 1).focus();
    }
  }
}

const onSubmit = () => {
    let status = false;
    return axios.post(route('workshops.codeVerifyAjax'), {
            code: codeComputed.value,
            workShopUserId: props.workShopUser.id
        }
    )
        .then(response => {
            status = true;
            console.log(response);
            form.transform((data) => ({
                code: codeComputed.value,
                workShopUserId: props.workShopUser.id
            })).post(route('workshops.codeVerify'));
        })
        .catch(error => {
            console.log(error.response.data);
            console.log('1');
            errors.code = error.response.data.message;
            status = false;
            return false;
        });


};

</script>

<template>
    <RegisterFormWrapper>
        <template #left-side>
            <div
                class="d-flex flex-column align-self-stretch justify-content-center align-items-center left-side-block py-5">
                <Head title="Email Verification"/>
                <div class="form-login-header text-center">
                    <h1 class="text-md login-title">
                        Введіть код підтввердження
                    </h1>
                    <p>Відправлений на {{ workShopUser.email }}</p>
                </div>

                <b-form class="login-form" @submit.prevent="onSubmit">

                    <div class="d-flex">
                      <b-form-input type="text" v-model="digits.digit1"
                                    class="form-control form-control-lg text-center"
                                    placeholder="0"
                                    @input="moveToNext(1)"
                                    @keydown="moveToPrev(1, $event)"
                                    maxLength="1"
                                    id="digit1-input"
                      />
                      <b-form-input type="text" v-model="digits.digit2"
                                    class="form-control form-control-lg text-center"
                                    placeholder="0"
                                    @input="moveToNext(2)"
                                    @keydown="moveToPrev(2, $event)"
                                    maxLength="1"
                                    id="digit2-input"
                      />
                      <b-form-input type="text" v-model="digits.digit3"
                                    class="form-control form-control-lg text-center"
                                    placeholder="0"
                                    @input="moveToNext(3)"
                                    @keydown="moveToPrev(3, $event)"
                                    maxLength="1"
                                    id="digit3-input"
                      />
                      <b-form-input type="text" v-model="digits.digit4"
                                    class="form-control form-control-lg text-center"
                                    placeholder="0"
                                    @input="moveToNext(4)"
                                    @keydown="moveToPrev(4, $event)"
                                    maxLength="1"
                                    id="digit4-input"
                      />


                    </div>
                    <div v-if="errors.code" class="text-danger py-3 text-center">
                        {{ errors.code }}
                    </div>

                    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
                        <b-button variant="primary" size="lg" pill type="submit" :disabled="form.processing">
                            Підтвердити
                        </b-button>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-end mt-4">
                        <b-button variant="text-variant" size="md"
                                  class="d-flex align-items-center justify-content-center" pill
                                  @click="() => router.get(
          route('register-to-work-shop'),{id:workShop.id})">
                            <i class="ri-arrow-left-s-line"></i> Назад
                        </b-button>
                    </div>
                </b-form>
            </div>
        </template>
        <template #right-side>
            <VideoInfo :work-shop="workShop"/>
        </template>
    </RegisterFormWrapper>
</template>

<style lang="scss">
.form-login-header {
    p {
        color: var(--Grey, #C2C2C2);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%; /* 22.4px */
    }
}

.verification-digit {
    text-align: center;
    display: flex;
    padding: 28px 18px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--stroke, #EBEBEB);
    background: var(--white, #FFF);
    color: black;
}
</style>
