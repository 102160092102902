<script setup>
import { useI18n } from "vue-i18n";

import PaymentHistory from "@/Pages/ProductOwnerProfile/Partials/Membership/PaymentHistory.vue";
import MembershipOptions from "@/Pages/ProductOwnerProfile/Partials/MembershipOptions.vue";
import BalanceManagement from "@/Pages/ProductOwnerProfile/Partials/BalanceManagement.vue";
import SadFace from "@/../images/empty-data/sad_face.png";
import Smile from "@/../images/empty-data/smile.png";
import PlanInfo from "@/Pages/ProductOwnerProfile/Partials/Membership/PlanInfo.vue";
import CurrentPlan from "@/Pages/ProductOwnerProfile/Partials/Membership/CurrentPlan.vue";
import { onMounted, ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const { t } = useI18n();

const props = defineProps({
    membershipOptions: Object,
    tariffs: Object,
    paymentHistory: Object,
});

const pageData = usePage();

onMounted(() => {
    if (props.membershipOptions.plan.name === "UNPAID")
        isCollapse.value = false;
});

const trialExpired = false;

const isCollapse = ref(true);

const paidPeriod = ref(false);
</script>

<template>
    <b-container fluid class="tab-form">
<!--        <pre>{{pageData.props.auth}}</pre>-->
<!--              <pre>{{membershipOptions}}</pre>-->
        <!--          <pre>{{tariffs}}</pre>-->
        <b-form>
            <b-row v-if="membershipOptions.plan.name !== 'UNPAID'">
                <b-col cols="12" class="p-3">
                    <CurrentPlan :plan="membershipOptions" :tariffs="tariffs" />
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12" class="p-3">
                    <div v-if="!trialExpired" class="empty-plan">
                        <img :src="Smile" alt="Оберіть тарифний план" />
                        <div class="empty-plan__title">
                            Оберіть тарифний план
                        </div>
                        <div class="empty-plan__description">
                            Щоб почати користуватися платформою, виберіть один
                            із варіантів
                        </div>
                    </div>
                    <div v-else class="empty-plan trial-expired">
                        <img :src="SadFace" alt="немає активного плану" />
                        <div class="empty-plan__title">
                            Ви вже використали 14 днів безкоштовного доступу
                        </div>
                        <div class="empty-plan__description">
                            Щоб продовжити користування платформою, оберіть
                            тарифний план.
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="p-2">
                <div class="billing-period-toggle mb-4">
                    <div class="toggle-switch">
                        <input
                            :checked="paidPeriod"
                            type="checkbox"
                            id="billing-period-toggle"
                            class="toggle-checkbox"
                            @click="paidPeriod = !paidPeriod"
                        />
                        <label for="billing-period-toggle" class="toggle-label">
                            <span class="toggle-button"></span>
                            <span class="toggle-text monthly"
                                >Оплата щомісяця</span
                            >
                            <span class="toggle-text yearly"
                                >Оплата раз на рік</span
                            >
                        </label>
                    </div>
                </div>
                <div class="plan-list">
                    <PlanInfo
                        v-for="plan in tariffs.data"
                        :key="plan.id"
                        :plan="plan"
                        :course-exchange="membershipOptions.plan.courseExchange"
                        :collapse="isCollapse"
                        :billing-period="paidPeriod"
                        :is-active="
                            plan.id === membershipOptions.plan.tariff_id && membershipOptions.plan.isActive
                        "
                        :current-plan="membershipOptions"
                        @handle-collapse="isCollapse = false"
                    />
                    <PlanInfo
                        :plan="{ name: 'Індивідуальний', isIndividual: true }"
                        :collapse="isCollapse"
                        :billing-period="paidPeriod"
                        @handle-collapse="isCollapse = false"
                    />
                </div>
                <!--          <b-col-->
                <!--              cols="3"-->
                <!--              v-for="(plan, index) in availablePlans"-->
                <!--              :key="index"-->
                <!--          >-->
                <!--              <PlanInfo :plan="plan" :is-active="plan.isActive" :collapse="isCollapse" @handle-collapse="isCollapse = false" />-->
                <!--          </b-col>-->
            </b-row>
            <b-row>
                <b-col class="p-3">
                    <PaymentHistory :payment-history="paymentHistory" />
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<style lang="scss"></style>
