<script setup>
import {ref, watch} from 'vue';

defineProps({
  title: String,
})

const expand = ref(false);
const isContentVisible = ref(false);
const tmpContentVisible = ref(false);

watch(expand, (newValue, oldValue) => {
  if (newValue) {
    tmpContentVisible.value = isContentVisible.value;
    isContentVisible.value = true;
  } else isContentVisible.value = tmpContentVisible.value
});

</script>

<template>
  <div ref="fullscreenElement" class="expandable-block" :class="{ 'expandable-block--expand': expand }">
    <div class="expandable-block__header">
      <div class="expandable-block__title">{{ title }}</div>
      <b-button v-if="!expand" variant="text" @click="isContentVisible = !isContentVisible">
        <i v-if="isContentVisible" class="ph-caret-up-fill fs-5"></i>
        <i v-else class="ph-caret-down-fill fs-5"></i>
      </b-button>
      <b-button variant="text" @click="expand = !expand">
        <i v-if="!expand" class="ph-arrows-out-bold fs-5"></i>
        <i v-else class="ph-arrows-in-bold fs-5"></i>
      </b-button>
    </div>
    <b-collapse v-model="isContentVisible">
      <div class="expandable-block__content">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>
<style lang="scss" scoped>
  .expandable-block {
    border-radius: 8px;
    background: #F7F7F8;
    .expandable-block__header {
      display: flex;
      align-items: center;
      padding: 8px 0 8px 16px;
      .expandable-block__title {
        font-family: e-Ukraine;
        display: flex;
        flex: 1;
      }
    }
  }
  .expandable-block__content {
    padding: 12px 24px;
  }
  .expandable-block--expand {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1010;
  }
</style>
