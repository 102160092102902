<script setup>
import {useI18n} from "vue-i18n";

defineProps({
  type: {
    type: String
  },
});

const typesClasses = {
    PENDING: "table-col__status-badge bage-text status-badge__pending",
    ACTIVE: "table-col__status-badge bage-text status-badge__active",
    FINISHED: "table-col__status-badge bage-text status-badge__finished",
    EXPIRED: "table-col__status-badge bage-text status-badge__expired",
    UPCOMING: "table-col__status-badge bage-text status-badge__upcoming",
    DRAFT: "table-col__status-badge bage-text module-table__col--inactive",
    PUBLISH: "table-col__status-badge bage-text",
    new: "user-status-badge user-status-badge__new",
    approved: "user-status-badge",
}

const { t } = useI18n();
</script>

<template>
<!--    <span-->
<!--      v-if="type !== 'DRAFT' && type !== 'new' && type !== 'approved'"-->
<!--      class="table-col__status-badge bage-text"-->
<!--    >-->
<!--        {{ $t(type) }}-->
<!--    </span>-->
<!--    <span-->
<!--      v-if="type === 'DRAFT'"-->
<!--      class="table-col__status-badge bage-text module-table__col&#45;&#45;inactive"-->
<!--    >-->
<!--      {{ $t(type) }}-->
<!--    </span>-->
<!--    <span-->
<!--      v-if="type === 'new'"-->
<!--      class="user-status-badge user-status-badge__new"-->
<!--    >-->
<!--      {{ type }}-->
<!--    </span>-->
<!--    <span-->
<!--      v-if="type === 'approved'"-->
<!--      class="user-status-badge"-->
<!--    >-->
<!--      {{ type }}-->
<!--    </span>-->
    <span
        :class="typesClasses[type]"
    >
      {{  t(type) }}
    </span>
</template>
<style lang="scss">
  .user-status-badge {
    width: 75px;
    text-align: center;
    background-color: green;
    color: white;
    padding: 2px;
    margin-right: 12px;
    border-radius: 5px;
  }
  .user-status-badge__new {
    background: var(--purple, #F24822);
  }
  .table-col__status-badge {
      display: block!important;
      width: 100%!important;
  }
  .bage-text {
      font-family: e-Ukraine;
      font-size: 11px;
      font-weight: 300;
      line-height: 15.4px;
      text-align: center;
      color: #1E1E1E;
  }
  .status-badge__finished {
      background: #EBEBEB;
      border: 1px solid #C2C2C2;
      color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
  }
  .status-badge__expired {
      background: #EBEBEB;
      border: 1px solid #C2C2C2;
      color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
  }
  .status-badge__upcoming {
      background: color-mix(in srgb, var(--Alert, #F1C963), transparent 90%);
      border: 1px solid var(--Alert, #F1C963);
      color: #1E1E1E;

  }
  .status-badge__active {
      background: #F1C9631A;
      border: 1px solid var(--Alert, #F1C963)
  }
  .status-badge__pending {
      background: #1DB9541A;
      border: 1px solid #1DB954;
  }
</style>
