<script setup>
import {Head, Link, router, useForm, usePage} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";

import TabPersonalData from "@/Pages/ProductOwnerProfile/Partials/TabPersonalData.vue";
import TabPlan from "@/Pages/ProductOwnerProfile/Partials/TabPlan.vue";
import TabSchool from '@/Pages/ProductOwnerProfile/Partials/TabSchool.vue';
import {computed} from "vue";
import {toast} from "vue3-toastify";

const props = defineProps({
  profile: Object,
  paymentHistory: Object,
  membershipOptions: Object,
  tariffs: Object,
  appUrl: '',
  errors: Object
})

const {t} = useI18n();

const pageData = usePage()

const accountType = pageData?.props?.auth?.user?.current_account_type || ''
const isOwner = accountType === "ACCOUNT_TYPE_PRODUCT_OWNER"
const isStudent = accountType === "ACCOUNT_TYPE_STUDENT"

const page = {
  title: t('my-profile'),
  description: "Рекомедуємо використовувати реальні дані для зручної взаємодії з іншими користувачами платформи."
}

const hash = computed(() => {
  if (window.location.hash === '#plan') {
    return 1;
  } else if (window.location.hash === '#school') {
    return 2;
  }
  return 0;
})

const form = useForm({
  profile: props.profile
});

function save() {
      router.visit(route(isStudent ? 'student.save.profile' : 'save.profile'),
      {
        method: 'post',
        data: form.profile,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true
      })
}

function saveDomain() {
  router.visit(route('change-domain'),
      {
        method: 'post',
        data: form.profile,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
          // return Promise.all([
          //   this.doThing(),
          //   this.doAnotherThing()
          // ])
          console.log(reponse);
          form.profile = reponse.props.profile;
        }
      })
}

function updateForm(value) {
}

function logout() {
  toast.success('User logged out!', {
    autoClose: 3000,
  });
  setTimeout(() => {
    sessionStorage.clear();
    this.$router.push({name: 'login'});
  }, 1500);
}

</script>

<template>
  <div>
    <Head :title="t('my-profile')"/>

    <PageHeader :title="page.title"/>
    <PageContentCard :title="page.title" :description="page.description">

      <template #header-end>
        <div class="header-end">
        </div>
      </template>

      <template #default>
        <b-tabs nav-class="page-tabs" v-model="hash">
          <b-tab title="Особисті дані">
            <TabPersonalData
                :form="form"
                :errors="errors"
                @save="save"
                @personal-data-change="updateForm"/>
          </b-tab>
          <b-tab v-if="isOwner" title="Білінг">

            <TabPlan :membership-options="membershipOptions" :payment-history="paymentHistory" :tariffs="tariffs"
                     @personal-data-change="updateForm"/>
          </b-tab>
          <b-tab v-if="isOwner" title="Школа">
            <TabSchool :form="form" @changeDomain="saveDomain" :app-url="appUrl"/>
          </b-tab>
          <template #tabs-end>
            <div class="tabs-end">
              <!--              <div class="logout-button" @click="logout">-->
              <!--                Вихід-->
              <!--                <i class="ph-power-bold"></i>-->
              <!--              </div>-->
              <Link :href="route('logout')" method="get" class="logout-button" @click="logout">
                Вихід
                <i class="ph-power-bold"></i>
              </Link>
            </div>
          </template>
        </b-tabs>
      </template>
    </PageContentCard>

  </div>
</template>

<style lang="scss" scoped>

.module-content {
  min-height: calc(100vh - 120px);
}

.header-end {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
}

.tabs-end {
  .logout-button {
    color: var(--purple, #F24822);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 8px 26px;
    display: flex;
    cursor: pointer;

    i {
      margin-left: 6px;
      font-size: 20px;
    }
  }
}
</style>
