<script setup>
import { computed, onMounted, onUnmounted } from "vue";

const props = defineProps({
    objectId: String,
    modelValue: Boolean,
    title: String,
    warningText: String,
    acceptLabel: String,
    declineLabel: String,
    swapButtons: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["acceptBtn", "update:modelValue"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const handleAccept = () => {
    emit("acceptBtn", props.objectId);
    isVisible.value = !isVisible.value;
};

onMounted(() => {
    setTimeout(() => handleAccept(), 3000);
});
onUnmounted(() => {
    handleAccept();
});
</script>

<template>
    <b-modal v-model="isVisible" hide-footer hide-header centered>
        <div class="confirm-modal">
            <div class="confirm-modal__body">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    class="confirm-modal__icon"
                >
                    <g clip-path="url(#clip0_994_71179)">
                        <path
                            d="M21.0002 28.7827C19.826 28.7827 18.8418 29.767 18.8418 30.9412C18.8418 32.1153 19.826 33.0996 21.0002 33.0996C22.1313 33.0996 23.1587 32.1153 23.1069 30.993C23.1587 29.7583 22.1831 28.7827 21.0002 28.7827Z"
                            fill="#DA7F14"
                        />
                        <path
                            d="M40.978 36.4327C42.3335 34.093 42.3421 31.3043 40.9952 28.9731L27.4748 5.55842C26.1366 3.2014 23.7191 1.80273 21.0081 1.80273C18.2971 1.80273 15.8797 3.21004 14.5414 5.54978L1.00371 28.9904C-0.34315 31.3474 -0.334517 34.1534 1.02962 36.4931C2.37648 38.807 4.7853 40.197 7.47903 40.197H34.4854C37.1878 40.197 39.6138 38.7897 40.978 36.4327ZM38.0425 34.7405C37.2914 36.0356 35.9618 36.804 34.4768 36.804H7.47039C6.00266 36.804 4.68169 36.0528 3.94782 34.7837C3.20532 33.4972 3.19669 31.9604 3.93919 30.6654L17.4769 7.23336C18.2108 5.94693 19.5231 5.18716 21.0081 5.18716C22.4845 5.18716 23.8054 5.95557 24.5393 7.242L38.0684 30.674C38.7936 31.9345 38.785 33.4541 38.0425 34.7405Z"
                            fill="#DA7F14"
                        />
                        <path
                            d="M20.4632 13.6308C19.4358 13.9243 18.7969 14.8568 18.7969 15.9878C18.8487 16.6698 18.8918 17.3605 18.9436 18.0426C19.0904 20.6414 19.2372 23.1883 19.384 25.7871C19.4358 26.6677 20.1178 27.3066 20.9985 27.3066C21.8791 27.3066 22.5698 26.6246 22.613 25.7353C22.613 25.2 22.613 24.7079 22.6648 24.1639C22.7598 22.4976 22.8634 20.8313 22.9583 19.165C23.0101 18.0858 23.1051 17.0066 23.1569 15.9273C23.1569 15.5388 23.1051 15.1935 22.9583 14.8481C22.518 13.8811 21.4906 13.389 20.4632 13.6308Z"
                            fill="#DA7F14"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_994_71179">
                            <rect width="42" height="42" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <div class="confirm-modal__text">
                    <div class="confirm-modal__title">{{ title }}</div>
                    <div class="confirm-modal__desc">
                        <slot name="description"></slot>
                        <div v-if="warningText" class="warning-text">
                            {{ warningText }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="confirm-modal__actions"
                :class="{ 'confirm-modal__actions--reverse': swapButtons }"
            >
                <b-button
                    class="confirm-modal__accept"
                    pill
                    @click="handleAccept"
                >
                    {{ acceptLabel }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss" scoped>
.confirm-modal {
    padding: 20px;
    .confirm-modal__body {
        display: flex;
        .confirm-modal__icon {
            width: 42px;
            height: 42px;
        }
        .confirm-modal__text {
            margin-left: 20px;
            width: calc(100% - 62px);
            .confirm-modal__title {
                color: #da7f14;
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 22px */
                margin-bottom: 12px;
            }
            .confirm-modal__desc {
                color: var(--dark, #1e1e1e);
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 160%; /* 22.4px */
                .warning-text {
                    padding: 12px 17px;
                    border-radius: 4px;
                    border: 1px solid #856404;
                    background: #fff3cd;
                    color: #856404;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 160%;
                }
            }
        }
    }
    .confirm-modal__actions {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        margin-top: 28px;
        &.confirm-modal__actions--reverse {
            justify-content: flex-start;
            flex-direction: row-reverse;
        }
        .confirm-modal__accept {
            margin: 2px;
            display: flex;
            padding: 16px 24px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 50px;
            border: 0;
            background: var(--green, #1db954);
            color: var(--white, #fff);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
        }
        .confirm-modal__accept:hover {
            background-color: var(--green, #1db954);
            opacity: 0.8;
        }
    }
}
</style>
