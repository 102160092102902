<script setup>
import SpeakerField from "@/Components/form/SpeakerField.vue";
import WorkshopTeamModal from "@/Components/modals/WorkshopTeamModal.vue";
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    form: Object,
    team: Object,
    roles: Object,
    teamPresenter: Object,
    teamModerator: Object,
    withoutModerator: {
        type: Boolean,
        default: false,
    },
    isMasterClass: {
        type: Boolean,
        default: false,
    },
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isExpired = computed(
    () => props.form?.workShop?.config?.workShopStatus === "EXPIRED" && props.form?.workShop?.type !== "MASTERCLASS"
);
const isFinished = computed(
    () => props.form?.workShop?.config?.workShopStatus === "FINISHED"
);

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles) || isFinished.value || isExpired.value;

const speakerModal = ref(false);
const moderatorModal = ref(false);

const speakerList = computed(() =>
    props.form.workShop.speakers.filter(
        (speaker) => speaker && speaker.role === "PRESENTER"
    )
);
const moderatorList = computed(() =>
    props.form.workShop.speakers.filter(
        (speaker) => speaker && speaker.role === "MODERATOR"
    )
);

const handleWorkshopTeamMember = (data) => {
    if (data.id === null) {
        props.form.workShop.speakers = props.form.workShop.speakers.filter(
            (speaker) => speaker.role !== data.type
        );
        return;
    }

    let member =
        data.type === "MODERATOR"
            ? props.teamModerator.find((member) => member.id === data.id)
            : props.teamPresenter.find((member) => member.id === data.id);

    if (member) {
        member.role = data.type;
        member.label = data.type === "MODERATOR" ? "Модератор" : "Спікер";

        const sameTypeIndex = props.form.workShop.speakers.findIndex(
            (speaker) => speaker.role === data.type
        );

        if (sameTypeIndex !== -1) {
            if (props.form.workShop.speakers[sameTypeIndex].id === data.id) {
                props.form.workShop.speakers.splice(sameTypeIndex, 1);
            } else {
                props.form.workShop.speakers[sameTypeIndex] = member;
            }
        } else {
            props.form.workShop.speakers.push(member);
        }
    }
};

const selectSpeaker = () => {
    speakerModal.value = !speakerModal.value;
};

const selectModerator = () => {
    moderatorModal.value = !moderatorModal.value;
};
</script>

<template>
    <div class="card__title mb-4">
        Команда <i id="tooltip-spikers" class="bi bi-info-circle"></i>
    </div>
    <b-tooltip
        target="tooltip-spikers"
        custom-class="custom-tooltip"
        offset="5"
        :no-fade="true"
        :delay="{ show: 50, hide: 50 }"
    >
    Сформуйте команду, яка буде проводити воркшоп.
        <!--        За замовчуванням, всі присутні зазначаються як "Спікер", проте ви маєте можливість змінити цю назву.-->
    </b-tooltip>
    <div class="d-flex gap-4 w-100">
        <div
            class="d-flex flex-column"
            :class="!withoutModerator ? 'w-50' : 'w-100'"
        >
            <div class="card__title mb-2">
                 {{ isMasterClass ? "Автор" : "Спікер" }}
<!--                Спікер-->
                <i
                    v-if="!isMasterClass"
                    id="workshop-presenter-role"
                    class="bi bi-info-circle"
                ></i>
            </div>
            <b-tooltip
                        class="text-left"
                        target="workshop-presenter-role"
                        custom-class="custom-tooltip"
                        offset="5"
                        triggers="hover"
                        :no-fade="true"
                        :delay="{ show: 50, hide: 50 }"
                    >
                    Спікер має всі права для проведення події: керує трансляцією, завантажує матеріали та контролює процес.
                    </b-tooltip>
            <div
                class="d-flex justify-content-between align-items-end w-100 flex-grow-1 gap-4"
            >
                <div class="d-flex flex-wrap gap-3 flex-grow-1 w-100">
                    <SpeakerField
                        v-for="speaker in speakerList"
                        :speaker="speaker"
                        class="w-100"
                    />
                </div>
                <b-button
                    variant="danger"
                    class="red-plus-button"
                    :disabled="isReadOnly"
                    @click="selectSpeaker"
                >
                    <i v-if="speakerList.length > 0" class="bx bx-edit"></i>
                    <i v-else class="bx bx-plus"></i>
                </b-button>
            </div>
        </div>
        <div v-if="!withoutModerator" class="w-50 d-flex flex-column">
            <div class="card__title mb-2">Модератор      <i
                    id="workshop-moderator-role"
                    class="bi bi-info-circle"
                ></i>
            </div>
            <b-tooltip
                        class="text-left"
                        target="workshop-moderator-role"
                        custom-class="custom-tooltip"
                        offset="5"
                        triggers="hover"
                        :no-fade="true"
                        :delay="{ show: 50, hide: 50 }"
                    >
                    Модератор відповідає за порядок під час події та стежить за дотриманням правил у чаті.
                    </b-tooltip>
            <div
                class="d-flex justify-content-between align-items-end w-100 flex-grow-1 gap-4"
            >
                <div class="d-flex flex-wrap gap-3 flex-grow-1 w-100">
                    <SpeakerField
                        v-for="speaker in moderatorList"
                        :speaker="speaker"
                        class="w-100"
                    />
                </div>
                <b-button
                    variant="danger"
                    class="red-plus-button"
                    :disabled="isReadOnly"
                    @click="selectModerator"
                >
                    <i v-if="moderatorList.length > 0" class="bx bx-edit"></i>
                    <i v-else class="bx bx-plus"></i>
                </b-button>
            </div>
        </div>
    </div>
    <WorkshopTeamModal
        v-model="speakerModal"
        :team="teamPresenter"
        :speakers="form.workShop.speakers"
        @on-save="handleWorkshopTeamMember"
    />
    <WorkshopTeamModal
        v-model="moderatorModal"
        :team="teamModerator"
        :speakers="form.workShop.speakers"
        :type="'MODERATOR'"
        @on-save="handleWorkshopTeamMember"
    />
</template>

<style scoped lang="scss"></style>
