<script setup>
import { useI18n } from "vue-i18n";
import InputGroup from "@/Components/InputGroup.vue";
import { nextTick, ref, watch } from "vue";
import avatar from "../../../../images/users/avatar-5.jpg";
import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps({
    form: Object,
});

const onSubmit = () => {
    v$.value["name"].$touch();
    v$.value["email"].$touch();
    v$.value["phone"].$touch();
    if (!v$.value.$invalid && !notValidPhone.value) {
        emit("save");
    }
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["personal-data-change", "save"]);

// watch(
//   () => props.form.studentProfile,
//   (value) => {
//     nextTick(() => emit('personal-data-change', value))
//   }
//   , {deep: true}
// );

const password = ref({
    current: "",
    new: "",
    confirmation: "",
});

const myTeamModal = ref(false);

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    email: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        email: helpers.withMessage("Введіть дійсну електронну адресу", email),
    },
    phone: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
};

const v$ = useVuelidate(rules, props.form.studentProfile, { $lazy: true });

const notValidPhone = ref(false);
const phoneValidate = (data) => {
    v$.value["phone"].$touch();
    if (!v$.value["phone"].$invalid)
        notValidPhone.value = !data.isValid ? !data.isValid : false;
    else notValidPhone.value = false;
};
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="12" xl="8">
                <b-form>
                    <div class="user-info">
                        <img
                            :src="avatar"
                            title="avatar"
                            alt="avatar"
                            class="user-info__avatar"
                        />
                        <div class="d-flex flex-column align-items-start">
                            <div class="user-info__fullname">
                                {{
                                    `${form.studentProfile.name} ${form.studentProfile.lastName}`
                                }}
                            </div>
                            <div class="user-info__email">
                                {{ form.studentProfile.email }}
                            </div>
                        </div>
                    </div>
                    <b-card no-body class="shadow-none">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start"
                        >
                            <div
                                class="d-flex justify-content-between align-items-end w-100"
                            >
                                <InputGroup
                                    label="Ім’я"
                                    v-model="form.studentProfile.name"
                                    size="lg"
                                    class="me-2 w-100"
                                    :is-error="v$.name.$invalid"
                                    :error-message="
                                        v$.name?.$errors[0]?.$message
                                    "
                                    @blur="v$.name.$touch()"
                                />
                                <InputGroup
                                    label="Прізвище"
                                    v-model="form.studentProfile.lastName"
                                    size="lg"
                                    class="ms-2 w-100"
                                />
                            </div>
                            <div
                                class="d-flex justify-content-between align-items-end w-100 mt-2"
                            >
                                <InputGroup
                                    label="Телефон"
                                    element="phone"
                                    v-model="form.studentProfile.phone"
                                    size="lg"
                                    class="me-2 w-100"
                                    :is-error="
                                        v$.phone.$invalid || notValidPhone
                                    "
                                    :error-message="
                                        notValidPhone
                                            ? 'Перевірте формат введення!'
                                            : v$.phone?.$errors[0]?.$message
                                    "
                                    @blur="phoneValidate"
                                />
                                <InputGroup
                                    label="Email"
                                    v-model="form.studentProfile.email"
                                    size="lg"
                                    class="ms-2 w-100"
                                    :is-error="v$.email.$invalid"
                                    :error-message="
                                        v$.email?.$errors[0]?.$message
                                    "
                                    @blur="v$.email.$touch()"
                                />
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="fs">Безпека</div>
                            <div
                                class="d-flex justify-content-between align-items-end w-100 gap-4 p-2"
                            >
                                <InputGroup
                                    label="Поточний пароль"
                                    v-model="form.studentProfile.password_old"
                                    type="password"
                                    size="lg"
                                    class="w-100"
                                />
                                <InputGroup
                                    label="Новий пароль"
                                    v-model="form.studentProfile.password"
                                    type="password"
                                    size="lg"
                                    placeholder="Новий пароль"
                                    class="w-100"
                                />
                                <InputGroup
                                    label="Повторіть пароль"
                                    v-model="
                                        form.studentProfile.password_confirm
                                    "
                                    type="password"
                                    size="lg"
                                    placeholder="Повторіть пароль"
                                    class="w-100"
                                />
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-button
                        variant="primary"
                        pill
                        size="lg"
                        class="mt-4"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.user-info {
    display: flex;
    align-items: center;
    .user-info__avatar {
        width: 134px;
        height: 134px;
        border-radius: 67px;
        flex-shrink: 0;
        margin: 0 20px;
    }
    .user-info__fullname {
        color: var(--dark, #1e1e1e);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 5px;
    }
    .user-info__email {
        color: var(--Grey, #c2c2c2);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        margin-top: 5px;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}
</style>
