<script setup>
import {Head, router, useForm} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import {computed, ref, watch} from "vue";

import TabFormGeneralInfo from "@/Pages/MasterClass/Partials/TabFormGeneralInfo.vue";
import TabFormContent from "@/Pages/MasterClass/Partials/TabFormContent.vue";
import TabFormRegisterInfo from '@/Pages/MasterClass/Partials/TabFormRegisterInfo.vue';
import TabFormUsers from '@/Pages/MasterClass/Partials/TabFormUsers.vue';
import MeetingButton from '@/Components/buttons/MeetingButton.vue';
import PreviewButton from '@/Components/buttons/PreviewButton.vue';
import DownloadXlsxButton from "@/Components/buttons/DownloadXlsxButton.vue";
import ShareModal from '@/Components/modals/ShareModal.vue';
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
// Todo
const props = defineProps({
  user: Object,
  masterClass: Object,
  workshopTypes: Object,
  team: Object,
  teamPresenter: Object,
  teamModerator: Object,
  webinarButtons: Object,
})

const emptyButtons = [
    { title: "", url: "" },
    { title: "", url: "" },
];

const form = useForm({
  workShop: props.masterClass,
  filePreview: null,
  publicLink: '',
  webinarButtons: props.webinarButtons || emptyButtons,
});

const {t} = useI18n();

const state = computed(() => {
  return 'new';
});

const page = {
  title: t('' + state.value) + ' ' + t('masterclass'),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route('dashboard'),
    },
    {
      text: "Воркшопи",
      href: route('workshops.workshops')
    },
    {
      text: t('' + state.value) + ' ' + t('masterclass'),
      //href: (state === 'new') ? route('workshops.masterclass.create') : '',
      active: true,
    },
  ],
}

const statuses = computed(() => {
  return (props.workshopTypes && props.workshopTypes.length > 0) ? props.workshopTypes.map((item) => {
    return {value: item, text: t(item)};
  }) : [];
});

function save() {
  console.log('Save masterClass');
  router.visit(route('workshops.store',{"type":"masterclass"}),
      {
        method: 'post',
        data: form,
        preserveState: true,
        preserveScroll: true,
        forceFormData: true
      })
}

watch(
    () => form.masterClass,
    (value) => {
      console.log('change edit');
    }
    , {deep: true}
);

const tabIndex = ref(0);

const selectedUsers = ref([]);

const downloadUsersXlsx = () => {
  console.log("handle download users: ", selectedUsers.value)
}

const shareLinkModal = ref(false);
const shareLink = ref('');

function handleShare() {
  shareLinkModal.value = !shareLinkModal.value;
  shareLink.value = form.masterClass.image; //TODO: get share link from masterClass
}

</script>

<template>
  <div>
    <Head title="Воркшопи"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>
    <PageContentCard :title="page.title">
      <template #header-end>
        <div class="header-end">
            <DiskUsageProgress />
<!--          <DownloadXlsxButton v-if="tabIndex === 3" @click="downloadUsersXlsx"/>-->
        </div>
      </template>

      <template #default>
        <b-tabs v-model="tabIndex" nav-class="page-tabs">
          <b-tab title="Основна інформація" active>
            <TabFormGeneralInfo @save="save" :form="form" :workshop-types="workshopTypes" :team="team" :team-presenter="teamPresenter" :team-moderator="teamPresenter" />
          </b-tab>
          <b-tab title="Контент майстеркласу" disabled>
<!--            <TabFormContent :form="form"/>-->
          </b-tab>
          <b-tab title="Реєстрація" disabled>
<!--            <TabFormRegisterInfo @save="save" :form="form"/>-->
          </b-tab>
          <b-tab title="Користувачі" disabled>
<!--            <TabFormUsers v-model="selectedUsers" :form="form"/>-->
          </b-tab>

          <template #tabs-end>
            <div class="tabs-end">
<!--              <PreviewButton href=""/>-->
            </div>
          </template>
        </b-tabs>
      </template>
    </PageContentCard>

  </div>
</template>

<style lang="scss" scoped>

.module-content {
  min-height: calc(100vh - 120px);
}

.header-end {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
</style>
