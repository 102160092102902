<script setup>

import {computed, ref} from 'vue';
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";

const props = defineProps({
  speaker: Object,
  roles: Object
})

const isDisabled = ref(true);

const speakerRole = computed({
  get() {
    // Якщо role порожнє, повертаємо 'VIEWER'
    return props.speaker.role || 'VIEWER';
  },
  set(value) {
    // Зберігаємо нове значення role
    props.speaker.role = value;
  }
})

</script>

<template>
<!--  <div class="speaker-field">-->
<!--    <div class="speaker-field__title">-->
<!--      <span v-if="isDisabled">{{ speaker.label }}</span>-->
<!--      <b-form-input-->
<!--          v-else-->
<!--          v-model="speaker.label"-->
<!--          autofocus-->
<!--          @keyup.enter="isDisabled = !isDisabled"-->
<!--          class="speaker-field__title-input"-->
<!--      >-->

<!--      </b-form-input>-->

<!--      <b-button-->
<!--          v-if="isDisabled"-->
<!--          variant="text"-->
<!--          class="speaker-field__title-edit"-->
<!--          @click="isDisabled = !isDisabled"-->
<!--      >-->
<!--        <i class="bi bi-pencil-square"></i>-->
<!--      </b-button>-->
<!--      <b-button-->
<!--          v-else-->
<!--          variant="text"-->
<!--          class="speaker-field__title-save"-->
<!--          @click="isDisabled = !isDisabled"-->
<!--      >-->
<!--        збер.-->
<!--      </b-button>-->
<!--    </div>-->
<!--    <div class="speaker-field__name">{{ speaker.name }}</div>-->
<!--    <div class="speaker-field__email">{{ speaker.email }}</div>-->
<!--    <SelectInputGroup-->
<!--        v-if="roles"-->
<!--        label="Роль"-->
<!--        v-model="speakerRole"-->
<!--        :options="roles"-->
<!--        class="speaker-field__role"-->
<!--    />-->
<!--  </div>-->
    <div class="team-member__card">
        <div class="team-member__card--name">{{speaker.name}}</div>
        <div class="team-member__card--email">{{speaker.email}}</div>
    </div>
</template>
<style lang="scss">
.speaker-field {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 6px;
  border-radius: 4px;
  background: var(--white, #FFF);
    flex: 1;
  .speaker-field__role {
    margin-top: 10px;
    .form-label {
      padding: 0 0 8px 0!important;
    }
    .form-select {
      height: auto!important;
      padding: 8px 30px 8px 0!important;
    }
  }
  .speaker-field__title {
    display: flex;
    align-items: center;
    height: 18px;
    width: 100%;
    justify-content: space-between;

    span {
      color: var(--dark, #1E1E1E);
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      opacity: 0.5;
      height: 18px;
      max-width: 50%!important;
    }

    .speaker-field__title-input {
      border: none;
      background: transparent;
      padding: 0;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      height: 18px;
    }

    .speaker-field__title-edit {
      padding: 0;
      width: 50px;
      text-align: right;
    }

    .speaker-field__title-save {
      padding: 0;
      color: var(--green, #1DB954);
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 10px */
      width: 50px;
      text-align: right;
    }
  }

  .speaker-field__name {
    color: var(--green, #1DB954);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .speaker-field__email {
    color: var(--dark, #1E1E1E);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    opacity: 0.5;
  }
}
</style>
