<script setup>
import {computed, ref} from 'vue';
import {router} from '@inertiajs/vue3';
import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {useI18n} from "vue-i18n";
import useRequestProgress from '@/composables/useRequestProgress.js';

const isLoading = ref(false);
const isVisit = ref(false);
const {t} = useI18n();

const {
  isLoading: isRequestProgressLoading,
  setIsLoading
} = useRequestProgress()

router.on('start', (event) => {
  setIsLoading(true);
  isLoading.value = true;
  isVisit.value = event.detail.visit && event.detail.visit.method !== "get";
});

router.on('success', (event) => {
  if(isVisit.value) toast.success(t('request-success'), {
    autoClose: 2000,
    position: toast.POSITION.BOTTOM_CENTER,
    multiple: false
  });
  setIsLoading(false);
});

router.on('error', (errors) => {
  if(isVisit.value) toast.error(t('request-error'), {
    autoClose: 2000,
    position: toast.POSITION.BOTTOM_CENTER,
    multiple: false
  });
  setIsLoading(false);
});

router.on('invalid', (event) => {
  event.preventDefault()
});

router.on('exception', (event) => {
  event.preventDefault()
});

router.on('finish', (event) => {
  isLoading.value = false;
  isVisit.value = false;
  setIsLoading(false);
});
</script>

<template>
  <div v-if="isLoading || isRequestProgressLoading" class="request-handler">
    <div class="request-handler__spinner"></div>
  </div>
</template>

<style lang="scss">
.request-handler {
  position: fixed;
  background: color-mix(in srgb,  #1E1E1E, transparent 75%);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .request-handler__spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 8px solid #1DB954;
    border-top: 8px solid transparent;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
