<script setup>
  import {Head} from '@inertiajs/vue3';

  import RoadMapLayout from '@/Layouts/RoadMapLayout.vue';
  import ProgramModule from '@/Components/ProgramModule.vue';
  import { useWindowScroll } from '@vueuse/core'
  import {ref, onMounted, computed} from "vue";
  import {useDateFormatter} from "@/composables/useDateFormatter.js";
  import {useI18n} from "vue-i18n";
  import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";

  defineOptions({layout: RoadMapLayout})

  const props = defineProps({
    roadmap: Object,
    modules: Array,
    mentor: Object
  })

  const {t} = useI18n();

  const roadMapModulesWrapper = ref(null);
  const moduleWidth = ref(0);

  const { formatDate } = useDateFormatter()

  onMounted(() => {
    moduleWidth.value = roadMapModulesWrapper.value.firstElementChild.clientWidth;
  });

  const expand = ref(true);

  const { x, y } = useWindowScroll({ behavior: 'smooth' });

  const scrollRight = () => {
    x.value += moduleWidth.value;
  }

  const scrollLeft = () => {
    x.value -= moduleWidth.value;
  }

  const avatar = computed(() => props.mentor?.imageUrl ? props.mentor.imageUrl : noAvatar)

</script>

<template>
    <div class="road-map">
        <Head title="Менторство" />
        <div class="road-map__header" :class="{ 'road-map__header--collapsed' : !expand }">
          <div class="road-map__info">
            <div class="road-map__main-info">
              <div v-if="expand" class="road-map__tags">
<!--                <ProgramTag v-for="tag in tags" :label="tag" />-->
              </div>
              <div class="road-map__title">{{ roadmap.name }}</div>
            </div>
            <div class="road-map__description">
              <p v-if="expand">{{ roadmap.description }}</p>
              <div class="road-map__date">
<!--                <div>-->
<!--                  <span>&nbsp</span>&lt;!&ndash; Старт потоку &ndash;&gt;-->
<!--                  <span>{{ formatDate(roadmap.start_date, 'd MMMM') }}</span>-->
<!--                </div>-->
                <div v-if="roadmap.duration && roadmap.duration_type">
                  <span>Тривалість</span>
                  <span>{{ t(roadmap.duration_type, { count: parseInt(roadmap.duration, 10) }) }}</span>
                </div>
                <div>
                  <span>Модулів</span>
                  <span>{{ modules.length }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="road-map__mentor">
            <div class="d-flex align-items-center">
              <b-avatar v-if="mentor" variant="light" :src="avatar" class="road-map__avatar"></b-avatar>
              <div v-if="mentor" class="road-map__mentor-name">
                <span v-if="expand">Ментор</span>
                <div>{{ mentor.name }}</div>
              </div>
            </div>
            <a v-if="mentor && roadmap.contact_url" :href="roadmap.contact_url" target="_blank" class="road-map__contact-btn">
              Зв`язатись з ментором
            </a>
          </div>
        </div>
        <div class="road-map__content" :class="{ 'road-map__content--collapsed' : !expand }">
            <div class="road-map__modules-wrapper" ref="roadMapModulesWrapper">
                <ProgramModule
                    v-for="(module, index) in modules"
                    :key="module.id"
                    :module="module"
                    :index="index + 1"
                />
                <div class="program-summary-spacer"></div>
                <!--            <div class="roadmap__summary">-->
                <!--                <ProgramModule v-if="roadmap?.summary && roadmap.summary.length > 0" :summary="roadmap.summary" />-->
                <!--            </div>-->
<!--                <ProgramModule v-if="roadmap?.summary && roadmap.summary.length > 0" :summary="roadmap.summary" />-->
            </div>
            <div class="road-map__slider-buttons">
                <b-button variant="text" class="slider-button" @click="scrollLeft">
                    <i class="ph-caret-left-bold"></i>
                </b-button>
                <b-button variant="text" class="slider-button" @click="scrollRight">
                    <i class="ph-caret-right-bold"></i>
                </b-button>
                <div class="divider"></div>
                <b-button variant="text" class="slider-button" @click="expand = !expand">
                    <i v-if="expand" class="ph-arrows-out-bold"></i>
                    <i v-else class="ph-arrows-in-bold"></i>
                </b-button>
            </div>
        </div>
        <div class="roadmap__summary" :class="{ 'roadmap__summary--collapsed' : !expand }">
            <div class="program-module-summary">
                <span>Ви навчитеся</span>
                <p>{{roadmap.summary}}</p>
            </div>
        </div>
        <div class="road-map__footer">
          <span>by</span>
          <span class="road-map__site-name">Bestclevers</span>
          <span>LMS</span>
        </div>
        <svg class="polygon-1" xmlns="http://www.w3.org/2000/svg" width="603" height="404" viewBox="0 0 603 404" fill="none">
          <path opacity="0.2" d="M602.023 179.515L515.992 -118.828L214.974 -193.601L0.0161885 29.9605L86.0476 328.304L387.037 403.085L602.023 179.515Z" fill="url(#paint0_linear_1281_44551)"/>
          <defs>
            <linearGradient id="paint0_linear_1281_44551" x1="612.202" y1="-342.863" x2="74.5813" y2="367.828" gradientUnits="userSpaceOnUse">
              <stop stop-color="#31C162"/>
              <stop offset="1" stop-color="#31C162" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
        <svg class="polygon-2" xmlns="http://www.w3.org/2000/svg" width="574" height="299" viewBox="0 0 574 299" fill="none">
          <path opacity="0.2" d="M0.510192 227.789L107.531 496.53L393.856 538.187L573.136 311.113L466.115 42.3714L179.816 0.704223L0.510192 227.789Z" fill="url(#paint0_linear_1281_44550)"/>
          <defs>
            <linearGradient id="paint0_linear_1281_44550" x1="38.5632" y1="713.123" x2="471.961" y2="3.92845" gradientUnits="userSpaceOnUse">
              <stop stop-color="#31C162"/>
              <stop offset="1" stop-color="#31C162" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
        <svg class="polygon-3" xmlns="http://www.w3.org/2000/svg" width="573" height="408" viewBox="0 0 573 408" fill="none">
          <path opacity="0.2" d="M42.9696 31.8486L-97.6771 337.546L96.8306 612.239L431.956 581.222L572.602 275.525L378.124 0.844859L42.9696 31.8486Z" fill="url(#paint0_linear_1281_44552)"/>
          <defs>
            <linearGradient id="paint0_linear_1281_44552" x1="-335.508" y1="453.118" x2="609.686" y2="249.623" gradientUnits="userSpaceOnUse">
              <stop stop-color="#31C162"/>
              <stop offset="1" stop-color="#31C162" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
    </div>
</template>

<style lang="scss" scoped>
    $header-height: 256px;
    $header-collapse-height: 92px;
    $footer-height: 28px;
    .road-map {
      background: #F5F5F5;
      position: relative;
      .road-map__header {
        background: transparent;
        display: flex;
        height: $header-height;
        border-radius: 0 0 50px 50px;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 1000;
        .road-map__info {
          display: flex;
          background: var(--green, #1DB954);
          height: 100%;
          width: calc(100vw - 25%);
          .road-map__main-info {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 124px;
            .road-map__tags {
              display: flex;
            }
            .road-map__title {
              color: var(--white, #FFF);
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 57.6px */
              text-transform: uppercase;
            }
          }
          .road-map__description {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            & p {
              overflow: hidden;
              color: var(--white, #FFF);
              text-overflow: ellipsis;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 180%;
            }
            & .road-map__date {
              display: flex;
              & div {
                display: flex;
                padding: 12px 26px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1px;
                border-radius: 7px;
                border: 1px solid var(--stroke, #EBEBEB);
                margin-right: 7px;
                &:last-child {
                  margin-right: 0;
                }
                span {
                  overflow: hidden;
                  color: var(--white, #FFF);
                  text-overflow: ellipsis;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 180%;
                  &:last-child {
                    color: var(--white, #FFF);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                  }
                }
              }
            }
          }
        }
        .road-map__mentor {
          background: var(--white, #FFF);
          width: calc(100vw - 75%);
          overflow: hidden;
          padding: 0 87px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 1000;
          @media only screen and (max-width: 1368px) {
            padding: 0 16px;
          }
          .road-map__avatar {
            width: 65px;
            height: 65px;
          }
          .road-map__mentor-name {
            margin-left: 10px;
            span {
              color: var(--dark, #1E1E1E);
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 300;
              line-height: 100%;
              opacity: 0.5;
            }
            div {
              color: var(--dark, #1E1E1E);
              font-size: 26px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              @media only screen and (max-width: 1368px) {
                font-size: 22px;
              }
            }
          }
          .road-map__contact-btn {
            position: relative;
            z-index: 1005;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 50px;
            width: 100%;
            border-radius: 50px;
            background: #1DB954;
            cursor: pointer;
            font-family: e-Ukraine;
            font-size: 14px;
            font-weight: 400;
            color: #FFF;
            text-align: center;
            text-decoration: none;
            margin-top: 12px;
            @media only screen and (max-width: 1368px) {

            }
          }
        }
      }
      .road-map__header--collapsed {
        height: $header-collapse-height;
        .road-map__info {
          .road-map__main-info {
            .road-map__title {
              color: var(--white, #FFF);
              font-size: 28px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 33.6px */
              text-transform: uppercase;
            }
          }
          .road-map__description {
            & .road-map__date {
              display: flex;
              & div {
                span {
                  &:last-child {
                    color: var(--white, #FFF);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                  }
                }
              }
            }
          }
        }
        .road-map__mentor {
          .road-map__avatar {
            width: 25px;
            height: 25px;
          }
          .road-map__mentor-name {
            div {
              color: var(--dark, #1E1E1E);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
            }
          }
          .road-map__contact-btn {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
      .road-map__content {
        height: calc(100vh - $header-height - $footer-height);
        padding: 20px 0 54px 0;
        position: relative;
        top: $header-height;
        .road-map__modules-wrapper {
          margin: 20px 0;
          display: flex;
          width: fit-content;
          padding: 0 0 0 124px;
          height: calc(100vh - $header-height - $footer-height - 94px);
          border-top: 1px solid var(--green, #1DB954);
          .program-summary-spacer {
              display: flex;
              flex-direction: column;
              min-width: 500px;
              height: 100%;
              padding: 30px 61px 10px 21px;
              border-left: 1px solid var(--green, #1DB954);
              position: relative;
          }
        }
        .road-map__slider-buttons {
          position: fixed;
          left: 50%;
          transform: translate(-50%, -75%);
          display: flex;
          padding: 8px 12px;
          gap: 4px;
          border-radius: 100px;
          background: #FFF;
          box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.25);
          z-index: 1002;
          .divider {
            border-left: 1px solid #EBEBEB;
            height: 40px;
          }
          .slider-button {
            color: var(--green, #1DB954);
            height: 40px;
            width: 40px;
            font-size: 24px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--white, #FFF);
            &:hover {
              background: var(--green, #1DB954);
              color: var(--white, #FFF);
            }
          }
        }
      }
      .road-map__content--collapsed {
        top: $header-collapse-height;
        height: calc(100vh - $header-collapse-height - $footer-height - 14px);
        .road-map__modules-wrapper {
          height: calc(100vh - $header-collapse-height - $footer-height - 94px);
        }
      }
      .road-map__footer {
        text-align: center;
        padding: 8px 0;
        gap: 3px;
        background: var(--dark, #1E1E1E);
        height: $footer-height;
        line-height: 100%;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        span {
          color: var(--white, #FFF);
          opacity: 0.4;
        }
        .road-map__site-name {
          color: var(--green, #1DB954);
          &:before, &:after {
            content: " ";
          }
        }
      }
      .polygon-1 {
        position: fixed;
        top: 0;
        right: 10%;
        z-index: 999;
      }
      .polygon-2 {
        position: fixed;
        bottom: 0;
        right: 10%;
        z-index: 999;
      }
      .polygon-3 {
        position: fixed;
        bottom: -15%;
        left: 0;
        z-index: 999;
      }
    }
    .roadmap__summary {
        position: fixed;
        right: 0;
        top: $header-height;
        height: calc(100vh - $header-height - $footer-height - 54px);
        z-index: 1001;
        background: #F5F5F5;
        padding: 0 130px 0 0;
        margin-top: 40px;
        .program-module-summary {
            display: flex;
            padding: 28px 32px;
            width: 350px;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            flex: 1 0 0;
            align-self: stretch;
            border-radius: 4px;
            background: var(--green, #1DB954);
            span {
                color: var(--white, #FFF);
                font-size: 21px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }
            p {
                color: var(--white, #FFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 160%;
            }
        }
    }
    .roadmap__summary--collapsed {
        height: calc(100vh - $header-collapse-height - $footer-height - 14px);
        top: $header-collapse-height;
        padding: 0 130px 45px 0;
        margin-top: 40px;
    }
</style>
