<script setup>
import { useI18n } from "vue-i18n";
import InputGroup from "@/Components/InputGroup.vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import avatar from "../../../../images/tmp/ProductOwnerProfile/avatar.png";
import {
    email,
    helpers,
    minLength,
    required,
    sameAs,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { router, usePage } from "@inertiajs/vue3";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const { t } = useI18n();

const page = usePage();

const props = defineProps({
    form: Object,
    errors: Object,
});

const containsUpperCase = (value) => /[A-ZА-ЯЁ]/.test(value);
const containsLowerCase = (value) => /[a-zа-яё]/.test(value);

const containsDigit = (value) => /\d/.test(value);

const containsSpecialCharacters = (value) =>
    /[!@#$%^&*(),.?":{}|<>]/.test(value);

const containsNonCyrillic = (value) => !/[а-яА-ЯЁё]/u.test(value);

const lettersValidator = helpers.withMessage(
    "Пароль повинен містити маленькі та великі літери латинського алфавіту",
    (value) => {
        if (!value) return true;
        return (
            containsUpperCase(value) &&
            containsLowerCase(value) &&
            containsNonCyrillic(value)
        );
    }
);

const digitValidator = helpers.withMessage(
    "Пароль повинен містити хоча б одну цифру",
    (value) => {
        if (!value) return true;
        return containsDigit(value);
    }
);

const hasSpecialCharacters = helpers.withMessage(
    "Поле повинно містити хоча б один спеціальний символ.",
    (value) => {
        if (!value) return true;
        return containsSpecialCharacters(value);
    }
);

const rules = computed(() => ({
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    email: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        email: helpers.withMessage("Введіть дійсну електронну адресу", email),
    },
    phone: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    password: {
        // required: helpers.withMessage('Це поле є обов\'язковим', required),
        minLength: helpers.withMessage("Не менше 8 символів", minLength(6)),
        lettersValidator,
        digitValidator,
        hasSpecialCharacters,
    },
    password_confirmation: {
        // required: helpers.withMessage('Це поле є обов\'язковим', required),
        sameAs: helpers.withMessage(
            "Паролі повинні співпадати",
            sameAs(props.form.profile.password)
        ),
    },
}));

const v$ = useVuelidate(rules, props.form.profile, { $lazy: true });

const onSubmit = () => {
    v$.value.$touch();
    if (!v$.value.$invalid && !notNewEmail.value && !notValidPhone.value) {
        emit("save");
    }
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["personal-data-change", "save"]);

watch(
    () => props.form.profile,
    (value) => {
        nextTick(() => emit("personal-data-change", value));
    },
    { deep: true }
);

const notValidPhone = ref(false);
const phoneValidate = (data) => {
    v$.value["phone"].$touch();
    if (!v$.value["phone"].$invalid)
        notValidPhone.value = !data.isValid ? !data.isValid : false;
    else notValidPhone.value = false;
};

const isVeryfy = ref(false);
const notNewEmail = ref(false);

const verifyAvailability = async () => {
    let verifyProps = {
        column: "email",
        text: props.form.profile.email,
        user_id: props.form.profile.id,
    };
    isVeryfy.value = true;
    try {
        console.log(page.props.auth.user.current_account_type);
        if (
            page.props.auth.user.current_account_type === "ACCOUNT_TYPE_STUDENT"
        ) {
            const response = await axios.post(
                "/verify-student-availability",
                verifyProps
            );
        } else {
            const response = await axios.post(
                "/verify-availability",
                verifyProps
            );
        }
        console.log("verifyAvailability", response);
        isVeryfy.value = false;
        return !response.data.exists;
    } catch (error) {
        isVeryfy.value = false;
        return false;
    }
};

const checkEmail = () => {
    v$.value["email"].$touch();
    if (!v$.value["email"].$invalid && props.form.profile.email.length > 0) {
        (async () => {
            const result = await verifyAvailability();
            notNewEmail.value = result ? result : false;
        })();
    } else notNewEmail.value = false;
};

const cropAvatarModal = ref(false);

const loadImage = (event) => {
    const { files } = event.target;

    if (files && files[0]) {
        if (imageTemp.value.src) {
            URL.revokeObjectURL(imageTemp.value.src);
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
            imageTemp.value = {
                src: blob,
                type: getMimeType(e.target.result, files[0].type),
            };
        };
        reader.readAsArrayBuffer(files[0]);
        cropAvatarModal.value = true;
    }
};

const image = ref({
    src: null,
    type: null,
});

const imageTemp = ref({
    src: null,
    type: null,
});

function getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = "";
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

const avatarCropper = ref(null);
const file = ref(null);

const crop = () => {
    const { canvas } = avatarCropper.value.getResult();
    image.value = imageTemp.value;
    image.value.src = canvas.toDataURL(image.value.type);
    cropAvatarModal.value = false;
    //
    // canvas.toBlob((blob) => {
    //   image.value.src = URL.createObjectURL(blob);
    //   cropAvatarModal.value = false;
    // }, image.value.type);
    props.form.profile.filePreview = image.value.src;
};

onMounted(() => {
    if (page?.props?.auth?.user?.imageUrl)
        image.value.src = page.props.auth.user.imageUrl;
});

function removeImage() {
    console.log('removeImage', props.form)
        router.visit(route("delete_logo"), {
        method: "post",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: () => {
            image.value = {
                src: null,
                type: null,
            };
            file.value.value = "";
        },
    });
}

const removeAvatarConfirmModal = ref(false);
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="12" xl="8">
                <b-form>
                    <div class="user-info">
                        <div v-if="image.src" class="user-info__avatar-wrapper">
                            <img
                                v-if="image.src"
                                :src="image.src"
                                title="avatar"
                                alt="avatar"
                                class="user-info__avatar"
                                @click="
                                    file.value = '';
                                    $refs.file.click();
                                "
                            />
                            <i
                                v-if="page?.props?.auth?.user?.imageUrl"
                                class="bi bi-x-circle-fill avatar__remove-btn"
                                @click="removeAvatarConfirmModal = true"
                            ></i>
                        </div>
                        <img
                            v-else
                            :src="avatar"
                            title="avatar"
                            alt="avatar"
                            class="user-info__avatar"
                            @click="
                                file.value = '';
                                $refs.file.click();
                            "
                        />
                        <input
                            type="file"
                            ref="file"
                            @change="loadImage($event)"
                            accept="image/*"
                            style="display: none"
                        />
                        <b-modal
                            v-model="cropAvatarModal"
                            v-if="cropAvatarModal"
                            hide-footer
                            hide-header
                            centered
                        >
                            <div class="modal-body">
                                <div
                                    class="w-100 d-flex flex-column justify-content-center align-items-center"
                                >
                                    <cropper
                                        ref="avatarCropper"
                                        class="avatar-cropper"
                                        :src="imageTemp.src"
                                        :stencil-component="CircleStencil"
                                        :canvas="{
                                            height: 250,
                                            width: 250,
                                        }"
                                    />
                                    <b-button
                                        variant="primary"
                                        pill
                                        size="lg"
                                        class="mt-4"
                                        @click="crop"
                                    >
                                        Зберегти
                                    </b-button>
                                </div>
                            </div>
                        </b-modal>
                        <ConfirmModal
                            v-if="removeAvatarConfirmModal"
                            v-model="removeAvatarConfirmModal"
                            title="Видалити аватар"
                            decline-label="Скасувати"
                            accept-label="Так, видалити"
                            @accept-btn="removeImage"
                        >
                            <template v-slot:description>
                                Видалити аватар?
                            </template>
                        </ConfirmModal>
                        <div class="d-flex flex-column align-items-start">
                            <div class="user-info__fullname">
                                {{
                                    `${form.profile.name} ${form.profile.lastName}`
                                }}
                            </div>
                            <div class="user-info__email">
                                {{ form.profile.email }}
                            </div>
                        </div>
                    </div>
                    <b-card no-body class="shadow-none">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start"
                        >
                            <div class="d-flex justify-content-between w-100">
                                <InputGroup
                                    label="Ім’я"
                                    v-model="form.profile.name"
                                    size="lg"
                                    class="me-2 w-100"
                                    :is-error="
                                        !!errors.name || v$.name.$invalid
                                    "
                                    :error-message="
                                        errors.name ||
                                        v$.name?.$errors[0]?.$message
                                    "
                                    @blur="v$.name.$touch()"
                                />
                                <InputGroup
                                    label="Прізвище"
                                    v-model="form.profile.lastName"
                                    size="lg"
                                    class="ms-2 w-100"
                                    :is-error="!!errors.lastName"
                                    :error-message="errors.lastName"
                                />
                            </div>
                            <div
                                class="d-flex justify-content-between w-100 mt-2"
                            >
                                <InputGroup
                                    element="phone"
                                    label="Телефон"
                                    v-model="form.profile.phone"
                                    size="lg"
                                    class="me-2 w-100"
                                    :is-error="
                                        !!errors.phone ||
                                        v$.phone.$invalid ||
                                        notValidPhone
                                    "
                                    :error-message="
                                        errors.phone || notValidPhone
                                            ? 'Перевірте формат введення!'
                                            : v$.phone?.$errors[0]?.$message
                                    "
                                    @blur="phoneValidate"
                                />
                                <InputGroup
                                    label="Email"
                                    v-model="form.profile.email"
                                    size="lg"
                                    class="ms-2 w-100"
                                    :is-loading="isVeryfy"
                                    :is-error="
                                        !!errors.email || v$.email.$invalid
                                    "
                                    :error-message="
                                        notNewEmail
                                            ? 'Ця електронна пошта вже зареєстрована!'
                                            : errors.email ||
                                              v$.email?.$errors[0]?.$message
                                    "
                                    @blur="checkEmail"
                                />
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="fs card__title">Змінити пароль</div>
                            <div class="security-desc">
                                <p>
                                    Подбайте про безпеку свого паролю: він має
                                    бути не менше 8 символів, містити великі та
                                    маленькі латинські літери, щонайменше одну
                                    цифру та спеціальний символ.
                                </p>
                                <p>
                                    Уникайте особистої інформації: імені,
                                    прізвища, дати народження та номерів
                                    телефону. Не використовуйте прості
                                    послідовності на кшталт «12345678» чи
                                    «qwerty».
                                </p>
                            </div>
                            <div
                                class="d-flex justify-content-between w-100 gap-4 p-2"
                            >
                                <InputGroup
                                    label="Поточний пароль"
                                    v-model="form.profile.password_old"
                                    type="password"
                                    size="lg"
                                    class="w-100"
                                    autocomplete="new-password"
                                />
                                <InputGroup
                                    label="Новий пароль"
                                    v-model="form.profile.password"
                                    type="password"
                                    size="lg"
                                    placeholder="Новий пароль"
                                    class="w-100"
                                    :is-error="
                                        !!errors.password ||
                                        v$.password?.$invalid
                                    "
                                    :error-message="
                                        errors.password ||
                                        v$.password?.$errors[0]?.$message
                                    "
                                    @blur="v$.password?.$touch()"
                                />
                                <InputGroup
                                    label="Повторіть пароль"
                                    v-model="form.profile.password_confirmation"
                                    type="password"
                                    size="lg"
                                    placeholder="Повторіть пароль"
                                    class="w-100"
                                    :is-error="
                                        !!errors['password_confirmation'] ||
                                        v$['password_confirmation']?.$error
                                    "
                                    :error-message="
                                        errors['password_confirmation'] ||
                                        v$['password_confirmation']?.$errors[0]
                                            ?.$message
                                    "
                                    @blur="
                                        v$['password_confirmation']?.$touch()
                                    "
                                />
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-button
                        variant="primary"
                        pill
                        size="lg"
                        class="mt-4"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.user-info {
    display: flex;
    align-items: center;

    .user-info__avatar {
        width: 134px;
        height: 134px;
        border-radius: 67px;
        flex-shrink: 0;
        margin: 0 20px;
        cursor: pointer;
        object-fit: cover;
    }

    .user-info__fullname {
        color: var(--dark, #1e1e1e);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 5px;
    }

    .user-info__email {
        color: var(--Grey, #c2c2c2);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        margin-top: 5px;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

.security-desc {
    padding: 12px 20px 12px 20px;
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--tb-primary);
    background-color: #1db9540a;
    margin-top: 25px;

    p {
        font-family: e-Ukraine;
        font-size: 13px;
        font-weight: 300;
        line-height: 16.9px;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.user-info__avatar-wrapper {
    position: relative;
    width: 134px;
    height: 134px;
    margin: 0 20px;

    .avatar__remove-btn {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        line-height: 20px;
        opacity: 50%;
        cursor: pointer;

        &:hover {
            opacity: 100%;
        }
    }

    .user-info__avatar {
        margin: 0 !important;
    }
}

.avatar-cropper {
    width: 500px;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    background: #f7f7f8;
    border: 1px solid #ebebeb;
}
</style>
