<script setup>
import sessions from '../../images/empty-data/sessions.png'
import webinar from '../../images/empty-data/webinar.png'
import program from '../../images/empty-data/program.png'
import masterclass from '../../images/empty-data/masterclass.png'
import cloud from '../../images/empty-data/cloud.png'

const props = defineProps({
  type: {
    type: String,
    default: 'standing'
  },
  arrow: String,
});

const icons = {
    sessions: sessions,
    webinar: webinar,
    program: program,
    masterclass: masterclass,
    cloud: cloud
}

</script>

<template>
  <div class="empty-data">
    <div class="empty-data__body">
        <div class="empty-data__img">
             <img :src="icons[type]" :alt="type" />
        </div>
        <div class="empty-data__title">
            <slot name="title" />
        </div>
        <div class="empty-data__description">
            <slot name="description" />
        </div>
    </div>
    <div class="empty-data__action">
      <slot name="action" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
 .empty-data {
   width: 500px;
display: flex;
     flex-direction: column;
     gap: 32px;
     .empty-data__body {
         display: flex;
         flex-direction: column;
         gap: 12px;
         padding: 40px 24px;
         border-radius: 12px;
         background: #F7F7F8;
         .empty-data__img {
             display: flex;
             justify-content: center;
             padding: 10px;
         }
         .empty-data__title {
             color: var(--dark, #1E1E1E);
             font-family: e-Ukraine;
             font-size: 22px;
             font-weight: 500;
             line-height: 26.4px;
             text-align: center;

         }
         .empty-data__description {
             color: var(--dark, #1E1E1E);
             font-family: e-Ukraine;
             font-size: 12px;
             font-weight: 300;
             line-height: 16.8px;
             text-align: center;
             opacity: 0.4;
         }
     }

   .empty-data__action {
     display: flex;
     justify-content: center;
   }
 }
</style>
