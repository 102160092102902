<script setup>
import {computed, ref} from "vue";
import InputGroup from "@/Components/InputGroup.vue";

  const props = defineProps({
    modelValue: Boolean,
    modalTitle: String,
    inputLabel: String,
    buttonText: String,
    maxLength: String
  });

  const emit = defineEmits([
    'update:modelValue',
    'sendInput'
  ]);

  const isVisible = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })

const input = ref('');

const handleSend = () => {
  emit('sendInput', input.value);
  isVisible.value = !isVisible.value;
}

</script>

<template>
  <b-modal v-model="isVisible" v-if="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h3>{{ modalTitle }}</h3>
        <h3><i class="bi bi-x-lg cursor-pointer" @click="isVisible = !isVisible"></i></h3>
      </div>
      <InputGroup
        :label="inputLabel"
        v-model="input"
        size="lg"
        :max-length="maxLength"
      />
      <div class="d-flex justify-content-end">
        <b-button variant="primary" size="md" pill class="mt-3" @click="handleSend">
          {{ buttonText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>

</style>
