<script setup>
import Multiselect from "@vueform/multiselect";
import {computed, reactive, watch} from "vue";

const props = defineProps({
    types: Array,
    entitiesOptions: Array,
})

const emit = defineEmits(["apply-filter"])

const contactSearchForm = reactive({
    type: null,
    value: null,
    search: null,
})

const entitiesOptionsFiltered = computed(() => {
    return props.entitiesOptions
        ? props.entitiesOptions.filter((item) => {
            return !contactSearchForm.type || item.type === contactSearchForm.type
        })
        : []
})

watch(() => contactSearchForm.type, () => {
    contactSearchForm.value = null
})

const onSearchClicked = () => {
    emit('apply-filter', contactSearchForm)
}

</script>

<template>
    <div class="chat-list__filter">
        <div class="search-box mt-1">
            <b-form-input
                v-model="contactSearchForm.search"
                type="text"
                class="form-control bg-light border-light rounded-3"
                placeholder="Шукати по імені"
            ></b-form-input>
            <i class="ri-search-2-line search-icon"></i>
        </div>
        <div class="mt-1">
            <Multiselect
                v-model="contactSearchForm.type"
                placeholder="Тип"
                label="title"
                valueProp="value"
                :options="types"
            />
        </div>
        <div class="mt-1">
            <Multiselect
                v-model="contactSearchForm.type"
                placeholder="Оберіть курс, воркшоп, МП"
                label="title"
                valueProp="value"
                :options="entitiesOptionsFiltered"
                :searchable="true"
            />
        </div>
        <b-button variant="success" class="mt-1 w-100" @click="onSearchClicked">Шукати</b-button>
    </div>
</template>

<style scoped lang="scss">

</style>
