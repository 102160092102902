<script setup>

import TabFormGeneralInfo from '@/Pages/Courses/Partials/TabFormGeneralInfo.vue';
import TabFormContent from '@/Pages/Courses/Partials/TabFormContent.vue';
import TabFormRegisterInfo from '@/Pages/Courses/Partials/TabFormRegisterInfo.vue';
import TabFormUsers from '@/Pages/Courses/Partials/TabFormUsers.vue';
import TabFormManagement from '@/Pages/Courses/Partials/TabFormManagement.vue';

const props = defineProps({
    statuses: Object,
    course: Object,
    team: Object,
    form: Object,
    certificates_type: Object,
    courseUsers: Object,
    students: Object,
    link: '#',
    isNew: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['save']);
const save = () => {
    emit('save');
};

</script>

<template>
    <b-tabs nav-class="page-tabs">
        <b-tab title="Основна інформація" active>
            <TabFormGeneralInfo :link="link" :form="form" :statuses="statuses" @save="save"/>
        </b-tab>
        <b-tab title="Контент курсу" :disabled="isNew">
            <TabFormContent v-if="!isNew" :form="form"/>
        </b-tab>
        <!--    <b-tab title="Реєстрація" :disabled="isNew">-->
        <!--      <TabFormRegisterInfo v-if="!isNew" :form="form" @save="save"/>-->
        <!--    </b-tab>-->
        <b-tab title="Управління " :disabled="isNew">
            <TabFormManagement v-if="!isNew" :form="form" :team="team" :certificates_type="certificates_type"
                               @save="save"/>
        </b-tab>
        <b-tab title="Користувачі" :disabled="isNew">
            <template #title>
                Користувачі
                <div v-if="!isNew && students && students.length > 0" class="nav-link__counter">{{ students.length }}
                </div>
            </template>
            <TabFormUsers v-if="!isNew" :form="form" :users="courseUsers" :students="students"/>
        </b-tab>

        <template #tabs-end v-if="!isNew">
            <div class="tabs-end">
                <b-button :href="route('courses.preview',{id:course.id})" target="_blank" type="button" variant="link"
                          class="d-flex align-items-center tabs-end__action-btn">
                    Попередній перегляд
                    <span class="tabs-end__action-icon">
            <i class="ph-eye label-icon align-middle rounded-pill"></i>
          </span>
                </b-button>
            </div>
        </template>
    </b-tabs>
</template>

<style scoped lang="scss">

</style>
