<script setup>
import { useForm } from "@inertiajs/vue3";
import { ref } from "vue";
import CustomFieldModal from "@/Components/modals/CustomFieldModal.vue";
import CustomFormField from "@/Components/form/CustomFormField.vue";
import PaymentMethodField from "@/Components/form/PaymentMethodField.vue";
import PaymentMethodModal from "@/Components/modals/PaymentMethodModal.vue";
import { nextTick, watch } from "vue";

const customFieldModal = ref(false);
const paymentMethodsModal = ref(false);

const tempPaymentMethod = ref(null);

const props = defineProps({
    form: Object,
});

function saveTempCustomFields(tempRegisterFields) {
    //props.form.workShop.registerFields = props.form.workShop.registerFields.concat(tempRegisterFields)
    props.form.workShop.registerFields = [
        ...new Set([
            ...props.form.workShop.registerFields,
            ...tempRegisterFields,
        ]),
    ];
    customFieldModal.value = false;
}

const onCustomFieldRemove = (id) => {
    const index = props.form.workShop.registerFields.findIndex(
        (el) => el.id === id
    );
    if (index >= 0) {
        props.form.workShop.registerFields.splice(index, 1);
    }
};
const onPaymentMethodsRemove = (id) => {
    const index = props.form.workShop.paymentFields.findIndex(
        (el) => el.id === id
    );
    if (index >= 0) {
        props.form.workShop.paymentFields.splice(index, 1);
    }
};
const onPaymentMethodsEdit = (field) => {
    tempPaymentMethod.value = field;
    paymentMethodsModal.value = true;
};
const onPaymentMethodsAdd = () => {
    tempPaymentMethod.value = {
        id: new Date().valueOf(),
        name: "",
        value: "",
    };
    paymentMethodsModal.value = true;
};

const onSubmit = () => {
    emit("save");
};

const emit = defineEmits(["workShop-change", "save"]);
function savePaymentMethods(paymentMethod) {
    const index = props.form.workShop.paymentFields.findIndex(
        (el) => el.id === paymentMethod.id
    );
    if (index >= 0) props.form.workShop.paymentFields[index] = paymentMethod;
    else props.form.workShop.paymentFields.push(paymentMethod);
    tempPaymentMethod.value = null;
    paymentMethodsModal.value = false;
}
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="5">
                <b-card no-body class="shadow-none mt-3">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-4"
                    >
                        <div class="fs mb-3">
                            Поля для реєстрації
                            <i
                                id="registration-fields-tooltip"
                                class="bi bi-info-circle"
                            ></i>
                        </div>
                        <b-tooltip
                            class="text-left"
                            target="registration-fields-tooltip"
                            custom-class="webinar-tooltip"
                            offset="5"
                            triggers="hover"
                        >
                            Поля, які відвідувачі будуть заповнювати під час
                            реєстрації. Окрім базових, ви можете додати власні
                            поля, щоб адаптувати реєстрацію під ваші потреби.
                        </b-tooltip>
                        <div class="p-4 bg-white rounded-3 w-100">
                            <div
                                class="d-flex align-items-center"
                                v-for="field in props.form.workShop
                                    .registerFields"
                            >
                                <CustomFormField
                                    :form-field="field"
                                    @remove-by-id="onCustomFieldRemove"
                                />
                            </div>
                            <div
                                class="fs-6 text-success py-3"
                                @click="customFieldModal = !customFieldModal"
                            >
                                Додати поля
                            </div>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card no-body class="overflow-hidden shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                    >
                        <b-form-group id="input-group-3" clex-colulass="my-4">
                            <b-form-checkbox
                                v-model="props.form.workShop.config.is_paid"
                                :unchecked-value="0"
                            >
                                Зробити вебінар платним
                                <i id="tooltip-1" class="bi bi-info-circle"></i>
                            </b-form-checkbox>
                        </b-form-group>
                        <div
                            v-if="props.form.workShop.config.is_paid"
                            class="w-100"
                        >
                            <div class="fs my-3">
                                Вартість вебінару
                                <i id="tooltip-3" class="bi bi-info-circle"></i>
                            </div>
                            <div class="p-3 bg-white rounded-3 w-100">
                                <b-form-input
                                    v-model="props.form.workShop.config.price"
                                    placeholder="Вартість (грн)"
                                ></b-form-input>
                            </div>
                            <div class="fs my-3">
                                Реквізити
                                <i id="tooltip-3" class="bi bi-info-circle"></i>
                            </div>
                            <div
                                v-for="paymentMethod in props.form.workShop
                                    .paymentFields"
                            >
                                <PaymentMethodField
                                    :form-field="paymentMethod"
                                    @remove-by-id="onPaymentMethodsRemove"
                                    @edit="onPaymentMethodsEdit"
                                />
                            </div>
                            <b-button
                                variant="text-variant"
                                pill
                                size="lg"
                                class="fs-6 mt-2 text-primary"
                                @click="onPaymentMethodsAdd"
                            >
                                Додати реквізити
                            </b-button>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                    >
                        <div class="fs mb-2">
                            Ліміт реєстрацій
                            <i
                                id="registration-limit-tooltip"
                                class="bi bi-info-circle"
                            ></i>
                        </div>
                        <b-tooltip
                            class="text-left"
                            target="registration-limit-tooltip"
                            custom-class="webinar-tooltip"
                            offset="5"
                            triggers="hover"
                        >
                            Максимальна кількість людей, що зможе увійти в
                            кімнату вебінару.
                        </b-tooltip>
                        <b-input-group class="webinar-input-group">
                            <b-form-input
                                class="webinar-form-input"
                                type="number"
                                step="1"
                                v-model="
                                    props.form.workShop.config
                                        .registration_limit
                                "
                            ></b-form-input>
                        </b-input-group>
                    </b-card-body>
                </b-card>

                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3"
                    @click="onSubmit"
                >
                    Зберегти
                </b-button>
            </b-col>
        </b-row>
    </b-container>

    <CustomFieldModal
        v-model="customFieldModal"
        @on-save="saveTempCustomFields"
    />
    <PaymentMethodModal
        v-model="paymentMethodsModal"
        :form-fields="tempPaymentMethod"
        @on-save="savePaymentMethods"
    />
</template>

<style scoped></style>
