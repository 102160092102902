<script setup>
import { computed, ref, watchEffect } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const props = defineProps({
    formFields: Object,
    index: Number,
});

const emit = defineEmits(["removeById"]);

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const addLesson = () => {
    props.formFields.lessons.push({
        id: new Date().valueOf(),
        title: "",
        order: props?.formFields?.lessons?.length + 1 || 1,
    });
};

const removeLesson = (id) => {
    const index = props.formFields.lessons.findIndex((el) => el.id === id);
    if (index >= 0) {
        props.formFields.lessons.splice(index, 1);
    }
};

const removeModuleById = () => {
    emit("removeById", props.formFields.id);
};

const collapsed = ref(true);

const lessonsCount = ref(0);

watchEffect(() => {
    lessonsCount.value = props.formFields?.lessons?.length || 0;
});

const removeModuleModal = ref(false);
</script>

<template>
    <b-card
        no-body
        class="overflow-hidden shadow-none bg-light rounded-3 mt-4 w-50"
    >
        <b-card-body class="mentor-program-module__body">
            <div class="mentor-program-module__header">
                <div class="mentor-program-module__title">
                    Модуль {{ index < 11 ? `0${index + 1}` : index + 1 }}
                    <button
                        :disabled="isReadOnly"
                        @click="removeModuleModal = true"
                    >
                        <i class="ri-close-fill"></i>
                    </button>
                </div>
                <div
                    class="mentor-program-module__collapse"
                    @click="collapsed = !collapsed"
                >
                    {{ collapsed ? "Згорнути" : "Розгорнути" }}
                    <i v-if="collapsed" class="ri-arrow-up-s-fill"></i>
                    <i v-else class="ri-arrow-down-s-fill"></i>
                </div>
            </div>
            <b-collapse v-model="collapsed" class="w-100">
                <div class="mentor-program-module__block">
                    <div class="d-flex w-100">
                        <b-form-group class="w-100" label="Назва модуля">
                            <b-form-input
                                v-model="formFields.name"
                                :disabled="isReadOnly"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="mentor-program-module__block">
                    <div
                        class="d-flex w-100 mentor-program-module__lesson"
                        v-for="(lesson, index) in formFields.lessons"
                        :key="lesson.id"
                    >
                        <b-form-group
                            class="w-100"
                            :label="`Заняття ${index + 1}`"
                        >
                            <b-form-input
                                v-model="lesson.title"
                                :disabled="isReadOnly"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                        <button
                            :disabled="isReadOnly"
                            @click="removeLesson(lesson.id)"
                        >
                            <i class="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <button
                        :disabled="isReadOnly"
                        class="mentor-program-module__add-lesson"
                        @click="addLesson"
                    >
                        <i class="ph-plus"></i>
                        Додати заняття
                    </button>
                </div>
                <div
                    class="mentor-program-module__block mentor-program-module__block--last"
                >
                    <InputGroup
                        element="textarea"
                        label="Результати модуля"
                        v-model="formFields.summary"
                        :rows="4"
                        :max-rows="10"
                        :max-length="255"
                        :disabled="isReadOnly"
                        size="lg"
                        class="mt-3 w-100"
                    />
                </div>
            </b-collapse>
        </b-card-body>
    </b-card>
    <ConfirmModal
        v-if="removeModuleModal"
        v-model="removeModuleModal"
        title="Видалення модуля"
        decline-label="Скасувати"
        accept-label="Так, видалити"
        @accept-btn="() => removeModuleById()"
        @decline-btn="() => console.log('decline')"
    >
        <template v-slot:description>
            Після видалення модуля його не можна буде повернути. Ви впевнені, що
            хочете продовжити?
        </template>
    </ConfirmModal>
</template>
<style lang="scss" scoped>
.mentor-program-module__body {
    display: flex;
    padding: 22px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--fields_color, #f7f7f8);
    .mentor-program-module__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mentor-program-module__title {
            color: var(--dark, #1e1e1e);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            display: flex;
            align-items: center;
            i {
                font-size: 18px;
                margin-left: 8px;
                cursor: pointer;
            }
        }
        .mentor-program-module__collapse {
            color: #8b8b8b;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
                font-size: 20px;
            }
        }
    }
    .mentor-program-module__block {
        margin-bottom: 12px;
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white, #fff);
        .amount-field {
            background-color: var(--white, #fff) !important;
            cursor: not-allowed;
            .form-control {
                background-color: var(--white, #fff) !important;
                cursor: not-allowed;
            }
        }
        .mentor-program-module__add-lesson {
            color: var(--purple, #f24822);
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            cursor: pointer;
            i {
                font-size: 12px;
            }
        }
        .mentor-program-module__lesson {
            margin: 6px 0;
        }
        .mentor-program-module__text-area__counter {
            color: var(--dark, #1e1e1e);
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            opacity: 0.5;
        }
        .mentor-program-module__input__text {
            color: var(--dark, #1e1e1e);
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
        }
    }
    .mentor-program-module__block--last {
        margin: 0;
    }
}
</style>
