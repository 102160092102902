<script setup>
import {useForm} from "@inertiajs/vue3";
import CustomFormField from '@/Components/form/CustomFormField.vue';
import {computed, ref} from 'vue';
import CustomFieldModal from '@/Components/modals/CustomFieldModal.vue';
import PaymentMethodField from '@/Components/form/PaymentMethodField.vue';
import PaymentMethodModal from '@/Components/modals/PaymentMethodModal.vue';

const props = defineProps({
  form: Object,
})


const onCustomFieldRemove = (id) => {
  const index = props.form.course.registerFields.findIndex((el) => el.id === id);
  if (index >= 0) {
    props.form.course.registerFields.splice(index, 1)
  }
}

const customFieldModal = ref(false);
const tempPaymentMethod = ref(null);
const paymentMethodsModal = ref(false);

function saveTempCustomFields(tempRegisterFields) {
  props.form.course.registerFields = [...new Set([...props.form.course.registerFields, ...tempRegisterFields])];
  customFieldModal.value = false
}

const onPaymentMethodsRemove = (id) => {
  const index = props.form.course.paymentFields.findIndex((el) => el.id === id);
  if (index >= 0) {
    props.form.course.paymentFields.splice(index, 1)
  }
}
const onPaymentMethodsEdit = (field) => {
  tempPaymentMethod.value = field;
  paymentMethodsModal.value = true;
}
const onPaymentMethodsAdd = () => {
  tempPaymentMethod.value = {
    id: new Date().valueOf(),
    name: '',
    value: ''
  };
  paymentMethodsModal.value = true;
}

function savePaymentMethods(paymentMethod) {
  const index = props.form.course.paymentFields.findIndex((el) => el.id === paymentMethod.id);
  if (index >= 0) props.form.course.paymentFields[index] = paymentMethod
  else props.form.course.paymentFields.push(paymentMethod)
  tempPaymentMethod.value = null;
  paymentMethodsModal.value = false;
}

const emit = defineEmits(['save']);
const save = () => {
  emit('save');
};

const registrationLimitInput = computed({
  get() {
    return props.form.course.config.registration_limit === 0 || props.form.course.config.registration_limit === '0' ? '' : props.form.course.config.registration_limit;
  },
  set(value) {
    props.form.course.config.registration_limit = value ? parseInt(value) : 0;
  },
});

const coursePrice = computed({
  get() {
    return props.form.course.config.price === 0 || props.form.course.config.price === '0' ? '' : props.form.course.config.price;
  },
  set(value) {
    props.form.course.config.price = value ? parseFloat(value) : 0;
  },
});

</script>

<template>
  <b-container fluid class="tab-form">
    <b-row>
      <b-col cols="5">
        <b-card no-body class="overflow-hidden shadow-none mt-4">
          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-4">
            <div class="fs mb-3">Поля для реєстрації <i id="registration-fields-tooltip" class="bi bi-info-circle"></i>
            </div>
            <b-tooltip class="text-left" target="registration-fields-tooltip" custom-class="custom-tooltip"
                       offset="5" triggers="hover">
              Поля, які будуть заповнювати <Br />користувачі під час реєстрації
            </b-tooltip>
            <div class="p-4 bg-white rounded-3 w-100">
              <div class="d-flex align-items-center" v-for="field in props.form.course.registerFields">
                <CustomFormField :form-field="field" @remove-by-id="onCustomFieldRemove"/>
              </div>
              <div class="fs-6 text-primary py-3" @click="customFieldModal = !customFieldModal">Додати поля</div>
            </div>
            <CustomFieldModal v-model="customFieldModal" @on-save="saveTempCustomFields"/>
          </b-card-body>
        </b-card>

        <b-card no-body class="shadow-none mt-4">
          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">
            <b-form-group id="input-group-3" clex-colulass="my-4">
              <b-form-checkbox v-model="props.form.course.config.is_paid" :unchecked-value="0" :value="1">
                Зробити курс платним <i id="make-paid-course" class="bi bi-info-circle"></i>
              </b-form-checkbox>
              <b-tooltip class="text-left" target="make-paid-course" custom-class="custom-tooltip" offset="5" triggers="hover">
                Ви можете вказати вартість входу на курс
              </b-tooltip>
            </b-form-group>
            <div v-if="props.form.course.config.is_paid" class="w-100">
              <div class="fs my-3">Вартість курсу <i id="course-price" class="bi bi-info-circle"></i></div>
              <b-tooltip class="text-left" target="course-price" custom-class="custom-tooltip" offset="5" triggers="hover">
                Ціна входу на курс
              </b-tooltip>
              <div class="p-3 bg-white rounded-3 w-100">
                <b-form-input v-model="coursePrice" placeholder="Вартість (грн)"></b-form-input>
              </div>
              <div class="fs my-3">Реквізити <i id="payment-fields" class="bi bi-info-circle"></i></div>
              <b-tooltip class="text-left" target="payment-fields" custom-class="custom-tooltip" offset="5" triggers="hover">
                Вкажіть реквізити, щоб студенти<Br /> могли оплатити курс. Рекомендуємо<Br /> вказувати не більше 3-х рахунків
              </b-tooltip>
              <div v-for="paymentMethod in props.form.course.paymentFields">
                <PaymentMethodField
                    :form-field="paymentMethod"
                    @remove-by-id="onPaymentMethodsRemove"
                    @edit="onPaymentMethodsEdit"
                />
              </div>
              <b-button
                  variant="text-variant"
                  pill size="lg"
                  class="fs-6 mt-2 text-primary"
                  @click="onPaymentMethodsAdd"
              >
                Додати реквізити
              </b-button>
            </div>
            <PaymentMethodModal v-model="paymentMethodsModal" :form-fields="tempPaymentMethod"
                                @on-save="savePaymentMethods"/>
          </b-card-body>
        </b-card>

        <b-card no-body class="shadow-none mt-4">
          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">
            <div class="fs">Ліміт реєстрацій <i id="registration-limit-tooltip" class="bi bi-info-circle"></i></div>
            <b-tooltip class="text-left" target="registration-limit-tooltip" custom-class="custom-tooltip" offset="5" triggers="hover">
              Максимальна кількість людей на курсі.
            </b-tooltip>
            <div class="alert alert-primary w-100 mt-3" role="alert" v-if="registrationLimitInput <= 0">
              Ліміт реєстрацій не встановлено
            </div>
            <b-input-group class="autowebinar-input-group">
              <b-form-input class="autowebinar-form-input" type="number" step="1"
                            v-model="registrationLimitInput" placeholder="Ліміт реєстрацій не встановлено">
              </b-form-input>
            </b-input-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-button :disabled="props.form.processing" variant="primary" size="lg" pill class="mt-3"
              @click="save">
      Зберегти
    </b-button>
  </b-container>
</template>

<style scoped>

</style>
