<script setup>
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import Session from "@/Pages/Students/Partials/Session.vue";
import img from "../../../../images/character/sit-lying.svg";
import imgArrow from "../../../../images/character/arrow-down-right.svg";
import EmptyData from "@/Components/EmptyData.vue";
import {
    useDateFormatter,
    useIsToday,
} from "@/composables/useDateFormatter.js";
import { ref, watch } from "vue";
import { router } from "@inertiajs/vue3";

const props = defineProps({
    sessions: Object,
});

const empty = false;

const currentPage = ref(null);

const emit = defineEmits(["set-schedule"]);

const { formatDate } = useDateFormatter();
const { isDateToday } = useIsToday();

const setPage = (e, page) => {
    router.visit(route("student.student_mentoring", { page: page }), {
        method: "get",
        preserveState: false,
        preserveScroll: false,
        forceFormData: true,
    });
};
</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div
                v-if="
                    sessions &&
                    sessions.data &&
                    Object.keys(sessions.data).length > 0
                "
            >
                <!--  .sort((a, b) => new Date(a) - new Date(b))-->
                <div v-for="day in Object.keys(sessions.data)" :key="day">
                    <div class="sessions__divider">
                        {{ isDateToday(day) ? "Сьогодні, " : ""
                        }}{{ formatDate(day) }}
                    </div>
                    <Session
                        v-for="session in sessions.data[day]"
                        :session="session"
                        :disable="!isDateToday(day)"
                    />
                </div>
                <b-pagination
                    class="mt-4"
                    :total-rows="sessions.pagination.total"
                    :per-page="sessions.pagination.per_page"
                    aria-controls="my-table"
                    @page-click="setPage"
                ></b-pagination>
            </div>
            <div
                v-else
                class="w-100 d-flex justify-content-center align-content-center"
            >
                <EmptyData type="sessions">
                    <template v-slot:title> Тут поки що нічого немає </template>
                    <template v-slot:description>
                        Ви можете забронювати собі сессію з ментором в розділі
                        "Менторські програми"
                    </template>
                </EmptyData>
            </div>
            <!--        <pre>{{sessions.data}}</pre>-->
        </b-form>
    </b-container>
</template>

<style lang="scss" scoped>
.sessions__empty-data {
    margin: 0 auto;
    padding: 25px 0;
    width: calc(100vh - 300px);
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    img {
        height: calc(100vh - 600px);
    }
    .sessions__empty-data__title {
        color: var(--dark, #1e1e1e);
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 26px */
    }
    .sessions__empty-data__description {
        color: var(--dark, #1e1e1e);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
        opacity: 0.4;
        margin-top: 12px;
    }
    .sessions__empty-data__actions {
        margin-top: 32px;
    }
    .sessions__empty-data__arrow {
        position: relative;
    }
}
</style>
