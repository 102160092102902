
export function useRoleChecker() {
    const hasReadOnlyAccess = (userRoles = [], additionalRoles = [], additionalRestrictedRole = null) => {
        const restrictedRoles = ["OWNER", "DIRECTOR", "EXPERT"];

        if (additionalRestrictedRole && additionalRoles.includes(additionalRestrictedRole)) {
            return false;
        }

        const combinedRoles = [...userRoles, ...additionalRoles];
        return !combinedRoles.some(role => restrictedRoles.includes(role));
    }

    const isStudent = (user) => {

        return user && user.current_account_type && user.current_account_type === 'ACCOUNT_TYPE_STUDENT';
    }

    return {
        hasReadOnlyAccess,
        isStudent,
    }
}
