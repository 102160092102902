import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

if (import.meta.env.VITE_BROADCAST_CONNECTION) {
    window.Echo = new Echo({
        broadcaster: import.meta.env.VITE_BROADCAST_CONNECTION,
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        wssPort: import.meta.env.VITE_PUSHER_PORT,
        forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'http') === 'https',
        useTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'http') === 'https',
        enabledTransports: ['ws', 'wss'],
        namespace: '',
    });
}
