<script setup>
import axios from "axios";
import { computed, ref } from "vue";
import {
    compareNewTimeWithSpecifiedTime,
    diffToMilliseconds,
    fromMillisecondsToObjectTime,
    getVideoId,
} from "./helpers.js";
import { useI18n } from "vue-i18n";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker";

const props = defineProps({
    event: Object,
    startEventTime: String,
    finishEventTime: String,
    actionStatuses: Object,
    eventTypes: Object,
});

const emit = defineEmits([
    "setIsLoading",
    "replacementEvent",
    "openWebinarEventEditModal",
    "setPenultimateEventTime",
    "openRemoveEventModal",
]);

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

// Вычисляемое свойство для хранения миллисекунд
const diffMilliseconds = computed(() =>
    diffToMilliseconds(props.event.created_at, props.startEventTime)
);
// const isPlusEvent = ref(diffMilliseconds.value >= 0 ? true : false);

const isPlusEvent = computed(() =>
    diffMilliseconds.value >= 0 ? true : false
);

// Вычисляемое свойство для хранения форматированного времени
const formattedTime = computed(() =>
    fromMillisecondsToObjectTime(diffMilliseconds.value)
);

const { t } = useI18n();

const thumbnailUrl = computed(() => {
    if (props.event.type !== props.eventTypes["START_WEBINAR"].value)
        return null;

    if (!props.event.data?.work_shop_config?.meta?.broadcast) return null;

    const videoId = getVideoId(
        props.event.data.work_shop_config.meta.broadcast
    );
    const url = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    return url;
});
const slideUrl = computed(() => {
    if (
        props.event.type !==
            props.eventTypes["CHANGE_SLIDE_PRESENTATION"].value &&
        props.event.type !== props.eventTypes["CHANGE_PRESENTATION"].value
    )
        return null;

    if (!props.event.data?.current_slide_url) return null;

    // const url = props.event.data?.work_shop_config?.meta?.current_slide_url;

    const url = `${import.meta.env.VITE_AWS_CDN}/${
        props.event.data?.current_slide_url
    }`;
    return url;
});

// const changeDeletedStatusForEvent = async (status) => {
//     emit("setIsLoading", true);
//     const response = await axios.post(
//         route("workshops.event-edit", {
//             id: props.event.id,
//         }),
//         {
//             changeData: {
//                 deleted: !!status,
//             },
//         }
//     );

//     emit("replacementEvent", response.data.event);

//     if (!!response.data?.penultimateEvent) {
//         emit(
//             "setPenultimateEventTime",
//             response.data.penultimateEvent.created_at
//         );
//     }
//     emit("setIsLoading", false);
// };

// const deleteEvent = async () => await changeDeletedStatusForEvent(true);
// const restoreEvent = async () => await changeDeletedStatusForEvent(false);

const deleteEvent = async () => emit("openRemoveEventModal");

const isDisabledRestore = computed(() =>
    compareNewTimeWithSpecifiedTime(
        diffMilliseconds.value,
        props.startEventTime,
        props.finishEventTime
    )
);
</script>

<template>
    <div class="session event">
        <div
            class="event__type"
            :class="{
                'session__label--blue': !isPlusEvent,
                'session__label--green': isPlusEvent,
            }"
        >
            {{ eventTypes[event.type]?.text }}
        </div>
        <div class="event__content">
            <div class="event__info pt-2">
                <div>
                    <div>
                        <span class="fw-bold">
                            {{
                                isPlusEvent
                                    ? "Час після початку вебінару: "
                                    : "Час до початку вебінару: "
                            }}
                        </span>
                        <span class="event__value-item">
                            {{ formattedTime.hours }} год.
                            {{ formattedTime.minutes }} хв.
                            {{ formattedTime.seconds }} сек.
                        </span>
                    </div>
                    <template v-if="event.type === eventTypes['MESSAGE'].value">
                        <div class="mt-2">
                            <span class="fw-bold">Ім'я: </span>
                            <span>
                                {{ event.data?.author_name }}
                            </span>
                        </div>
                        <div v-if="event.data?.author_role" class="mt-2">
                            <span class="fw-bold">Роль: </span>
                            <span>
                                {{ t(event.data?.author_role) }}
                            </span>
                        </div>
                        <div class="mt-2 event__text">
                            <p>
                                {{ event.data.message }}
                            </p>
                        </div>
                    </template>
                    <template
                        v-if="
                            event.type === eventTypes['BUTTON_SHOW'].value ||
                            event.type === eventTypes['BUTTON_HIDE'].value
                        "
                    >
                        <div class="pt-2">
                            <div class="pb-2">
                                <span class="fw-bold"> Назва: </span>
                                <span class="event__value-item">
                                    {{ event.data.button.title }}
                                </span>
                            </div>
                            <div>
                                <span class="fw-bold"> Посилання: </span>
                                <span class="event__value-item">
                                    {{ event.data.button.url }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="event.type === eventTypes['START_WEBINAR'].value"
                    >
                        <div class="pt-2">
                            <div
                                class="pb-2"
                                v-if="
                                    event.data?.work_shop_config?.meta
                                        ?.broadcast
                                "
                            >
                                <span class="fw-bold"> Посилання: </span>
                                <span class="event__value-item">
                                    {{
                                        event.data?.work_shop_config?.meta
                                            ?.broadcast
                                    }}
                                </span>
                            </div>
                            <div
                                v-if="
                                    event.data?.work_shop_config?.action_status
                                "
                            >
                                <span class="fw-bold"> Вікно вебінару: </span>
                                <span class="event__value-item">
                                    {{
                                        t(
                                            event.data.work_shop_config
                                                .action_status
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="
                            event.type ===
                                eventTypes['CHANGE_SLIDE_PRESENTATION'].value ||
                            event.type ===
                                eventTypes['CHANGE_PRESENTATION'].value
                        "
                    >
                        <div class="pt-2">
                            <div v-if="event.data.current_slide" class="pb-2">
                                <span class="fw-bold"> Поточний слайд: </span>
                                <span class="event__value-item">
                                    {{ event.data.current_slide }}
                                </span>
                            </div>
                            <div
                                v-if="
                                    event.type ===
                                        eventTypes['START_WEBINAR'].value ||
                                    event.type ===
                                        eventTypes['CHANGE_ACTION'].value
                                "
                            >
                                <span class="fw-bold"> Вікно вебінару: </span>
                                <span class="event__value-item">
                                    {{
                                        t(
                                            event.data.work_shop_config
                                                .action_status
                                        )
                                    }}
                                </span>
                            </div>
                            <div class="mt-2">
                                <div class="fs card__title">
                                    Вікно вебінару
                                    <i
                                        :id="`event-presentation-warning${event.id}`"
                                        class="bi bi-info-circle"
                                    ></i>
                                </div>
                                <b-tooltip
                                    class="text-left"
                                    :target="`event-presentation-warning${event.id}`"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Перевірте, щоб для попередньої події "Зміна
                                    вікна вебінару" було встановлено значення
                                    "Презентація" або "Презентація та
                                    трансляція".
                                </b-tooltip>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="event.type === eventTypes['CHANGE_ACTION'].value"
                    >
                        <div class="pt-2">
                            <div v-if="event.data?.action_status">
                                <span class="fw-bold"> Вікно вебінару: </span>
                                <span class="event__value-item">
                                    {{ t(event.data.action_status) }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="event__thumbnail" v-if="!!thumbnailUrl">
                <img :src="thumbnailUrl" alt="#" />
            </div>

            <div class="event__slide" v-if="!!slideUrl">
                <img :src="slideUrl" alt="#" />
            </div>
            <div class="event__setting">
                <b-button
                    class="edit-button"
                    size="sm"
                    :disabled="isReadOnly"
                    @click="() => emit('openWebinarEventEditModal')"
                >
                    <span> Редагувати </span>
                    <i class="bi bi-pencil"></i>
                </b-button>
                <template
                    v-if="
                        event.type !== eventTypes['START_WEBINAR'].value &&
                        event.type !== eventTypes['FINISH_WEBINAR'].value
                    "
                >
                    <b-button
                        v-if="!event.deleted"
                        class="delete-button"
                        size="sm"
                        @click="deleteEvent"
                        :disabled="isReadOnly"
                    >
                        <span> Видалити </span>
                        <i class="bi bi-trash"></i>
                    </b-button>
                    <!-- <b-button
                        :disabled="isDisabledRestore"
                        class="restore-button"
                        v-else
                        size="sm"
                        @click="restoreEvent"
                    >
                        <span> Відновити </span>
                        <i class="bi bi-arrow-90deg-left"></i>
                    </b-button> -->
                </template>
            </div>
        </div>
    </div>
    <!-- <pre>{{ event }}</pre> -->
</template>

<style scoped>
.event {
    padding: 30px 24px;
    width: 100%;
}

.event__content {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    padding: 0 8px;
}
.event__thumbnail {
    width: 25%;
    border-radius: 0.5rem;
    overflow: hidden;
}
.event__thumbnail img {
    width: 100%;
}
.event__slide {
    width: 25%;
    display: flex;
    justify-content: center;
}

.event__slide img {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
}
.event__info {
    width: 50%;
    padding-right: 1rem;
}
.event__setting {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
}

.event__setting button {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    max-width: 200px;
}

.edit-button {
    background-color: var(--primary, #1db954);
    display: inline-block;
}

.delete-button {
    background-color: var(--danger, #f24822);
}

/* .restore-button {
    background-color: var(--yellow, #da7f14);
} */

.event__type {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 24px; */
    /* height: 24px; */
    padding: 6px 16px;
    flex-shrink: 0;
    border-radius: 6px 0;
    color: var(--white, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: px;
}
.event__value-item {
    display: inline-block;
    /* padding: 4px 8px; */
}
.event__text {
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
}

.event__text p {
    margin: 0;
    line-height: 150%;
}
</style>
