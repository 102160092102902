<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  element: {
    type: String,
    default: 'input'
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: "text"
  },
  errorMessage: {
    type: String,
    default: null
  },
  isError: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: "text"
  },
  required: {
    type: Boolean,
    default: false
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object,
    required: true
  },
  size: String,
});

const emit = defineEmits(['update:modelValue', 'change']);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const isValid = computed(() => !(!!props.errorMessage || props.isError));

const input = ref();

const isFocus = ref(false)

</script>

<template>
  <b-form-group :label="label" v-uid :label-for="input?.id" :state="isValid" :class="{ 'is-focused' : isFocus && isValid }">
    <b-form-select
        v-model="value"
        :placeholder="placeholder"
        :options="options"
        :size="size"
        :required="required"
        :autofocus="autofocus"
        :state="isValid"
        ref="input"
        v-uid
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @change="emit('change', value)"
    />
    <b-form-invalid-feedback :state="isValid" >
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<style lang="scss">
fieldset,
[role=group] {
  position: relative;

  &.is-invalid {
    .form-label {
      color: #DA1414;
    }
  }

  .form-label,
  .col-form-label {
    padding: 0 12px;
    margin-bottom: -10px;
    font-size: 10px;
    line-height: 10px;
    color: #8F8F8F;
    font-weight: var(--tb-font-weight-normal);
    white-space: nowrap;
  }

  .invalid-feedback {
    margin-top: 0;
    padding: 6px 10px;
    background: #FEEFEF;
    color: #DA1414;
    font-size: 13px;
    font-style: normal;
    font-weight: var(--tb-font-weight-normal);
    line-height: 20px
  }
}

.form-control,
.form-select {
  border-radius: 0;
  border: none;
  padding: 16px 12px;
  height: calc(12px * 2 + 22px);
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid var(--Grey, #C2C2C2);
  background-color: var(--white, #FFF);
  background-image: var(--tb-form-select-bg-img);
  font-weight: var(--tb-font-weight-normal);

  &.is-valid:not([multiple]):not([size]) {
    padding-right: 2.7rem;
    border-bottom: 1px solid var(--Grey, #C2C2C2);
  }
}

.is-focused {
    .form-select {
        border-color: var(--tb-primary)!important;
    }
    .form-label {
        color: var(--tb-primary)!important;
    }
}
</style>
