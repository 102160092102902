<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import LoginLayout from "@/Layouts/LoginLayout.vue";
import LoginFormWrapper from "@/Pages/Auth/Partials/LoginFormWrapper.vue";
import InputGroup from "@/Components/InputGroup.vue";
import {email, helpers, minLength, required, sameAs} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {computed} from "vue";

defineOptions({layout: LoginLayout})

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

const form = useForm({
  token: props.token,
  email: props.email,
  password: '',
  password_confirmation: '',
});

const containsUpperCase = (value) => /[A-ZА-ЯЁ]/.test(value);
const containsLowerCase = (value) => /[a-zа-яё]/.test(value);

const containsDigit = (value) => /\d/.test(value);

const containsSpecialCharacters = (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value);

const containsNonCyrillic = (value) => !/[а-яА-ЯЁё]/u.test(value);

const lettersValidator = helpers.withMessage(
    'Пароль повинен містити великі та маленькі літери',
    (value) => {
        if (!value) return true;
        return containsUpperCase(value) && containsLowerCase(value) && containsNonCyrillic(value)
    }
);

const digitValidator = helpers.withMessage(
    'Пароль повинен містити хоча б одну цифру',
    (value) => {
        if (!value) return true;
        return containsDigit(value);
    }
);

const nonCyrillicValidator = helpers.withMessage(
    'Домен не може містити кириличних символів.',
    (value) => {
        if (!value) return true;
        return containsNonCyrillic(value);
    }
);

const hasSpecialCharacters = helpers.withMessage(
    'Поле повинно містити хоча б один спеціальний символ.',
    (value) => {
        if (!value) return true;
        return containsSpecialCharacters(value)
    }
)

const rules = computed(() => ({
    password: {
        required: helpers.withMessage('Це поле є обов\'язковим', value => !!value),
        minLength: helpers.withMessage('Не менше 8 символів', minLength(8)),
        lettersValidator,
        digitValidator,
        hasSpecialCharacters
    },
    password_confirmation: {
        sameAs: helpers.withMessage('Паролі повинні співпадати', sameAs(form.password))
    }
}));

const v$ = useVuelidate(rules, form, { $lazy: true })

const submit = () => {
    v$.value.$touch();
    if(!v$.value.$error){
        form.post(route('password.store'), {
            onFinish: () => form.reset('password', 'password_confirmation'),
        });
    }
};
</script>

<template>
    <LoginFormWrapper>
        <template #left>
            <div class="d-flex flex-column align-self-stretch justify-content-center align-items-center">
            <Head title="Реєстрація на платформі"/>

            <div class="form-login-header text-center">
                <h1 class="text-md login-title">
                    Відновлення паролю
                </h1>
            </div>
            <form class="login-form">
                <input type="hidden" id="email" v-model="form.email" name="email">

                <InputGroup
                    v-model="form.password"
                    label="Пароль"
                    placeholder="Введіть пароль"
                    type="password"
                    required
                    :error-message="form.errors.password || v$.password?.$errors[0]?.$message"
                    :is-error="!!form.errors.password || v$.password.$error"
                    @blur="v$.password.$touch()"
                />
                <InputGroup
                    v-model="form.password_confirmation"
                    label="Повторіть пароль"
                    placeholder="Введіть пароль"
                    type="password"
                    required
                    :is-error="!!form.errors.password_confirmation || v$['password_confirmation']?.$error"
                    :error-message="form.errors.password_confirmation || v$['password_confirmation']?.$errors[0]?.$message"
                    @blur="v$['password_confirmation']?.$touch()"
                />
<!--                <ul class="list-inline password-recomendations-list mt-4 text-center w-100">-->
<!--                    <li-->
<!--                        class="list-inline-item"-->
<!--                        :class="{'invalid': v$.password?.$errors.some(error => error['$validator'] === 'minLength') }"-->
<!--                    >-->
<!--                        не менше 8 символів-->
<!--                    </li>-->
<!--                    <li-->
<!--                        class="list-inline-item"-->
<!--                        :class="{'invalid': v$.password?.$errors.some(error => error['$validator'] === 'lettersValidator') }"-->
<!--                    >-->
<!--                        маленькі та великі літери латинського алфавіту-->
<!--                    </li>-->
<!--                    <li-->
<!--                        class="list-inline-item"-->
<!--                        :class="{'invalid': v$.password?.$errors.some(error => error['$validator'] === 'digitValidator') }"-->
<!--                    >-->
<!--                        цифри-->
<!--                    </li>-->
<!--                    <li-->
<!--                        class="list-inline-item"-->
<!--                        :class="{'invalid': v$.password?.$errors.some(error => error['$validator'] === 'hasSpecialCharacters') }"-->
<!--                    >-->
<!--                        спеціальний символ-->
<!--                    </li>-->
<!--                </ul>-->
                <div class="d-flex justify-content-center mt-4">
                    <b-button type="button" size="md" variant="primary" pill :class="{ 'opacity-25': form.processing }" :disabled="form.processing || v$.$error" @click="submit">
                        Відправити
                    </b-button>
                </div>
            </form>
        </div>
    </template>
    <template #right>
        <div class="login-right-overlay"></div>
    </template>
    </LoginFormWrapper>
<!--  <GuestLayout>-->
<!--    <Head title="Reset Password"/>-->

<!--    <form @submit.prevent="submit">-->

<!--      <input type="hidden" id="email" v-model="form.email" name="email">-->

<!--      <div class="mt-4">-->
<!--        <InputLabel for="password" value="Password"/>-->

<!--        <TextInput-->
<!--            id="password"-->
<!--            type="password"-->
<!--            class="mt-1 block w-full"-->
<!--            v-model="form.password"-->
<!--            required-->
<!--            autocomplete="new-password"-->
<!--        />-->

<!--        <InputError class="mt-2" :message="form.errors.password"/>-->
<!--      </div>-->

<!--      <div class="mt-4">-->
<!--        <InputLabel for="password_confirmation" value="Confirm Password"/>-->

<!--        <TextInput-->
<!--            id="password_confirmation"-->
<!--            type="password"-->
<!--            class="mt-1 block w-full"-->
<!--            v-model="form.password_confirmation"-->
<!--            required-->
<!--            autocomplete="new-password"-->
<!--        />-->

<!--        <InputError class="mt-2" :message="form.errors.password_confirmation"/>-->
<!--      </div>-->

<!--      <div class="flex items-center justify-end mt-4">-->
<!--        <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">-->
<!--          Reset Password-->
<!--        </PrimaryButton>-->
<!--      </div>-->
<!--    </form>-->
<!--  </GuestLayout>-->
</template>
