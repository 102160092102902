<script setup>
import { Head, router } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import UploadButton from "@/Components/buttons/UploadButton.vue";
import TabCloudFiles from "@/Pages/Cloud/Partials/TabCloudFiles.vue";
import FileUploadModal from "@/Components/modals/FileUploadModal.vue";
import {computed, onMounted, ref, watch, watchEffect} from "vue";
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";
import { formatISO } from "date-fns";
import {BTabs} from "bootstrap-vue-next";

const props = defineProps({
    user: Object,
    files: Array,
    type: String,
    pagination: Object,
});

const tabs = [
    {
        title: 'Відео',
        type:'video'
    },
    {
        title: 'Документи',
        type:'file'

    },
    {
        title: 'Зображення',
        type:'photo'
    },
];

const { t } = useI18n();

const page = {
    title: "Диск",
};

const fileUploadModal = ref(false);
const videoUploadModel = ref(false);
const fileType = ref("file");
const localFiles = ref([]);

const currentPage = ref(props?.pagination?.currentPage || 1);
const perPage = ref(10);

const totalItems = ref(props?.pagination?.total || 0);



const currentTab = ref(
  tabs.findIndex(({ type }) => type === props.type) !== -1
    ? tabs.findIndex(({ type }) => type === props.type)
    : 0
);

watchEffect(() => {
    localFiles.value = [...props.files];
});

const openModalUpload = () => {
    if (currentTab.value === 0) {
        videoUploadModel.value = !videoUploadModel.value;
    } else if (currentTab.value === 1) {
        fileUploadModal.value = !fileUploadModal.value;
        fileType.value = "file";
    } else if (currentTab.value === 2) {
        fileUploadModal.value = !fileUploadModal.value;
        fileType.value = "image";
    }
    // fileUploadModal = !fileUploadModal
};

const handleUploadComplete = (data) => {
    if (data.discUsage) discUsage.value = data.discUsage;
    localFiles.value = [
        ...localFiles.value,
        {
            type: data.storageData.storageType,
            original_name: data.storageData.originalName,
            id: data.storageData.id,
            size: formatBytes(data.storageData.fileSize),
            date: formatISO(new Date()),
            description: data.storageData.description,
        },
    ];
};

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [" Bytes", " Kb", " Mb", " Gb"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const handleRemoveLocal = (id) => {
    const index = localFiles.value.findIndex((item) => item.id === id);

    if (index !== -1) {
        localFiles.value.splice(index, 1);
    }
    if(!localFiles.value.length && currentPage.value !==1){
        currentPage.value = currentPage.value - 1
    }
    if(!localFiles.value.length) totalItems.value = 0
    discUsage.value = {};
};

const discUsage = ref({});

watch(
    () => currentTab.value,
    (newIndex) => {
        const type = tabs[newIndex] ? tabs[newIndex].type : 'video'
        router.get(`/cloud/disk/${type}`, {
            page: 1,
            perPage: perPage.value,
        });
    },
)
watch(
    () => currentPage.value,
    (page) => {
        const type = tabs[currentTab.value] ? tabs[currentTab.value].type : 'video'
        router.get(`/cloud/disk/${type}`, {
            page: page,
            perPage: perPage.value,
        });
    }
);
// watch(()=>localFiles.value,(value)=>{

//     if(!value.length && currentPage.value !== 1){
//         currentPage.value = currentPage.value - 1
//     }
// })

</script>

<template>
    <div>
        <Head :title="page.title" />
        <PageHeader :title="page.title" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress :disc-usage="discUsage" />
                    <UploadButton @click="openModalUpload"></UploadButton>
                </div>
            </template>
            <template #default>
                <b-tabs nav-class="page-tabs" v-model="currentTab">
                        <b-tab
                            v-for="(tab, index) in tabs"
                            :title="tab.title"
                            :key="tab.type"
                            :active="index === currentTab"
                        >
                            <TabCloudFiles
                            :files="localFiles"
                            file-type="video"
                            @remove-file="handleRemoveLocal"
                            @add-file="openModalUpload"
                        />
                    </b-tab>

                    <template #tabs-end>
                        <div class="tabs-end"></div>
                    </template>
                </b-tabs>
                <FileUploadModal
                    v-if="fileUploadModal"
                    v-model="fileUploadModal"
                    :fileType="fileType"
                    @upload-complete="handleUploadComplete"
                />
                <VideoUploadModal
                    v-if="videoUploadModel"
                    v-model="videoUploadModel"
                    @upload-complete="handleUploadComplete"
                />
            </template>
        </PageContentCard>
    </div>
    <b-pagination
        v-if="totalItems !== 0"
        class="mt-4"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        aria-controls="my-table"
    ></b-pagination>
</template>

<style lang="scss">
.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}

.upload-file__button {
    display: flex;
    align-items: center;
    gap: 10px;

    i {
        color: var(--green, #1db954);
    }
}
</style>
