<script setup>

</script>

<template>
  <div class="login-main register-wrapper">
    <b-container fluid class="page-login">
      <b-row class="page-login-row">
        <b-col md="4" class="d-flex flex-column align-self-stretch justify-content-start align-items-center">
          <!-- ============================================================== -->
          <div
            class="login-navbar d-flex flex-row justify-content-between align-items-center"
        >
            <div class="login-navbar__logo">
                <img
                    src="../../../../images/landing/logo_dark.svg"
                    alt=""
                    height="44"
                />
            </div>
            <div
                class="login-navbar__language-switch d-flex flex-row align-items-center"
            ></div>
        </div>
        <div class="d-flex h-100">
            <slot name="left"/>
        </div>
        <div class="footer-description">Bestclevers TM: Online Education Platform</div>
          <!-- ============================================================== -->
        </b-col>

        <b-col md="8" class="align-self-stretch px-0">
            <slot name="right"/>
            <!-- <div class="login-right-overlay"></div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.register-wrapper {
  .login-right-overlay {
    background: url("../../../../images/auth/login-right-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
}
$navbar-height: 68px;

.footer-description {
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 300;
    line-height: 22.4px;
    text-align: center;
    color: #C2C2C2;
    margin-bottom: 50px;
}

.login-layout {
}

.login-navbar {
    height: $navbar-height;
    background-color: var(--tb-header-bg);
    padding: 0 40px;
    width: 100%;
}

.login-title {
    color: #1e1e1e;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 28px */
}

.login-subtitle {
    color: #c2c2c2;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 22.4px */
}

.login-bottom-text {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 14px */

    a {
        color: #0085ff;
    }
}

.page-login {
    min-height:100vh;
    background-color: var(--tb-header-bg);
}

.page-login-row {
    min-height: 100vh;
}
</style>

