<script setup>

import {useI18n} from "vue-i18n";
import {ref} from "vue";

const props = defineProps({
    roles: Object,
    user: Object,
})

const emit = defineEmits(['save-roles'])

const {t} = useI18n();

const visibleDropdown = ref(false);

const handleSaveRoles = () => {
    visibleDropdown.value = false
    emit('save-roles', props.user)
}

</script>

<template>
    <b-dropdown id="dropdown-form" variant="light" v-model="visibleDropdown" auto-close="outside">
        <template #button-content>
            Обрані ролі
        </template>
        <b-dropdown-form>
            <div class="team__user--checkbox" v-for="role of roles" :key="role">
                <b-form-checkbox
                    :value="role"
                    v-model="user.roles"
                >
                    {{ t(role) }} <i :id="`tooltip-${role}-${user.id}`" class="bi bi-info-circle"></i>
                </b-form-checkbox>
                <b-tooltip class="text-left" :target="`tooltip-${role}-${user.id}`" custom-class="custom-tooltip" offset="5" triggers="hover" :no-fade="true" :delay="{ show: 50, hide: 50 }">
                    {{ t(role+'_TOOLTIP') }}
                </b-tooltip>
            </div>
            <div class="d-flex w-100 justify-content-center mt-3">
                <b-button variant="primary" size="md" pill @click="handleSaveRoles()">Зберегти</b-button>
            </div>
        </b-dropdown-form>
    </b-dropdown>
</template>

<style scoped lang="scss">

</style>
