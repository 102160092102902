<script setup>

import {Head, router, useForm} from '@inertiajs/vue3';
import {computed, reactive} from "vue";
import VideoInfo from "@/Pages/MasterClass/Partials/VideoInfo.vue";
import PaidRegistration from "@/Pages/Workshops/Partials/Registration/PaidRegistration.vue";
import RegisterFormWrapper from "@/Pages/Autowebinars/Partials/RegisterFormWrapper.vue";

const props = defineProps({
  workShopUser: Object,
  workShop: Object,
  isPaid: false
})

const form = useForm({
  code: '',
  workShopUserId: props.workShopUser.id
});

const digits = reactive({
  digit1: null,
  digit2: null,
  digit3: null,
  digit4: null
})

const codeComputed = computed(() => '' + (digits.digit1 ?? '') + (digits.digit2 ?? '') + (digits.digit3 ?? '') + (digits.digit4 ?? ''))

const isFinished = computed(() => codeComputed.value.length === 4)

const getInputElement = (index) => {
  return document.getElementById('digit' + index + '-input');
};

const moveToNext = (index) => {
  if (getInputElement(index).value.length === 1) {
    if (index !== 4) {
      digits['digit' + (index + 1)] = null
      getInputElement(index + 1).focus();
    } else {
      getInputElement(index).blur();
    }
  }
}

const onSubmit = () => {
  form.transform((data) => ({
    code: codeComputed.value,
    workShopUserId: props.workShopUser.id
  })).post(route('workshops.codeVerify'));
};

</script>

<template>
  <RegisterFormWrapper>
    <template #left-side>
      <div class="d-flex flex-column align-self-stretch justify-content-center align-items-center left-side-block py-5">
        <Head title="Email Verification"/>
        <div class="form-login-header text-center">
          <h1 class="text-md login-title">
            Введіть код підтввердження
          </h1>
          <p>Відправлений на {{ workShopUser.email }}</p>
        </div>

        <b-form class="login-form" @submit.prevent="onSubmit">

          <div class="d-flex">
            <b-form-input
                type="text"
                v-model="digits.digit1"
                class="verification-digit"
                placeholder="0" v-on:keyup="moveToNext(1)" maxLength="1"
                id="digit1-input"
            />
            <b-form-input
                type="text"
                v-model="digits.digit2"
                class="verification-digit"
                placeholder="0" v-on:keyup="moveToNext(2)" maxLength="1"
                id="digit2-input"
            />
            <b-form-input
                type="text"
                v-model="digits.digit3"
                class="verification-digit"
                placeholder="0" v-on:keyup="moveToNext(3)" maxLength="1"
                id="digit3-input"
            />
            <b-form-input
                type="text"
                v-model="digits.digit4"
                class="verification-digit"
                placeholder="0" v-on:keyup="moveToNext(4)" maxLength="1"
                id="digit4-input"
            />

            <div v-if="form.errors.code" class="text-danger py-3 text-center">
              {{ form.errors.code }}
            </div>

          </div>

          <div class="d-flex flex-column align-items-center justify-content-end mt-4">
            <b-button variant="primary" size="lg" pill type="submit">
              Підтвердити
            </b-button>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-end mt-4">
            <b-button variant="text-variant" size="md" class="d-flex align-items-center justify-content-center" pill
                      @click="() => router.get(
          route('register-to-work-shop'),{id:workShop.id})">
              <i class="ri-arrow-left-s-line"></i> Назад
            </b-button>
          </div>
        </b-form>
      </div>
    </template>
    <template #right-side>
      <VideoInfo :work-shop="workShop"/>
    </template>
  </RegisterFormWrapper>
</template>

<style lang="scss">
.form-login-header {
  p {
    color: var(--Grey, #C2C2C2);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 22.4px */
  }
}

.verification-digit {
  text-align: center;
  display: flex;
  padding: 28px 18px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  background: var(--white, #FFF);
  color: black;
}
</style>
