;
<script setup>

import {computed, ref, triggerRef} from 'vue';
import CommentButton from "@/Components/buttons/CommentButton.vue";
import UserDetailsModal from "@/Components/modals/UserDetailsModal.vue";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    form: Object,
    modelValue: Array
})

const emit = defineEmits([
    'update:modelValue',
]);

const selectedUsers = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const handleUserSelect = (id) => {
    const index = selectedUsers.value.findIndex((el) => el === id);
    if (index >= 0) selectedUsers.value.splice(index, 1);
    else selectedUsers.value.push(id);
    triggerRef(selectedUsers);
}

const userDetailsModal = ref(false);

const tmpUser = ref(null);

const handleUserDetails = (id) => {
    const index = props.form.workShop.users.findIndex((el) => el.id === id);
    if (index >= 0) {
        tmpUser.value = props.form.workShop.users[index];
        userDetailsModal.value = !userDetailsModal.value;
    }
}

const selectedRegisterId = ref(null);

function removeUser(userId) {
    router.visit(route('workshops.remove-user'),
        {
            method: 'post',
            data: {
                registerId: userId,
                webinarId: props.form.workShop.id
            },
            preserveState: false,
            preserveScroll: true,
            forceFormData: true
        });
    selectedRegisterId.value = null;
    // props.form.webinar = value;
}

function grantAccess(userId) {
    console.log(userId);
    router.visit(route('workshops.grant-access'),
        {
            method: 'post',
            data: {
                registerId: selectedRegisterId.value,
                webinarId: props.form.workShop.id
            },
            preserveState: false,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: () => {
                console.log('sss');
            }
        });
    selectedRegisterId.value = null;
}

</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div class="module-table">
                <div class="module-table__header border-bottom pb-2">
                    <div class="module-table__col module-table__col&#45;&#45;email text-uppercase opacity-50"
                         v-for="field of form.workShop.registerFields" :key="field.id"
                    >
                        {{ field.label }}
                    </div>
                    <div class="module-table__col module-table__col&#45;&#45;email text-uppercase opacity-50"
                    >
                        Чек
                    </div>
                    <div class="module-table__col module-table__col&#45;&#45;email text-uppercase opacity-50"
                    >
                        Доступ
                    </div>
                </div>
                <div
                    v-for="user of form.workShop.users"
                    :key="user.id"
                    class="module-table__row border-bottom py-3"
                    :class="{'module-table__row--selected-user': selectedUsers.includes(user.id)}"
                    @click.self="handleUserSelect(user.id)"
                >
                    <div class="module-table__col module-table__col&#45;&#45;email"
                         v-for="field of user.fields" :key="field.id"
                    >
                            <span v-if="field.name === 'email'"
                                  class="module-table__email-bage"
                                  :class="[user.access === false ? 'module-table__email-bage-new' : '']"
                            >
                            {{ user.access === false ? 'new' : 'approved' }}
                        </span>
                        {{ field.value }}
                    </div>
                    <div class="module-table__col module-table__col--payment">
                        <i class="ph-arrow-down-bold"></i> <a target="_blank" :href="user.payment_check">download</a>
                    </div>
                    <div class="module-table__col module-table__col--actions">

                        <b-button variant="success" pill v-if="user.access === false" v-b-modal.grantAccessModal
                                  @click="selectedRegisterId = user.id">
                            Надати доступ
                        </b-button>
                        <i class="bi bi-x-lg" @click="removeUser(user.id)"></i>
                    </div>
                </div>
                <!--        </b-form-checkbox-group>-->
            </div>
            <UserDetailsModal
                v-model="userDetailsModal"
                :user="tmpUser"
                :ignored-fields="['id', 'comment', 'logo']"
            />
            <b-modal id="grantAccessModal" hide-footer hide-header centered>
                <div class="modal-body">
                    <div class="grand-modal-title">
                        <i class="ph-warning"></i>
                        <h4>Підтвердіть надання доступу</h4>
                    </div>
                    <p class="grand-modal-desc">Доступ до курсу не можна буде відмінити.</p>
                    <div class="grand-modal-actions">
                        <b-button class="outline-dark" pill v-b-modal.grantAccessModal
                                  @click="selectedRegisterId = null">
                            Скасувати
                        </b-button>
                        <b-button class="grand-modal-accept m-2" pill v-b-modal.grantAccessModal @click="grantAccess">
                            Надати доступ
                        </b-button>
                    </div>
                </div>
            </b-modal>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.module-table__col--email {
    display: flex;
    width: 20%;
}

.module-table__col--name {
    width: 20%;
}

.module-table__col--phone {
    width: 10%;
}

.module-table__col--stub {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.module-table__row--selected-user {
    border-color: #1DB954;
    border-style: solid;
    border-width: 1px 0 1px 0;
}

.tab-form {

}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #EBEBEB);
    background: var(--white, #FFF);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1E1E1E);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1E1E1E);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

#input-1 {
    background-color: #1DB9540A;
    border: 1px solid #1DB954;
    border-radius: 4px;
}
</style>
