<script setup>
import WebinarButton from "@/Components/WebinarButtons/WebinarButton.vue";
import { ref, computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    modelValue: {
        type: Array,
        required: true,
    },
    form: Object,
    title: {
        type: String,
        default: "Маркетингові кнопки",
    },
});

const emit = defineEmits(["update:modelValue"]);

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isExpired = computed(
    () => props.form?.workShop?.type !== 'MASTERCLASS' ? props.form?.workShop?.config?.workShopStatus === "EXPIRED" : false
);
const isFinished = computed(
    () => props.form?.workShop?.type !== 'MASTERCLASS' ? props.form?.workShop?.config?.workShopStatus === "FINISHED" : false
);

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles) || isFinished.value || isExpired.value;

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const addItem = () => {
    value.value.push({
        title: "",
        url: "",
    });
    //value.value = [...value.value]
};

const updateItem = (index, updatedItem) => {
    value.value = value.value.map((item, i) =>
        i === index
            ? { ...updatedItem, order: updatedItem.order ?? index }
            : item
    );
};

const removeItem = (index) => {
    value.value = value.value.filter((_, i) => i !== index);
};
</script>

<template>
    <b-card no-body class="shadow-none mt-4 webinar-buttons__card">
        <b-card-body
            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
        >
            <div class="card__title">
                {{ title }}
                <i id="marketing-button" class="bi bi-info-circle"></i>
            </div>
            <b-tooltip
                class="text-left"
                target="marketing-button"
                custom-class="custom-tooltip"
                offset="5"
                triggers="hover"
                :no-fade="true"
                :delay="{ show: 50, hide: 50 }"
            >
                Вкажіть назву кнопки та відповідне URL посилання. Кнопки будуть доступні під час події, що дозволить глядачам легко перейти на ваш сайт, соціальні мережі або інші ресурси.
            </b-tooltip>

            <template v-for="(item, index) in value" :key="index">
                <span class="webinar-button__label"
                    >Кнопка {{ index + 1 }}</span
                >
                <WebinarButton
                    :item="item"
                    :is-read-only="isReadOnly"
                    @update:item="updateItem(index, $event)"
                    @remove="removeItem(index)"
                />
            </template>
            <!--      <div class="text-primary webinar-buttons__add" @click="addItem">Додати кнопку</div>-->
        </b-card-body>
    </b-card>
</template>
<style scoped lang="scss">
.webinar-buttons__card {
    .webinar-button__label {
        font-family: e-Ukraine;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        margin-top: 16px;
    }
}
.webinar-buttons__add {
    font-family: e-Ukraine;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 16px;
    cursor: pointer;
}
</style>
