<script setup>
import { Link, router, usePage } from "@inertiajs/vue3";

import course1 from "../../../../images/tmp/course/course1.png";
import { useI18n } from "vue-i18n";
import noImage from "../../../../images/no-image.png";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { ref } from "vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const { t } = useI18n();

const props = defineProps({
    course: Object,
});

const { formatDate } = useDateFormatter();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const deleteCourseModal = ref(false);

const removeCourse = () => {
    router.visit(route("courses.course_delete", { id: props.course.id }), {
        method: "get",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
        },
    });
};
</script>

<template>
    <b-card no-body class="overflow-hidde bg-light course-item">
        <b-card-body
            class="text-center learning-widgets d-flex align-items-center justify-content-center course-item__image"
        >
            <img
                v-if="course.image"
                :src="course.image"
                :title="course.title"
                :alt="course.title"
                class="course-image"
            />
            <img
                v-else
                :src="noImage"
                :title="course.title"
                :alt="course.title"
                class="no-image"
            />
        </b-card-body>
        <b-card-body class="course-item__content">
            <h6 class="text-truncate fs-md text-capitalize course-item__title">
                <Link
                    :href="`/courses/items/${course.id}`"
                    class="text-reset"
                    >{{ course.title }}</Link
                >
            </h6>
            <ul class="list-unstyled course-item__info">
                <li>
                    Автор: <span>{{ course.owner }}</span>
                </li>
                <li>
                    Створено:
                    <span>{{
                        formatDate(course.createdAt, "d MMMM yyyy, HH:mm")
                    }}</span>
                </li>
                <li>
                    Статус: <span>{{ $t(course.status) }}</span>
                </li>
            </ul>
            <div class="hstack align-items-center gap-2">
                <ul
                    class="list-unstyled d-flex align-items-center gap-2 flex-grow-1 position-relative mb-0 fs-5"
                >
                    <li class="d-flex align-items-center me-1">
                        <i
                            class="ph-list-fill align-baseline text-primary fs-4 me-1"
                        ></i>
                        <span class="fs-6">{{ course.lessonsCount }}</span>
                    </li>
                    <li class="d-flex align-items-center me-1">
                        <i
                            class="ph-users-fill align-baseline text-primary fs-4 me-1"
                        ></i>
                        <span class="fs-6">{{ course.studentsCount }}</span>
                    </li>
                    <li class="d-flex align-items-center me-1">
                        <i
                            class="ph-chart-pie-slice align-baseline text-primary fs-4 me-1"
                        ></i>
                        <span class="fs-6">{{ course.size }}</span>
                    </li>
                </ul>

                <div class="dropdown flex-shrink-0">
                    <b-button
                        class="outline-dark btn-icon"
                        variant="link"
                        size="sm"
                        role="button"
                        data-bs-toggle="dropdown"
                        pill
                        aria-expanded="false"
                    >
                        <i class="bi bi-three-dots"></i>
                    </b-button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <!--                        <li>-->
                        <!--                            <Link class="dropdown-item" :href="`/courses/items/${course.id}/preview`">-->
                        <!--                                <i class="bi bi-eye align-baseline me-1"></i>-->
                        <!--                                Preview-->
                        <!--                            </Link>-->
                        <!--                        </li>-->
                        <li>
                            <Link
                                class="dropdown-item"
                                :href="`/courses/items/${course.id}`"
                            >
                                <i
                                    v-if="isReadOnly"
                                    class="bi bi-eye align-baseline me-1"
                                ></i>
                                <i
                                    v-else
                                    class="bi bi-pencil-square align-baseline me-1"
                                ></i>
                                {{ isReadOnly ? "Переглянути" : "Редгувати" }}
                            </Link>
                        </li>
                        <li v-if="!isReadOnly">
                            <span
                                class="dropdown-item"
                                @click="deleteCourseModal = true"
                            >
                                <i class="bi bi-trash3 align-baseline me-1"></i>
                                Видалити
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </b-card-body>
    </b-card>
    <ConfirmModal
        v-model="deleteCourseModal"
        title="Видалення курсу"
        decline-label="Скасувати"
        accept-label="Так, видалити"
        @accept-btn="() => removeCourse()"
        @decline-btn="() => console.log('decline')"
    >
        <template v-slot:description>
            Після видалення курсу його не можна буде повернути. Ви впевнені, що
            хочете продовжити?
        </template>
    </ConfirmModal>
</template>

<style scoped>
.course-item {
    border-radius: 12px;
    background: #f8faf8;
    .course-item__image {
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        background: #f7f7f8;
        height: 234px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ebebeb;
        .course-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .no-image {
            height: 34px;
            width: 35px;
        }
    }
    .course-item__content {
        padding: 28px 24px !important;
        .course-item__title {
            font-family: e-Ukraine;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        }
        .course-item__info {
            font-family: e-Ukraine;
            li {
                font-size: 10px;
                font-weight: 300;
                color: #000;
                line-height: 12px;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    font-size: 12px;
                    font-weight: 200;
                    line-height: 12px;
                    color: color-mix(
                        in srgb,
                        var(--dark, #1e1e1e),
                        transparent 50%
                    );
                }
            }
        }
    }
}
</style>
