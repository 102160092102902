<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import chatUser from "../../../images/chat-user.jpeg";
import avatar3 from "../../../images/users/avatar-3.jpg";
import ChatMessage from "@/Components/chat/ChatMessage.vue";
import {onClickOutside} from "@vueuse/core";
import useRequestProgress from "@/composables/useRequestProgress.js";
import {sendMessage, deleteMessage} from "@/api/chat.js";
import AttachmentsUploadModal from "@/Components/chat/AttachmentsUploadModal.vue";
import {BRow} from "bootstrap-vue-next";
// import picker component
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import {toast} from "vue3-toastify";
import {usePage} from "@inertiajs/vue3";
import {useRoleChecker} from "@/composables/useRoleChecker.js";

const props = defineProps({
    currentConversation: Object,
    userProfile: Object,
})

const pageData = usePage();
const { isStudent } = useRoleChecker();

// const userRoles = pageData?.props?.auth?.roles || [];
const isCurrentUserStudent = computed(() => isStudent(pageData?.props?.auth?.user))

const searchMessages = ref(null)
const loader = ref(false)
const channelMessage = ref([]) //channelMessage

const {
    setIsLoading
} = useRequestProgress()

const fileUploadModal = ref(false)
const newMessage = ref(null)
const isShowEmojiPicker = ref(false)
const emojiPickerModal = ref(null)
onClickOutside(emojiPickerModal, event => {
    isShowEmojiPicker.value = false
})
const onSelectEmoji = (emoji) => {
    newMessage.value = `${newMessage.value}` + emoji.i;
}

const conversation = computed(() => {
    return props.currentConversation.currentConversation
})

const chatContainerId = computed(() => conversation.value?.type == "user" ? "users-chat" : "channel-chat");

const userChatConversationRef = ref()
const channelChatConversationRef = ref()

const userAvatar = ref(conversation.value.imageUrl)
const userAvatarAcronym = ref(conversation.value.avatarAcronym)

const messages = ref(props.currentConversation.messages)

const resultQuery = computed(() => {
    if (searchMessages.value) {
        const search = searchMessages.value.toLowerCase();

        return props.messages.filter((data) => data.message.some((message) => message.msg.toLowerCase().includes(search)))
            .filter(element => {
                let newMsg = Object.assign({}, element);
                newMsg = newMsg.message.filter(message => message.msg.toLowerCase().includes(search));
                return newMsg;
            });
    } else {
        return messages.value;
    }
})

const onMessageAction = ({action, data}) => {
    switch (action) {
        case 'delete':
            // delete message
            deleteMessage(data).then((response) => {
                if (response.data && response.data.id) {
                    messages.value = messages.value.filter((message) => message.id !== response.data.id)
                    // update conversations list
                }
            }).catch(error => {
                toast.error(
                    error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message, {
                        autoClose: 2000,
                        position: toast.POSITION.TOP_RIGHT,
                        limit: 3,
                        dangerouslyHTMLString: true,
                        // icon: "✉️",
                    });
            })
            break;
        default:
            return;
    }
}

const scrollToBottom = () => {
    const id = chatContainerId.value
    setTimeout(function () {
        console.log('scrollToBottom', id)
        // const simpleBar = document.getElementById(id).querySelector("#chat-conversation .simplebar-content-wrapper") ? document.getElementById(id).querySelector("#chat-conversation .simplebar-content-wrapper") : '';
        const simpleBar = document.getElementById('chat-conversation-1');
        if (!simpleBar) return;

        const offsetHeight = simpleBar.scrollHeight;
        if (offsetHeight) {
            simpleBar.scrollTop = offsetHeight
            // userChatConversationRef.value.ps.scrollTo({
            //     top: offsetHeight,
            //     behavior: "smooth"
            // });
        }
    }, 300);
}

const fileUploadComplete = (e) => {
    // clear message field
    newMessage.value = ''
    messages.value.push({
        id: e.message.id,
        ...e.message
    })

    scrollToBottom()
}

const formSubmit = (e) => {
    e.preventDefault();
    const message = newMessage.value;
    newMessage.value = null;
    if (message !== '' && message !== null) {
        const newMessageId = new Date().getTime()
        const newMessage = {
            content: message,
            conversation_id: conversation.value.id,
            id: newMessageId,
            sender_id: props.userProfile.id,
            created_at: new Date(),
            updated_at: null,
        }

        messages.value.push(newMessage);
        newMessage.value = null;

        scrollToBottom();

        if(conversation.value) {
            setIsLoading(true);
            sendMessage(conversation.value.id, { message: newMessage  })
                .then(response => {
                    console.log('send-message', response);
                    if (response.data && response.data.message) {
                        messages.value = messages.value.filter((messageItem) => `${messageItem.id}` !== `${newMessageId}`);
                        messages.value.push({
                            id: response.data.message.id,
                            ...response.data.message
                        })
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => setIsLoading(false));
        }

    } else {
        document.querySelector(".chat-input-feedback").classList.remove("d-none");
        document.querySelector(".chat-input-feedback").classList.add("d-block");
        setTimeout(function () {
            document.querySelector(".chat-input-feedback").classList.remove("d-block");
            document.querySelector(".chat-input-feedback").classList.add("d-none");
        }, 1000);
    }
}

const replyMessage = () => {
    // const itemList = document.querySelector(".chat-conversation-list");
    // const replyMessage = itemList.querySelectorAll(".reply-message");
    // const replyToggleOpen = document.querySelector(".replyCard");
    // const replyToggleClose = document.querySelector("#close_toggle");
    // const isThis = this;
    // replyMessage.forEach(function (item) {
    //     const This = isThis;
    //     item.addEventListener("click", function () {
    //         This.isReplyClicked = true;
    //         replyToggleOpen.classList.add("show");
    //         replyToggleClose.addEventListener("click", function () {
    //             replyToggleOpen.classList.remove("show");
    //         });
    //
    //         const replyMsg = item.closest(".ctext-wrap").children[ 0 ].children[ 0 ].innerText;
    //         document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0").innerText = replyMsg;
    //         const replyuser = document.querySelector(".user-chat-topbar .text-truncate .username").innerHTML;
    //         const msgOwnerName = (item.closest(".chat-list")) ? item.closest(".chat-list").classList.contains("left") ? replyuser : 'You' : replyuser;
    //         document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name").innerText = msgOwnerName;
    //
    //     }, This);
    // }, isThis);
}

const deleteMsg = (e) => {
    e.target.closest(".user-chat-content").childElementCount === 2 ? e.target.closest(".chat-list").remove() : e.target.closest(".ctext-wrap").remove();
}

const closeChatbox = () => {
    document.querySelector('.user-chat').classList.remove('user-chat-show')
}

const eventName = "MessageSent";

const newMessageListener = (e) => {
    if (!messages.value.some(msg => msg.id === e.message.id)) {
        messages.value.push(e.message);
    }
    scrollToBottom();
}

onMounted(() => {
    scrollToBottom();

    if (window.Echo && conversation.value && conversation.value.id) {
        window.Echo.private(`conversation.${conversation.value.id}`)
            .listen(eventName, newMessageListener)
            .notification(newMessageListener);
    }
})

onBeforeUnmount(() => {
    if (window.Echo) {
        window.Echo.private(`conversation.${conversation.value.id}`)
            .stopListening(eventName);
        window.Echo.leaveChannel(`conversation.${conversation.value.id}`);
    }
})

</script>

<template>
    <div class="user-chat w-100 overflow-hidden">
        <div class="d-none flex-column justify-content-between h-100 video-content">
            <div class="p-3 user-chat-topbar border-bottom border-2">
                <b-row class="align-items-center justify-content-between flex-nowrap">
                    <b-col sm="4">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 d-block d-lg-none me-3">
                                <a href="javascript:void(0)" class="user-chat-remove fs-lg p-1" @click="closeChatbox">
                                    <i class="ri-arrow-left-s-line align-bottom"></i>
                                </a>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <div class="d-flex align-items-center">
                                    <div
                                        class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                        <img v-if="userAvatar" :src="userAvatar" class="rounded-circle avatar-xs" alt=""/>
                                        <div v-else>{{ userAvatarAcronym }}</div>
                                        <span class="user-status"></span>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden">
                                        <h5 class="text-truncate mb-1 fs-lg">
                                            <b-link
                                                class="text-reset username"
                                                data-bs-toggle="offcanvas" href="#userProfileCanvasExample"
                                                aria-controls="userProfileCanvasExample">{{ conversation.name }}
                                            </b-link>
                                        </h5>
                                        <p class="text-truncate text-muted fs-md mb-0 userStatus">
                                            <small>Creative Designer</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
<!--                    <b-col lg="4" cols="auto">-->
<!--                        <div class="text-end">-->
<!--                            <b-button variant="primary" @click="inviteMembersModal = !inviteMembersModal">-->
<!--                                <i class="bi bi-plus d-block d-sm-none"></i>-->
<!--                                <span class="ms-1 d-none d-sm-block">Add Participant</span>-->
<!--                            </b-button>-->
<!--                        </div>-->
<!--                    </b-col>-->
                </b-row>
            </div>

            <!-- Messages  -->
            <div class="p-3 chat-conversation">
                <b-row class="g-3">
                    <b-col lg="9">
                        <div class="position-relative">
                            <b-link href="#!">
                                <img :src="chatUser" alt="" class="object-fit-cover w-100 rounded" height="515"/>
                            </b-link>
                            <div class="btn-group position-absolute top-0 end-0 bg-white bg-opacity-50 p-1 m-3"
                                 role="group" aria-label="Basic example">
                                <b-button variant="none" type="button" class="btn-close"></b-button>
                            </div>
                            <div
                                class="badge bg-danger d-inline-block position-absolute bottom-0 end-0 m-3 fs-lg fw-normal">
                                <span id="outputt" class="timerClock" value="00:00:00">00:00:00</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="3">
                        <b-row class="g-3">
                            <b-col lg="12">
                                <div class="position-relative">
                                    <b-link href="#!">
                                        <img :src="avatar3" alt="" class="w-100 object-fit-cover rounded" height="250"/>
                                    </b-link>
                                </div>
                            </b-col>
                            <b-col lg="12">
                                <div class="position-relative">
                                    <b-link href="#!">
                                        <img :src="avatar3" alt="" class="w-100 object-fit-cover rounded" height="250"/>
                                    </b-link>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <!-- /Messages  -->

            <div class="p-3 chat-input-section border-top border-2">
                <b-row class="align-items-center justify-content-between">
                    <b-col lg="12">
                        <div class="hstack gap-2 justify-content-center">
                            <b-button variant="subtle-secondary" class="fs-lg btn-icon rounded-circle">
                                <i class="ph-video-camera-slash"></i>
                            </b-button>
                            <b-button variant="danger" class="fs-lg btn-icon rounded-circle call-disconnect">
                                <i class="ph-phone-disconnect"></i>
                            </b-button>
                            <b-button variant="subtle-secondary" class="fs-lg btn-icon rounded-circle">
                                <i class="ph-microphone-slash"></i>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <div class="chat-content d-lg-flex">
            <div class="w-100 overflow-hidden position-relative">
                <div class="position-relative">

                    <div v-if="conversation.type == 'user'" class="position-relative" id="users-chat">
                        <!-- Topbar  -->
                        <div class="p-3 user-chat-topbar border-bottom border-2">
                            <b-row class="align-items-center flex-nowrap">
                                <b-col sm="4">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 d-block d-lg-none me-3">
                                            <b-link href="#" class="user-chat-remove fs-lg p-1" @click="closeChatbox"><i
                                                class="ri-arrow-left-s-line align-bottom"></i></b-link>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                    <img v-if="userAvatar" :src="userAvatar" class="rounded-circle avatar-xs" alt=""/>
                                                    <div v-else>{{ userAvatarAcronym }}</div>
                                                    <span class="user-status"></span>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="text-truncate mb-1 fs-lg">
                                                        <b-link
                                                            class="text-reset username"
                                                            data-bs-toggle="offcanvas"
                                                            href="#userProfileCanvasExample"
                                                            aria-controls="userProfileCanvasExample"
                                                        >
                                                            {{ conversation.name }}
                                                        </b-link>
                                                    </h5>
<!--                                                    <p class="text-truncate text-muted fs-md mb-0 userStatus">-->
<!--                                                        <small>Online</small>-->
<!--                                                    </p>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>

                                <b-col sm="8" cols="auto">
                                    <ul class="list-inline user-chat-nav text-end mb-0">
                                        <li class="list-inline-item m-0 me-3">
                                            <div class="dropdown">
                                                <b-button variant="ghost-secondary" class="btn-icon" type="button"
                                                          data-bs-toggle="dropdown" aria-haspopup="true"
                                                          aria-expanded="false">
                                                    <i class="bi bi-search"></i>
                                                </b-button>
                                                <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                                    <div class="p-2">
                                                        <div class="search-box">
                                                            <input
                                                                type="text"
                                                                class="form-control bg-light border-light"
                                                                placeholder="Шукати в повідомленнях"
                                                                v-model="searchMessages"
                                                                id="searchMessage"
                                                            >
                                                            <i class="ri-search-2-line search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <!--                                            <li class="list-inline-item d-none d-lg-inline-block m-0 me-3">-->
                                        <!--                                                <b-button variant="outline-primary"><i class="bi bi-camera-video" style="font-size: 20px;"></i> Дзвінок</b-button>-->
                                        <!--                                            </li>-->

                                        <!--                      <li class="list-inline-item d-none d-lg-inline-block m-0 me-3">-->
                                        <!--                        <b-button variant="outline-primary"><i class="bi bi-camera-video"></i> Дзвінок</b-button>-->
                                        <!--                      </li>-->

                                        <!--                      <li class="list-inline-item d-none d-lg-inline-block m-0">-->
                                        <!--                        <b-button type="button" variant="ghost-secondary" class="fs-lg btn-icon"-->
                                        <!--                                  data-bs-toggle="offcanvas"-->
                                        <!--                                  data-bs-target="#userProfileCanvasExample"-->
                                        <!--                                  aria-controls="userProfileCanvasExample">-->
                                        <!--                          <i class="bi bi-telephone"></i>-->
                                        <!--                        </b-button>-->
                                        <!--                      </li>-->

                                        <li class="list-inline-item m-0">
                                            <div class="dropdown">
                                                <b-button variant="ghost-secondary" class="fs-lg btn-icon"
                                                          type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                          aria-expanded="false">
                                                    <i class="bi bi-three-dots-vertical"></i>
                                                </b-button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-inbox-archive-line align-bottom text-muted me-2"></i>
                                                        Очистити історію
                                                    </b-link>
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-mic-off-line align-bottom text-muted me-2"></i>
                                                        Відключити сповіщення
                                                    </b-link>
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>
                                                        Видалити
                                                    </b-link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>

                        </div>
                        <!-- Topbar end  -->

                        <perfect-scrollbar ref="userChatConversationRef" class="chat-conversation p-3 p-lg-4" id="chat-conversation-1">
                            <div id="elmLoader" v-if="loader">
                                <div class="spinner-border text-primary avatar-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <!--                                {{resultQuery}}-->
                            <ul class="list-unstyled chat-conversation-list" id="users-conversation">
                                <ChatMessage
                                    v-for="(msg, index) of resultQuery"
                                    :key="index"
                                    :my="msg.sender_id === userProfile.id"
                                    :allowed-actions="{
                                        'delete': !isCurrentUserStudent || msg.sender_id === userProfile.id,
                                        'edit': msg.sender_id === userProfile.id
                                    }"
                                    :message="msg"
                                    @message-action="onMessageAction"
                                />
                            </ul>
                        </perfect-scrollbar>

                        <b-alert
                            :model-value="true"
                            variant="warning"
                            class="alert-dismissible copyclipboard-alert px-4 fade show"
                            id="copyClipBoard"
                        >
                            Message copied
                        </b-alert>
                    </div>
                    <!-- User conversation block end -->

                    <div v-if="conversation.type == 'channel'" class="position-relative" id="channel-chat">
                        <!-- Topbar  -->
                        <div class="p-3 user-chat-topbar border-bottom border-2">
                            <b-row class="align-items-center flex-nowrap">
                                <b-col sm="4">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 d-block d-lg-none me-3">
                                            <b-link href="#" class="user-chat-remove fs-lg p-1" @click="closeChatbox"><i
                                                class="ri-arrow-left-s-line align-bottom"></i></b-link>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <div class="d-flex align-items-center">
                                                <div
                                                    class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                    <img v-if="userAvatar" :src="userAvatar" class="rounded-circle avatar-xs" alt=""/>
                                                    <div v-else>{{ userAvatarAcronym }}</div>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="text-truncate mb-0 fs-lg">
                                                        <b-link
                                                            class="text-reset username" data-bs-toggle="offcanvas"
                                                            href="#userProfileCanvasExample"
                                                            aria-controls="userProfileCanvasExample">{{
                                                                username
                                                            }}
                                                        </b-link>
                                                    </h5>
                                                    <p class="text-truncate text-muted fs-md mb-0 userStatus">
                                                        <small>24 Members</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col sm="8" cols="auto">
                                    <ul class="list-inline user-chat-nav text-end mb-0">
                                        <li class="list-inline-item m-0">
                                            <div class="dropdown">
                                                <b-button variant="ghost-secondary" class="btn-icon" type="button"
                                                          data-bs-toggle="dropdown" aria-haspopup="true"
                                                          aria-expanded="false">
                                                    <i class="bi bi-search"></i>
                                                </b-button>
                                                <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg">
                                                    <div class="p-2">
                                                        <div class="search-box">
                                                            <input type="text"
                                                                   class="form-control bg-light border-light"
                                                                   placeholder="Search here..."
                                                                   v-model="searchMessages" id="searchMessage">
                                                            <i class="ri-search-2-line search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="list-inline-item d-none d-lg-inline-block m-0">
                                            <b-button type="button" variant="ghost-secondary" class="btn-icon"
                                                      data-bs-toggle="offcanvas"
                                                      data-bs-target="#userProfileCanvasExample"
                                                      aria-controls="userProfileCanvasExample">
                                                <i class="bi bi-telephone"></i>
                                            </b-button>
                                        </li>

                                        <li class="list-inline-item m-0">
                                            <div class="dropdown">
                                                <b-button variant="ghost-secondary" class="fs-lg btn-icon"
                                                          type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                          aria-expanded="false">
                                                    <i class="bi bi-three-dots-vertical"></i>
                                                </b-button>
                                                <div class="dropdown-menu dropdown-menu-end">
                                                    <b-link
                                                        class="dropdown-item d-block d-lg-none user-profile-show"
                                                        href="#"><i
                                                        class="ri-user-2-fill align-bottom text-muted me-2"></i>
                                                        View Profile
                                                    </b-link>
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-inbox-archive-line align-bottom text-muted me-2"></i>
                                                        Archive
                                                    </b-link>
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-mic-off-line align-bottom text-muted me-2"></i>
                                                        Muted
                                                    </b-link>
                                                    <b-link class="dropdown-item" href="#"><i
                                                        class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>
                                                        Delete
                                                    </b-link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </b-col>
                            </b-row>

                        </div>
                        <!-- Topbar end  -->

                        <perfect-scrollbar ref="channelChatConversationRef" class="chat-conversation p-3 p-lg-4" id="chat-conversation">
                            <ul class="list-unstyled chat-conversation-list" id="channel-conversation">
                                <li
                                    v-for="(msg, index) of channelMessage"
                                    :key="index"
                                    class="chat-list"
                                    :class="(msg.isRight === true) ? 'right' : 'left'"
                                    :id="msg.id"
                                    style=""
                                >
                                    <div class="conversation-list">
                                        <div class="chat-avatar">
                                            <img v-if="msg.image" :src="msg.image" alt="">
                                        </div>
                                        <div class="user-chat-content">

                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content" :id="msg.id">
                                                    <p class="mb-0 ctext-content">{{msg.content}}</p>
                                                </div>
                                                <div class="dropdown align-self-start message-box-drop">
                                                    <b-link
                                                        class="dropdown-toggle" href="#" role="button"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"><i
                                                        class="bi bi-three-dots-vertical"></i></b-link>
                                                    <div class="dropdown-menu" style="">
                                                        <b-link
                                                            class="dropdown-item reply-message" href="#"><i
                                                            class="bi bi-reply me-2 text-muted"></i>Reply
                                                        </b-link>
                                                        <b-link class="dropdown-item" href="#"><i
                                                            class="bi bi-share me-2 text-muted"></i>Forward
                                                        </b-link>
                                                        <b-link class="dropdown-item copy-message" href="#"><i
                                                            class="bi bi-clipboard-check me-2 text-muted"></i>Copy
                                                        </b-link>
                                                        <b-link class="dropdown-item" href="#"><i
                                                            class="bi bi-bookmarks me-2 text-muted"></i>Bookmark
                                                        </b-link>
                                                        <b-link class="dropdown-item delete-item" href="#"><i
                                                            class="bi bi-trash3 me-2 text-muted"></i>Delete
                                                        </b-link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="conversation-name">
                                                <span class="d-none name">{{ msg.sender_id }}</span>
                                                <small class="text-muted time">{{ msg.created_at }}</small>
                                                <span class="text-success check-message-icon">
                                                    <i class="bx bx-check-double"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </perfect-scrollbar>
                        <b-alert
                            :model-value="true"
                            variant="warning"
                            class="alert-dismissible copyclipboard-alert px-4 fade show" id="copyClipBoard"
                        >
                            Message copied
                        </b-alert>
                    </div>
                    <!-- Group conversation block end -->

                    <div class="chat-input-section p-3 p-lg-3 border-top border-2">
                        <form @submit.prevent="formSubmit" id="chatinput-form" enctype="multipart/form-data" action="#">
                            <b-row class="g-0 align-items-center">
                                <b-col cols="auto">
                                    <div class="chat-input-links">
                                        <div class="links-list-item">
                                            <b-button type="button" variant="link" class="text-decoration-none emoji-btn d-flex align-items-center justify-content-center" id="emoji-btn" @click="fileUploadModal = true">
                                                <i class="p ph-paperclip-bold align-middle"></i>
                                            </b-button>
                                            <AttachmentsUploadModal
                                                v-if="conversation && conversation.id && fileUploadModal"
                                                v-model="fileUploadModal"
                                                :fileType="'file'"
                                                :route-args="['chat.send-message', {id: conversation.id}]"
                                                :initialMessage="newMessage"
                                                @uploadComplete="fileUploadComplete"
                                            />
                                        </div>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div class="chat-input-feedback d-none">
                                        Введіть повідомлення
                                    </div>
                                    <b-form-input
                                        type="text"
                                        v-model="newMessage"
                                        class="chat-input bg-light border-light"
                                        id="chat-input"
                                        placeholder="Введіть повідомлення..."
                                        autocomplete="off"
                                    >
                                    </b-form-input>
                                </b-col>
                                <b-col cols="auto">
                                    <div class="chat-input-links ms-2 position-relative">
                                        <div
                                            :class="{
                                              'emoji-picker--hide': !isShowEmojiPicker,
                                              'emoji-picker--show': isShowEmojiPicker,
                                            }"
                                            ref="emojiPickerModal"
                                        >
                                            <EmojiPicker
                                                :disabled-groups="[
                                                    // 'smileys_people',
                                                    'animals_nature',
                                                    'food_drink',
                                                    'activities',
                                                    'travel_places',
                                                    'objects',
                                                    'symbols',
                                                    'flags',
                                                  ]"
                                                :hide-group-names="true"
                                                :hide-search="true"
                                                :hide-group-icons="true"
                                                :disable-skin-tones="true"
                                                :display-recent="true"
                                                :native="true"
                                                @select="onSelectEmoji"
                                            />
                                        </div>
                                        <div class="links-list-item d-flex flex-row align-items-center">
                                            <b-button
                                                type="button"
                                                variant="link"
                                                class="text-decoration-none emoji-btn d-flex align-items-center text-center justify-content-center"
                                                id="emoji-btn"
                                                @click="isShowEmojiPicker = !isShowEmojiPicker"
                                            >
                                                <i class="bx bx-smile align-middle"></i>
                                            </b-button>
                                            <b-button
                                                type="submit"
                                                variant="dark"
                                                class="text-decoration-none d-flex align-items-center text-center justify-content-center ms-2"
                                            >
                                                <i class="ph-paper-plane-right align-middle"></i>
                                            </b-button>
                                        </div>
                                    </div>
                                </b-col>

                            </b-row>
                        </form>
                    </div>

                    <div class="replyCard">
                        <b-card no-body class="mb-0">
                            <b-card-body class="py-3">
                                <div class="replymessage-block mb-0 d-flex align-items-start">
                                    <div class="flex-grow-1">
                                        <h5 class="conversation-name"></h5>
                                        <p class="mb-0"></p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <b-button type="button" id="close_toggle" size="sm" variant="link"
                                                  class="mt-n2 me-n3 fs-lg">
                                            <i class="bx bx-x align-middle"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.emoji-picker--hide {
    display: none;
}

.emoji-picker--show {
    display: initial;
    position: absolute;
    bottom: 55px;
    right: 0;
}
</style>
