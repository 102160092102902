<script setup>
import { Link, router } from "@inertiajs/vue3";
import { computed, nextTick, ref, watch, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import { useSupportChatStore } from "@/stores/supportChatStore.js";

const props = defineProps({
    item: Object,
    modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const target = ref(null);
const supportChatStore = useSupportChatStore();

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

onClickOutside(target, (event) => {
    nextTick(() => emit("update:modelValue", false));
});

router.on("navigate", (event) => {
    if (value.value) emit("update:modelValue", false);
});

// onMounted(() => {
//     const chatIframe = document.getElementById("keepincrmChatIframe");
//     if (!!chatIframe) {
//         supportChatStore.onElementAdded(chatIframe);
//     } else {
//         supportChatStore.setObserver();
//     }
// });
</script>

<template>
    <div>
        <div
            v-if="item.subItems"
            :id="item.link"
            class="menu-dropdown menu-dropdown-white app-menu__submenu"
            :class="{ visible: value }"
            ref="target"
        >
            <div class="app-menu__submenu-title text-center">
                <i v-if="item.icon" :class="item.icon"></i> {{ $t(item.label) }}
            </div>
            <ul class="nav nav-sm flex-column">
                <li
                    class="nav-item"
                    v-for="(subItem, index) in item.subItems"
                    :key="index"
                >
                    <!-- {{ subItem }} -->
                    <!-- for without subitems -->
                    <div
                        v-if="!subItem.subItems && !subItem.isHeader"
                        class="nav-link d-flex align-items-center"
                        :class="{
                            active: $page.url === subItem.link,
                            'justify-content-between': !subItem.isContacts,
                            'justify-content-center': subItem.isContacts,
                        }"
                    >
                        <div class="app-menu__submenu-item">
                            <Link v-if="subItem.link" :href="subItem.link">
                                <i
                                    v-if="subItem.icon"
                                    :class="subItem.icon"
                                ></i>
                                <span :data-key="subItem.label">{{
                                    $t(subItem.label)
                                }}</span>
                            </Link>
                            <a
                                v-if="subItem.thirdPartyLink"
                                :href="subItem.thirdPartyLink"
                                target="_blank"
                            >
                                <i
                                    v-if="subItem.icon"
                                    :class="subItem.icon"
                                ></i>
                                <span :data-key="subItem.label">{{
                                    $t(subItem.label)
                                }}</span>
                            </a>
                            <div v-if="!!subItem.isContacts" class="contacts">
                                <i
                                    v-if="subItem.icon"
                                    :class="subItem.icon"
                                ></i>
                                <span :data-key="subItem.label">{{
                                    $t(subItem.label)
                                }}</span>
                                <div
                                    class="d-flex justify-content-center pt-3 gap-2 contacts"
                                >
                                    <a
                                        v-for="(
                                            contactItem, contactIndex
                                        ) in subItem.contacts"
                                        :key="contactIndex"
                                        :href="contactItem.url"
                                        target="_blank"
                                    >
                                        <i
                                            v-if="contactItem.icon"
                                            :class="contactItem.icon"
                                        ></i>
                                    </a>
                                </div>
                            </div>
                            <button
                                v-if="subItem.isSupportChat"
                                class="supportChatButton d-flex align-items-center"
                                :class="{
                                    'support-chat__button--disabled':
                                        !supportChatStore.element,
                                }"
                                @click="supportChatStore.toggleSupportChat"
                                :disabled="!supportChatStore.element"
                            >
                                <i
                                    v-if="subItem.icon"
                                    :class="subItem.icon"
                                ></i>
                                <span :data-key="subItem.label">{{
                                    $t(subItem.label)
                                }}</span>
                            </button>
                        </div>
                        <div v-if="subItem.actions">
                            <DropdownButtons :buttons="subItem.actions">
                                <template v-slot:toggle-button>
                                    <i
                                        class="ph-plus-light app-menu__submenu-action"
                                    ></i>
                                </template>
                            </DropdownButtons>
                        </div>
                    </div>

                    <!-- for with subitems -->
                    <Link
                        v-if="subItem.subItems"
                        :href="'#' + subItem.link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        :aria-controls="subItem.link"
                        class="nav-link menu-link collapsed"
                        :class="{ active: $page.url === item.link }"
                    >
                        <i :class="subItem.icon" v-if="subItem.icon"></i>
                        <span :data-key="subItem.label">{{
                            $t(subItem.label)
                        }}</span>
                    </Link>
                    <div
                        :class="
                            subItem.id === 'bootstrapui'
                                ? 'collapse menu-dropdown mega-dropdown-menu dropdown-custom-right'
                                : 'collapse menu-dropdown'
                        "
                        :id="subItem.link"
                        v-if="subItem.subItems"
                    >
                        <!-- for bootstrap ui -->
                        <b-row v-if="subItem.id === 'bootstrapui'">
                            <b-col
                                lg="4"
                                v-for="(bootItem, index) in subItem.subItems"
                                :key="index"
                            >
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <Link
                                            :href="bootItem.link"
                                            class="nav-link"
                                            :data-key="bootItem.label"
                                        >
                                            {{ $t(bootItem.label) }}
                                        </Link>
                                    </li>
                                </ul>
                            </b-col>
                        </b-row>

                        <!-- for other all ui -->
                        <ul
                            class="nav nav-sm flex-column"
                            v-if="subItem.id !== 'bootstrapui'"
                        >
                            <li
                                class="nav-item"
                                v-for="(childItem, index) in subItem.subItems"
                                :key="index"
                            >
                                <!-- for without child items -->
                                <Link
                                    v-if="!childItem.childItems"
                                    class="nav-link"
                                    :href="childItem.link"
                                >
                                    <span>{{ $t(childItem.label) }}</span>
                                </Link>

                                <!-- for with childitems -->
                                <Link
                                    v-if="childItem.childItems"
                                    :href="'#' + childItem.link"
                                    class="nav-link collapsed"
                                    data-bs-toggle="collapse"
                                    role="button"
                                    aria-expanded="false"
                                    :aria-controls="childItem.link"
                                    :data-key="childItem.label"
                                >
                                    {{ $t(childItem.label) }}
                                </Link>
                                <div
                                    class="collapse menu-dropdown"
                                    :id="childItem.link"
                                    v-if="childItem.childItems"
                                >
                                    <ul class="nav nav-sm flex-column">
                                        <li
                                            class="nav-item"
                                            v-for="(
                                                subItem, index
                                            ) in childItem.childItems"
                                            :key="index"
                                        >
                                            <!-- for without subitems -->
                                            <Link
                                                v-if="!subItem.childItems"
                                                :href="subItem.link"
                                                class="nav-link"
                                            >
                                                <span>{{
                                                    $t(subItem.label)
                                                }}</span>
                                            </Link>

                                            <!-- for with subitems -->
                                            <Link
                                                :href="'#' + subItem.link"
                                                class="nav-link collapsed"
                                                data-bs-toggle="collapse"
                                                role="button"
                                                aria-expanded="false"
                                                :aria-controls="subItem.link"
                                                :data-key="subItem.label"
                                                v-if="subItem.childItems"
                                                >{{ $t(subItem.label) }}
                                            </Link>
                                            <div
                                                class="collapse menu-dropdown"
                                                :id="subItem.link"
                                                v-if="subItem.childItems"
                                            >
                                                <ul
                                                    class="nav nav-sm flex-column"
                                                >
                                                    <li
                                                        class="nav-item"
                                                        v-for="(
                                                            lastMenu, index
                                                        ) in subItem.childItems"
                                                        :key="index"
                                                    >
                                                        <!-- for last menu  -->
                                                        <Link
                                                            :href="
                                                                lastMenu.link
                                                            "
                                                            class="nav-link"
                                                            >{{
                                                                $t(
                                                                    lastMenu.label
                                                                )
                                                            }}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>

        <div class="vertical-overlay" :class="{ 'd-block': value }"></div>
    </div>
</template>

<style scoped>
/* .app-menu__submenu-item {
    color: initial;
} */
.contacts {
    color: initial;
}
.contacts:hover {
    color: initial;
}

.contacts > a {
    color: initial;
}
.contacts > a:hover {
    color: initial;
}
.contacts > a > i {
    color: initial;
}
.contacts > a > i:hover {
    color: #1db954;
}

.supportChatButton:hover {
    color: #1db954;
}

.support-chat__button--disabled:hover {
    color: #1010104d;
}
</style>
