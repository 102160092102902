<script setup>
import broadcast from "../../../../images/svg/lesson-icons/broadcast.svg";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import testing from "../../../../images/svg/lesson-icons/testing.svg";
import addFile from "../../../../images/svg/lesson-icons/add-file.svg";
import trueFalse from "../../../../images/svg/lesson-icons/true-false.svg";
import matching from "../../../../images/svg/lesson-icons/matching.svg";
import simpleLesson from "../../../../images/svg/lesson-icons/book.svg";
import {VideoPlayer} from 'vue-hls-video-player';
import {computed, ref} from "vue";
import WaitModal from "@/Components/modals/WaitModal.vue";
import {useDateFormatter, useDisableBroadcast} from "@/composables/useDateFormatter.js";
import {Link, router} from "@inertiajs/vue3";
import {BButton} from "bootstrap-vue-next";

const props = defineProps({
  lesson: Object,
  is_preview: Boolean
})

const { disableBroadcast } = useDisableBroadcast()

const iconClass = {
  broadcast: 'bi bi-broadcast module-lesson__header--red',
  video: 'bi bi-play-circle module-lesson__header--blue'
}

const icon = {
  'LESSON_TYPE_WITH_TRANSLATION': broadcast,
  'LESSON_TASK_VIDEO': addVideo,
  'LESSON_TASK_TEST': testing,
  'LESSON_TASK_FILE': addFile,
  'LESSON_TASK_TRUE_FALSE': trueFalse,
  'LESSON_TASK_MATCHING': matching,
  'LESSON_TYPE_WITHOUT_TRANSLATION': simpleLesson,
}


const iconTaskClass = {
  test: 'bi bi-file-earmark-check lesson-task__icon--green',
  compare: 'bi bi-back lesson-task__icon--blue',
  trueOrFalse: 'bi bi-check2-circle lesson-task__icon--yellow'
}
// const videoOptions = {
//   autoplay: true,
//       controls: true,
//       sources: [
//     {
//       src:
//           'https://media.bestcleverslms-dev.click/d0561f7d-b9f1-41fd-a812-d9c22b5d9ac2/playlist.m3u8',
//       type: 'video/mp4'
//     }
//   ]
// }
const waitModal = ref(false);
let timeoutId;

async function joinStudent(start) {
  try {
      waitModal.value = start;
      axios.post(route('student.joinStudent', {id: props.lesson.id}))
          .then(response => {
              if (response.data.success === true) {
                  // Відкрити нову вкладку з отриманим URL
                  window.open(response.data.url, '_blank');
                  waitModal.value = false;
              } else {
                  // Продовжувати надсилати запити кожні 5 секунд
                  // setTimeout(joinStudent, 5000);
                  scheduleJoinStudent();
              }
          })
          .catch(error => console.log('error', error));
  } catch (error) {
    console.error('Помилка при надсиланні запиту:', error);
    // Продовжувати надсилати запити кожні 5 секунд у випадку помилки
    // setTimeout(joinStudent, 5000);
      scheduleJoinStudent();
  }
}

function scheduleJoinStudent() {
    if (waitModal.value) {
        timeoutId = setTimeout(() => {
            joinStudent(waitModal.value);
        }, 5000);
    } else clearTimeout(timeoutId);
}

const { formatDate } = useDateFormatter()

const isLiveLesson = computed(() => props.lesson.type === 'LESSON_TYPE_WITH_TRANSLATION')

// Розпочати процес надсилання запитів
// joinStudent();
function markComplete(lessonId) {
    console.log(lessonId);
    router.visit(route('student.markLessonCompete'),
        {
            method: 'post',
            data: {
                lessonId: lessonId,
            },
            preserveState: false,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: () => {
                console.log('sss');
            }
        });
}

</script>

<template>
<!--    <pre>{{lesson}}</pre>-->
<!--    {{isLiveLesson}}-->
<!--    {{lesson.id}}-->
  <b-card no-body class="shadow-none module-lesson student-view">
    <b-card-body class="module-lesson__body">
      <div class="module-lesson__icon">
          <img v-if="isLiveLesson" :src="icon[lesson.type]" title="" alt="" />
          <img v-else :src="icon['LESSON_TYPE_WITHOUT_TRANSLATION']" title="" alt="" />
      </div>
      <div class="module-lesson__content">
          <div class="module-lesson__content--header">
              <div>
                  <div class="module-lesson__label">
                      Назва уроку
                  </div>
                  <div class="module-lesson__title">
                      {{ lesson.title }}
                  </div>
              </div>
              <div v-if="isLiveLesson" class="d-flex gap-1">
                  <div class="d-flex gap-1">
                      <div class="me-3">
                          <div class="module-lesson__info-label">Дата</div>
                          <div class="module-lesson__info-text">{{ formatDate(lesson.date, 'dd.MM.yyyy') }}</div>
                      </div>
                      <div class="me-3">
                          <div class="module-lesson__info-label">Тривалість</div>
                          <div class="module-lesson__info-text">{{ lesson.duration }}</div>
                      </div>
                      <div class="me-3">
                          <div class="module-lesson__info-label">Початок</div>
                          <div class="module-lesson__info-text">{{ formatDate(lesson.date, 'HH:mm') }}</div>
                      </div>
                  </div>
              </div>
              <div v-else v-if="lesson.description" class="me-3">
                  <div class="module-lesson__info-label">Опис</div>
                  <div class="module-lesson__info-text">{{ lesson.description }}</div>
              </div>
          </div>
          <div v-if="lesson.tasks.data && lesson.tasks.data.length > 0" class="module-lesson__content--tasks">
              <div
                v-for="task in lesson.tasks.data"
                :key="task.id"
                class="module-lesson__task"
              >
                  <div class="module-lesson__task-icon">
                      <img :src="icon[task.type]" title="" alt=""/>
                  </div>
                  <div class="module-lesson__task-info">
                      <div class="lesson-task__label">{{ task.type === 'LESSON_TASK_FILE' ? 'Файл' : 'Відео' }}</div>
                      <div class="lesson-task__title">{{ task.type === 'LESSON_TASK_FILE' ? task.file['original_name'] : task.video['original_name'] }}</div>
                  </div>
              </div>
          </div>
          <div class="module-lesson__content--controls">
<!--              visible: {{lesson.visible}} done: {{lesson.done}}-->
              <b-button
                  v-if="isLiveLesson && (lesson.live_status === 'running' || lesson.live_status === 'upcoming')"
                  :id="'tooltip-join_' + lesson.id"
                  variant="link"
                  class="btn-icon broadcast-btn"
                  role="button"
                  pill
                  aria-expanded="false"
                  :disabled="is_preview || disableBroadcast(lesson)"
                  @click="joinStudent"
              >
                  live
<!--                  <i class="ph-broadcast-bold broadcast-icon"></i>-->
                  <b-tooltip :target="'tooltip-join_' + lesson.id" custom-class="custom-tooltip" offset="5" :no-fade="true" :delay="{ show: 50, hide: 50 }">
                      Приєднатися до трансляції.
                  </b-tooltip>
              </b-button>
              <b-button
                  v-if="!isLiveLesson && lesson.visible"
                  :id="'tooltip-open_' + lesson.id"
                  variant="link"
                  class="btn-icon"
                  role="button"
                  pill
                  aria-expanded="false"
                  :disabled="is_preview"
                  :href="route('student.lesson_details', {id: lesson.id})"
              >
                  <i class="ph-eye label-icon align-middle rounded-pill"></i>
                  <b-tooltip :target="'tooltip-open_' + lesson.id" custom-class="custom-tooltip" offset="5" :no-fade="true" :delay="{ show: 50, hide: 50 }">
                      Відкрити урок.
                  </b-tooltip>
              </b-button>
              <!-- ri-checkbox-circle-line ph-check-square-thin -->
              <b-button
                  v-if="!lesson.done"
                  :id="'tooltip-compleete_' + lesson.id"
                  variant="link"
                  class="btn-icon btn-icon-circle"
                  pill
                  aria-expanded="false"
                  :disabled="is_preview || !lesson.visible"
                  @click="markComplete(lesson.id)"
              >
                  <i class="ph-check-bold label-icon align-middle rounded-pill"></i>
                  <b-tooltip :target="'tooltip-compleete_' + lesson.id" custom-class="custom-tooltip" offset="5" :no-fade="true" :delay="{ show: 50, hide: 50 }">
                      Відмітити як завершений
                  </b-tooltip>
              </b-button>
              <b-button
                  v-else
                  variant="link"
                  class="btn-icon btn-icon-circle btn-icon-done"
                  pill
                  aria-expanded="false"
                  disabled
              >
                  <i class="ph-check-bold label-icon align-middle rounded-pill"></i>
              </b-button>
          </div>
<!--          <div v-if="!is_preview" class="d-flex justify-content-center w-100 mt-3 join-lesson-btn">-->
<!--              <div v-if="isLiveLesson" @click="joinStudent">Приєднатись до уроку</div>-->
<!--              <Link-->
<!--                  v-else-->
<!--                  :href="route('student.lesson_details', {id: lesson.id})"-->
<!--                  method="get"-->
<!--                  type="button"-->
<!--              >-->
<!--                  Відкрити урок-->
<!--              </Link>-->
<!--              <span> {{ lesson.live_status }}</span>-->
<!--          </div>-->
<!--          <div v-else class="d-flex justify-content-center w-100 mt-3 join-lesson-btn">-->
<!--              <div v-if="isLiveLesson">Приєднатись до уроку</div>-->
<!--              &lt;!&ndash;              <button v-else>Відкрити урок</button>&ndash;&gt;-->
<!--              <div-->
<!--                  v-else-->
<!--              >-->
<!--                  Відкрити урок-->
<!--              </div>-->
<!--              <span> {{ lesson.live_status }}</span>-->
<!--          </div>-->

      </div>
<!--      <div v-if="false" class="module-lesson__header">-->
<!--        <div class="d-flex">-->
<!--          <img :src="icon[lesson.type]" title="" alt=""/>-->
<!--          &lt;!&ndash;          <i :class="iconClass[lesson.type]"></i>&ndash;&gt;-->
<!--          <button v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" @click="joinStudent(true)">Join to lesson</button>-->
<!--          <div>-->
<!--            <div class="module-lesson__header">-->
<!--                <div class="module-lesson__label">-->
<!--                  Назва уроку-->
<!--                </div>-->
<!--                <div class="module-lesson__title">-->
<!--                  {{ lesson.title }}-->
<!--                </div>-->
<!--            </div>-->
<!--            <div v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" class="d-flex mt-1">-->
<!--              <div class="me-3">-->
<!--                <div class="module-lesson__info-label">Дата</div>-->
<!--                <div class="module-lesson__info-text">{{ formatDate(lesson.date, 'dd.MM.yyyy') }}</div>-->
<!--              </div>-->
<!--              <div class="me-3">-->
<!--                <div class="module-lesson__info-label">Приблизна тривалість</div>-->
<!--                <div class="module-lesson__info-text">{{ lesson.duration }}</div>-->
<!--              </div>-->
<!--              <div class="me-3">-->
<!--                <div class="module-lesson__info-label">Початок</div>-->
<!--                <div class="module-lesson__info-text">{{ formatDate(lesson.date, 'HH:mm') }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-else class="d-flex mt-1">-->
<!--                <div v-if="lesson.description" class="me-3">-->
<!--                    <div class="module-lesson__info-label">Опис</div>-->
<!--                    <div class="module-lesson__info-text">{{ lesson.description }}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--              <button v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" @click="joinStudent">Join to lesson</button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <i v-if="lesson.done" class="bi bi-check-circle-fill module-lesson__done"></i>-->
<!--      </div>-->


<!--      <div-->
<!--          v-if="false && lesson.tasks.data && lesson.tasks.data.length > 0"-->
<!--      >-->
<!--        <div style="width: 100%"-->
<!--             v-for="task in lesson.tasks.data"-->
<!--             :key="task.id"-->
<!--             class="lesson-task"-->
<!--        >-->
<!--          &lt;!&ndash;          <img v-if="task.video_preview" :src="task.video_preview" title="" alt=""/>&ndash;&gt;-->
<!--          <a v-if="task.file" :href="task.image" :target="task.file['file_type'] === 'pdf' ? '_blank' : '_self'">-->
<!--            {{ task.file['original_name'] }}-->
<!--            <img :src="task.image" title="" alt=""/>-->
<!--            <img :src="icon[task.type]" title="" alt=""/>-->
<!--            &lt;!&ndash;          <i&ndash;&gt;-->
<!--            &lt;!&ndash;              class="lesson-task__icon"&ndash;&gt;-->
<!--            &lt;!&ndash;              :class="icon[task.type]"&ndash;&gt;-->
<!--            &lt;!&ndash;          />&ndash;&gt;-->
<!--            &lt;!&ndash;          https://cdn.bestcleverslms-dev.click/files/9c1cb195-8098-42f9-96fc-2f26a5f0ebf6/39fe58b6-82c2-4fc3-9dee-7d41785e46dd.jpg?response-content-disposition=attachment&ndash;&gt;-->

<!--            <div>-->
<!--              <div class="lesson-task__label">Завдання</div>-->
<!--              <div class="lesson-task__title">{{ task.file.title }}</div>-->
<!--            </div>-->
<!--          </a>-->
<!--          <a v-else-if="task.video" href="#">-->
<!--            <VideoPlayer-->
<!--                :previewImageLink="task.video_preview"-->
<!--                :link="task.video.path"-->
<!--                class="customClassName"-->
<!--                type="default"-->
<!--                @pause="processPause"-->
<!--                :progress="30"-->
<!--                :isMuted="false"-->
<!--                :isControls="true"-->
<!--            />-->
<!--            &lt;!&ndash;            <video width="352" height="198" controls>&ndash;&gt;-->
<!--            &lt;!&ndash;              <source src="https://media.bestcleverslms-dev.click/d0561f7d-b9f1-41fd-a812-d9c22b5d9ac2/playlist.m3u8" type="application/x-mpegURL">&ndash;&gt;-->
<!--            &lt;!&ndash;            </video>&ndash;&gt;-->
<!--            &lt;!&ndash;            <video width="320" height="240" controls>&ndash;&gt;-->
<!--            &lt;!&ndash;              <source src="https://media.bestcleverslms-dev.click/d0561f7d-b9f1-41fd-a812-d9c22b5d9ac2/playlist.m3u8" type="application/x-mpegURL">&ndash;&gt;-->
<!--            &lt;!&ndash;              <source src="https://media.bestcleverslms-dev.click/d0561f7d-b9f1-41fd-a812-d9c22b5d9ac2/playlist.m3u8" type="application/x-mpegURL">&ndash;&gt;-->
<!--            &lt;!&ndash;              Your browser does not support the video tag.&ndash;&gt;-->
<!--            &lt;!&ndash;            </video>&ndash;&gt;-->
<!--            &lt;!&ndash;            <video src="https://media.bestcleverslms-dev.click/04fa2562-ad17-48ca-8418-8439d626f3c5/playlist.m3u8"></video>&ndash;&gt;-->
<!--            <img :src="icon[task.type]" title="" alt=""/>-->
<!--            &lt;!&ndash;          <i&ndash;&gt;-->
<!--            &lt;!&ndash;              class="lesson-task__icon"&ndash;&gt;-->
<!--            &lt;!&ndash;              :class="icon[task.type]"&ndash;&gt;-->
<!--            &lt;!&ndash;          />&ndash;&gt;-->
<!--            <div>-->
<!--              <div class="lesson-task__label">Завдання</div>-->
<!--              <div class="lesson-task__title">{{ task.title }}</div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->

<!--      </div>-->
    </b-card-body>
  </b-card>
  <WaitModal v-model="waitModal"/>
</template>
<style lang="scss" scoped>
.module-lesson {
  margin-top: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--stroke, #EBEBEB);
  background: var(--white, #FFF);
  font-family: e-Ukraine;
  cursor: default;
  &:hover {
      cursor: default;
  }

    .module-lesson__body {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;
      padding: 0;
      margin: 0;
      cursor: default;
      .module-lesson__icon {
          width: 20px;
          img {
              width: 20px;
          }
      }
      .module-lesson__content {
          flex: 1;
          .module-lesson__content--header {
              display: flex;
              flex-direction: column;
              gap: 16px;
              & > div {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
              }
          }
          .module-lesson__content--tasks {
              margin-top: 16px;
              .module-lesson__task {
                  padding: 16px 0;
                  display: flex;
                  align-items: flex-start;
                  border-top: 1px solid #EBEBEB;
                  gap: 8px;
                  .module-lesson__task-info {
                      display: flex;
                      flex-direction: column;
                      gap: 4px;
                      .lesson-task__label {
                          color: var(--dark, #1E1E1E);
                          font-size: 10px;
                          font-style: normal;
                          font-weight: 200;
                          line-height: 100%;
                          opacity: 0.5;
                      }

                      .lesson-task__title {
                          color: var(--dark, #1E1E1E);
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 300;
                          line-height: 140%;
                          opacity: 0.5;
                      }
                  }

              }
          }
      }
  }
  .module-lesson__header {
    display: flex;
    justify-content: space-between;

    .module-lesson__done {
      font-size: 25px;
      color: var(--green, #1DB954);
      margin: 0;
    }

    i {
      font-size: 20px;
      margin-right: 16px;
    }

    .module-lesson__header--red {
      color: #DA1414;
    }

    .module-lesson__header--blue {
      color: #31A6D9;
    }
    .module-lesson__header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .module-lesson__label {
            color: var(--dark, #1E1E1E);
            font-size: 10px;
            font-style: normal;
            font-weight: 200;
            line-height: 100%;
            opacity: 0.5;
        }

        .module-lesson__title {
            color: var(--dark, #1E1E1E);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            align-self: stretch;
        }
    }
  }

  .module-lesson__info-label {
    color: var(--dark, #1E1E1E);
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    opacity: 0.5;
    margin-bottom: 4px;
  }

  .module-lesson__info-text {
    color: var(--dark, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }

  .lesson-task {
    border-top: 1px solid #EBEBEB;
    margin: 0 0 0 36px;
    padding: 15px 0;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 15px;
    }

    .lesson-task__icon {
      font-size: 22px;
      margin-right: 12px;
    }

    .lesson-task__icon--green {
      color: #1DB954;
    }

    .lesson-task__icon--blue {
      color: #2B7BC4;
    }

    .lesson-task__icon--yellow {
      color: #F1C963;
    }

    .lesson-task__label {
      color: var(--dark, #1E1E1E);
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 100%;
      opacity: 0.5;
    }

    .lesson-task__title {
      color: var(--dark, #1E1E1E);
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      opacity: 0.5;
    }
  }
  .join-lesson-btn {
      font-family: e-Ukraine;
      font-weight: bold;
      color: var(--green, #1DB954)!important;
      div {
          cursor: pointer;
      }
  }
}
</style>
