<script setup>
import {Head, router, usePage} from '@inertiajs/vue3';

import "flatpickr/dist/flatpickr.css";
import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import CalendarComponent from "@/Components/Schedule/CalendarComponent.vue";
import WeekSchedule from "@/Components/Schedule/WeekSchedule.vue";
import {computed, ref} from "vue";

defineProps({
  schedule: Object,
})

const { t } = useI18n();

const pageData = usePage();
const userType = pageData?.props?.auth?.user?.current_account_type;

const page = {
    title: t('schedule'),
}

</script>

<template>
    <div>
        <Head :title="page.title"/>

        <PageContentCard :title="page.title">
            <template #header-start>

            </template>

            <template #default>
              <CalendarComponent :student="true" no-modal :account-type="userType" />
            </template>
        </PageContentCard>
    </div>
</template>
