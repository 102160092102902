<script setup>


defineProps({
    tooltipText: String,
    link: String,
    confirm:{
        type : Boolean,
        default: false,
    }
});

const emit = defineEmits(['click', 'openConfirm']);
</script>

<template>
    <div id="webinar-room" class="position-relative meeting-button" @click="confirm ? ()=>console.log('confirm') :emit('click')">
        <!-- <div v-if="isDisabledMeetingButton" class="p-0">
            <i
                class="bi bi-camera-video position-absolute top-50 start-50 translate-middle meeting-button-icon"
            ></i>
        </div> -->
        <a v-if="!confirm" :href="link" target="_blank" class="p-0">
            <i
                class="bi bi-camera-video position-absolute top-50 start-50 translate-middle meeting-button-icon"
            ></i>
            <i
                class="bi bi-camera-video-fill position-absolute top-50 start-50 translate-middle meeting-button-icon__hovered"
            ></i>
        </a>
          <button v-else class="p-0" @click="emit('openConfirm')">
            <i
                class="bi bi-camera-video position-absolute top-50 start-50 translate-middle meeting-button-icon"
            ></i>
            <i
                class="bi bi-camera-video-fill position-absolute top-50 start-50 translate-middle meeting-button-icon__hovered"
            ></i>
        </button>
    </div>
   <template v-if="confirm">
    <slot name="confirm_modal">

    </slot>
   </template>
    <b-tooltip
        target="webinar-room"
        triggers="hover"
        custom-class="webinar-tooltip"
        offset="5"
        :no-fade="true"
        :delay="{ show: 50, hide: 50 }"
    >
        {{ tooltipText }}
    </b-tooltip>
</template>
<style lang="scss" scoped>
.meeting-button-base {
    width: 50px;
    height: 50px;
    border: 2px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    transition: border 500ms ease;
    color: #000000;
    cursor: pointer;

    a {
        color: #000000;
    }

    .meeting-button-icon {
        font-size: 30px;
        opacity: 1;
        transition: font-size 500ms ease, opacity 500ms ease;
    }

    .meeting-button-icon__hovered {
        font-size: 30px;
        color: red;
        opacity: 0;
        transition: font-size 500ms ease, opacity 500ms ease;
    }
}

.meeting-button {
    @extend .meeting-button-base;

    &:hover {
        border: 2px solid red;

        .meeting-button-icon {
            opacity: 0;
            font-size: 20px;
        }

        .meeting-button-icon__hovered {
            opacity: 1;
            font-size: 20px;
        }
    }
}

// .meeting-button-disabled {
//     @extend .meeting-button-base;
//     cursor: not-allowed;

//     .meeting-button-icon {
//         cursor: not-allowed;
//     }
// }

.webinar-room-icon {
    color: initial;
    cursor: not-allowed;

    &:hover {
        color: initial;
    }
}
</style>
