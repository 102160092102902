<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import TabFormGeneralInfo from "@/Pages/Webinars/Partials/TabFormGeneralInfo.vue";
import TabFormSettings from "@/Pages/Webinars/Partials/TabFormSettings.vue";
import TabFormUsers from "@/Pages/Workshops/Partials/TabFormUsers.vue";
import TabFormRegisterInfo from "@/Pages/Webinars/Partials/TabFormRegisterInfo.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import PageHeader from "@/Components/PageHeader.vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import { useWorkshopStatus } from "@/composables/useDateFormatter.js";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";

const props = defineProps({
    user: Object,
    webinar: Object,
    workshopTypes: Object,
    registerLink: "",
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
    roles: "",
    link: "",
    webinarButtons: Object,
    timeUntilAdjustedStart: Number,
});

const { t } = useI18n();
const state = computed(() => {
    return props.webinar && props.webinar.id > 0 ? "new" : "edit";
});

const page = ref({
    title: t("WEBINAR") + ": " + props.webinar.title,
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route("dashboard"),
        },
        {
            text: "Воркшопи",
            href: route("workshops.workshops"),
        },
        {
            text: t("WEBINAR") + ": " + props.webinar.title,
            active: true,
        },
    ],
});

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const getButtonsFields = (webinarButtons) => {
    const buttons = [...webinarButtons];

    if (buttons.length === 0) {
        return [
            { title: "", url: "" },
            { title: "", url: "" },
        ];
    }

    if (buttons.length === 1) {
        if (buttons[0].order)
            buttons.push({ title: "", url: "", order: buttons[0].order + 1 });
        else buttons.push({ title: "", url: "" });
    }
    return buttons;
};

const form = useForm({
    workShop: props.webinar,
    filePreview: null,
    publicLink: "",
    webinarButtons: getButtonsFields(props.webinarButtons),
});

function updateWebinar(value) {
    // console.log('Change');
    // props.form.workShop = value;
}

const newUsersCount = computed(
    () => form.workShop.users.filter((user) => !user.access).length
);

function save() {
    console.log("Save");
    router.visit(
        route("workshops.update", { type: "webinar", id: form.workShop.id }),
        {
            method: "post",
            data: form,
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
        }
    );

    // props.form.workShop = value;
}

watch(
    () => form.workShop.title,
    (value) => {
        console.log("change edit");
        page.value.title = t("WEBINAR") + ": " + value;
        page.value.breadcrumbs[page.value.breadcrumbs.length - 1].text =
            t("webinar") + ": " + value;
    },
    { deep: true }
);

const shareLinkModal = ref(false);
const shareLink = ref("");
const selectedUsers = ref([]);

function handleShare() {
    shareLinkModal.value = !shareLinkModal.value;
    shareLink.value = props.registerLink;
}

const { getWorkshopStatus } = useWorkshopStatus();

const isStartTimeGrant = computed(() => {
    return getWorkshopStatus({
        type: props.webinar.type,
        status: props.webinar.status,
        date: props.webinar.config.date,
        workShopDuration: props.webinar.config.duration,
        workShopStatus: props.webinar.config.workShopStatus,
    }).showLiveButton;
});
</script>

<template>
    <div>
        <Head title="Воркшопи" />

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress />
                    <MeetingButton
                        v-if="isStartTimeGrant"
                        :link="link"
                        tooltip-text="Кімната зустрічі"
                    />

                    <b-button
                        v-if="form.workShop.status === 'PUBLISH'"
                        class="outline-dark"
                        pill
                        size="lg"
                        @click="handleShare"
                    >
                        Поділитися подією
                        <i class="ph-share"></i>
                    </b-button>
                    <ShareModal
                        v-model="shareLinkModal"
                        :shared-link="shareLink"
                    />
                </div>
            </template>

            <template #default>
                <b-tabs nav-class="page-tabs">
                    <b-tab title="Основна інформація" active>
                        <!--                        <pre>{{webinar}}</pre>-->
                        <TabFormGeneralInfo
                            @webinar-change="updateWebinar"
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :register-link="registerLink"
                        />
                    </b-tab>
                    <b-tab title="Налаштування кімнати">
                        <TabFormSettings
                            @webinar-change="updateWebinar"
                            @save="save"
                            :form="form"
                            :workshop-types="workshopTypes"
                            :team-presenter="teamPresenter"
                            :team-moderator="teamModerator"
                            :link="link"
                            :roles="roles"
                        />
                    </b-tab>
                    <b-tab
                        v-if="!form.workShop.config.access"
                        title="Реєстрація"
                        :disabled="form.workShop.config.access"
                    >
                        <TabFormRegisterInfo @save="save" :form="form" />
                    </b-tab>
                    <b-tab title="Користувачі">
                        <template #title>
                            Користувачі
                            <div
                                v-if="newUsersCount > 0"
                                class="nav-link__counter"
                            >
                                {{ newUsersCount }}
                            </div>
                        </template>
                        <TabFormUsers v-model="selectedUsers" :form="form" />
                    </b-tab>

                    <template #tabs-end>
                        <div class="tabs-end">
                            <PreviewButton
                                v-if="form.workShop.status === 'DRAFT'"
                                :href="registerLink"
                                target="_blank"
                            />
                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
.module-content {
    min-height: calc(100vh - 120px);
}
.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>
