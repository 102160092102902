



/**
 * Send message.
 *
 * @param {string} conversationId
 * @param {Object} messageData {
 *     message: "Message text",
 *     attachments: [], // attachments ids
 * }
 * @returns {Promise<*>}
 */
export async function sendMessage(conversationId, messageData) {
    return axios.post(`/chat/conversation/${conversationId}/send-message`, {
        ...messageData
    });
}

/**
 * Delete message by id (for_all or for_sender only).
 *
 * @param messageId
 * @param params
 * @returns {Promise<*>}
 */
export async function deleteMessage(messageId, params) {
    return axios.post(`/chat/message-delete`, {
        id: messageId,
        ...params
    });
}

export async function getConversationById(conversationId, params) {
    return axios.get(`/chat/conversations/${conversationId}`);
}

export async function getConversationsList() {
    return axios.get(`/chat/conversations`);
}

export async function getLatestMessages(page) {
    return axios.get(`/chat/latest-messages`, {
        current_page: !!page ? page : 1,
    });
}
