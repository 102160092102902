<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { computed, ref } from "vue";
import { Link, usePage } from "@inertiajs/vue3";

import i18n from "../i18n";
import english from "../../images/flags/gb.svg";
import ukraine from "../../images/flags/ua.svg";

import logoSm from "../../images/logo-sm.png";
import logoDark from "../../images/logo-dark.png";
import logoLight from "../../images/logo-light.png";

import avatar1 from "../../images/users/32/avatar-1.jpg";
import avatar2 from "../../images/users/32/avatar-2.jpg";
import avatar3 from "../../images/users/32/avatar-3.jpg";
import avatar5 from "../../images/users/32/avatar-5.jpg";
import avatar8 from "../../images/users/32/avatar-8.jpg";
import noAvatar from "../../images/tmp/ProductOwnerProfile/avatar.png";

import product1 from "../../images/products/32/img-1.png";
import product5 from "../../images/products/32/img-5.png";
import product8 from "../../images/products/32/img-8.png";
import product10 from "../../images/products/32/img-10.png";
import product11 from "../../images/products/32/img-11.png";

import github from "../../images/brands/github.png";
import bitbucket from "../../images/brands/bitbucket.png";
import dribbble from "../../images/brands/dribbble.png";
import dropbox from "../../images/brands/dropbox.png";
import mail_chimp from "../../images/brands/mail_chimp.png";
import slack from "../../images/brands/slack.png";
import NotificationsDropdown from "@/Components/NotificationsDropdown.vue";
import MessagesDropdown from "@/Components/MessagesDropdown.vue";
import { BButton, BModal } from "bootstrap-vue-next";
import { useLocalStorage } from "@vueuse/core";

/**
 * Nav-bar Component
 */
export default {
    components: {
        BButton,
        BModal,
        MessagesDropdown,
        NotificationsDropdown,
        Link,
    },
    props: {
        isPreview: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const page = usePage();

        const user = computed(() =>
            page?.props?.auth?.user ? page.props.auth.user : {}
        );
        const auth = computed(() => (page?.props?.auth ? page.props.auth : {}));
        const storageStatistic = computed(() =>
            page?.props?.auth?.storageStatistic
                ? page.props.auth.storageStatistic
                : { balance: 0 }
        );

        const avatar = computed(() =>
            page?.props?.auth?.user?.imageUrl
                ? page.props.auth.user.imageUrl
                : noAvatar
        );

        const isTrialAlertVisible = useLocalStorage(
            "isTrialAlertVisible",
            true
        );

        const closeTrialAlert = () => {
            isTrialAlertVisible.value = false;
        };

        return {
            page,
            auth,
            user,
            storageStatistic,
            avatar,
            isPreview: props.isPreview,
            isTrialAlertVisible,
            closeTrialAlert,
        };
    },
    data() {
        return {
            us: english,
            logoSm,
            logoDark,
            logoLight,
            avatar1,
            avatar2,
            avatar3,
            avatar5,
            avatar8,
            noAvatar,
            product1,
            product5,
            product8,
            product10,
            product11,
            github,
            bitbucket,
            dribbble,
            dropbox,
            mail_chimp,
            slack,
            languages: [
                {
                    flag: english,
                    language: "en",
                    title: "English",
                },
                {
                    flag: ukraine,
                    language: "ua",
                    title: "Українська",
                },
                // {
                //     flag: russia,
                //     language: "ru",
                //     title: "русский",
                // },
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            myVar: 1,
            removeCartModal: false,
            removeNotificationModal: false,
            showLogoutModal: false,
        };
    },
    methods: {
        logout() {
            toast.success("User logged out!", {
                autoClose: 3000,
            });
            setTimeout(() => {
                sessionStorage.clear();
                this.$router.push({ name: "login" });
            }, 1500);
        },
        isCustomDropdown() {
            //Search bar
            var searchOptions = document.getElementById("search-close-options");
            var dropdown = document.getElementById("search-dropdown");
            /*var searchInput = document.getElementById("search-options");*/

            /*searchInput.addEventListener("focus", () => {
          var inputLength = searchInput.value.length;
          if (inputLength > 0) {
              dropdown.classList.add("show");
              searchOptions.classList.remove("d-none");
          } else {
              dropdown.classList.remove("show");
              searchOptions.classList.add("d-none");
          }
      });*/

            /*searchInput.addEventListener("keyup", () => {
          var inputLength = searchInput.value.length;
          if (inputLength > 0) {
              dropdown.classList.add("show");
              searchOptions.classList.remove("d-none");
          } else {
              dropdown.classList.remove("show");
              searchOptions.classList.add("d-none");
          }
      });*/

            // searchOptions.addEventListener("click", () => {
            //     searchInput.value = "";
            //     dropdown.classList.remove("show");
            //     searchOptions.classList.add("d-none");
            // });

            // document.body.addEventListener("click", (e) => {
            //     if (e.target.getAttribute("id") !== "search-options") {
            //         dropdown.classList.remove("show");
            //         searchOptions.classList.add("d-none");
            //     }
            // });
        },
        toggleHamburgerMenu() {
            document.documentElement.setAttribute(
                "data-sidebar-visibility",
                "show"
            );

            var windowSize = document.documentElement.clientWidth;
            let layoutType =
                document.documentElement.getAttribute("data-layout");
            let visiblilityType = document.documentElement.getAttribute(
                "data-sidebar-visibility"
            );

            if (windowSize > 767)
                document
                    .querySelector(".hamburger-icon")
                    .classList.toggle("open");

            //For collapse horizontal menu
            if (
                document.documentElement.getAttribute("data-layout") ===
                "horizontal"
            ) {
                document.body.classList.contains("menu")
                    ? document.body.classList.remove("menu")
                    : document.body.classList.add("menu");
            }

            //For collapse vertical menu
            if (visiblilityType === "show" && layoutType === "vertical") {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute(
                        "data-sidebar-size"
                    ) == "md"
                        ? document.documentElement.setAttribute(
                              "data-sidebar-size",
                              "lg"
                          )
                        : document.documentElement.setAttribute(
                              "data-sidebar-size",
                              "md"
                          );
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute(
                        "data-sidebar-size"
                    ) == "lg"
                        ? document.documentElement.setAttribute(
                              "data-sidebar-size",
                              "md"
                          )
                        : document.documentElement.setAttribute(
                              "data-sidebar-size",
                              "lg"
                          );
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute(
                        "data-sidebar-size",
                        "lg"
                    );
                }
            }

            //Two column menu
            if (
                document.documentElement.getAttribute("data-layout") ==
                "twocolumn"
            ) {
                document.body.classList.contains("twocolumn-panel")
                    ? document.body.classList.remove("twocolumn-panel")
                    : document.body.classList.add("twocolumn-panel");
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            // document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        setLanguage(locale, country, flag) {
            this.lan = locale;
            this.text = country;
            this.flag = flag;
            if (document.getElementById("header-lang-img")) {
                document
                    .getElementById("header-lang-img")
                    .setAttribute("src", flag);
            }
            i18n.global.locale = locale;
        },
        initTopbarComponents() {
            var cartList = document.querySelectorAll(".product").length;

            document
                .querySelectorAll(".cartitem-badge")
                .forEach(function (item) {
                    item.innerHTML = cartList;
                });
            var This = this;
            document.querySelectorAll(".product-list").forEach(function (elem) {
                elem.querySelectorAll(".product-count").forEach(function (
                    subelem
                ) {
                    subelem.innerHTML =
                        elem.querySelectorAll(".product").length;
                });
            }, This);
        },

        removeProduct(element) {
            this.removeCartModal = true;
            document
                .getElementById("remove-cartproduct")
                .addEventListener("click", function () {
                    if (
                        element.target.parentElement.parentElement.closest(
                            ".product"
                        )
                    ) {
                        element.target.parentElement.parentElement
                            .closest(".product")
                            .remove();

                        document
                            .querySelector("#removeCartModal .btn-close")
                            .click();

                        Array.from(
                            document.querySelectorAll(".cartitem-badge")
                        ).forEach((badge) => {
                            badge.innerHTML =
                                document.querySelectorAll(".product").length;
                        });
                        if (
                            document.querySelectorAll(".product").length === 0
                        ) {
                            if (document.getElementById("empty-cart")) {
                                document.getElementById(
                                    "empty-cart"
                                ).style.display = "block";
                            }
                            if (
                                document.getElementById("checkout-elem") ||
                                document.getElementById("count-table")
                            ) {
                                document.getElementById(
                                    "checkout-elem"
                                ).style.display = "none";
                                // document.getElementById("count-table").style.display = "none";
                            }
                        }
                    }
                });
        },

        deleteNotification() {
            this.removeNotificationModal = true;
            document
                .getElementById("delete-notification")
                .addEventListener("click", function () {
                    Array.from(
                        document.querySelectorAll(".notification-item")
                    ).forEach(function (element) {
                        if (element.classList.contains("active")) {
                            element.remove();
                        }
                        Array.from(
                            document.querySelectorAll(".notification-badge")
                        ).forEach(function (item) {
                            item.innerHTML = document.querySelectorAll(
                                ".notification-check input"
                            ).length;
                        });
                        Array.from(
                            document.querySelectorAll(".notification-unread")
                        ).forEach(function (item) {
                            item.innerHTML = document.querySelectorAll(
                                ".notification-item.unread-message"
                            ).length;
                        });
                    });
                    document
                        .querySelector("#removeNotificationModal .btn-close")
                        .click();

                    if (
                        document.querySelectorAll(".notification-item")
                            .length === 0
                    ) {
                        document
                            .querySelectorAll(".notification-title")
                            .forEach(function (item) {
                                item.style.display = "none";
                            });

                        var emptyNotificationElem = document.querySelector(
                            "#notificationItemsTabContent .empty-notification-elem"
                        );
                        if (
                            !emptyNotificationElem &&
                            document.getElementById(
                                "notificationItemsTabContent"
                            )
                        ) {
                            document.getElementById(
                                "notificationItemsTabContent"
                            ).innerHTML +=
                                '<div class="empty-notification-elem text-center px-4">\
						<div class="mt-3 avatar-md mx-auto">\
							<div class="avatar-title bg-info-subtle text-info fs-24 rounded-circle">\
							<i class="bi bi-bell "></i>\
							</div>\
						</div>\
						<div class="pb-3 mt-2">\
							<h6 class="fs-lg fw-semibold lh-base">Hey! You have no any notifications </h6>\
						</div>\
					</div>';
                        }
                    }
                });
        },
        // light-dark mode
        changeMode(mode) {
            this.$parent.changeMode(mode);
            if (mode == "dark") {
                return this.$parent.changeLayout({
                    mode: mode,
                    topbar: "dark",
                });
            } else if (mode == "light") {
                return this.$parent.changeLayout({
                    mode: mode,
                    topbar: "light",
                });
            } else {
                return this.$parent.changeLayout({
                    mode: mode,
                    topbar: "light",
                });
            }
        },
        windowResizeHover() {
            var windowSize = document.documentElement.clientWidth;
            if (windowSize < 1025 && windowSize > 767) {
                if (document.querySelector(".hamburger-icon")) {
                    document
                        .querySelector(".hamburger-icon")
                        .classList.add("open");
                }
            } else if (windowSize >= 1025) {
                if (document.querySelector(".hamburger-icon")) {
                    document
                        .querySelector(".hamburger-icon")
                        .classList.remove("open");
                }
            } else if (windowSize <= 767) {
                if (document.querySelector(".hamburger-icon")) {
                    document
                        .querySelector(".hamburger-icon")
                        .classList.add("open");
                }
            }
        },
    },
    computed: {},
    mounted() {
        this.flag = this.$i18n.locale;
        this.lan = this.$i18n.locale;
        this.languages.forEach((item) => {
            if (item.language == this.flag) {
                document.getElementById("header-lang-img")
                    ? document
                          .getElementById("header-lang-img")
                          .setAttribute("src", item.flag)
                    : "";
            }
        });

        document.addEventListener("scroll", function () {
            var pageTopbar = document.getElementById("page-topbar");
            if (pageTopbar) {
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
                    ? pageTopbar.classList.add("topbar-shadow")
                    : pageTopbar.classList.remove("topbar-shadow");
            }
        });

        if (document.getElementById("empty-cart")) {
            document.getElementById("empty-cart").style.display = "none";
        }
        if (
            document.getElementById("checkout-elem") ||
            document.getElementById("count-table")
        ) {
            document.getElementById("checkout-elem").style.display = "block";
            // document.getElementById("count-table").style.display = "block";
        }

        // count notifications
        Array.from(
            document.querySelectorAll(".notification-check input")
        ).forEach(function (element) {
            element.addEventListener("change", function (el) {
                el.target
                    .closest(".notification-item")
                    .classList.toggle("active");

                var checkedCount = document.querySelectorAll(
                    ".notification-check input:checked"
                ).length;

                if (
                    el.target
                        .closest(".notification-item")
                        .classList.contains("active")
                ) {
                    checkedCount > 0
                        ? (document.getElementById(
                              "notification-actions"
                          ).style.display = "block")
                        : (document.getElementById(
                              "notification-actions"
                          ).style.display = "none");
                } else {
                    checkedCount > 0
                        ? (document.getElementById(
                              "notification-actions"
                          ).style.display = "block")
                        : (document.getElementById(
                              "notification-actions"
                          ).style.display = "none");
                }
                document.getElementById("select-content").innerHTML =
                    checkedCount;
            });

            var notificationDropdown = document.getElementById(
                "notificationDropdown"
            );
            if (notificationDropdown) {
                notificationDropdown.addEventListener(
                    "hide.bs.dropdown",
                    function () {
                        element.checked = false;
                        document
                            .querySelectorAll(".notification-item")
                            .forEach(function (item) {
                                item.classList.remove("active");
                            });
                        document.getElementById(
                            "notification-actions"
                        ).style.display = "";
                    }
                );
            }
        });

        // show no notifications
        if (document.querySelectorAll(".notification-item").length > 0) {
            document
                .querySelectorAll(".notification-title")
                .forEach(function (item) {
                    item.style.display = "block";
                });
        }

        window.addEventListener("resize", this.windowResizeHover);
        this.windowResizeHover();

        this.isCustomDropdown();
        this.initTopbarComponents();

        window.addEventListener("fullscreenchange", function () {
            document.body.classList.toggle("fullscreen-enable");
        });
    },
};
</script>

<template>
    <header id="page-topbar">
        <div
            v-if="auth.trial_status === 'STARTED' && isTrialAlertVisible && user.current_account_type !== 'ACCOUNT_TYPE_STUDENT'"
            class="trial-alert"
        >
            <div>
                Термін пробного періоду закінчиться через
                {{ Math.ceil(auth.trial_day_left) }} днів
            </div>
            <i class="bi bi-x-lg cursor-pointer" @click="closeTrialAlert"></i>
        </div>
        <div
            v-if="!auth.isTariffActive && user.current_account_type !== 'ACCOUNT_TYPE_STUDENT'"
            class="trial-alert"
        >
            <div v-if="user.current_account_type === 'ACCOUNT_TYPE_PRODUCT_OWNER'">
                Щоб активувати увесь функціонал, будь ласка, оберіть пакет послуг. Перейдіть до
                <Link
                    :href="route('product_owner_profile') + '#plan'"
                >
                білінгу
                </Link>.
            </div>
            <div v-else>
                Щоб продовжити користування, будь ласка, сплатіть пакет послуг. Зверніться до власника акаунта.
            </div>
        </div>
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex align-items-center">
                    <div class="navbar-brand-box horizontal-logo">
                        <Link href="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img :src="logoSm" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img :src="logoDark" alt="" height="22" />
                            </span>
                        </Link>

                        <Link href="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img :src="logoSm" alt="" height="22" />
                            </span>
                            <span class="logo-lg">
                                <img :src="logoLight" alt="" height="22" />
                            </span>
                        </Link>
                    </div>
                    <b-button
                        v-if="false"
                        type="button"
                        size="sm"
                        variant="white"
                        class="px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                        @click="toggleHamburgerMenu"
                        id="topnav-hamburger-icon"
                    >
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </b-button>

                    <!--          <b-button v-if="user.current_account_type === 'ACCOUNT_TYPE_PRODUCT_OWNER'" type="button" pill variant="subtle-success" class="h-75 d-flex">-->
                    <!--            <i class="bx bx-coin-stack label-icon align-middle rounded-pill fs-xl me-2"></i>-->
                    <!--            ${{ storageStatistic.balance }}-->
                    <!--          </b-button>-->
                </div>

                <div class="d-flex align-items-center">
                    <div
                        v-if="false"
                        class="dropdown ms-1 topbar-head-dropdown header-item"
                    >
                        <b-button
                            type="button"
                            variant="ghost-secondary"
                            class="btn-icon btn-topbar rounded-circle text-uppercase"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <!--                            <img id="header-lang-img" :src="us" alt="Header Language" height="20" class="rounded">-->
                            {{ lan }}
                        </b-button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <Link
                                href="#"
                                v-for="(entry, i) in languages"
                                :key="`Lang${i}`"
                                :value="entry"
                                @click="
                                    setLanguage(
                                        entry.language,
                                        entry.title,
                                        entry.flag
                                    )
                                "
                                :class="{ active: lan === entry.language }"
                                class="dropdown-item notify-item language py-2"
                                data-lang="en"
                                title="English"
                            >
                                <!--                                <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18"/>-->
                                <span class="align-middle">{{
                                    entry.title
                                }}</span>
                            </Link>
                        </div>
                    </div>

                    <div class="ms-1 header-item d-none d-sm-flex">
                        <b-button
                            type="button"
                            variant="ghost-dark"
                            class="btn-icon btn-topbar rounded-circle"
                            data-toggle="fullscreen"
                            @click="initFullScreen"
                        >
                            <i class="bi bi-arrows-fullscreen fs-lg"></i>
                        </b-button>
                    </div>

                    <!--          <div class="dropdown topbar-head-dropdown ms-1 header-item">-->
                    <!--            <b-button type="button" variant="ghost-dark"-->
                    <!--                      class="btn-icon btn-topbar rounded-circle mode-layout"-->
                    <!--                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                    <!--              <i class="bi bi-sun align-middle fs-3xl"></i>-->
                    <!--            </b-button>-->
                    <!--            <div class="dropdown-menu p-2 dropdown-menu-end" id="light-dark-mode">-->
                    <!--              <Link href="#!" class="dropdown-item" @click="changeMode('light')" data-mode="light"><i-->
                    <!--                  class="bi bi-sun align-middle me-2"></i> Світла тема-->
                    <!--              </Link>-->
                    <!--              <Link href="#!" class="dropdown-item" @click="changeMode('dark')" data-mode="dark"><i-->
                    <!--                  class="bi bi-moon align-middle me-2"></i> Темна тема-->
                    <!--              </Link>-->
                    <!--              <Link href="#!" class="dropdown-item" @click="changeMode('auto')" data-mode="auto"><i-->
                    <!--                  class="bi bi-moon-stars align-middle me-2"></i> Авто (system defualt)-->
                    <!--              </Link>-->
                    <!--            </div>-->
                    <!--          </div>-->

<!--                    <NotificationsDropdown :is-preview="isPreview" />-->

                    <MessagesDropdown :is-preview="isPreview" />

                    <div
                        v-if="!isPreview"
                        class="dropdown ms-sm-3 header-item topbar-user"
                    >
                        <b-button
                            type="button"
                            variant="white"
                            class="shadow-none"
                            id="page-header-user-dropdown"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span class="d-flex align-items-center">
                                <img
                                    class="rounded-circle header-profile-user"
                                    :src="avatar"
                                    alt="Header Avatar"
                                />
                                <span class="text-start ms-xl-2">
                                    <span
                                        class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                                    >
                                        {{ user.name }}
                                    </span>
                                </span>
                            </span>
                        </b-button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <Link
                                v-if="
                                    user.current_account_type !==
                                    'ACCOUNT_TYPE_STUDENT'
                                "
                                class="dropdown-item"
                                :href="route('product_owner_profile')"
                            >
                                <i
                                    class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"
                                ></i>
                                <span class="align-middle">Особисті дані</span>
                            </Link>
                            <Link
                                v-if="
                                    user.current_account_type ===
                                    'ACCOUNT_TYPE_STUDENT'
                                "
                                class="dropdown-item"
                                :href="route('student.student_profile')"
                            >
                                <i
                                    class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"
                                ></i>
                                <span class="align-middle">Особисті дані</span>
                            </Link>
                            <Link
                                v-if="
                                    user.current_account_type ===
                                    'ACCOUNT_TYPE_PRODUCT_OWNER'
                                "
                                class="dropdown-item"
                                :href="route('product_owner_profile') + '#plan'"
                            >
                                <i
                                    class="bi bi-wallet2 text-muted align-middle me-1 wallet-fs"
                                ></i>
                                <span class="align-middle">Білінг</span>
                            </Link>
                            <Link
                                v-if="
                                    user.current_account_type ===
                                    'ACCOUNT_TYPE_PRODUCT_OWNER'
                                "
                                class="dropdown-item"
                                :href="
                                    route('product_owner_profile') + '#school'
                                "
                            >
                                <i
                                    class="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"
                                ></i>
                                <span class="align-middle">Моя школа</span>
                            </Link>
                            <Link
                                class="dropdown-item cursor-pointer"
                                :href="route('logout')"
                            >
                                <i
                                    class="mdi mdi-logout text-muted fs-lg align-middle me-1"
                                ></i>
                                <span class="align-middle" data-key="t-logout"
                                    >Вихід</span
                                >
                            </Link>
                        </div>
                    </div>
                    <div
                        v-else
                        class="dropdown topbar-head-dropdown ms-1 header-item"
                    >
                        <span class="d-flex align-items-center ms-2">
                            <img
                                class="rounded-circle header-profile-user"
                                :src="noAvatar"
                                alt="Header Avatar"
                            />
                            <span class="text-start ms-xl-2">
                                <span
                                    class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                                >
                                    Ім'я студента
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <b-modal
        v-model="showLogoutModal"
        hide-footer
        centered
        class="zoomIn"
        body-class="p-md-5"
        id="removeCartModal"
    >
        <div class="text-center">
            <div class="text-danger">
                <i class="bi bi-trash display-5"></i>
            </div>
            <div class="mt-4">
                <h4>Бажаєте вийти?</h4>
                <p class="text-muted mx-4 mb-0">Ви дійсно хочете вийти?</p>
            </div>
        </div>
        <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
            <b-button
                type="button"
                variant="light"
                class="w-sm"
                @click="showLogoutModal = false"
                >Ні</b-button
            >
            <b-button
                type="button"
                variant="danger"
                class="w-sm"
                @click="logout"
                >Так</b-button
            >
        </div>
    </b-modal>
</template>

<style lang="scss" scoped>
.wallet-fs{
    font-size: 13px;
    padding-left: 1px;
}
</style>
