<script setup>
import {computed, ref, watch} from 'vue';

const props = defineProps({
  modelValue: String
});

const emit = defineEmits([
  'update:modelValue',
  'commentUpdate'
]);

const editedValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value);
  }
})

const isEditMode = ref(props.modelValue === '');

</script>

<template>

  <div class="comment-field">
    <div class="d-flex justify-content-between">
      <div class="comment-field__label">
        Коментарі
      </div>
      <div v-if="isEditMode" class="comment-field__save-btn" @click="isEditMode = !isEditMode; emit('commentUpdate');">
        Зберегти
      </div>
      <div v-if="!isEditMode" class="comment-field__edit-btn" @click="isEditMode = !isEditMode">
        <i class="bi bi-pencil-square"></i>
      </div>
    </div>
    <b-form-textarea
        v-model="editedValue"
        placeholder=""
        rows="6"
        max-rows="6"
        class="comment-field__text"
        :disabled="!isEditMode"
    />
  </div>
</template>
<style lang="scss" scoped>
.comment-field{
  .comment-field__label {
    color: var(--dark, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 14px;
  }
  .comment-field__save-btn {
    color: var(--green, #1DB954);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }
  .comment-field__edit-btn {
    padding: 5px;
    cursor: pointer;
  }
  .comment-field__text {
    color: var(--dark, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    background: transparent;
  }
}
</style>
