<script setup>

import {ref} from "vue";
import TeamMemberChooserModal from "@/Components/modals/TeamMemberChooserModal.vue";
import {usePage} from "@inertiajs/vue3";
import {useRoleChecker} from "@/composables/useRoleChecker.js";

const props = defineProps({
    speaker: Array,
    team: Object,
    type: {
        type: String,
        required: true
    }
})

const emit = defineEmits([
    'onSave',
]);

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const teamMemberChooserModal = ref(false)

const handleTeamMemberChoose = (member) => {
    emit('onSave');
}

</script>

<template>
    <b-container col="6" fluid class="tab-form">
        <b-form>
            <b-card no-body class="overflow-hidden shadow-none mt-4 w-100">
                <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">
                    <div class="fs mb-2 header-title">
                        <slot name="header"></slot>
                    </div>
                    <div class="d-flex justify-content-between align-items-end w-100">
                        <div class="d-flex flex-column flex-wrap gap-3 chooser-description">
                            <slot name="description"></slot>
                            <div v-for="user in speaker" :key="user.id" class="team-member__card">
                                <div class="team-member__card--name">{{user.name}}</div>
                                <div class="team-member__card--email">{{user.email}}</div>
                            </div>
                        </div>
                        <b-button variant="danger" class="red-plus-button" :disabled="isReadOnly" @click="teamMemberChooserModal = !teamMemberChooserModal">
                            <i class="bx bx-plus"></i>
                        </b-button>
                    </div>
                    <TeamMemberChooserModal v-if="teamMemberChooserModal" v-model="teamMemberChooserModal" :speakers="speaker" :team="team" :type="type" @on-save="handleTeamMemberChoose" />
                </b-card-body>
            </b-card>
        </b-form>
    </b-container>
</template>

<style scoped lang="scss">
    .header-title {
        font-family: e-Ukraine;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;
        color: #1E1E1E;
    }
    .chooser-description {
        font-family: e-Ukraine;
        font-size: 14px;
        font-weight: 300;
        line-height: 19.6px;
        text-align: left;
        color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
    }
</style>
