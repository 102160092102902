<script setup>

import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";

const props = defineProps({
    modules: Object,
    user: Object,
    disabled: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['save-modules'])

const {t} = useI18n();

const visibleDropdown = ref(false);

const moduleCounts = computed(() => {
    return props.modules.reduce(
        (acc, item) => {
            if (item.module_type === "MULTI_LESSON") {
                acc.multiLesson += 1;
            } else if (item.module_type === "SINGLE_LESSON") {
                acc.singleLesson += 1;
            }
            return acc;
        },
        { multiLesson: 0, singleLesson: 0 }
    );
});

const avalaibleModuleCounts = computed(() => {
    return props.modules.reduce(
        (acc, item) => {
            const selected = props.user.user_id && props.user.access[props.user.user_id] ? Object.keys(props.user.access[props.user.user_id]) : []
            if (selected.includes(item.id)) {
                if (item.module_type === "MULTI_LESSON") {
                    acc.multiLesson += 1;
                } else if (item.module_type === "SINGLE_LESSON") {
                    acc.singleLesson += 1;
                }
            }
            return acc;
        },
        { multiLesson: 0, singleLesson: 0 }
    );
});

const isModuleChecked = (moduleId) => {
    const userAccess = props.user.access[props.user.user_id];
    return userAccess && userAccess[moduleId] && userAccess[moduleId][0] === true;
}

const handleCheckboxChange = (data) => {
    emit('save-modules', data)
}

</script>

<template>
    <div class="course-module__dropdown-selector">
        <b-dropdown id="dropdown-form" variant="link" v-model="visibleDropdown" menu-class="dropdown-selector" no-caret auto-close="outside">
            <template #button-content>
                <div class="dropdown-selector__btn">
                    Доступно:
                    <span class="module-type__badge module-type__badge--multi">
                    Модулів: {{avalaibleModuleCounts.multiLesson}}/{{moduleCounts.multiLesson}}
                </span>
                    <span class="module-type__badge module-type__badge--single">
                    Уроків: {{avalaibleModuleCounts.singleLesson}}/{{moduleCounts.singleLesson}}
                </span>
                    <i v-if="!visibleDropdown" class="ph-caret-down-bold" />
                    <i v-else class="ph-caret-up-bold" />
                </div>
            </template>
            <b-dropdown-form class="dropdown-selector__form">
                <label v-for="(module, index) of modules" :key="module.id" class="dropdown-selector--form__checkbox" :class="{'dropdown-selector--form__checkbox--multi': module.module_type === 'MULTI_LESSON'}">
                    <input
                        type="checkbox"
                        :checked="isModuleChecked(module.id)"
                        :disabled="disabled"
                        @change="handleCheckboxChange(
                        {
                                user_id: user.user_id,
                                module_id: module.id
                            })"
                    />
                    <span class="checkbox-icon">
                        <i v-if="isModuleChecked(module.id)" class="mdi mdi-lock-open" />
                        <i v-else class="mdi mdi-lock" />
                    </span>
                    <span class="checkbox-text">
                    <span v-if="module.module_type === 'MULTI_LESSON'">
                        Модуль: {{module.title}}
                    </span>
                    <span v-if="module.module_type === 'SINGLE_LESSON'">
                        Урок: {{ module.lessons && module.lessons.length > 0 ? module.lessons[0].title : 'Без назви'}}
                    </span>
                </span>
                </label>
            </b-dropdown-form>
        </b-dropdown>
    </div>
</template>

<style lang="scss">
.course-module__dropdown-selector {
    .dropdown-selector--form__checkbox {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        border-left: 3px solid #C2C2C2;
        background: #F7F7F8;
        border-radius: 4px;
        padding: 8px 10px;
        overflow-wrap: normal;
        white-space: nowrap;
        &:hover {
            border-color: var(--purple, #F24822);
            .checkbox-text {
                color: var(--dark, #1E1E1E);
            }
        }
    }

    .dropdown-selector--form__checkbox--multi:hover {
        border-color: var(--green, #1DB954);
    }

    .dropdown-selector--form__checkbox input[type="checkbox"] {
        display: none;
    }

    .checkbox-icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border-radius: 4px;
        i {
            font-size: 16px;
            color: #DA1414;
        }
    }

    .dropdown-selector--form__checkbox input[type="checkbox"]:checked + .checkbox-icon {
        i {
            color: var(--green, #1DB954);
        }
    }

    .checkbox-text {
        flex: 1;
        font-family: e-Ukraine;
        font-size: 12px;
        font-weight: 300;
        line-height: 12px;
        text-align: left;
        color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 40%);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
