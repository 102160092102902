export const diffToMilliseconds = (eventDate, startDate) => {
    return new Date(eventDate).getTime() - new Date(startDate).getTime();
};

export const compareNewTimeWithSpecifiedTime = (
    newMillisecondsDiff,
    startEventTime,
    specifiedTime,
    comparison = ">"
) => {
    const newTime = newMillisecondsDiff + new Date(startEventTime).getTime();
    const targetTime = new Date(specifiedTime).getTime();

    console.log(newTime, targetTime, newTime - targetTime);
    if (comparison === ">") {
        return newTime > targetTime;
    } else if (comparison === "<") {
        return newTime < targetTime;
    } else {
        throw new Error("Invalid comparison operator. Use '>' or '<'.");
    }
};
export const fromMillisecondsToObjectTime = (ms) => {
    ms = Math.abs(ms);
    let milliseconds = ms % 1000;
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

    hours = hours;
    minutes = minutes;
    seconds = seconds;
    milliseconds = milliseconds;

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        milliseconds: milliseconds,
    };
};

export const fromObjectTimeToMilliseconds = ({
    hours,
    minutes,
    seconds,
    milliseconds,
}) => {
    // Проверяем, что все значения являются числами и если нет, то приводим их к числу
    hours = Number(hours) || 0;
    minutes = Number(minutes) || 0;
    seconds = Number(seconds) || 0;
    milliseconds = Number(milliseconds) || 0;

    // Конвертируем все значения в миллисекунды и складываем их
    const totalMilliseconds =
        hours * 3600000 + // 1 час = 3600000 миллисекунд
        minutes * 60000 + // 1 минута = 60000 миллисекунд
        seconds * 1000 + // 1 секунда = 1000 миллисекунд
        milliseconds;

    return totalMilliseconds;
};

export const validURL = (str) => {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
};

export const getVideoId = (url) => {
    const regex =
        /(?:youtube\.com\/(?:watch\?v=|embed\/|live\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
};
