<script setup>
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { ref } from "vue";
import { Link, router, useForm, usePage } from "@inertiajs/vue3";
import EmptyData from "@/Components/EmptyData.vue";
import InputModal from "@/Components/modals/InputModal.vue";
import noImage from "../../../../images/no-image.png";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    mentorprograms: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const actions = [
    {
        id: 1,
        label: isReadOnly ? "Переглянути" : "Редагувати",
        link: "",
        icon: isReadOnly
            ? "bi bi-eye align-baseline me-1"
            : "bi bi-pencil-square align-baseline me-1",
    },
    ...(!isReadOnly
        ? [
              {
                  id: 2,
                  label: "Видалити",
                  link: "",
                  icon: "bi bi-trash3 align-baseline me-1",
              },
          ]
        : []),
    {
        id: 3,
        label: "Поділитись",
        link: "",
        icon: "ri-upload-2-line align-baseline me-1",
    },
];

const shareLink = ref("");
const shareLinkModal = ref(false);
const removeModal = ref(false);
const inputModal = ref(false);

const tempId = ref(null);

function handleActionClick(row, id) {
    if (id === 3) {
        shareLinkModal.value = !shareLinkModal.value;
        shareLink.value = route("roadmap.view", { programId: row });
    }
    if (id === 2) {
        removeModal.value = !removeModal.value;
        tempId.value = row;
    }
    if (id === 1) {
        router.visit(route("mentoring.program.edit", { id: row }), {
            method: "get",
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
        });
    }
}

function deleteProgram(id) {
    console.log("deleteProgram", id);
    router.visit(route("mentoring.program.delete", { id: id }), {
        method: "post",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
    });
}

const empty = true;
</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div
                v-if="!mentorprograms"
                class="w-100 d-flex justify-content-center align-content-center"
            >
                <EmptyData type="program">
                    <template v-slot:title> Тут поки що нічого немає </template>
                    <template v-slot:description>
                        <div class="mb-2">
                            Створіть свою першу менторську програму.
                        </div>
                        <div>
                            Менторська програма призначена для демонстрації,
                            студентам, поетапного навчального процесу,
                            розподіленого по модулях, заняттях та результатах
                        </div>
                    </template>
                    <template v-slot:action>
                        <b-button
                            class="outline-dark"
                            pill
                            size="lg"
                            href="/mentoring/program/create"
                        >
                            <!--@click="inputModal = !inputModal"-->
                            Створити менторську програму<i class="ph-plus"></i>
                        </b-button>
                    </template>
                </EmptyData>
            </div>
            <div v-else class="module-table mentoring-program-list">
                <div class="module-table__header border-bottom pb-2">
                    <div
                        class="module-table__col module-table__col--name text-uppercase opacity-50"
                    >
                        Назва програми
                    </div>
                    <div
                        class="module-table__col module-table__col--view text-uppercase opacity-50"
                    >
                        Переглянути
                    </div>
                    <div
                        class="module-table__col module-table__col--stages text-uppercase opacity-50"
                    >
                        Кількість модулів
                    </div>
                    <div
                        class="module-table__col module-table__col--actions text-uppercase opacity-50"
                    >
                        Дії
                    </div>
                </div>
                <div
                    v-for="program of mentorprograms"
                    :key="program.id"
                    class="module-table__row border-bottom"
                >
                    <Link
                        :href="
                            route('mentoring.program.edit', { id: program.id })
                        "
                        class="module-table__col module-table__col--name"
                    >
                        <!--            {{ program.name }} image-->
                        <div class="module-table__title-image">
                            <b-img
                                :src="program.image ? program.image : noImage"
                                :title="program.name"
                                :alt="program.name"
                                rounded
                                fluid
                            ></b-img>
                        </div>
                        <div class="module-table__title-text">
                            <span class="table-col__title">{{
                                program.name
                            }}</span>
                            <span class="table-col__subtitle">{{
                                program.description &&
                                program.description.length > 0
                                    ? program.description
                                    : "без опису"
                            }}</span>
                        </div>
                    </Link>
                    <div class="module-table__col module-table__col--view">
                        <div
                            class="module-table__view--button"
                            @click="
                                router.get(`/mentoring/roadmap/${program.id}`)
                            "
                        >
                            <a
                                :href="
                                    route('roadmap.view', {
                                        programId: program.id,
                                    })
                                "
                                target="_blank"
                            >
                                <i
                                    class="ph-eye label-icon align-middle rounded-pill"
                                ></i>
                            </a>
                        </div>
                    </div>
                    <div class="module-table__col module-table__col--stages">
                        <i class="bi bi-list-ul"></i>{{ program.modules }}
                    </div>
                    <div class="module-table__col module-table__col--actions">
                        <DropdownButtons
                            :buttons="actions"
                            @on-click="
                                (id) => handleActionClick(program.id, id)
                            "
                        >
                            <template v-slot:toggle-button>
                                <i
                                    class="bi bi-three-dots-vertical dropdown-menu__button"
                                ></i>
                            </template>
                        </DropdownButtons>
                    </div>
                </div>
            </div>
            <ShareModal v-model="shareLinkModal" :shared-link="shareLink" />
            <ConfirmModal
                v-model="removeModal"
                :object-id="tempId"
                title="Видалення менторської програми"
                decline-label="Скасувати"
                accept-label="Так, видалити"
                @accept-btn="(id) => deleteProgram(id)"
                @decline-btn="(id) => console.log('decline', id)"
            >
                <template v-slot:description>
                    Після видалення менторської програми її не можна буде
                    повернути. Ви впевнені, що хочете продовжити?
                </template>
            </ConfirmModal>
            <InputModal
                v-model="inputModal"
                modal-title="Назва програми"
                input-label="Назва"
                button-text="Створити"
                @send-input="(str) => save()"
            />
        </b-form>
    </b-container>
</template>

<style lang="scss" scoped>
.mentoring-program-list {
    .module-table__col--name {
        display: flex;
        width: 50%;
        align-items: center;
        padding-right: 16px;
        margin: 10px 0;
        flex: 1;
        .module-table__title-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6px;
            flex-wrap: nowrap;
            padding: 10px 16px;
            width: calc(100% - 125px);

            .table-col__title {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                color: #1e1e1e;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            .table-col__subtitle {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 200;
                line-height: 1.5;
                letter-spacing: 0;
                text-align: left;
                color: #1e1e1e;
                opacity: 0.5;
                display: -webkit-box ;
                display: -moz-box;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .module-table__col--view {
        width: 20%;
        display: flex;
        justify-content: center;
    }
    .module-table__col--stages {
        width: 20%;
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        i {
            color: #000;
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            opacity: 0.5;
            margin-right: 4px;
        }
    }
    .module-table__col--actions {
        width: 50px !important;
        justify-content: center !important;
    }
    .module-table__view--button {
        a {
            color: var(--dark, #1e1e1e);
            display: flex;
            width: 22px;
            height: 22px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 31px;
            border: 1px solid var(--dark, #1e1e1e);
            opacity: 0.5;
            background: var(--white, #fff);
            &:hover {
                color: var(--tb-primary);
                border-color: var(--tb-primary);
            }
        }
    }
    .module-table__title-image {
        width: 128px;
        height: 72px;
        border-radius: 8px;
        border: 1px solid #ebebeb;
        background: #f7f7f8;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 128px;
            max-height: 72px;
        }
    }
}
</style>
