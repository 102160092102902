<script setup>

</script>

<template>
  <div class="login-main register-wrapper">
    <b-container fluid class="page-login">
      <b-row class="page-login-row">
        <b-col md="4" class="d-flex flex-column justify-content-center align-items-center page-login-container">
          <!-- ============================================================== -->
          <slot name="left-side"/>
          <!-- ============================================================== -->
<!--          <p class="register-copyright mt-5">-->
<!--              <a href="https://zentixsoft.com" target="_blank">Dev by Zentix</a>-->
<!--          </p>-->
        </b-col>
        <b-col md="8" class="align-self-stretch p-0">
          <slot name="right-side" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.register-wrapper {
  height: calc(100vh - 100px);
  .register-form-wrapper {
    display: flex;
    padding: 16px 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
  }
}
.register-copyright {
  color: var(--dark, #1E1E1E);
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 12.6px */
  opacity: 0.5;
}
.page-login-container {
  background: #F7F7F8;
}
</style>

