<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { computed, nextTick, watch } from "vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import PublicLink from "@/Components/form/PublicLink.vue";
import { helpers, required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const { t } = useI18n();

const props = defineProps({
    form: Object,
    workshopTypes: Object,
    registerLink: "",
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isExpired = computed(
    () => props.form?.workShop?.config?.workShopStatus === "EXPIRED"
);
const isFinished = computed(
    () => props.form?.workShop?.config?.workShopStatus === "FINISHED"
);

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles) || isFinished.value || isExpired.value;

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const onSubmit = () => {
    v$.value["title"].$touch();
    if (!v$.value.$invalid) {
        emit("save");
    }
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["webinar-change", "save"]);

watch(
    () => props.form.workShop,
    (value) => {
        nextTick(() => emit("webinar-change", value));
    },
    { deep: true }
);

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        maxLength: helpers.withMessage("Не більше 90 символів", maxLength(90)),
    },
};

const v$ = useVuelidate(rules, props.form.workShop, { $lazy: true });
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="6">
                <!--        <input type="file" @input="form.filePreview = $event.target.files[0]" />-->
                <b-form>
                    <FileUploader
                        :form="form"
                        :imagePreview="form.workShop.image"
                        :disabled="isReadOnly"
                        customTitle=" або перетягніть прев’ю"
                    />
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <SelectInputGroup
                                label="Статус*"
                                v-model="form.workShop.status"
                                :options="statuses"
                                size="lg"
                                class="w-100"
                                :disabled="isReadOnly"
                            />
                            <PublicLink :public-link="registerLink" />
                        </b-card-body>
                    </b-card>

                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light-subtle p-3"
                        >
                            <div class="fs card__title">Основні дані</div>

                            <InputGroup
                                label="Назва вебінару*"
                                v-model="form.workShop.title"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :maxLength="90"
                                :disabled="isReadOnly"
                                :is-error="v$.title?.$errors[0]?.$message"
                                :error-message="v$.title?.$errors[0]?.$message"
                                @blur="v$.title.$touch()"
                            />

                            <InputGroup
                                element="textarea"
                                label="Анонс до вебінару"
                                v-model="form.workShop.description"
                                :options="statuses"
                                :rows="3"
                                :max-rows="10"
                                :maxLength="255"
                                :disabled="isReadOnly"
                                size="lg"
                                class="mt-3 w-100"
                            />
                        </b-card-body>
                    </b-card>
                    <b-button
                        :disabled="form.processing || isReadOnly"
                        variant="primary"
                        size="lg"
                        pill
                        class="mt-3"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.webinar-input-group {
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;

    i {
        font-size: medium;
    }

    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

#input-1 {
    background-color: #1db9540a;
    border: 1px solid #1db954;
    border-radius: 4px;
}
</style>
