export const LESSON_ACTION_EMIT = 'lesson-action';

export const LESSON_TYPE_WITH_TRANSLATION = 'TYPE_WITH_TRANSLATION';
export const LESSON_TYPE_WITHOUT_TRANSLATION = 'TYPE_WITHOUT_TRANSLATION';

export const COURSE_ITEM_TYPE_MODULE = 'COURSE_ITEM_TYPE_MODULE';
export const COURSE_ITEM_TYPE_LESSON = 'COURSE_ITEM_TYPE_LESSON';

export const LESSON_TASK_TEST = 'LESSON_TASK_TEST';
export const LESSON_TASK_MATCHING = 'LESSON_TASK_MATCHING';
export const LESSON_TASK_TRUE_FALSE = 'LESSON_TASK_TRUE_FALSE';
export const LESSON_TASK_FILE = 'LESSON_TASK_FILE';
export const LESSON_TASK_VIDEO = 'LESSON_TASK_VIDEO';
