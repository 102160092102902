<script setup>
import { Head, router, useForm, usePage } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { ref } from "vue";
import AddUserModal from "@/Components/modals/AddUserModal.vue";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";
import RoleSelector from "@/Components/form/RoleSelector.vue";

const props = defineProps({
    team: Object,
    roles: Object,
    isLimit: false
});

const { t } = useI18n();

const page = {
    title: t("team"),
};

const pageData = usePage();
const currentAccountType = pageData?.props?.auth?.user?.current_account_type;

const form = useForm({
    team: props.team,
});

const selectedUser = ref(null);

// watch(
//   () => form.team,
//   (value) => {
//     console.log(value);
//     router.visit(route('team.change_roles'),
//       {
//         method: 'post',
//         data: {team: value[ 0 ]},
//         preserveState: true,
//         preserveScroll: true,
//         forceFormData: true
//       }
//     )
//   }
//   , {deep: true}
// );

const tabIndex = ref(0);

const addUserModal = ref(false);
const updateUserModal = ref(false);

const handleAddUser = (user) => {
    console.log("Add new user: ", user);
    if (user.id && user.id.length > 10) {
        router.visit(route("team.update_member"), {
            method: "post",
            data: { member: user },
            preserveState: false,
            preserveScroll: false,
            forceFormData: true,
        });
    } else {
        router.visit(route("team.add_member"), {
            method: "post",
            data: { member: user },
            preserveState: false,
            preserveScroll: false,
            forceFormData: true,
        });
    }
};
const deleteUser = (value) => {
    console.log("user: ", value);
    router.visit(route("team.deleteMember"), {
        method: "post",
        data: { id: value },
        preserveState: false,
        preserveScroll: false,
        forceFormData: true,
        onFinish: () => (tmpTeamMember.value = null),
    });
};

const handleChangeUserRole = (value) => {
    console.log("change role: ", value);
    router.visit(route("team.change_roles"), {
        method: "post",
        data: { team: value },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
    });
};

const editTeamMember = (member) => {
    console.log("user member: ", member);
    selectedUser.value = member;
    updateUserModal.value = !updateUserModal.value;
};

const newTeamMember = () => {
    selectedUser.value = null;
    addUserModal.value = !addUserModal.value;
    form.reset();
};

const actions = [
    {
        id: 1,
        label: "Редагувати",
        link: "",
        icon: "bi bi-pencil-square align-baseline me-1",
    },
    {
        id: 2,
        label: "Видалити",
        link: "",
        icon: "bi bi-trash3 align-baseline me-1",
    },
];

function handleActionClick(user, id) {
    if (id === 2) {
        tmpTeamMember.value = user;
        deleteTeamMember.value = true;
        //deleteUser(user)
    }
    if (id === 1) {
        editTeamMember(user);
    }
}

const deleteTeamMember = ref(false);
const tmpTeamMember = ref(null);
</script>

<template>
    <div>
        <Head :title="page.title" />

        <PageHeader :title="page.title" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">
                    <b-button
                        variant="primary"
                        pill
                        size="lg"
                        @click="newTeamMember"
                    >
                        Додати користувача
                    </b-button>
                </div>
            </template>

            <template #default>
                <b-container fluid class="tab-form">
                    <b-form>
                        <div
                            v-if="!form.team || form.team.length > 0"
                            class="module-table team__container"
                        >
                            <div
                                class="module-table__header border-bottom pb-2"
                            >
                                <div
                                    class="module-table__col module-table__col--user text-uppercase opacity-50"
                                >
                                    Користувач
                                </div>
                                <div
                                    class="module-table__col module-table__col--phone text-uppercase opacity-50"
                                >
                                    телефон
                                </div>
                                <div
                                    class="module-table__col module-table__col--permission text-uppercase opacity-50"
                                >
                                    Дозвіл
                                </div>
                                <div
                                    class="module-table__col module-table__col--actions text-uppercase opacity-50"
                                >
                                    Дії
                                </div>
                            </div>
                            <div
                                v-for="(user, index) of form.team"
                                :key="user.id"
                                class="module-table__row border-bottom py-3"
                            >
                                <div
                                    class="module-table__col module-table__col--user team__user"
                                    @click="editTeamMember(user)"
                                >
                                    <b-avatar
                                        variant="light"
                                        :src="
                                            user.image ? user.image : noAvatar
                                        "
                                        class="team__user--logo"
                                    ></b-avatar>
                                    <div
                                        class="d-flex flex-column justify-content-center"
                                    >
                                        <span class="team__user--name"
                                            >{{
                                                user.isOwner &&
                                                currentAccountType ===
                                                    "ACCOUNT_TYPE_TEAM_MEMBER"
                                                    ? "(" + t("OWNER") + ")"
                                                    : user.isOwner
                                                    ? "(Ви)"
                                                    : ""
                                            }}{{ user.name }}</span
                                        >
                                        <span class="team__user--email">{{
                                            user.email
                                        }}</span>
                                    </div>
                                </div>
                                <div
                                    class="module-table__col module-table__col--phone team__user--phone"
                                >
                                    {{ user.phone }}
                                </div>
                                <div
                                    class="module-table__col module-table__col--permission"
                                >
                                    <!--                  <div class="team__user&#45;&#45;checkbox" v-for="role of roles" :key="role">-->
                                    <!--                    <b-form-checkbox-->
                                    <!--                        v-if="!user.isOwner"-->
                                    <!--                        :value="role"-->
                                    <!--                        v-model="user.roles"-->
                                    <!--                        @change="handleChangeUserRole(user)"-->
                                    <!--                    >-->
                                    <!--                      {{ t(role) }} <i :id="`tooltip-${role}-${user.id}`" class="bi bi-info-circle"></i>-->
                                    <!--                    </b-form-checkbox>-->
                                    <!--                    <b-tooltip class="text-left" :target="`tooltip-${role}-${user.id}`" custom-class="custom-tooltip" offset="5" triggers="hover" :no-fade="true" :delay="{ show: 50, hide: 50 }">-->
                                    <!--                        {{ t(role+'_TOOLTIP') }}-->
                                    <!--                    </b-tooltip>-->
                                    <!--                  </div>-->
                                    <RoleSelector
                                        v-if="!user.isOwner"
                                        :user="user"
                                        :roles="roles"
                                        @save-roles="handleChangeUserRole(user)"
                                    />
                                    <div v-else class="ps-2">
                                        {{ t("OWNER") }}
                                        <i
                                            :id="`tooltip-owner`"
                                            class="bi bi-info-circle"
                                        ></i>
                                        <b-tooltip
                                            class="text-left"
                                            target="tooltip-owner"
                                            custom-class="custom-tooltip"
                                            offset="5"
                                            triggers="hover"
                                            :no-fade="true"
                                            :delay="{ show: 50, hide: 50 }"
                                        >
                                            {{ t("OWNER_TOOLTIP") }}
                                        </b-tooltip>
                                    </div>
                                </div>
                                <div
                                    class="module-table__col module-table__col--actions"
                                >
                                    <!-- <i
                                        v-if="!user.isOwner"
                                        class="bi bi-chat-text-fill fs-3"
                                    ></i> -->
                                    <DropdownButtons
                                        v-if="!user.isOwner"
                                        :buttons="actions"
                                        @on-click="
                                            (id) => handleActionClick(user, id)
                                        "
                                    >
                                        <template v-slot:toggle-button>
                                            <i
                                                class="bi bi-three-dots-vertical dropdown-menu__button"
                                            ></i>
                                        </template>
                                    </DropdownButtons>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h3 class="text-center m-5 text-black-50">
                                Ще нікого нема
                            </h3>
                        </div>
                        <AddUserModal
                            v-if="updateUserModal"
                            v-model="updateUserModal"
                            @add-user="handleAddUser"
                            :user="selectedUser"
                            email-with-tenant
                        />
                        <AddUserModal
                            v-if="addUserModal"
                            v-model="addUserModal"
                            @add-user="handleAddUser"
                            email-with-tenant
                        />
                        <ConfirmModal
                            v-model="deleteTeamMember"
                            title="Видалення користувача"
                            decline-label="Скасувати"
                            accept-label="Так, видалити"
                            @accept-btn="() => deleteUser(tmpTeamMember)"
                            @decline-btn="() => console.log('decline')"
                        >
                            <template v-slot:description>
                                Ви впевнені, що хочете видалити цього
                                користувача?
                            </template>
                        </ConfirmModal>
                    </b-form>
                </b-container>
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss">
.team__container {
    min-height: calc(100vh - 270px);

    .team__user--logo {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        margin: 0 8px;
    }

    .team__user--name {
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 5px;
    }

    .team__user--email {
        color: var(--Grey, #c2c2c2);
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
    }

    .team__user--phone {
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .team__user--checkbox {
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &:last-child {
            margin: 0;
        }
    }
    .module-table__col--user {
        display: flex;
        flex: 1;
    }

    .module-table__col--phone {
        width: 25%;
    }
    .module-table__col--permission {
        width: 25%;
    }

    .module-table__col--actions {
        width: 40px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
            margin: 0!important;
            padding: 0!important;
            width: 30px;
            height: 30px;
        }
        //i {
        //  font-size: x-large;
        //  color: #000;
        //}
    }
    //@media only screen and (max-width: 1368px) {
    //    .module-table__col--user {
    //        width: 25%;
    //    }
    //}
}

.module-content {
    min-height: calc(100vh - 120px);
}

.header-end {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}
</style>
