<script setup>
import { Head, router } from "@inertiajs/vue3";

import "flatpickr/dist/flatpickr.css";
import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import CalendarComponent from "@/Components/Schedule/CalendarComponent.vue";
import WeekSchedule from "@/Components/Schedule/WeekSchedule.vue";
import { computed, ref } from "vue";

const props = defineProps({
    accountType: String,
    weekSchedule: Object,
    specificDatesSchedules: Object,
    link: String,
});

const { t } = useI18n();

const page = {
    title: t("schedule"),
};

const scheduleCanvas = ref(null);

const bOffCanvasShow = ref(false);

const scheduleActive = ref(true);
const handleChangeType = (type) => {
    if (type === "schedule") {
        scheduleActive.value = true;
    }
    if (type === "calendar") {
        scheduleActive.value = false;
    }
};

const scheduleClass = computed(() => {
    if (!scheduleActive.value)
        return "schedule-сanvas schedule-canvas--calendar";
    return "schedule-сanvas";
});

const WeekScheduleMock = ref({});
</script>

<template>
    <div>
        <Head :title="page.title" />

        <PageContentCard :title="page.title">
            <template #header-start> </template>

            <template #default>
                <CalendarComponent
                    :accountType="accountType"
                    button-text="Задати графік менторських сесій"
                    :specific-dates-schedules="specificDatesSchedules"
                    @custom-button-click="bOffCanvasShow = !bOffCanvasShow"
                    no-modal
                />
                <b-offcanvas
                    v-model="bOffCanvasShow"
                    :class="scheduleClass"
                    ref="scheduleCanvas"
                    headerClass="schedule-сanvas__title"
                    headerCloseClass="schedule-сanvas__close-btn"
                    placement="end"
                >
                    <template #title> Графік менторських сесій </template>
                    <div class="schedule-toggle">
                        <div
                            class="schedule-toggle__item"
                            :class="{
                                'schedule-toggle__item--active': scheduleActive,
                            }"
                            @click="handleChangeType('schedule')"
                        >
                            Графік
                        </div>
                        <div
                            class="schedule-toggle__item"
                            :class="{
                                'schedule-toggle__item--active':
                                    !scheduleActive,
                            }"
                            @click="handleChangeType('calendar')"
                        >
                            Календар
                        </div>
                    </div>
                    <div v-if="scheduleActive">
                        <WeekSchedule v-model="props.weekSchedule" />
                    </div>
                    <div class="calendar-wrapper" v-else>
                        <CalendarComponent
                            v-if="!scheduleActive"
                            :isShowDescription="true"
                            :accountType="accountType"
                            :specificDatesSchedules="specificDatesSchedules"
                            button-text="Розклад"
                            @custom-button-click="() => router.get(`/schedule`)"
                        />
                    </div>
                </b-offcanvas>
            </template>
        </PageContentCard>
    </div>
</template>
