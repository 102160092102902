<script setup>
const props = defineProps({
    disabled: Boolean,
});

const emit = defineEmits(["watchPreview"]);
</script>

<template>
    <b-button
        type="button"
        :disabled="!!disabled"
        variant="link"
        class="d-flex align-items-center tabs-end__action-btn"
        @click="emit('watchPreview')"
    >
        Попередній перегляд
        <span class="tabs-end__action-icon">
            <i class="ph-eye label-icon align-middle rounded-pill"></i>
        </span>
    </b-button>
</template>
<style lang="scss" scoped></style>
