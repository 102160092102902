<template>
    <div class="input-group">
        <button class="btn btn-decrement" @click="decrement" :disabled="modelValue <= min">
            <i class="bi bi-dash-square-fill" />
        </button>
        <input
            type="number"
            :value="modelValue"
            @input="updateValue($event.target.value)"
            class="form-control"
        />
        <button class="btn btn-increment" @click="increment">
            <i class="bi bi-plus-square-fill" />
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    step: {
        type: Number,
        default: 1,
    },
    min: {
        type: Number,
        default: 0,
    }
});

// Відправляємо події для оновлення моделі
const emit = defineEmits(['update:modelValue']);

// Оновлюємо значення
const updateValue = (value) => {
    const parsedValue = parseInt(value);
    emit('update:modelValue', isNaN(parsedValue) ? props.min : parsedValue);
};

// Інкрементуємо значення
const increment = () => {
    emit('update:modelValue', props.modelValue + props.step);
};

// Декрементуємо значення, перевіряючи мінімальне значення
const decrement = () => {
    if (props.modelValue - props.step >= props.min) {
        emit('update:modelValue', props.modelValue - props.step);
    } else {
        emit('update:modelValue', props.min);
    }
};
</script>

<style scoped>
.input-group {
    display: flex;
    align-items: center;
    max-width: 160px!important;
}

.btn {
    padding: 0!important;
    margin: 0!important;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1db954;
    height: 20px;
    width: 20px;
}

.form-control {
    text-align: center;
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
    color: #1E1E1E!important;
    height: 20px!important;
    padding: 3px 0!important;
}
</style>
