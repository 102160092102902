<script setup>
import { computed, ref } from "vue";
import { toast } from "vue3-toastify";

const file = ref(null);

const fileInput = ref(null);
const imagePreviewRef = ref("");

const props = defineProps({
    form: Object,
    imagePreview: String,
    customTitle: {
        type: String,
    },
    customSubTitle: {
        type: String,
    },
    noSubTitle: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const openFilePicker = () => {
    if (fileInput) {
        fileInput.value.click();
    }
};

const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        file.value = selectedFile;
        props.form.file = selectedFile;
        props.form.filePreview = selectedFile;
    }
};

const isImage = computed(
    () => file.value && file.value.type.startsWith("image")
);
const filePreview = computed(() =>
    isImage.value ? URL.createObjectURL(file.value) : props.imagePreview
);
imagePreviewRef.value = filePreview;

const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
};

const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
        file.value = droppedFile;
        props.form.file = droppedFile;
    }
};

const removeImage = () => {
    file.value = null;
    imagePreviewRef.value = "";
    props.form.file = "";
    props.form.filePreview = "";
};
</script>

<template>
    <div
        v-if="(file && isImage) || imagePreviewRef.value"
        class="image-uploader__preview"
    >
        <button
            class="image-uploader__preview--delete"
            :disabled="disabled"
            @click="removeImage"
        >
            <i class="bi bi-trash3"></i>
        </button>
        <img
            class="image-uploader__preview--image"
            :src="filePreview"
            alt="Превью"
        />
    </div>
    <div
        v-else
        class="image-uploader"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleDrop"
        @click="openFilePicker"
    >
        <div v-if="!file" class="image-uploader__description">
            <i class="ri-image-add-line text-primary fs-1"></i>
            <div class="image-uploader__title">
                <span class="text-primary">Завантажте</span>
                <span v-if="customTitle"> {{ customTitle }}</span>
                <span v-else> або перетягніть зображення </span>
            </div>
            <div v-if="!noSubTitle" class="image-uploader__subtitle">
                {{
                    !!customSubTitle
                        ? customSubTitle
                        : "Рекомендований формат 16:9, підтримуються JPEG або PNG"
                }}
            </div>
        </div>
        <div v-else class="image-uploader__description">
            <i class="bi bi-paperclip text-primary fs-1"></i>
            {{ file.name }}
            <div class="image-uploader__subtitle">
                Натисніть повторно, щоб вибрати інший файл.
            </div>
        </div>
        <input
            ref="fileInput"
            type="file"
            :disabled="disabled"
            style="display: none"
            @change="handleFileChange"
        />
    </div>
</template>
<style lang="scss" scoped>
.image-uploader__preview {
    position: relative;
    width: fit-content;
}

.image-uploader__preview--image {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
}

.image-uploader__preview--delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--stroke, #ebebeb);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    i {
        font-size: 18px;
        color: var(--dark, #1e1e1e);
    }

    &:hover {
        background: var(--dark, #1e1e1e);

        i {
            color: var(--stroke, #ebebeb);
        }
    }
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}
</style>
