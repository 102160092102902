<script setup>
import {Head, usePage} from '@inertiajs/vue3';

import PageHeader from "@/Components/PageHeader.vue";

import "flatpickr/dist/flatpickr.css";
import {useI18n} from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import StudentCourseModule from "@/Pages/StudentCourses/Partials/StudentCourseModule.vue";
import {computed} from "vue";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";

const props = defineProps({
  modules: Object,
  course: Object,
  is_preview: Boolean
})

const pageData = usePage()

const {t} = useI18n();

const page = {
  title: props.course.title,
  breadcrumbs: [
    {
      text: "Всі курси",
        href: route('student.student_courses'),
    },
    {
      text: props.course.title,
      active: true,
    },
  ],
}

const avatar = computed(() => pageData?.props?.course?.mentor_image ? pageData.props.course.mentor_image : noAvatar)

</script>

<template>
  <div>
    <Head :title="page.title"/>

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs"/>

    <PageContentCard :title="page.title">
      <template #header-icons>
        <div class="hstack align-items-center gap-2 mt-2">
          <ul class="list-unstyled d-flex align-items-center gap-2 flex-grow-1 position-relative mb-0 fs-5">
            <li class="d-flex align-items-center me-1">
              <i class="ph-list-fill align-baseline text-primary fs-4 me-1"></i>
              <span class="fs-6 opacity-50 me-1">Модулі:</span>
              <span class="fs-6">{{ course.moduleCount }}</span>
            </li>
            <li class="d-flex align-items-center me-1">
              <i class="ph-book-open align-baseline text-primary fs-4 me-1"></i>
              <span class="fs-6 opacity-50 me-1">Уроки:</span>
              <span class="fs-6">{{ course.lessonsCount }}</span>
            </li>
          </ul>
        </div>
      </template>
      <template #header-end>
        <div class="mentor-info">
          <b-avatar variant="light" :src="avatar" class="team__user--logo"></b-avatar>
          <div class="d-flex flex-column align-items-start">

            <div class="mentor-info__label">Куратор</div>
            <div class="mentor-info__name">{{ course.mentor ? course.mentor : course.owner }}</div>
          </div>
        </div>
      </template>
      <template #default>
        <div class="d-flex flex-column align-items-center">
          <div class="course-label">Програма курсу</div>
          <StudentCourseModule
              v-for="( module, index ) in modules"
              :key="module.id"
              :module="module"
              :index="index"
              :is_preview="is_preview"
          />
        </div>
      </template>
    </PageContentCard>
  </div>
</template>

<style lang="scss" scoped>
.mentor-info {
  display: flex;
  align-items: center;
  gap: 8px;

  .mentor-info__avatar {
    width: 46px;
    height: 46px;
    border-radius: 34px;
    margin-right: 8px;
  }

  .mentor-info__label {
    font-family: e-Ukraine;
    color: var(--dark, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    opacity: 0.8;
    margin-bottom: 4px;
  }

  .mentor-info__name {
    font-family: e-Ukraine;
    color: var(--dark, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.course-label {
  font-family: e-Ukraine;
  color: var(--dark, #1E1E1E);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
