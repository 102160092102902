import { defineStore } from "pinia";

export const useSupportChatStore = defineStore("supportChat", {
    state: () => {
        return {
            element: null,
            showIframe: false,
            observer: null,
        };
    },
    actions: {
        setShowIframe(value) {
            this.showIframe = value;
        },
        setElement(element) {
            if (!!this.element) {
                return this.element;
            }
            this.element = element;
            return this.element;
        },

        toggleSupportChat(shouldSendEvent = false) {
            if (!this.showIframe) {
                this.setShowIframe(true);
                if (this.element.classList.contains("d-none")) {
                    this.element.classList.remove("d-none");
                }
            }

            if (shouldSendEvent && this.element) {
                this.element.contentWindow.postMessage(
                    {
                        type: "KEEPINCRM_TOGGLE_MODAL",
                    },
                    "*"
                );
            }
        },
        onElementAdded(element) {
            if (!this.element) {
                this.setElement(element);
                this.element.classList.add("d-none");
                console.log("add d-none");
            }
        },
        setObserver() {
            console.log("support chat observer start");
            const existingElement = document.getElementById(
                "keepincrmChatIframe"
            );
            if (existingElement) {
                this.onElementAdded(existingElement);
                return;
            }

            if (!this.observer) {
                const targetNode = document.body;

                const config = { childList: true, subtree: true };

                this.observer = new MutationObserver((mutationsList) => {
                    for (let mutation of mutationsList) {
                        for (let node of mutation.addedNodes) {
                            if (node.id === "keepincrmChatIframe") {
                                this.onElementAdded(node);
                                this.observer.disconnect();
                                return;
                            }
                        }
                    }
                });

                this.observer.observe(targetNode, config);
            }
        },
    },
});
