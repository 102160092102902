<script setup>
import { router, useForm, usePage } from "@inertiajs/vue3";
import { computed, reactive, ref } from "vue";
import CourseModuleItem from "@/Pages/Courses/Partials/CourseModuleItem.vue";
import {
    COURSE_ITEM_TYPE_MODULE,
    LESSON_TYPE_WITH_TRANSLATION,
    LESSON_TYPE_WITHOUT_TRANSLATION,
} from "@/Pages/Courses/Partials/lessonTypes.js";
import AddCourseLessonModal from "@/Components/modals/AddCourseLessonModal.vue";
import AddCourseLessonWithTranslationModal from "@/Components/modals/AddCourseLessonWithTranslationModal.vue";
import AddCourseSimpleLesson from "@/Components/modals/AddCourseSimpleLesson.vue";
import InputGroup from "@/Components/InputGroup.vue";
import ExpandableComponent from "@/Components/ExpandableComponent.vue";

import addFile from "../../../../images/svg/lesson-icons/add-file.svg";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import testing from "../../../../images/svg/lesson-icons/testing.svg";
import matching from "../../../../images/svg/lesson-icons/matching.svg";
import trueFalse from "../../../../images/svg/lesson-icons/true-false.svg";
import soon from "../../../../images/svg/lesson-icons/soon.svg";
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";
import FileUploadModal from "@/Components/modals/FileUploadModal.vue";
import VideoLesson from "@/Pages/Courses/Partials/VideoLesson.vue";
import LessonFiles from "@/Pages/Courses/Partials/LessonFiles.vue";
import DateTimeSelector from "@/Components/form/DateTimeSelector.vue";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import InputModal from "@/Components/modals/InputModal.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import {useDateFormatter} from "@/composables/useDateFormatter.js";

const props = defineProps({
    form: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();
const { formatDate } = useDateFormatter();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const videoUploadModal = ref(false);
const fileUploadModal = ref(false);
const presentationUploadModal = ref(false);
const onSubmit = () => {
    form.post(route("course_edit"));
};
const onReset = () => {
    form.reset();
};
const createModule = (title) => {
    router.visit(
        route("courses.course_module_add", { courseId: props.form.course.id }),
        {
            method: "post",
            data: {
                title: title && title.trim().length > 0 ? title : "Модуль",
            },
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: (response) => {
                console.log("createModule", response);
                props.form.course.contentItems =
                    response.props.course.contentItems;
            },
        }
    );
};
const modals = reactive({
    addCourseLesson: { state: false, courseId: null, moduleId: null },
    addCourseLessonWithTranslation: {
        state: false,
        courseId: null,
        moduleId: null,
    },
    addCourseSimpleLesson: { state: false, courseId: null, moduleId: null },
});
const currentEditLesson = useForm({});
// const isFile = ref(false);
// const isVideo = ref(false);

const findLesson = (lessonId, moduleId) => {
    let foundItem = null;

    props.form.course.contentItems.some((item) => {
        if (item.type === "COURSE_ITEM_TYPE_MODULE") {
            const foundLesson = item.lessons.find(
                (item1) => item1.id === lessonId
            );
            if (foundLesson) {
                foundItem = foundLesson;
                return true;
            }
        } else {
            if (item.id === lessonId) {
                foundItem = item;
                return true;
            }
        }
        return false;
    });
    return foundItem;
};
const handleEditLesson = (lessonId, moduleId) => {
    console.log("handleEditLesson", findLesson(lessonId, moduleId));
    const lesson = findLesson(lessonId, moduleId);
    if (lesson) {
        Object.assign(currentEditLesson, lesson);
    }
    // currentEditLesson.value = findLesson(lessonId, moduleId);
    //  isFile.value = false;
    //  for (const item of currentEditLesson.tasks) {
    //    if (item.type === 'LESSON_TASK_FILE') {
    //      isFile.value = true;
    //    }
    //  }
    //  isVideo.value = false;
    //  for (const item of currentEditLesson.tasks) {
    //    if (item.type === 'LESSON_TASK_VIDEO') {
    //      isVideo.value = true;
    //    }
    //  }
};

const isFile = computed(() => {
    if (!currentEditLesson.tasks || currentEditLesson.tasks.length === 0) {
        return false;
    }
    return currentEditLesson.tasks.some(
        (item) => item.type === "LESSON_TASK_FILE"
    );
});

const isVideo = computed(() => {
    if (!currentEditLesson.tasks || currentEditLesson.tasks.length === 0) {
        return false;
    }
    return currentEditLesson.tasks.some(
        (item) => item.type === "LESSON_TASK_VIDEO"
    );
});

const handleAddLessonModal = (type, course_id, module_id) => {
    console.log("handleAddLessonModal", type, course_id, module_id);
    switch (type) {
        case LESSON_TYPE_WITH_TRANSLATION:
            modals.addCourseLessonWithTranslation.state = true;
            modals.addCourseLessonWithTranslation.courseId = course_id;
            modals.addCourseLessonWithTranslation.moduleId = module_id;
            break;
        case LESSON_TYPE_WITHOUT_TRANSLATION:
            modals.addCourseSimpleLesson.state = true;
            modals.addCourseSimpleLesson.courseId = course_id;
            modals.addCourseSimpleLesson.moduleId = module_id;
            break;
        default:
            modals.addCourseLesson.state = true;
            modals.addCourseLesson.courseId = course_id;
            modals.addCourseLesson.moduleId = module_id;
            break;
    }
};

const addPresentation = (type, course_id, module_id) => {
    presentationUploadModal;
};

const handleAddLesson = (data) => {
    console.log(data);
    router.visit(
        route("courses.course_lesson_add", { courseId: props.form.course.id }),
        {
            method: "post",
            data: { lesson: data },
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: (response) => {
                props.form.course.contentItems =
                    response.props.course.contentItems;
                modals.addCourseLessonWithTranslation.state = false;
                modals.addCourseLesson.state = false;
                modals.addCourseSimpleLesson.state = false;
            },
        }
    );
};
const handleDeleteModule = (id) => {
    console.log("handleDeleteModule", id);
    router.visit(route("courses.course_module_remove", { moduleId: id }), {
        method: "post",
        data: {},
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            props.form.course.contentItems = response.props.course.contentItems;
            clearCurrentLesson();
        },
    });
};
const handleDeleteModuleLesson = (lessonId, moduleId) => {
    console.log("handleDeleteModuleLesson", lessonId, moduleId);
};
const handleDeleteLesson = (id) => {
    console.log("handleDeleteLesson", id);
    router.visit(route("courses.course_lesson_remove", { lessonId: id }), {
        method: "post",
        data: {},
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            props.form.course.contentItems = response.props.course.contentItems;
            clearCurrentLesson();
        },
    });
};

const handleDeleteTask = (id) => {
    console.log("handleDeleteTask", id);
    router.visit(route("courses.task_remove", { taskId: id }), {
        method: "post",
        data: {},
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            props.form.course.contentItems = response.props.course.contentItems;
        },
    });
};
const moduleEdit = (id) => {
    const module = Object.assign({}, id);
    module.title =
        module.title && module.title.trim().length > 0
            ? module.title
            : "Модуль";
    console.log("moduleEdit", module);
    router.visit(route("courses.course_module_update"), {
        method: "post",
        data: { module: module },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            props.form.course.contentItems = response.props.course.contentItems;
        },
    });
};
const saveCurrent = (id) => {
    console.log("saveCurrent", id);
    v$.value.$touch();
    if (!v$.value.$invalid) {
        router.visit(route("courses.course_lesson_update"), {
            method: "post",
            data: { lesson: currentEditLesson },
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: (response) => {
                props.form.course.contentItems =
                    response.props.course.contentItems;
                clearCurrentLesson();
                console.log("saveCurrent");
            },
        });
    }
};

const clearCurrentLesson = () => {
    Object.keys(currentEditLesson).forEach((key) => {
        if (typeof currentEditLesson[key] !== "function") {
            currentEditLesson[key] = null;
        }
    });
};

const videoUploadComplete = (event) => {
    console.log("upload compleat", event);
    router.visit(route("courses.course_by_id", { id: props.form.course.id }), {
        method: "get",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
            props.form.course.contentItems = response.props.course.contentItems;
            const lesson = findLessonById(
                response.props.course.contentItems,
                currentEditLesson.id
            );
            if (lesson) {
                Object.assign(currentEditLesson, lesson);
            }
        },
    });
};

const fileUploadComplete = (event) => {
    console.log("upload compleat", event);
    router.visit(route("courses.course_by_id", { id: props.form.course.id }), {
        method: "get",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
            props.form.course.contentItems = response.props.course.contentItems;
            const lesson = findLessonById(
                response.props.course.contentItems,
                currentEditLesson.id
            );
            if (lesson) {
                Object.assign(currentEditLesson, lesson);
            }
        },
    });
};
const presentationUploadComplete = (event) => {
    console.log("upload compleat", event);
    router.visit(route("courses.course_by_id", { id: props.form.course.id }), {
        method: "get",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
            props.form.course.contentItems = response.props.course.contentItems;
            const lesson = findLessonById(
                response.props.course.contentItems,
                currentEditLesson.id
            );
            if (lesson) {
                Object.assign(currentEditLesson, lesson);
            }
        },
    });
};

const videoUploadCompleteCallBack = (event) => {
    console.log("videoUploadCompleteCallBack", event);
    router.visit(route("courses.course_by_id", { id: props.form.course.id }), {
        method: "get",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
            props.form.course.contentItems = response.props.course.contentItems;
        },
    });
};

const removeFile = (task) => {
    console.log("removeFile", task);
    router.visit(route("courses.remove_lesson_file"), {
        method: "post",
        data: {
            redirect: router.page.url,
            lessonTaskId: task.id,
        },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
            props.form.course.contentItems = reponse.props.course.contentItems;
            const index = currentEditLesson.tasks.findIndex(
                (file) => file.id === task.id
            );
            if (index !== -1) {
                currentEditLesson.tasks.splice(index, 1);
            }
        },
    });
};

const removePresentation = (presentation) => {
    console.log("removePresentation", presentation);
    router.visit(route("courses.remove_lesson_presentation"), {
        method: "post",
        data: {
            redirect: router.page.url,
            lessonId: currentEditLesson.id,
            presentationId: presentation.id,
        },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            props.form.course.contentItems = response.props.course.contentItems;
            const lesson = findLessonById(
                response.props.course.contentItems,
                currentEditLesson.id
            );
            if (lesson) {
                Object.assign(currentEditLesson, lesson);
            }
        },
    });
};

const findLessonById = (arr, id) => {
    for (const obj of arr) {
        if (obj.id === id) {
            return obj;
        }
        if (obj.lessons) {
            const found = findLessonById(obj.lessons, id);
            if (found) {
                return found;
            }
        }
        if (obj.tasks) {
            const found = findLessonById(obj.tasks, id);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    description: {
        maxLength: helpers.withMessage(
            "Не більше 300 символів",
            maxLength(300)
        ),
    },
};

const v$ = useVuelidate(rules, currentEditLesson, { $lazy: true });

const inputModal = ref(false);

const orderModule = (moduleId, order, position) => {
    console.log(moduleId);
    console.log(order);
    console.log(position);
    router.visit(route("courses.module_order"), {
        method: "post",
        data: { moduleId: moduleId, order: order, position: position },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log("createModule", response);
            props.form.course.contentItems = response.props.course.contentItems;
        },
    });
};
const orderLesson = (lessonId, order, position) => {
    console.log(lessonId);
    console.log(order);
    console.log(position);
    router.visit(route("courses.lesson_order"), {
        method: "post",
        data: { lessonId: lessonId, order: order, position: position },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log("createModule", response);
            props.form.course.contentItems = response.props.course.contentItems;
        },
    });
};

const removeModal = ref(false)

</script>

<template>
    <div>
        <b-row no-gutters>
            <b-col cols="5" class="pe-4">
                <!--          <pre>{{form.course.contentItems}}</pre>-->
                <div
                    v-if="
                        form.course.contentItems &&
                        form.course.contentItems.length > 0
                    "
                >
                    <template
                        v-for="item in form.course.contentItems"
                        :key="item._id"
                    >
                        <CourseModuleItem
                            v-if="item.type === COURSE_ITEM_TYPE_MODULE"
                            :module="item"
                            :key="item.id"
                            :editing="currentEditLesson"
                            :course-status="form.course.status"
                            @add-lesson="
                                (type) =>
                                    handleAddLessonModal(
                                        type.type,
                                        form.course.id,
                                        item.id
                                    )
                            "
                            @edit-lesson="
                                (lesson_id) =>
                                    handleEditLesson(lesson_id.id, item.id)
                            "
                            @delete-module="handleDeleteModule"
                            @delete-lesson="handleDeleteLesson"
                            @module-title-edit="moduleEdit(item)"
                            @module-up="orderModule"
                            @module-down="orderModule"
                            @lesson-up="orderLesson"
                            @lesson-down="orderLesson"
                        />
                    </template>
                </div>
                <div class="d-flex align-items-center">
                    <button
                        class="outline-dark btn btn-lg rounded-pill border-color-important"
                        pill
                        size="lg"
                        :disabled="isReadOnly"
                        data-bs-toggle="dropdown"
                    >
                        Створити урок
                        <i class="ph-plus-thin"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li>
                            <span
                                class="dropdown-item"
                                @click="
                                    () =>
                                        handleAddLessonModal(
                                            LESSON_TYPE_WITH_TRANSLATION,
                                            form.course.id,
                                            0
                                        )
                                "
                            >
                                Урок з прямим ефіром
                                <i
                        id="lesson-live"
                        class="bi bi-info-circle"
                    ></i>
                            </span>
                            <b-tooltip
                    class="text-left"
                    target="lesson-live"
                    custom-class="custom-tooltip"
                    offset="5"
                    triggers="hover"
                    :no-fade="true"
                    :delay="{ show: 50, hide: 50 }"
                >
                    <p class=" fw-bold text-center m-0 pb-2">
                        Урок з ефіром
                    </p>
                    Цей тип уроку дозволяє провести заняття з учнями у прямому ефірі.
                </b-tooltip>
                        </li>
                        <li>
                            <span
                                class="dropdown-item d-flex align-items-center"
                                @click="
                                    () =>
                                        handleAddLessonModal(
                                            LESSON_TYPE_WITHOUT_TRANSLATION,
                                            form.course.id,
                                            0
                                        )
                                "
                            >
                                Урок без прямого ефіру
                                <i
                        id="lesson-no-live"
                        class="bi bi-info-circle ps-1"
                    ></i>
                            </span>
                            <b-tooltip
                    class="text-left"
                    target="lesson-no-live"
                    custom-class="custom-tooltip"
                    offset="5"
                    triggers="hover"
                    :no-fade="true"
                    :delay="{ show: 50, hide: 50 }"
                >
                    <p class=" fw-bold text-center m-0 pb-2">
                        Урок із завданням
                    </p>
                    Цей тип уроку дозволяє студентам виконувати практичні роботи самостійно, без присутності викладача.
                </b-tooltip>
                        </li>
                    </ul>
                    <b-button
                        class="outline-dark ms-4"
                        pill
                        size="lg"
                        :disabled="isReadOnly"
                        @click="inputModal = true"
                    >
                        Створити модуль
                        <i class="ph-plus-thin"></i>
                    </b-button>
                    <i
                        id="tooltip-content"
                        class="bi bi-info-circle fs-4 ms-2"
                    ></i>
                    <b-tooltip
                        target="tooltip-content"
                        custom-class="custom-tooltip"
                        offset="5"
                    >
                        <div>Додайте новий розділ</div>
                        <ul>
                            <li>
                                Уроки — онлайн зустрічі,<br />
                                а також текстові та відео<br />
                                матеріали, які можна пройти<br />
                                за один раз. 
                            </li>
                            <li>
                                Модулі — набори з декількох<br />
                                послідовних уроків, що<br />
                                охоплюють об’ємну тему.
                            </li>
                        </ul>
                        <p>
                            *Плануйте структуру заздалегідь,<br />
                            адже уроки, створені поза<br />
                            модулями, не можна об’єднувати.
                        </p>
                    </b-tooltip>
                </div>
            </b-col>
            <b-col
                v-if="currentEditLesson && currentEditLesson.id"
                cols="7"
                class="course-content ps-4"
            >
                <div>
                    <ExpandableComponent
                        v-if="currentEditLesson && currentEditLesson.id"
                        :title="`Редагування уроку «${currentEditLesson.title}»`"
                    >
                        <b-card no-body class="shadow-none course-module">
                            <b-card-body class="d-flex flex-column">
                                <div class="card__title">
                                    Загальна Інформація
                                </div>
                                <InputGroup
                                    label="Назва уроку"
                                    v-model="currentEditLesson.title"
                                    size="lg"
                                    class="mt-3 w-100"
                                    :disabled="isReadOnly"
                                    :is-error="v$.title?.$error"
                                    :error-message="
                                        v$.title?.$errors[0]?.$message
                                    "
                                    @blur="v$.title.$touch()"
                                />
                                <InputGroup
                                    element="textarea"
                                    label="Опис уроку"
                                    v-model="currentEditLesson.description"
                                    :rows="5"
                                    :max-rows="10"
                                    :max-length="255"
                                    size="lg"
                                    class="mt-3 w-100"
                                    :disabled="isReadOnly"
                                    :is-error="v$.description.$error"
                                    :error-message="
                                        v$.description.$errors[0]?.$message
                                    "
                                    @blur="v$.description.$touch()"
                                />
                            </b-card-body>
                        </b-card>
                        <!--            <b-card no-body class="shadow-none course-module"-->
                        <!--                    v-if="currentEditLesson.type === 'LESSON_TYPE_WITHOUT_TRANSLATION'">-->
                        <!--              <b-card-body class="d-flex flex-column">-->
                        <!--                <div class="card__title">Налаштування</div>-->
                        <!--                <div class="d-flex justify-content-between gap-3 mt-3">-->
                        <!--                  <b-form-group class="w-50" label="Дата початку">-->
                        <!--                    <b-form-input type="date" v-model="currentEditLesson.date"></b-form-input>-->
                        <!--                  </b-form-group>-->
                        <!--                  <b-form-group class="w-50" label="Час початку">-->
                        <!--                    <b-form-input type="time" v-model="currentEditLesson.time"></b-form-input>-->
                        <!--                  </b-form-group>-->
                        <!--                </div>-->
                        <!--              </b-card-body>-->
                        <!--            </b-card>-->
                        <b-card
                            no-body
                            class="shadow-none course-module"
                            v-if="
                                currentEditLesson.type ===
                                'LESSON_TYPE_WITH_TRANSLATION'
                            "
                        >
                            <b-card-body class="d-flex flex-column">
                                <div class="card__title">
                                    Налаштування прямого ефіру
                                </div>
                                <div class="date-time-duration__group my-3">
                                    <InputGroup
                                        element="date"
                                        label="Дата початку"
                                        v-model="currentEditLesson.date"
                                        :disabled="isReadOnly"
                                        size="lg"
                                    />
                                    <InputGroup
                                        element="time"
                                        label="Час початку"
                                        v-model="currentEditLesson.date"
                                        :disabled="isReadOnly"
                                        size="lg"
                                    />
                                    <InputGroup
                                        element="duration"
                                        label="Тривалість"
                                        type="duration"
                                        v-model="currentEditLesson.duration"
                                        :disabled="isReadOnly"
                                        size="lg"
                                        class="w-25"
                                    />
                                </div>
                                <!--                <div class="d-flex mt-3 gap-3">-->
                                <!--                  <div>-->
                                <!--                    <b-form-checkbox  v-model="currentEditLesson.is_chat_write">-->
                                <!--                      Дозволити писати в чаті-->
                                <!--                    </b-form-checkbox>-->
                                <!--                    <b-form-checkbox  v-model="currentEditLesson.is_video_record">-->
                                <!--                      Зробити запис відео <i id="tooltip-record" class="bi bi-info-circle"></i>-->
                                <!--                    </b-form-checkbox>-->
                                <!--                    <b-tooltip target="tooltip-record" custom-class="custom-tooltip" offset="5">-->
                                <!--                      Трансляція буде збережена на<Br/> курсі. Студенти в будь-який час<Br/> зможуть переглянути її-->
                                <!--                      повторно в<Br/> режимі запису.-->
                                <!--                    </b-tooltip>-->
                                <!--                  </div>-->
                                <!--                  <div>-->
                                <!--                    <b-form-checkbox v-model="currentEditLesson.is_chat_record">-->
                                <!--                      Записувати чат-->
                                <!--                    </b-form-checkbox>-->
                                <!--                    <b-form-checkbox-->
                                <!--                                     v-model="currentEditLesson.is_save_to_auto_webinar">-->
                                <!--                      Зберегти запис до автовебінарів <i id="tooltip-save-record" class="bi bi-info-circle"></i>-->
                                <!--                    </b-form-checkbox>-->
                                <!--                    <b-tooltip target="tooltip-save-record" custom-class="custom-tooltip" offset="5">-->
                                <!--                      Трансляція буде збережена в<Br/> розділ <span class="text-primary">Cloud</span>. Ви зможете-->
                                <!--                      подавати<Br/> даний матеріал повторно, як урок,<Br/> що не прив’язаний до курсу-->
                                <!--                    </b-tooltip>-->
                                <!--                  </div>-->
                                <!--                </div>-->
                            </b-card-body>
                        </b-card>
                    </ExpandableComponent>
                    <ExpandableComponent
                        v-if="
                            Object.keys(currentEditLesson).length > 0 && isVideo
                        "
                        :title="t('t-video')"
                        class="mt-3"
                    >
                        <template
                            v-for="task of currentEditLesson.tasks"
                            :key="task.id"
                        >
                            <VideoLesson
                                v-if="task.type === 'LESSON_TASK_VIDEO'"
                                :model-value="task"
                                :disabled="isReadOnly"
                                @remove-lesson-file="removeFile"
                            ></VideoLesson>
                        </template>
                    </ExpandableComponent>
                    <ExpandableComponent
                        v-if="
                            Object.keys(currentEditLesson).length > 0 && isFile
                        "
                        :title="t('t-files')"
                        class="mt-3"
                    >
                        <template
                            v-for="task of currentEditLesson.tasks"
                            :key="task.id"
                        >
                            <LessonFiles
                                v-if="task.type === 'LESSON_TASK_FILE'"
                                :model-value="task"
                                :disabled="isReadOnly"
                                @remove-lesson-file="removeFile"
                            ></LessonFiles>
                        </template>
                    </ExpandableComponent>
                    <ExpandableComponent
                        v-if="
                            Object.keys(currentEditLesson).length > 0 && currentEditLesson.presentation
                        "
                        :title="t('t-presentation')"
                        class="mt-3"
                    >
                        <div class="lesson-files">
                            <div class="lesson-file">
                                <div class="lesson-file__title">{{ currentEditLesson.presentation.original_name }}</div>
                                <div class="lesson-file__props">{{ currentEditLesson.presentation.size }}</div>
                                <div class="lesson-file__props">{{ formatDate(currentEditLesson.presentation.created_at, 'dd.MM.yyyy') }}</div>
                                <button :disabled="isReadOnly" @click="removeModal = !removeModal">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <ConfirmModal
                                v-model="removeModal"
                                title="Видалити презентацію"
                                decline-label="Скасувати"
                                accept-label="Так, видалити"
                                @accept-btn="() => removePresentation(currentEditLesson.presentation)"
                                @decline-btn="() => console.log('decline')"
                            >
                                <template v-slot:description>
                                    Видаливши презентацію, її не можна буде повернути. Видалити презентацію?
                                </template>
                            </ConfirmModal>
                        </div>
                    </ExpandableComponent>
                    <div class="mt-3 card__title">
                        {{
                            currentEditLesson.type ===
                            "LESSON_TYPE_WITH_TRANSLATION"
                                ? "Додати презентацію до ефіру"
                                : "Додати матеріали до уроку"
                        }}
                    </div>
                    <!-- завдання -->
                    <div
                        v-if="
                            currentEditLesson.type ===
                            'LESSON_TYPE_WITH_TRANSLATION'
                        "
                        class="d-flex gap-3 mt-3"
                    >
                        <button
                            class="lesson-button lesson-button--red"
                            :disabled="isReadOnly"
                            @click="presentationUploadModal = true"
                        >
                            <img
                                :src="addFile"
                                title="Додати файл"
                                alt="Додати файл"
                            />
                            {{
                                currentEditLesson.presentation
                                    ? "Замінити презентацію"
                                    : "Додати презентацію"
                            }}
                        </button>
                    </div>
                    <div v-else class="d-flex gap-3 mt-3">
                        <button
                            class="lesson-button lesson-button--blue"
                            :disabled="isReadOnly"
                            @click="videoUploadModal = true"
                        >
                            <img
                                :src="addVideo"
                                title="Додати відео"
                                alt="Додати відео"
                            />
                            Додати відео
                        </button>
                        <button
                            class="lesson-button lesson-button--red"
                            :disabled="isReadOnly"
                            @click="fileUploadModal = true"
                        >
                            <img
                                :src="addFile"
                                title="Додати файл"
                                alt="Додати файл"
                            />
                            Додати файл
                        </button>
                        <button
                            class="lesson-button lesson-button--green"
                            disabled
                        >
                            <img
                                :src="soon"
                                title="Тестування"
                                alt="Тестування"
                            />
                            <!-- testing -->
                            скоро (Тестування)
                        </button>
                        <button
                            class="lesson-button lesson-button--dark-blue"
                            disabled
                        >
                            <img :src="soon" title="Matching" alt="Matching" />
                            <!-- matching -->
                            скоро (Matching)
                        </button>
                        <button
                            class="lesson-button lesson-button--yellow"
                            disabled
                        >
                            <img
                                :src="soon"
                                title="True \ False"
                                alt="True \ False"
                            />
                            <!-- trueFalse -->
                            скоро (True\False)
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-end gap-3">
                    <b-button
                        variant="link"
                        pill
                        class="mt-3 text-black"
                        @click="clearCurrentLesson"
                    >
                        Закрити
                    </b-button>
                    <b-button
                        variant="primary"
                        pill
                        class="mt-3"
                        :disabled="isReadOnly"
                        @click="saveCurrent"
                    >
                        Зберегти
                    </b-button>
                </div>
                <VideoUploadModal
                    v-if="
                        currentEditLesson &&
                        currentEditLesson.id &&
                        videoUploadModal
                    "
                    @uploadComplete="videoUploadComplete"
                    v-model="videoUploadModal"
                    :route-args="[
                        'courses.upload-lesson-video',
                        { lessonId: currentEditLesson.id },
                    ]"
                />

                <FileUploadModal
                    v-if="
                        currentEditLesson &&
                        currentEditLesson.id &&
                        fileUploadModal
                    "
                    v-model="fileUploadModal"
                    :fileType="'file'"
                    :route-args="[
                        'courses.upload-lesson-file',
                        { lessonId: currentEditLesson.id },
                    ]"
                    all-except-video
                    @uploadComplete="fileUploadComplete"
                />

                <FileUploadModal
                    v-if="
                        currentEditLesson &&
                        currentEditLesson.id &&
                        presentationUploadModal
                    "
                    v-model="presentationUploadModal"
                    :fileType="'file'"
                    :custom-file-extensions="['.pdf']"
                    :route-args="[
                        'courses.upload-lesson-presentation',
                        { lessonId: currentEditLesson.id },
                    ]"
                    @uploadComplete="presentationUploadComplete"
                />
            </b-col>
        </b-row>

        <AddCourseLessonModal
            v-if="modals.addCourseLesson.state"
            v-model="modals.addCourseLesson.state"
            title="Оберіть формат уроку"
            :addCourseLessonProps="modals.addCourseSimpleLesson"
            @add-lesson="handleAddLesson"
        />

        <AddCourseLessonWithTranslationModal
            v-if="modals.addCourseLessonWithTranslation.state"
            v-model="modals.addCourseLessonWithTranslation.state"
            title="Урок з прямим ефіром"
            :addCourseLessonProps="modals.addCourseLessonWithTranslation"
            @add-lesson="handleAddLesson"
        />

        <AddCourseSimpleLesson
            v-if="modals.addCourseSimpleLesson.state"
            v-model="modals.addCourseSimpleLesson.state"
            title="Урок без прямого ефіру"
            :addCourseLessonProps="modals.addCourseSimpleLesson"
            @add-lesson="handleAddLesson"
        />
        <InputModal
            v-if="inputModal"
            v-model="inputModal"
            modal-title="Введіть назву модуля"
            input-label="Назва"
            button-text="Створити"
            max-length="90"
            @send-input="(str) => createModule(str)"
        />
    </div>
</template>

<style lang="scss" scoped>
.coming_soon {
    opacity: 0.25;
    position: relative;

    &:before {
        content: "В розробці";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #1db954;
        font-size: 30px;
        text-shadow: 1px 1px black;
    }
}

.lesson-button {
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid rgba(167, 20, 218, 0.1);
    background: var(--white, #fff);
    cursor: pointer;
    font-family: e-Ukraine;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    text-align: center;
    max-width: 140px;

    &.lesson-button--red {
        color: #f24822;

        &:hover {
            border: 1px solid var(--purple, #f24822);
            background: rgba(142, 0, 254, 0.05);
        }
    }

    &.lesson-button--blue {
        color: #31a6d9;

        &:hover {
            border: 1px solid #31a6d9;
            background: rgba(142, 0, 254, 0.05);
        }
    }

    &.lesson-button--dark-blue {
        color: #2b7bc4;

        &:hover {
            border: 1px solid #2b7bc4;
            background: rgba(43, 123, 196, 0.05);
        }
    }

    &.lesson-button--green {
        color: #1db954;

        &:hover {
            border: 1px solid var(--green, #1db954);
            background: rgba(162, 241, 99, 0.05);
        }
    }

    &.lesson-button--yellow {
        color: #f1c963;

        &:hover {
            border: 1px solid #f1c963;
            background: rgba(241, 201, 99, 0.05);
        }
    }
    &:disabled {
        &.lesson-button--red,
        &.lesson-button--blue,
        &.lesson-button--dark-blue,
        &.lesson-button--green,
        &.lesson-button--yellow {
            color: #c2c2c2;
            cursor: not-allowed;
            img {
                opacity: 0.5;
            }
            &:hover {
                border: 1px solid rgba(167, 20, 218, 0.1);
                background: var(--white, #fff);
            }
        }
    }
}
.course-content {
    font-family: e-Ukraine;
}
.lesson-files {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 20px;

    .lesson-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 16px 0;
        border-bottom: 1px solid #EBEBEB;

        .lesson-file__title, .lesson-file__props {
            font-family: e-Ukraine;
            font-size: 13px;
            font-weight: 300;
            line-height: 16px;
        }

        .lesson-file__title {
            flex: 1;
            color: #1E1E1E;
        }

        .lesson-file__props, .remove-btn {
            color: #C2C2C2;
        }

        .remove-btn {
            font-size: 16px;
            cursor: pointer;
            &:hover {
                color: black;
            }
        }
    }
}

.border-color-important{
    border-color:  #1E1E1E !important;
    &:hover{
        border-color:#1DB954 !important;
    }
}
</style>
