const horMenu = [
    {
        id: "profile",
        label: "t-account",
        icon: "ri-account-circle-fill",
        link: "/student/profile",
    },
    {
        id: "learning",
        label: "Навчання",
        icon: "ri-book-mark-fill",
        link: "/learning",
        subItems: [
            {
                id: "courses",
                label: "Курси",
                icon: "ri-movie-line",
                link: "/student/courses",
                parentId: "learning",
            },
            {
                id: "workshops",
                label: "Воркшопи",
                icon: "ri-play-circle-line",
                link: "/student/workshops",
                parentId: "learning",
            },
            {
                id: "mentoring",
                label: "Менторство",
                icon: "ri-book-open-line",
                link: "/student/mentoring",
                parentId: "learning",
            },
        ],
    },
    {
        id: "schedule",
        label: "Розклад",
        icon: "ri-calendar-todo-fill",
        link: "/student/schedule",
    },
    {
        id: "chats",
        label: "Чати",
        icon: " ri-message-2-fill",
        link: "/chat",
    },
];

export default horMenu;
