<script setup>
import { ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    statuses: Object,
    team: Object,
    form: Object,
    certificates_type: Object,
    users: {
        type: Array,
        default: [],
    },
    students: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const workshopRoles = props.form?.mentorprogram?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles, workshopRoles, "MENTOR");

const addUserToProgram = (userId) => {
    console.log("userId:", userId);
    router.visit(route("mentoring.program.add-user"), {
        method: "post",
        data: { userId: userId, programId: props.form.mentorprogram.id },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
};

const addStudent = ref({});

function removeUser(userId) {
    router.visit(route("mentoring.program.remove-user"), {
        method: "post",
        data: { id: userId },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
}

const removeStudentModal = ref(false);
const tempStudent = ref(null);

function handleActionClick(user, id) {
    tempStudent.value = user;
    if (id === 2) {
        removeStudentModal.value = true;
    }
}
</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div class="module-table mentoring-program-users-list">
                <div class="module-table__header border-bottom pb-2">
                    <div
                        class="module-table__col module-table__col--email text-uppercase opacity-50"
                    >
                    Email
                    </div>
                    <div
                        class="module-table__col module-table__col--name text-uppercase opacity-50"
                    >
                        ім'я
                    </div>
                    <div
                        class="module-table__col module-table__col--phone text-uppercase opacity-50"
                    >
                        телефон
                    </div>

                    <div
                        class="module-table__col module-table__col--actions text-uppercase"
                    >
                        <b-button
                            variant="primary"
                            pill
                            v-b-modal.grantAccessModal1
                            v-if="students"
                            :disabled="isReadOnly"
                        >
                            <!-- && Object.keys(students).length > 0 -->
                            Додати студента
                        </b-button>
                        <b-modal
                            id="grantAccessModal1"
                            hide-footer
                            hide-header
                            centered
                            v-if="students"
                        >
                            <!-- && Object.keys(students).length > 0 -->
                            <div class="modal-body">
                                <div class="grand-modal-title">
                                    <i class="ph-warning"></i>
                                    <h4>Виберіть студента</h4>
                                </div>
                                <p class="grand-modal-desc">
                                    Доступ до курсу не можна буде скасувати.
                                </p>
                                <b-form-select
                                    v-if="
                                        students &&
                                        Object.keys(students).length > 0
                                    "
                                    v-model="addStudent"
                                    :placeholder="'Обрати студента'"
                                    :options="students"
                                    size="xl"
                                    ref="input"
                                    v-uid
                                />
                                <p v-else class="empty-student-list">
                                    Список студентів поки що порожній.
                                </p>
                                <div class="grand-modal-actions">
                                    <b-button
                                        class="outline-dark"
                                        pill
                                        v-b-modal.grantAccessModal1
                                    >
                                        Скасувати
                                    </b-button>
                                    <b-button
                                        @click="addUserToProgram(addStudent)"
                                        :disabled="
                                            !(
                                                students &&
                                                Object.keys(students).length > 0
                                            )
                                        "
                                        class="grand-modal-accept m-2"
                                        pill
                                        v-b-modal.grantAccessModal1
                                    >
                                        Надати доступ
                                    </b-button>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
                <div
                    v-for="user of users.data"
                    :key="user.id"
                    class="module-table__row border-bottom py-3"
                >
                    <div class="module-table__col module-table__col--email">
                        {{ user.email }}
                    </div>
                    <div class="module-table__col module-table__col--name">
                        {{ user.name }}
                    </div>
                    <div class="module-table__col module-table__col--phone">
                        {{ user.phone }}
                    </div>
                    <div class="module-table__col module-table__col--actions">
                        <div class="dropdown flex-shrink-0">
                            <b-button
                                variant="link"
                                size="lg"
                                class="btn-icon dropdown-menu__button"
                                role="button"
                                data-bs-toggle="dropdown"
                                pill
                                aria-expanded="false"
                                :disabled="isReadOnly"
                            >
                                <i class="bi bi-three-dots-vertical"></i>
                            </b-button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                    <div
                                        class="dropdown-item"
                                        @click="handleActionClick(user, 2)"
                                    >
                                        <i
                                            class="bi bi-trash3 align-baseline me-1"
                                        ></i>
                                        Видалити
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <ConfirmModal
                            v-model="removeStudentModal"
                            title="Видалення студента"
                            decline-label="Скасувати"
                            accept-label="Так, видалити"
                            @accept-btn="() => removeUser(user.id)"
                            @decline-btn="() => console.log('decline')"
                        >
                            <template v-slot:description>
                                Ви впевнені, що хочете видалити цього студента?
                            </template>
                        </ConfirmModal>
                    </div>
                </div>
            </div>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.mentoring-program-users-list {
    .module-table__col--email {
        display: flex;
        width: 20%;
    }

    .module-table__col--name {
        width: 20%;
    }

    .module-table__col--phone {
        width: 20%;
    }

    .module-table__col--actions {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        .btn-icon {
            i {
                font-size: 22px !important;
            }
            &:hover {
                i {
                    color: #000;
                }
            }
        }
    }

    @media only screen and (max-width: 1368px) {
        .module-table__col--email {
            width: 25%;
        }
        .module-table__col--name {
            width: 25%;
        }
        .module-table__col--phone {
            width: 25%;
        }
    }
}

.empty-student-list {
    font-family: e-Ukraine;
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin: 20px 0;
}
</style>
