import { defineStore } from "pinia";
import {computed, ref} from "vue";
import {useLocalStorage} from "@vueuse/core";

export const useMessagesNotificationsStore = defineStore(
    "messagesStore",
    () => {
        const messages = ref([]);
        const messagesCount = ref(0);
        const countUnread = computed(() => messagesCount.value);

        const toastsEnabled = useLocalStorage('messagesToastsEnabled', true);
        const soundsEnabled = useLocalStorage('messagesSoundsEnabled', true);

        const toggleToasts = (flag) => {
            toastsEnabled.value = flag !== undefined ? flag : !toastsEnabled.value;
        }

        const toggleSounds = (flag) => {
            soundsEnabled.value = flag !== undefined ? flag : !soundsEnabled.value;
        }

        const addMessage = (message) => {
            if (!messages.value.some(msg => msg.id === message.id)) {
                messages.value.push(message);
                messagesCount.value++;
            }
        }

        const setMessages = (messagesData) => {
            messages.value = messagesData
        }

        const removeMessage = (id) => {
            const index = messages.value.findIndex(
                (notification) => notification.id === id
            );
            if (index !== -1) {
                messages.value.splice(index, 1);
            }
        };

        const removeMessagesOfConversation = (conversationId) => {
            messages.value = messages.value.filter(
                (message) => message.conversationId !== conversationId
            );
        }

        const setMessagesCount = (count) => {
            messagesCount.value = count;
        }

        const incrementMessagesCount = () => {
            messagesCount.value++
        }

        return {
            messages,
            messagesCount,
            countUnread,
            toastsEnabled,
            soundsEnabled,
            addMessage,
            removeMessage,
            toggleToasts,
            toggleSounds,
            setMessagesCount,
            setMessages,
            incrementMessagesCount,
            removeMessagesOfConversation,
        };
    }
);
