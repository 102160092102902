<script setup>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Ukrainian } from 'flatpickr/dist/l10n/uk.js'

const props = defineProps({
  timeInterval: Object,
  index: Number
});

const emit = defineEmits([
  'removeByIndex'
]);

const removeIntervalByIndex = () => {
  emit('removeByIndex', props.index);
}

</script>

<template>
 <div class="time-interval-item">
<!--   <b-form-input type="time" v-model="timeInterval.from"></b-form-input>-->
<!--   <b-form-input type="time" v-model="timeInterval.to"></b-form-input>-->
     <div class="time-picker-wrapper">
         <flat-pickr
             v-model="timeInterval.from"
             :config="{
                  altFormat: 'H:i',
                  altInput: true,
                  dateFormat: 'H:i',
                  enableTime: true,
                  noCalendar: true,
                  time_24hr: true,
                  defaultHour: '8',
                  minuteIncrement: 1,
                  locale: Ukrainian,
            }"
             placeholder="Від"
             ref="input"
             class="form-control form-control-time"
         />
         <i class="ph-clock-bold time-picker-icon"></i>
     </div>
     <div class="time-picker-wrapper">
         <flat-pickr
             v-model="timeInterval.to"
             :config="{
                  altFormat: 'H:i',
                  altInput: true,
                  dateFormat: 'H:i',
                  enableTime: true,
                  noCalendar: true,
                  time_24hr: true,
                  defaultHour: '8',
                  minuteIncrement: 1,
                  locale: Ukrainian,
            }"
             placeholder="До"
             ref="input"
             class="form-control form-control-time"
         />
         <i class="ph-clock-bold time-picker-icon"></i>
     </div>
   <i class="bi bi-trash3 time-interval-item__remove-interval" @click="removeIntervalByIndex" />
 </div>
</template>
<style lang="scss" scoped>
    .time-picker-wrapper{
        position: relative;
        .time-picker-icon {
            top: 17px;
            left: 8px!important;
        }
    }
  .time-interval-item {
    display: flex;
    margin-bottom: 8px;
    gap: 16px;
    align-items: center;
    flex: 1;
    .time-interval-item__remove-interval {
      font-size: 18px;
      padding: 0;
      cursor: pointer;
    }
  }
</style>
