<script setup>
import { computed, ref } from "vue";
import broadcast from "../../../../images/svg/lesson-icons/broadcast.svg";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import testing from "../../../../images/svg/lesson-icons/testing.svg";
import addFile from "../../../../images/svg/lesson-icons/add-file.svg";
import trueFalse from "../../../../images/svg/lesson-icons/true-false.svg";
import matching from "../../../../images/svg/lesson-icons/matching.svg";
import simpleLesson from "../../../../images/svg/lesson-icons/book.svg";
import CourseLessonTaskItem from "@/Pages/Courses/Partials/CourseLessonTaskItem.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import WaitModal from "@/Components/modals/WaitModal.vue";
import { addMinutes, format, parse, parseISO } from "date-fns";
import { uk } from "date-fns/locale";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import { router } from "@inertiajs/vue3";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const props = defineProps({
    lesson: Object,
    active: Boolean,
    editing: Object,
    disabled: {
        type: Boolean,
        default: false,
    },
    courseStatus: String
});

const emit = defineEmits([
    "delete-lesson",
    "edit-lesson",
    "delete-task",
    "edit-task",
    "module-up",
    "module-down",
]);
const deleteLesson = (id) => {
    emit("delete-lesson", { id: props.lesson.id });
};
const deleteTask = (id) => {
    emit("delete-task", { id: id });
};
const editLesson = (id) => {
    isLessonsVisible.value = true;
    emit("edit-lesson", { id: id });
};

const isLessonsVisible = ref(false);

const icon = {
    LESSON_TYPE_WITH_TRANSLATION: broadcast,
    LESSON_TYPE_WITHOUT_TRANSLATION: simpleLesson,
    LESSON_TASK_VIDEO: addVideo,
    LESSON_TASK_TEST: testing,
    LESSON_TASK_FILE: addFile,
    LESSON_TASK_TRUE_FALSE: trueFalse,
    LESSON_TASK_MATCHING: matching,
};

// async function checkServer() {
//   try {
//     const response = await axios.get('ВАШ_УРЛ_СЕРВЕРА');
//     const data = response.data;
//
//     if (data.success === true) {
//       // Відкрити нову вкладку з отриманим URL
//       window.open(data.url, '_blank');
//     } else {
//       // Продовжувати надсилати запити кожні 5 секунд
//       setTimeout(checkServer, 5000);
//     }
//   } catch (error) {
//     console.error('Помилка при надсиланні запиту:', error);
//     // Продовжувати надсилати запити кожні 5 секунд у випадку помилки
//     setTimeout(checkServer, 5000);
//   }
// }

// Розпочати процес надсилання запитів
// checkServer();

const joinMentor = async () => {
    console.log("join mentor");
    // const response = await axios.post(route('joinMentor', {id: props.lesson.id}));
    return axios
        .post(route("joinMentor", { id: props.lesson.id }))
        .then((response) => {
            props.lesson.live_status = "running";
            return window.open(response.data.url, "_blank");
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
    // if (response.data.success === true) {
    //   window.open(response.data.url, '_blank');
    // }
};

const { formatDate } = useDateFormatter();

const editMode = computed(
    () =>
        props.editing &&
        props.editing.id &&
        props.editing.id === props.lesson.id
);

const stopLesson = (id) => {
    router.visit(route("stopLesson", { id: id }), {
        method: "post",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
            props.lesson.live_status = "finished";
        },
    });
};

/*
const lessonStatus = ref(false);

async function getStatus(start) {
    console.log('Check Status');
    try {
        waitModal.value = start;
        const response = await axios.post(route('student.joinStudent', {id: props.lesson.id}));
        const data = response.data;

        if (data.success === true) {
            // Відкрити нову вкладку з отриманим URL
            waitModal.value = false;
            return window.open(data.url, '_blank');
        } else {
            // Продовжувати надсилати запити кожні 5 секунд
            // setTimeout(joinStudent, 5000);
            checkStatus();
        }
    } catch (error) {
        console.error('Помилка при надсиланні запиту:', error);
        // Продовжувати надсилати запити кожні 5 секунд у випадку помилки
        // setTimeout(joinStudent, 5000);
        checkStatus();
    }
}

function checkStatus() {
    setTimeout(getStatus(true), 5000);
}

checkStatus();
 */
const moduleUp = () => {
    emit("module-up");
};
const moduleDown = () => {
    emit("module-down");
};

import { useDisableBroadcast } from "@/composables/useDateFormatter.js";
const { disableBroadcast } = useDisableBroadcast();

const stopLessonModal = ref(false);
</script>

<template>
    <b-card no-body class="shadow-none course-lesson">
        <!-- <b-card no-body class="shadow-none" :class="{'module-lesson--editing' : editMode }"> -->
        <b-card-body
            class="d-flex flex-column bg-light p-3 course-lesson__body"
        >
            <b-row
                no-gutters
                class="w-100 d-flex justify-content-end position-relative"
            >
                <b-col cols="12" class="d-flex flex-column gap-2">
                    <div
                        class="course-module__type d-flex align-items-center gap-2"
                        style="cursor: default"
                    >
                        Урок
                    </div>
                    <div
                        class="course-module__title d-flex align-items-center gap-2"
                    >
                        <span>{{ lesson.title }}</span>
                    </div>
                    <!--          <button v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" @click="joinMentor">Join to work</button>-->
                    <!--                    <div v-if="lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'" class="w-100 d-flex" >-->
                    <!--                        <button class="join-btn" @click="joinMentor">Приєднатись до уроку</button>-->
                    <!--                        <span>{{ lesson.live_status }}</span>-->
                    <!--                        <button class="join-btn"-->
                    <!--                                @click="stopLesson(lesson.id)">Завершити урок-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </b-col>
                <div class="single-lesson__controls">
                    <div
                        class="d-flex align-items-center justify-content-end w-100"
                    >
                        <!--                        <div class="course-module__label">Урок</div>-->
                        <div
                            v-if="
                                lesson.type === 'LESSON_TYPE_WITH_TRANSLATION'
                            "
                            class="w-100 d-flex justify-content-end"
                        >
                            <b-button
                                :id="'tooltip-stop_' + lesson.id"
                                v-if="lesson.live_status === 'running'"
                                variant="link"
                                class="btn-icon broadcast-icon"
                                :disabled="disabled"
                                @click="stopLessonModal = true"
                            >
                                <i class="ph-stop-fill"></i>
                                <!--Завершити урок-->
                            </b-button>
                            <b-tooltip
                                :target="'tooltip-stop_' + lesson.id"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Зупинити трансляцію.
                            </b-tooltip>
                            <b-button
                                :id="'tooltip-join_' + lesson.id"
                                variant="link"
                                class="btn-icon broadcast-icon"
                                :disabled="disableBroadcast(lesson) || courseStatus === 'DRAFT'"
                                @click="joinMentor"
                            >
                                live
                                <!--                                <i class="ph-broadcast-bold"></i>-->
                                <!--Приєднатись до уроку-->
                            </b-button>
                            <b-tooltip
                                :target="'tooltip-join_' + lesson.id"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Приєднатися до трансляції.
                            </b-tooltip>
                            <ConfirmModal
                                v-model="stopLessonModal"
                                title="Зупинити?"
                                decline-label="Скасувати"
                                accept-label="Так, зупинити"
                                @accept-btn="() => stopLesson(lesson.id)"
                                @decline-btn="() => console.log('decline')"
                            >
                                <template v-slot:description>
                                    Ви точно хочете зупинити пряму трансляцію?
                                </template>
                            </ConfirmModal>
                        </div>
                        <div class="dropdown flex-shrink-0">
                            <b-dropdown
                                size="lg"
                                :disabled="disabled"
                                variant="link"
                                toggle-class="text-decoration-none text-black module-lesson__control--dropdown"
                                no-caret
                            >
                                <template #button-content>
                                    <i class="bi bi-three-dots-vertical"></i>
                                </template>
                                <!-- <b-dropdown-text>Порядковість</b-dropdown-text> -->
                                <b-dropdown-item @click="moduleUp">
                                    <i class="bi bi-arrow-up"></i>
                                    Пересунути вгору
                                </b-dropdown-item>
                                <b-dropdown-item @click="moduleDown">
                                    <i class="bi bi-arrow-down"></i>
                                    Пересунути вниз
                                </b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item @click="() => deleteLesson()">
                                    <i
                                        class="bi bi-trash3 align-baseline me-1"
                                    ></i>
                                    Видалити
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div
                            class="single-lesson__control--edit"
                            @click="() => editLesson(lesson.id)"
                        >
                            <i
                                v-if="!disabled"
                                class="ph-note-pencil-bold cursor-pointer"
                            ></i>
                            <i
                                v-else
                                class="mdi mdi-file-eye-outline cursor-pointer control--view"
                            ></i>
                        </div>
                    </div>
                </div>
            </b-row>

            <b-row no-gutters class="w-100 d-flex mt-2">
                <b-col cols="12">
                    <div
                        class="course-module__lessons-title cursor-pointer"
                        @click="isLessonsVisible = !isLessonsVisible"
                    >
                        Деталі
                        <!-- Матеріали Завдання -->
                        <i v-if="isLessonsVisible" class="ph-caret-up-fill"></i>
                        <i v-else class="ph-caret-down-fill"></i>
                    </div>
                    <b-collapse v-model="isLessonsVisible" class="w-100 mt-2">
                        <div
                            class="course-module__lessons d-flex gap-2"
                            :class="{
                                'course-module__lessons--edit': editMode,
                            }"
                        >
                            <div>
                                <img :src="icon[lesson.type]" title="" alt="" />
                            </div>
                            <div class="d-flex flex-column gap-3 w-100">
                                <div v-if="lesson.description">
                                    <div class="module-lesson__label">
                                        Опис уроку
                                    </div>
                                    <div class="module-lesson__text">
                                        {{ lesson.description }}
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        lesson.type ===
                                        'LESSON_TYPE_WITH_TRANSLATION'
                                    "
                                    class="d-flex gap-3"
                                >
                                    <div>
                                        <div class="module-lesson__label">
                                            Дата
                                        </div>
                                        <div class="module-lesson__text">
                                            {{
                                                formatDate(
                                                    lesson.date,
                                                    "d MMMM yyyy"
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div v-if="lesson.duration">
                                        <div class="module-lesson__label">
                                            Тривалість
                                        </div>
                                        <div class="module-lesson__text">
                                            {{ lesson.duration }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module-lesson__label">
                                            Початок
                                        </div>
                                        <div class="module-lesson__text">
                                            {{
                                                formatDate(lesson.date, "HH:mm")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        lesson.tasks && lesson.tasks.length > 0
                                    "
                                    class="module-lesson__label"
                                >
                                    Матеріали
                                </div>
                                <CourseLessonTaskItem
                                    @delete-task="deleteTask(task.id)"
                                    v-for="task in lesson.tasks"
                                    :task="task"
                                ></CourseLessonTaskItem>
                                <!--                <div v-if="lesson.tasks && lesson.tasks.length > 0">-->
                                <!--                  <div v-for="task in lesson.tasks" class="lesson-task">-->
                                <!--                    <img :src="icon[task.type]" title="" alt="" />-->
                                <!--                    <div>-->
                                <!--                      <div class="lesson-task__lable">Завдання</div>-->
                                <!--                      <div class="lesson-task__title">{{ task.title }}</div>-->
                                <!--                    </div>-->
                                <!--                  </div>-->
                                <!--                </div>-->
                            </div>
                        </div>
                    </b-collapse>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<style scoped lang="scss">
.course-module__lessons {
    margin-top: 12px;
    background: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--stroke, #ebebeb);
    &.course-module__lessons--edit {
        border: 1px solid var(--green, #1db954);
    }
}

.module-lesson__label {
    font-family: e-Ukraine;
    color: var(--dark, #1e1e1e);
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%;
    opacity: 0.5;
}

.module-lesson__text {
    font-family: e-Ukraine;
    color: var(--dark, #1e1e1e);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
}

.course-lesson {
    border-radius: 8px;
    outline: 3px solid transparent;
    outline-offset: -3px;
    position: relative;
    &::before {
        content: "";
        width: 8px;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 8px 0 0 8px;
        background: var(--purple, #f24822);
    }
    &::after {
        content: "";
        width: 8px;
        height: 100%;
        position: absolute;
        left: 3px;
        border-radius: 8px 0 0 8px;
        background: #f7f7f8;
    }
    &:hover {
        outline: 3px solid var(--purple, #f24822);
        &::after,
        &::before {
            content: "";
            display: none;
        }
    }
    .course-module__type {
        color: var(--purple, #f24822);
    }
    .course-lesson__body {
        border-radius: 8px;
        .broadcast-icon {
            //  color: var(--purple, #F24822);
        }
        .btn-icon {
            // font-size: 24px;
            height: 24px;
            width: 24px;
        }
    }
}
.join-btn {
    color: var(--green, #1db954);
    font-family: e-Ukraine;
    font-weight: bold;
    margin: 8px;
}
</style>
