<script setup>
  const props = defineProps({
    buttons: Array,
    titleField: String
  })
  const emit = defineEmits([
    'onClick',
  ]);
</script>

<template>
  <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" menu-class="dropdown-buttons" no-caret>
    <template #button-content>
      <slot name="toggle-button"></slot>
    </template>
    <div v-if="!! titleField" class="d-flex justify-content-center py-1">
        <p class="m-0">
           {{  titleField }}
        </p>
    </div>
    <b-dropdown-item
      v-for="button in buttons"
      :href="button.link"
      link-class="dropdown-buttons-item"
      @click="() => emit('onClick', button.id)"
    >
      <i v-if="button.icon" :class="button.icon"></i>
      {{ button.label }}
      <i v-if="button.tooltip"  :id="button.id + '-tooltip'" class="bi bi-info-circle"></i>
      <b-tooltip v-if="button.tooltip" class="text-left" :target="button.id + '-tooltip'" custom-class="dropdown-tooltip" offset="5" triggers="hover">
        {{ button.tooltipText }}
      </b-tooltip>
    </b-dropdown-item>

  </b-dropdown>
</template>
<style lang="scss">
  .dropdown-buttons-toggle {
    background-color: black;
  }
  .dropdown-buttons {
    border-radius: 12px;
    border: 1px solid var(--stroke, #EBEBEB);
    background: var(--white, #FFF);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    .dropdown-buttons-item {
      padding: 14px 25px;
      background: var(--white, #FFF);
      color: var(--dark, #1E1E1E);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
    .dropdown-buttons-item:hover {
      background: #e7eef3;
    }
  }
  .dropdown-tooltip {
    border-radius: 16px;
    background: var(--dark, #1E1E1E);
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: fit-content;
    box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.50);
  }
</style>
